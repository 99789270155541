import React from "react";
import "../index.scss";

export default function Step04(props) {
  function validNext() {
    if (props.form.brasilseg === null) {
      return true;
    } /* else if (props.form.brasilseg && !props.form.brasilsed_01.length) {
      return true
    } */
    return false;
  }
  console.log(props.form.brasilseg)
  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        <span>{label}</span>
      </label>
    );
  };

  function checkChecked(value) {
    return props.form.brasilsed_01.includes(value);
  }

  function selectValueCheckbox(value) {
    const newValues = { ...props.form };
    if (!props.form.brasilsed_01.includes(value)) {
      newValues.brasilsed_01.push(value);
    } else {
      const index = newValues.brasilsed_01.indexOf(value);
      newValues.brasilsed_01.splice(index, 1);
    }
    props.setform(newValues);
  }

  return (
    <>
      <h2>Possui algum seguro com a BB Seguros?</h2>
      <form action="">
        <div className="form-group form-group-radio">
          <span>
            <input type="radio" id="sim" name="radio-group"
              value={props.form.brasilseg}
              checked={props.form.brasilseg === true}
              onChange={() => props.setform({ ...props.form, brasilseg: true })}
            />
            <label for="sim">Sim</label>
          </span>
          <span>
            <input type="radio" id="nao" name="radio-group"
              value={props.form.brasilseg}
              checked={props.form.brasilseg === false}
              onChange={() => props.setform({ ...props.form, brasilseg: false })}
            />
            <label for="nao">Não</label>
          </span>
        </div>
        {/* <h2>Se sim, assinale quais são os produtos:</h2> */}
        {/* {props.form.brasilseg &&
          <div className="form-group form-group-checkbox">
            <Checkbox
              label="Seguro Residencial (Planos Essencial, Prático Total)"
              value={checkChecked('Seguro Residencial (Planos Essencial, Prático Total)')}
              onChange={() => selectValueCheckbox('Seguro Residencial (Planos Essencial, Prático Total)')}
            />
            <Checkbox
              label="Seguro de Vida (Planos Leve, Pleno e Total)"
              value={checkChecked('Seguro de Vida (Planos Leve, Pleno e Total)')}
              onChange={() => selectValueCheckbox('Seguro de Vida (Planos Leve, Pleno e Total)')}
            />
            <Checkbox
              label="Seguro Empresarial (Planos Essencial, Prático e Total)"
              value={checkChecked('Seguro Empresarial (Planos Essencial, Prático e Total)')}
              onChange={() => selectValueCheckbox('Seguro Empresarial (Planos Essencial, Prático e Total)')}
            />
            <Checkbox
              label="Seguro Agrícola Flex; Ouro Vida Produtor Rural"
              value={checkChecked('Seguro Agrícola Flex; Ouro Vida Produtor Rural')}
              onChange={() => selectValueCheckbox('Seguro Agrícola Flex; Ouro Vida Produtor Rural')}
            />
            <Checkbox
              label="Seguro Patrimônio Rural"
              value={checkChecked('Seguro Patrimônio Rural')}
              onChange={() => selectValueCheckbox('Seguro Patrimônio Rural')}
            />
            <Checkbox
              label="Seguro Pecuário Faturamento"
              value={checkChecked('Seguro Pecuário Faturamento')}
              onChange={() => selectValueCheckbox('Seguro Pecuário Faturamento')}
            />
            <Checkbox
              label="Seguro Vida MP"
              value={checkChecked('Seguro Vida MP')}
              onChange={() => selectValueCheckbox('Seguro Vida MP')}
            />
          </div>
        } */}
        <div className="actions">
          <button onClick={() => props.setStep(3)} >Voltar</button>
          <button disabled={validNext()} onClick={() => props.setStep(6)} >Próximo</button>
        </div>
      </form>
    </>
  );
}
