import React from "react";
import "./index.scss";
import OwlCarousel from 'react-owl-carousel';
import ReactPlayer from 'react-player';
import AgrishowImage1 from '../../../assets/img/agrishow/01.jpg'
import AgrishowImage2 from '../../../assets/img/agrishow/02.jpg'
import AgrishowImage3 from '../../../assets/img/agrishow/03.jpg'

export default function PageAgrishow() {
  return (
    <div className="page-interna page-agrishow">
      <div className="content">
        <div className="left">
          <div>
            <h3>AGRISHOW 2023</h3>
            <p>Definida como uma Feira Internacional de Tecnologia Agrícola em Ação, a Agrishow 2023 será realizada em Ribeirão Preto, interior de São Paulo, entre os dias 1º e 5 de maio.< br />Em sua 28ª edição, a Feira contará com a participação de 800 marcas do Brasil e do mundo, apresentando as principais novidades em máquinas e equipamentos agrícolas, agricultura de precisão, irrigação, armazenagem, pecuária, sementes e outros.</p>
            <p>Uma das principais novidades deste ano é o Agrishow Pra Elas, uma série de bate-papos entre mulheres que atuam no agro, visando valorizar e estimular a atuação feminina no setor.</p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >

            <div class='item'>
              <iframe src="https://www.youtube.com/embed/ivW7Gq0p_OQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            {/* <div class='item'>
              <ReactPlayer
                url="../../../assets/img/agrishow/agrishow.MP4"
                controls
                width="100%"
                height="auto"
              />
            </div> */}
            <div class='item'>
              <img src={AgrishowImage1} />
            </div>
            <div class='item'>
              <img src={AgrishowImage2} />
            </div>
            <div class='item'>
              <img src={AgrishowImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}