import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { ReactComponent as Menu } from "../../assets/img/Menu.svg";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as CartoesBand } from "../../assets/img/bandeira-cartao.svg";
import "./index.scss";
import { useHistory } from "react-router-dom";

export default function HeaderDefault(props) {
  const globalContext = useGlobalContext();
  var query = window.location.search.slice(1);

  const [showMenu, setshowMenu] = useState(false);

  const history = useHistory();

  function goTo(link) {
    setshowMenu(false);
    history.push(link);
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setshowMenu(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <header className={props.classCss}>
      <div className="container">
        <div className="content">
          <figure className="menu">
            <Menu onClick={() => setshowMenu(!showMenu)} />
            {showMenu && (
              <div className="bgMenu" onClick={() => setshowMenu(false)}></div>
            )}
            <nav className={showMenu && "active"}>
              <ul>
                <li>
                  <a onClick={() => goTo("/")}>Home</a>
                </li>
                <li>
                  <a
                    href="https://www.bbseguros.com.br/seguros"
                    target="_blank"
                  >
                    BB Seguros
                  </a>
                </li>
                <li>
                  <a onClick={() => goTo("/regulamento")}>Regulamento</a>
                </li>
                {/* <li>
                  <a onClick={() => goTo("/expodireto")}>
                    Expodireto Cotrijal 2024
                  </a>
                </li> */}
                {/* <li>
                  <b>Próximos Eventos 2024</b>
                  <ul>
                    <li>
                      <a onClick={() => goTo("/eventos/expointer")}>
                        Expointer
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <b>Por onde passamos 2024</b>
                  <ul>
                    <li>
                      <a onClick={() => goTo("/showrural2024")}>
                        Show Rural Coopavel
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/expodireto")}>
                        Expodireto Cotrijal
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/tecnoshow2024")}>
                        Tecnoshow Comigo
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/agrishow2024")}>Agrishow</a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/eventos/bahiafarmshow")}>
                        Bahia Farm Show
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/eventos/agrobrasilia")}>
                        AgroBrasilia
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/eventos/expointer")}>
                        Expointer
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  <b>Por onde passamos 2023</b>
                  <ul>
                    <li>
                      <a onClick={() => goTo("/tecnoshow")}>Tecnoshow Comigo</a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/coopavel")}>
                        Show Rural Coopavel
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/cotrijal")}>
                        Expodireto Cotrijal
                      </a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/agrishow")}>Agrishow</a>
                    </li>
                    <li>
                      <a onClick={() => goTo("/expointer")}>Expointer</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </figure>

          <figure className="logo hidden_mobile">
            <CartoesBand onClick={() => goTo("/")} />
          </figure>
          <figure className="logo">
            <a href="https://www.bbseguros.com.br/" target="_blank">
              <Logo />
            </a>
          </figure>

          <div className="action">
            {/* <button onClick={() => globalContext.setShowRegister(true)}>
              Cadastre-se
            </button> */}
            <button
              type="button"
              style={{
                cursor: "not-allowed",
              }}
            >
              Encerrado
            </button>
          </div>
          {/* <span>Expointer 2023</span> */}
        </div>
      </div>
    </header>
  );
}
