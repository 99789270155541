import React from "react";
import "./index.scss";

export default function PageProximosEventos({ children, title }) {
  return (
    <div className="page-prox-event">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Proximos Eventos 2024</h2>
            <h3>{title}</h3>
            {children}
          </div>
        </div>
      </div>
      {/* <div className="content">
        <div className="left">
          <div>
            <h3>Agrishow</h3>
            <p>
              Um dos mais importantes eventos do setor, reconhecida como palco
              dos lançamentos das principais tendências e inovações para o
              agronegócio, além de, reunir soluções para todos os tipos de
              culturas e tamanhos de propriedades, e a BB Seguros marcará
              presença dentro do estande do Banco do Brasil, destacando nossos
              produtos e com a promoção “Colheita de Prêmios”, que neste ano
              sorteará uma picape Ram Rampage Laramie 0km.
            </p>

            <p>
              A Agrishow acontecerá entre os dias 29 de abril a 03 de maio de
              2024 em Ribeirão Preto - SP.
            </p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <div>
            <h3>AgroBrasília</h3>
            <p>
              Uma feira de tecnologia e negócios voltada para empreendedores
              rurais de diversos portes e segmentos e a BB Seguros não poderia
              deixar de participar, estávamos presentes no estande do Banco do
              Brasil, com nossos produtos e a promoção “Colheita de Prêmios”, da
              BB Seguros, que neste ano sorteará uma picape Ram Rampage Laramie
              0km.
            </p>

            <p>
              A feira AgroBrasília acontecerá entre os dias 21 a 25 maio de 2024
              em Brasília - DF
            </p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <div>
            <h3>Bahia Farm Show</h3>
            <p>
              Evento voltado à inovação que reúne soluções para todos os tipos
              de culturas e tamanhos de propriedades, e a BB Seguros marcará
              presença dentro do estande do Banco do Brasil reforçando nossos
              produtos e a promoção “Colheita de Prêmios”, que neste ano
              sorteará uma picape Ram Rampage Laramie 0km.
            </p>

            <p>
              A Bahia farm Show ocorreu entre os dias 11 a 15 de junho de 2024
              em Luis Eduardo Magalhães
            </p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <div>
            <h3>Expointer</h3>
            <p>
              É uma feira agropecuária de destaque nacional e internacional,
              considerada a maior feira de exposição de animais da América
              Latina. Mais uma vez, a BB Seguros estará presente dentro do
              estande do Banco do Brasil levando informações de nossos produtos
              e a promoção “Colheita de Prêmios”, que neste ano sorteará uma
              picape Ram Rampage Laramie 0km.
            </p>

            <p>
              A Expointer acontecerá entre os dias 29 de agosto a 01 de setembro
              de 2024 em Porto Alegre, capital do Rio Grande do Sul.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
