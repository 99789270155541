import React from "react";
import "./index.scss";
import OuroCardImage from "../../assets/img/ourocard.png";
import OwlCarousel from "react-owl-carousel";
import ImageCotrijal1 from "../../assets/img/cotrijal2024/ExpodiretoCotrijal1.jpg";
import ImageCotrijal2 from "../../assets/img/cotrijal2024/ExpodiretoCotrijal2.jpg";

export default function PageOndePassaremos() {
  return (
    <div className="page-interna page-showRural">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Onde Estamos</h2>
            <h3>EXPODIRETO COTRIJAL 2024</h3>
            <p>
              Uma feira focada em tecnologia e negócios rurais, contribui de
              forma decisiva para o desenvolvimento do setor, aproximando o
              produtor rural do conhecimento disponível no mercado. Foi
              realizada em Não Me Toque no Rio Grande do Sul, entre os dias 04 e
              08 de abril de 2024. A BB Seguros marcou presença dentro do
              estande do Banco do Brasil trazendo a promoção “Colheita de
              Prêmios”, que neste ano sorteará uma picape Ram Rampage Laramie 0
              km.
            </p>

            <p>
              Lembrando que essa edição da promoção “Colheita de Prêmios” tem
              duração mais longo, começou 05/02 e vai até 01/09/2024.
            </p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className="owl-theme" margin={50} nav options items={1}>
            <div class="item">
              <img src={ImageCotrijal1} />
            </div>
            <div class="item">
              <img src={ImageCotrijal2} />
            </div>
          </OwlCarousel>
        </div>
      </div>

      {/* <div className="bullets_cards">
        <div className="items">
          <div className="item">
            <div className="content">
              <p>
                Participante
                <b> não</b> cliente Banco do Brasil:
              </p>
            </div>
            <div className="cupons">01 cupom</div>
          </div>

          <div className="item">
            <div className="content">
              <p>
                Participante
                <b> cliente </b>
                Banco do Brasil:
              </p>
            </div>
            <div className="cupons">04 cupons</div>
          </div>

          <div className="item">
            <div className="content">
              <p>Participante cliente BB Seguros:</p>
            </div>
            <div className="cupons">10 cupons</div>
          </div>

          <div className="item item-invert">
            <div className="content">
              <p>
                Contratação dos produtos <b>descritos no regulamento</b> ganham
                no período de 19/04 à 05/05:
              </p>
            </div>
            <div className="cupons">30 cupons</div>
          </div>

          <div className="item item-invert">
            <div className="content">
              <p>
                <b>Cliente Superprotegido, </b> ao se cadastrar na promoção,
                ganha
              </p>
            </div>
            <div className="cupons">20 cupons</div>
          </div>

          <div className=" Iteminvible">
            <div className="content">
              <p>
                Participante que contratar seguro{" "}
                <b>mencionado no regulamento</b>
                no período de 19/04 à 05/05:
              </p>
            </div>
            <div className="cupons">+30 cupons</div>
          </div>

          <div className="item item-clean">
            <div className="ourocard">
              <div className="content">
                <p>
                  Contratações dos seguros
                  no período de 19/04 à 05/05
                  <b> descritos no regulamento</b> e pagos com Ourocard, na
                  função crédito, ganham
                </p>
              </div>
              <div className="cupons">+30 cupons</div>
            </div>
            <img
              src={OuroCardImage}
              className="ourocard-img"
              alt="Mãos segurando cartões do Banco do Brasil Ourocard"
            />
          </div>

          <div className="item Iteminvible">
            <div className="content">
              <p>
                Participante que contratar seguro{" "}
                <b>mencionado no regulamento</b>
                no período de 19/04 à 05/05:
              </p>
            </div>
            <div className="cupons">+30 cupons</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
