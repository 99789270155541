import React from "react";
import Regulamento from '../../assets/regulamento_bb_2024.pdf'
import "./index.scss";
export default function PageRegulamento() {
  return (
    <div className="page-interna page-regulamento">
      <div className="content">
        <div className="center">
          <iframe frameborder="1" className="pdf" src={Regulamento} ></iframe>
        </div>
      </div>
    </div>
  );
}
