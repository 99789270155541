import React, { useState } from "react";
import InputMask from 'react-input-mask';
import "../index.scss";

import Dados from "../../../utils/municipios.json"
import { Firestore } from "../../../utils/firebaseUtils";
import { toast } from "react-toastify";
import Loading from '../../../assets/img/loading.svg';
import { useGlobalContext } from "../../../context/GlobalContext";

export default function Step01(props) {
  const [loading, setloading] = useState(false);

  const globalContext = useGlobalContext();

  function validNext() {
    if (
      props.form.nome === '' ||
      props.form.cpf === '' ||
      props.form.telefone === '' ||
      props.form.email === '' ||
      props.form.telefone.replace(/[^0-9]/g, '').length !== 11 ||
      props.form.cidade === '' ||
      !props.form.aceiteContato ||
      !validaNascimento() ||
      !validacaoEmail()
    ) {
      return true;
    }
    return false;
  }

  function validaNascimento() {
    const nascimento = props.form.dataNascimento;
    const regexData = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    if (!regexData.test(nascimento)) {
      return false;
    }

    const [_, dia, mes, ano] = regexData.exec(nascimento);

    const diaInt = parseInt(dia, 10);
    const mesInt = parseInt(mes, 10);
    const anoInt = parseInt(ano, 10);

    if (anoInt >= 2023) {
      return false;
    }

    const data = new Date(anoInt, mesInt - 1, diaInt);
    if (
      data.getFullYear() !== anoInt ||
      data.getMonth() + 1 !== mesInt ||
      data.getDate() !== diaInt
    ) {
      return false;
    }

    return true;
  }


  function goTo() {

    if (!TestaCPF()) {
      toast.error('CPF Inválido');
      return false;
    }

    setloading(true);
    Firestore.collection(globalContext.dataEvent.bd).where('cpf', '==', props.form.cpf).get().then((response) => {
      if (response.size === 0) {
        props.setStep(2);
      } else {
        toast.error('Usuário já cadastrado!');
      }
      setloading(false);
    }).catch((error) => {
      toast.error('Erro ao consultar usuário');
      setloading(false);
    })
  }

  function validacaoEmail() {
    const usuario = props.form.email.substring(0, props.form.email.indexOf("@"));
    const dominio = props.form.email.substring(props.form.email.indexOf("@") + 1, props.form.email.length);

    if ((usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") === -1) &&
      (dominio.search("@") === -1) &&
      (usuario.search(" ") === -1) &&
      (dominio.search(" ") === -1) &&
      (dominio.search(".") !== -1) &&
      (dominio.indexOf(".") >= 1) &&
      (dominio.lastIndexOf(".") < dominio.length - 1)) {
      return true;
    }
    else {
      return false;
    }
  }

  function TestaCPF() {
    const strCPF = props.form.cpf.replace(/[^0-9]/g, '');
    if (strCPF === 11) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF === "00000000000") return false;

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto === 10) || (Resto === 11)) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    }
    return true;

  }

  function newTel(e) {
    if (props.form.cpf.replace(/[^0-9]/g, '').length === 11) {
      props.setform({ ...props.form, cpf: props.form.cpf.replace(/[^0-9]/g, '') + e.key })
    }
  }

  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label style={{ width: '100%' }}>
        <input type="checkbox" checked={value} onChange={onChange} />
        <span>{label}</span>
      </label>
    );
  };

  return (
    <>
      <h2>Preencha seus dados e participe!</h2>
      <div className="form-group">
        <input
          type="text"
          placeholder="Nome completo"
          value={props.form.nome}
          onChange={(value) => props.setform({ ...props.form, nome: value.target.value })}
        />
      </div>
      <div className="form-group">
        <InputMask
          type="text"
          mask={props.form.cpf.replace(/[^0-9]/g, '').length <= 11 ? '999.999.999-99' : '99.999.999/9999-99'}
          placeholder="CPF ou CNPJ"
          value={props.form.cpf}
          onChange={(value) => props.setform({ ...props.form, cpf: value.target.value })}
          onKeyPress={newTel}
        />
        <InputMask
          type="text"
          mask="(99) 9 9999-9999"
          placeholder="Telefone"
          value={props.form.telefone}
          onChange={(value) => props.setform({ ...props.form, telefone: value.target.value })}
        />
      </div>
      <div className="form-group">
        <InputMask
          type="text"
          mask="99/99/9999"
          placeholder="Data de Nascimento"
          value={props.form.dataNascimento}
          onChange={(value) => props.setform({ ...props.form, dataNascimento: value.target.value })}
        />
        <input
          type="text"
          placeholder="Cidade"
          value={props.form.cidade}
          onChange={(value) => props.setform({ ...props.form, cidade: value.target.value/* .replace(/\s/g, '') */ })}
        />
        {/* <select
          value={props.form.cidade}
          onChange={(value) => props.setform({ ...props.form, cidade: value.target.value })}>
          <option value="">Selecione sua cidade</option>
          <optgroup label={globalContext.dataEvent.label}>
            {Dados[globalContext.dataEvent.uf].map(e =>
              <option value={e.Nome}>{e.Nome}</option>
            )}
          </optgroup>
        </select> */}
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="E-mail"
          value={props.form.email}
          onChange={(value) => props.setform({ ...props.form, email: value.target.value.replace(/\s/g, '') })}
        />

      </div>
      <div className="form-group form-group-checkbox">
        <Checkbox
          label="Aceito receber contato do Banco do Brasil e suas coligadas"
          value={props.form.aceiteContato}
          onChange={(value) => props.setform({ ...props.form, aceiteContato: value.target.checked })}
        />
      </div>
      <div className="actions">
        <button disabled={true} >Voltar</button>
        <button disabled={validNext()} onClick={goTo} >Próximo</button>
      </div>
      {loading &&
        <div className="filterLoading-global">
          <div>
            <img src={Loading} className="loadingMask" />
          </div>
        </div>
      }
    </>
  );
}
