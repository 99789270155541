import React, { useState } from "react";
import "./index.scss";
import Step01 from "./steps/01";
import Step02 from "./steps/02";
import Step03 from "./steps/03";
import Step04 from "./steps/04";
import Step05 from "./steps/05";
import Step06 from "./steps/06";
import FinishSuccess from "./steps/parabens";
import MaskLoading from '../maskLoading/maskLoading';
import { toast } from "react-toastify";
import UserService from "../../services/user";
import { useGlobalContext } from "../../context/GlobalContext";
import PesquisaSatisfacao from "./steps/pesquisa";

export default function ModalRegister() {
  const [controllStep, SetcontrollStep] = useState(1)
  const [loading, setloading] = useState(false);
  var query = window.location.search.slice(1);

  const [form, Setform] = useState({
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    cidade: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    estado: '',
    correntista: null,
    agencia: '',
    conta: '',
    brasilseg: null,
    brasilsed_01: [],
    brasilsed_02: '',
    copavel: null,
    protocolo_copavel: '',
    seguro_copavel: null,
    protocolo_seguro_copavel: '',
    termos: null,
    promotor: 0,
    aceiteContato: true,
    dataNascimento: null,
    pesquisaSatisfacacao: [
      {
        enum: 'Em uma escala de 1 a 5, onde 1 é "muito insatisfeito" e 5 é "muito satisfeito", como você avalia os seguintes pontos do estande BB na Expointer:',
        options: [
          {
            pergunta: 'A estrutura',
            resposta: null
          },
          {
            pergunta: 'O atendimento',
            resposta: null
          },
          {
            pergunta: 'As atrações e programação',
            resposta: null
          }
        ]
      },
      {
        enum: 'Em uma escala de 1 a 5, onde 1 é "muito insatisfeito" e 5 é "muito satisfeito", como você avalia a experiência no estande BB como um todo?',
        options: [
          {
            pergunta: '',
            resposta: null
          },
        ]
      },
      {
        enum: 'Usando uma escala de 1 a 5, o quanto você concorda que o estande passou a mensagem "O Banco do Brasil me ajuda a organizar minha vida financeira"?',
        options: [
          {
            pergunta: '',
            resposta: null
          },
        ]
      },
      {
        enum: 'Se você já é cliente do Banco do Brasil, em uma escala de 1 a 5, onde 1 é "nem um pouco" e 5 é "muito", quanto a experiência no estande da Expointer deu vontade de ampliar seu relacionamento com o banco?',
        options: [
          {
            pergunta: '',
            resposta: null
          },
        ]
      },
      {
        enum: 'Se você não é cliente do Banco do Brasil, em uma escala de 1 a 5, onde 1 é "nem um pouco" e 5 é "muito", o quanto a experiência no estande da Expointer deu vontade de você se tornar cliente do banco?',
        options: [
          {
            pergunta: '',
            resposta: null
          },
        ]
      },
      {
        enum: 'Usando uma escala de 1 a 5, sendo que 1 significa ‘discordo totalmente’ e 5 ‘concordo totalmente’, o quanto você concorda que as ações e atividades realizadas pelo Banco no estande:',
        options: [
          {
            pergunta: 'São relevantes para você',
            resposta: null
          },
          {
            pergunta: 'Transmitem otimismo',
            resposta: null
          },
          {
            pergunta: 'São simples e inteligentes',
            resposta: null
          },
          {
            pergunta: 'Ajudam a conhecer as soluções e produtos do BB para atender as necessidades',
            resposta: null
          },
        ]
      },
      {
        enum: 'Usando uma escala de 1 a 5, sendo que 1 significa ‘discordo totalmente’ e 5 ‘concordo totalmente’, o quanto você concorda que o ambiente criado pelo Banco (estande e funcionários):',
        options: [
          {
            pergunta: 'Tem um espaço acolhedor e agradável',
            resposta: null
          },
          {
            pergunta: 'Promotores/palestrante/funcionários simpáticos e atenciosos',
            resposta: null
          }
        ]
      },
      {
        enum: 'Usando uma escala de 1 a 5, sendo que 1 significa ‘discordo totalmente’ e 5 ‘concordo totalmente’, o quanto você concorda que a participação do Banco neste evento demonstra:',
        options: [
          {
            pergunta: 'Que o Banco está conectado com o que está acontecendo na sociedade',
            resposta: null
          },
          {
            pergunta: 'Me incentiva na busca das minhas conquistas',
            resposta: null
          }
        ]
      },
      {
        enum: 'Usando uma escala de 0 a 5, sendo que 1 significa ‘discordo totalmente’ e 5 ‘concordo totalmente’, o quanto você concorda que a dinâmica da promoção demonstra que o BB:',
        options: [
          {
            pergunta: 'Transmite otimismo',
            resposta: null
          },
          {
            pergunta: 'Tem uma abordagem próxima e acolhedora',
            resposta: null
          },
          {
            pergunta: 'Entende suas necessidades',
            resposta: null
          },
          {
            pergunta: 'Está conectado com o que está acontecendo na sociedade',
            resposta: null
          },
          {
            pergunta: 'Incentiva na busca das suas conquistas',
            resposta: null
          }
        ]
      }
    ]
  });

  const globalContext = useGlobalContext();

  async function finish() {
    setloading(true);
    try {
      const payload = {
        ...form,
        telefone: form.telefone.replace(/[^0-9]/g, ''),
      }
      payload.bd = globalContext.dataEvent.bd;

      const sendMail = await UserService.registerUser({ data: payload });
      if (sendMail.status === 201) {
        // SetcontrollStep(7);
        // Setform({
        //   nome: '',
        //   cpf: '',
        //   telefone: '',
        //   email: '',
        //   cidade: '',
        //   cep: '',
        //   logradouro: '',
        //   numero: '',
        //   complemento: '',
        //   estado: '',
        //   correntista: null,
        //   agencia: '',
        //   conta: '',
        //   brasilseg: null,
        //   brasilsed_01: '',
        //   brasilsed_02: '',
        //   copavel: null,
        //   protocolo_copavel: '',
        //   seguro_copavel: null,
        //   protocolo_seguro_copavel: '',
        //   termos: null,
        //   promotor: 0,
        // });
        toast.success('Usuário registrado com sucesso!');
        if (query === 'promotor') {
          window.location.href = 'https://pt.surveymonkey.com/r/bb_expointer2024';
        } else {
          setloading(false);
          globalContext.setShowRegister(false)
        }
        //setloading(false);
        //globalContext.setShowRegister(false)

      } else {
        toast.error('Houve um erro ao tentar registrar-se, por favor, tente mais tarde.');
      }
    } catch (error) {
      setloading(false);
      toast.error('Houve um erro ao tentar registrar-se, por favor, tente mais tarde.');
    }
  }

  return (
    <div className="modalCadastrar">
      <div className="content">
        <button onClick={() => globalContext.setShowRegister(false)} className="close">
          X
        </button>
        <div className="conteudo">
          {
            controllStep === 1 &&
            <Step01
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
            />
          }
          {
            controllStep === 2 &&
            <Step02
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
            />
          }
          {
            controllStep === 3 &&
            <Step03
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
            />
          }
          {
            controllStep === 4 &&
            <Step04
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
            />
          }
          {
            controllStep === 5 &&
            <Step05
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
            />
          }
          {
            controllStep === 6 &&
            <Step06
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
              finish={() => finish()}
            />
          }
          {
            controllStep === 7 &&
            <FinishSuccess />
          }
          {
            controllStep === 8 &&
            <PesquisaSatisfacao
              form={form}
              setform={(event) => Setform(event)}
              setStep={(event) => SetcontrollStep(event)}
              finish={() => finish()}
            />
          }
        </div>
      </div>
      {loading && <MaskLoading />}
    </div>
  );
}
