import React from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import ModalRegister from "../modalRegister";
import "./index.scss";

export default function FooterDefault() {
  const globalContext = useGlobalContext();
  return (
    <>
      {/* <footer>
      </footer> */}
      {globalContext.showRegister && <ModalRegister />}
    </>
  );
}
