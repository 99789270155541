import React, { useState } from "react";
import "../index.scss";

export default function PesquisaSatisfacao(props) {
  const [controllPergunta, setControllPergunta] = useState(0);

  function validNext() {
    const valida = props.form.pesquisaSatisfacacao[controllPergunta].options.find(resp => resp.resposta === null)
    return !valida;
  }

  function controllNextPergunta(e) {
    e.preventDefault();
    const qtdPerguntas = props.form.pesquisaSatisfacacao.length;
    if((controllPergunta + 1) === qtdPerguntas) {
      props.finish();
    } else {
      setControllPergunta(controllPergunta + 1);
    }
  }

  function controllBackPergunta(e) {
    e.preventDefault();
    if(controllPergunta === 0) {
      props.setStep(6)
    } else {
      setControllPergunta(controllPergunta - 1);
    }
  }

  function preenchePesquisa(index, value) {
    const form = props.form.pesquisaSatisfacacao;
    form[controllPergunta].options[index].resposta = value;

    props.setform({ ...props.form, pesquisaSatisfacacao: form });
  }

  return (
    <>

      <h2>Pesquisa de satisfação</h2>
      <form>
        {/* {JSON.stringify(props.form.pesquisaSatisfacacao[controllPergunta])} */}
        <div className="form-group form-group-enunciado">
          <div className="enunciado">
            <p>{props.form.pesquisaSatisfacacao[controllPergunta].enum}</p>
          </div>

          <table className="table-pesquisa">
            <thead>
              <tr>
                <th></th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
              </tr>
            </thead>
            <tbody>
              {props.form.pesquisaSatisfacacao[controllPergunta].options.map((e, index) =>
                <tr>
                  <td>
                    {e.pergunta !== '' ?
                      <span>{e.pergunta}</span> :
                      ''
                    }
                  </td>
                  <td>
                    <span>
                      <input type="radio" id={index + controllPergunta + `1`}
                        name={`radio-group-${index+controllPergunta}`}
                        value="1"
                        checked={e.resposta === "1" ? 'checked': ''}
                        onChange={() => preenchePesquisa(index, "1")}
                      />
                      <label for={index + controllPergunta + `1`}>{' '}</label>
                    </span>
                  </td>
                  <td>
                    <span>
                      <input type="radio" id={index + controllPergunta + `2`}
                        name={`radio-group-${index+controllPergunta}`}
                        value="2"
                        checked={e.resposta === "2" ? 'checked': ''}
                        onChange={() => preenchePesquisa(index, "2")}
                      />
                      <label for={index + controllPergunta + `2`}>{' '}</label>
                    </span>
                  </td>
                  <td>
                    <span>
                      <input type="radio" id={index + controllPergunta + `3`}
                        name={`radio-group-${index+controllPergunta}`}
                        value="3"
                        checked={e.resposta === "3" ? 'checked': ''}
                        onChange={() => preenchePesquisa(index, "3")}
                      />
                      <label for={index + controllPergunta + `3`}>{' '}</label>
                    </span>
                  </td>
                  <td>
                    <span>
                      <input type="radio" id={index + controllPergunta + `4`}
                        name={`radio-group-${index+controllPergunta}`}
                        value="4"
                        checked={e.resposta === "4" ? 'checked': ''}
                        onChange={() => preenchePesquisa(index, "4")}
                      />
                      <label for={index + controllPergunta + `4`}>{' '}</label>
                    </span>
                  </td>
                  <td>
                    <span>
                      <input type="radio" id={index + controllPergunta + `5`}
                        name={`radio-group-${index+controllPergunta}`}
                        value="5"
                        checked={e.resposta === "5" ? 'checked': ''}
                        onChange={() => preenchePesquisa(index, "5")}
                      />
                      <label for={index + controllPergunta + `5`}>{' '}</label>
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="actions">
          <button onClick={controllBackPergunta} >Voltar</button>
          <button disabled={!validNext()} onClick={controllNextPergunta}>Próximo</button>
        </div>
      </form>
    </>
  );
}
