import React from "react";
import "./index.scss";
import OwlCarousel from 'react-owl-carousel';
import CoopavelImage1 from '../../../assets/img/coopavel/coopavel1.jpg'
import CoopavelImage2 from '../../../assets/img/coopavel/coopavel2.jpg'
import CoopavelImage3 from '../../../assets/img/coopavel/coopavel3.jpg'

export default function PageCoopavel() {
  return (
    <div className="page-interna page-coopavel">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Por onde passamos</h2>
            <h3>SHOW RURAL COOPAVEL 2023</h3>
            <p>Há 34 anos o Show Rural Coopavel é um dos maiores eventos do mundo em diversificação agrícola.</p>
            <p>Nele, grandes empresas e marcas dos principais segmentos do agronegócio se reúnem para difundir conhecimento, tecnologia e para oferecer oportunidades de negócio e crescimento para toda a cadeia.</p>
            <p>Idealizado e organizado pela Coopavel desde 1989, o Show Rural Coopavel de 2023, realizado no mês de fevereiro, recebeu cerca de 384 mil visitantes e o volume de vendas dos expositores alcançou os R$ 5 bilhões.</p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >
            <div class='item'>
              <iframe src="https://www.youtube.com/embed/uf0i6_Z6OUQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class='item'>
              <img src={CoopavelImage1} />
            </div>
            <div class='item'>
              <img src={CoopavelImage2} />
            </div>
            <div class='item'>
              <img src={CoopavelImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}