import api from "./api";

const UserService = {
  registerUser: async (params) => {
    try {
      const res = await api.post("/colheitadePremios", params);
      return res;
    } catch (error) {
      return error;
    }
  },
};

export default UserService;
