import React from "react";
import "../index.scss";

export default function Step03(props) {
  function validNext() {
    if (props.form.correntista === null) {
      return true;
    }
    return false;
  }

  return (
    <>
      <h2>Você é cliente BB?</h2>
      <form action="">
        <div className="form-group">
          <span>
            <input type="radio" id="sim"
              name="radio-group"
              value={props.form.correntista}
              checked={props.form.correntista === true}
              onChange={() => props.setform({ ...props.form, correntista: true })}
            />
            <label for="sim">Sim</label>
          </span>
          <span>
            <input type="radio" id="nao"
              name="radio-group"
              value={props.form.correntista}
              checked={props.form.correntista === false}
              onChange={() => props.setform({ ...props.form, correntista: false })}
            />
            <label for="nao">Não</label>
          </span>
        </div>
        {props.form.correntista &&
          <>
            <h2>Se sim,</h2>
            <div className="form-group">
              <input
                disabled={!props.form.correntista}
                type="text"
                placeholder="Agência sem o dígito"
                value={props.form.agencia}
                onChange={(value) => props.setform({ ...props.form, agencia: value.target.value })}
              />
              <input
                disabled={!props.form.correntista}
                type="text"
                placeholder="Conta  sem o dígito"
                value={props.form.conta}
                onChange={(value) => props.setform({ ...props.form, conta: value.target.value })}
              />
            </div>
          </>
        }
        <div className="actions">
          <button onClick={() => props.setStep(2)} >Voltar</button>
          <button disabled={validNext()} onClick={() => props.setStep(4)} >Próximo</button>
        </div>
      </form>
    </>
  );
}
