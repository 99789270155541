import React from "react";
import "./index.scss";
import OwlCarousel from "react-owl-carousel";
import TecnoshowImage1 from "../../assets/img/tecnoshow2024/img1.jpeg";
import TecnoshowImage2 from "../../assets/img/tecnoshow2024/img2.jpeg";
import TecnoshowImage3 from "../../assets/img/tecnoshow2024/img3.jpeg";

export default function PageAgrishow2024() {
  return (
    <div className="page-interna page-showRural">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">POR ONDE PASSAMOS 2024</h2>
            <h3>Agrishow 2024</h3>
            <p>
              Um dos mais importantes eventos do setor, reconhecida como palco
              dos lançamentos das principais tendências e inovações para o
              agronegócio, além de, reunir soluções para todos os tipos de
              culturas e tamanhos de propriedades, e a BB Seguros marcou
              presença dentro do estande do Banco do Brasil, destacando nossos
              produtos e com a promoção “Colheita de Prêmios”, que neste ano
              sorteará uma picape Ram Rampage Laramie 0km.
            </p>

            <p>
              A Agrishow aconteceu entre os dias 29 de abril a 03 de maio de
              2024 em Ribeirão Preto - SP.
            </p>
          </div>
        </div>
        {/*  <div className="right">
          <OwlCarousel className="owl-theme" margin={50} nav options items={1}>
            <div class="item">
              <img src={TecnoshowImage1} />
            </div>
            <div class="item">
              <img src={TecnoshowImage2} />
            </div>
            <div class="item">
              <img src={TecnoshowImage3} />
            </div>
          </OwlCarousel>
        </div> */}
      </div>
    </div>
  );
}
