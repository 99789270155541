import React from "react";
import OwlCarousel from 'react-owl-carousel';
import "./index.scss";
import CotrijalImage1 from '../../../assets/img/cotrijal/01.jpeg'
import CotrijalImage2 from '../../../assets/img/cotrijal/02.jpg'
import CotrijalImage3 from '../../../assets/img/cotrijal/03.jpg'

export default function PageCotrijal() {
  return (
    <div className="page-interna page-cotrijal">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Por onde passamos</h2>
            <h3>EXPODIRETO COTRIJAL 2023</h3>
            <p>A Expodireto Cotrijal é uma das maiores feiras do
              agronegócio internacional focada em tecnologia e negócios,
              e a cada edição contribui de forma decisiva para o
              desenvolvimento do setor.</p>

            <p>Realizada desde o ano 2000, a Expodireto Cotrijal atrai cerca
              de 250 mil pessoas nos 5 dias de evento, incluindo visitantes
              de mais de 70 países.</p>

            <p>A cada edição, os expositores apresentam as últimas
              novidades nas áreas de máquinas e equipamentos agrícolas,
              produção vegetal, produção animal, agricultura familiar,
              meio ambiente, pesquisa e serviços voltados ao campo.</p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >
            <div class='item'>
              <iframe src="https://www.youtube.com/embed/0oWgmJXbRpk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class='item'>
              <img src={CotrijalImage1} />
            </div>
            <div class='item'>
              <img src={CotrijalImage2} />
            </div>
            <div class='item'>
              <img src={CotrijalImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}