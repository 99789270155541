import React, { useEffect, useState } from "react";
import "../index.scss";
import Dados from "../../../utils/municipios.json"
import InputMask from 'react-input-mask';
import ViaCepService from "../../../services/viaCep";
import { toast } from "react-toastify";
import Loading from '../../../assets/img/loading.svg';
import { useGlobalContext } from "../../../context/GlobalContext";

export default function Step02(props) {
  const [loading, setLoading] = useState(false);

  const globalContext = useGlobalContext();

  useEffect(() => {
    if (props.form.cep.replace(/[^0-9]/g, '').length === 8) {
      getCep();
    }
  }, [props.form.cep]);

  async function getCep() {
    setLoading(true);
    let validRegiao = false;
    const result = await ViaCepService.getAddress(props.form.cep.replace(/[^0-9]/g, ''));
    if (result.status === 200) {
      validRegiao = true;
    } else {
      toast.error('Cep não encontrado');
      props.setform({
        ...props.form,
        cep: '',
        logradouro: '',
        numero: '',
        complemento: ''
      });
    }
    props.setform({
      ...props.form,
      logradouro: `${result.data.logradouro} - ${result.data.bairro}`,
      complemento: result.data.complemento,
      cidade: result.data.localidade,
      estado: result.data.uf,
      validRegiao
    });
    setLoading(false);
  }

  function validNext() {
    if (
      props.form.cep === '' ||
      props.form.cep.replace(/[^0-9]/g, '').length !== 8 ||
      props.form.logradouro === '' ||
      props.form.numero === ''
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <h2>Endereço</h2>
      {/* <small>*Promoção válida apenas para o estado do {globalContext.dataEvent.label}</small> */}
      <form action="">
        <div className="form-group">
          <InputMask
            type="text"
            placeholder="CEP"
            value={props.form.cep}
            mask="99.999-999"
            onChange={(value) => props.setform({ ...props.form, cep: value.target.value })}
          />
          <input
            type="text"
            placeholder="Logradouro"
            value={props.form.logradouro}
            onChange={(value) => props.setform({ ...props.form, logradouro: value.target.value })}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Número"
            value={props.form.numero}
            onChange={(value) => props.setform({ ...props.form, numero: value.target.value })}
          />
          <input
            type="text"
            placeholder="Complemento"
            value={props.form.complemento}
            onChange={(value) => props.setform({ ...props.form, complemento: value.target.value })}
          />
        </div>
        <div className="form-group">
          <select
            disabled
            value={props.form.cidade}
            onChange={(value) => props.setform({ ...props.form, cidade: value.target.value })}>
            <option value="">{props.form.cidade}</option>
          </select>
          <select
            disabled
            value={props.form.estado}
            onChange={(value) => props.setform({ ...props.form, estado: value.target.value })}>
            <option value="">{props.form.estado}</option>

          </select>
        </div>
        <div className="actions">
          <button onClick={() => props.setStep(1)} >Voltar</button>
          <button disabled={validNext()} onClick={() => props.setStep(3)} >Próximo</button>
        </div>
      </form>
      {loading &&
        <div className="filterLoading-global">
          <div>
            <img src={Loading} className="loadingMask" />
          </div>
        </div>
      }
    </>
  );
}
