import Axios from "axios";

const ViaCepService = {
  getAddress: async (cep) => {
    try {
      const res = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      return res;
    } catch (error) {
      return error;
    }
  },
};

export default ViaCepService;
