import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { auth, Firestore } from './utils/firebaseUtils';

import Routes from './routes/routes';
import history from './routes/history';
import './App.scss'

import { useGlobalContext } from './context/GlobalContext';
import MaskLoading from './components/maskLoading/maskLoading';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App() {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  async function onAuthStateChanged(user) {
    if (user && user.uid) {
      // Usuário logado
    } else if (initializing) {
      // Usuário não logado
    }
    setInitializing(false);
  }

  if (initializing) {
    return <MaskLoading />;
  }

  // if (query !== "promotor" && query !== "preview") {
  //   return <div></div>
  // }


  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;