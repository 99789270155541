import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../context/GlobalContext";
import { Firestore } from "../../utils/firebaseUtils";

import ExcelJS from "exceljs"; // Importe o exceljs
import moment from "moment";

import "./index.scss";

export default function AdminPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logado, setLogado] = useState(false);
  const [password, setpassword] = useState("");
  const [promotor, setPromotor] = useState("");
  const [optinIn, setOptinIn] = useState({ agrishow: [], tecnoshow: [] });
  const [dias, setDias] = useState([new Date()]);
  const [diaSelected, setDiaSelected] = useState("");
  const globalContext = useGlobalContext();

  useEffect(() => {
    const startDate = new Date("2024-02-04");
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date()) {
      dates.push(moment.utc(currentDate).format("YYYY-MM-DD"));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setDias(dates);
    setDiaSelected(moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (diaSelected) {
      getData();
    }
  }, [diaSelected]);

  function getData() {
    const date = new Date(diaSelected);
    setLoading(true);
    Firestore.collection(globalContext.dataEvent.bd)
      .where(
        "date",
        ">",
        new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      )
      .where(
        "date",
        "<=",
        new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2)
      )
      .orderBy("date")
      .get()
      .then((response) => {
        const dataResponse = [];
        response.docs.map((e) => {
          const myData = e.data();
          if (validarCPF(myData.cpf)) {
            const fireBaseTime = new Date(
              myData.date.seconds * 1000 + myData.date.nanoseconds / 1000000
            );
            dataResponse.push({
              id: e.id,
              nome: myData.nome,
              cpf: myData.cpf,
              email: myData.email,
              telefone: myData.telefone,
              cep: myData.cep,
              // eRS: verificaCEPnoRS(myData.cep) ? 'VERDADEIRO' : 'FALSO',
              termo: myData.termos ? "SIM" : "NÃO",
              contato: myData.aceiteContato ? "SIM" : "NÃO",
              endereco: `${myData.logradouro} - ${myData.numero} - ${myData.complemento} `,
              cidade: myData.cidade,
              estado: myData.estado,
              clienteBB: myData.correntista,
              conta: myData.conta,
              agencia: myData.agencia,
              clienteSeg: myData.brasilseg,
              seguros: myData.brasilsed_01,
              promotor: myData.promotor,
              data: fireBaseTime.toDateString(),
            });
          }
        });
        var reduced = [];

        dataResponse.forEach((item) => {
          var duplicated =
            reduced.findIndex((redItem) => {
              return item.cpf === redItem.cpf;
            }) > -1;

          if (!duplicated) {
            reduced.push(item);
          }
        });
        setData(reduced);
        setLoading(false);
      });
  }
  function getOptin(type = "agrishow") {
    Firestore.collection(type)
      .where("aceiteContato", "==", false)
      .get()
      .then((response) => {
        const dataResponse = [];
        response.docs.map((e) => {
          const myData = e.data();
          const fireBaseTime = new Date(
            myData.date?.seconds * 1000 + myData.date?.nanoseconds / 1000000
          );
          dataResponse.push({
            nome: myData.nome,
            cpf: myData.cpf,
            email: myData.email,
            telefone: myData.telefone,
            cep: myData.cep,
            eRS: verificaCEPnoRS(myData.cep) ? "VERDADEIRO" : "FALSO",
            termo: myData.termos ? "SIM" : "NÃO",
            contato: myData.aceiteContato ? "SIM" : "NÃO",
            endereco: `${myData.logradouro} - ${myData.numero} - ${myData.complemento} `,
            cidade: myData.cidade,
            estado: myData.estado,
            clienteBB: myData.correntista,
            conta: myData.conta,
            agencia: myData.agencia,
            clienteSeg: myData.brasilseg,
            seguros: myData.brasilsed_01,
            promotor: myData.promotor,
            data: fireBaseTime.toDateString(),
          });
        });
        var reduced = [];

        dataResponse.forEach((item) => {
          var duplicated =
            reduced.findIndex((redItem) => {
              return item.cpf === redItem.cpf;
            }) > -1;

          if (!duplicated) {
            reduced.push(item);
          }
        });
        const valor = type === "users" ? "tecnoshow" : type;
        updateOptinIn(valor, reduced);
        if (type === "agrishow") {
          getOptin("users");
        }
      });
  }
  const updateOptinIn = (type, reduced) => {
    setOptinIn((prevOptinIn) => ({ ...prevOptinIn, [type]: reduced }));
  };

  function debuggerLogs() {
    Firestore.collection("users")
      .get()
      .then((response) => {
        const dataResponse = [];
        response.docs.map((e) => {
          const myData = e.data();
          dataResponse.push({
            ...myData,
          });
        });
      });
  }

  function validarCPF(value) {
    const cpf = value ? value.replace(/[^\d]+/g, "").toString() : "";

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
      return false;
    }

    var soma = 0;
    for (var i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }

    var resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(9))) {
      return false;
    }

    soma = 0;
    for (var j = 0; j < 10; j++) {
      soma += parseInt(cpf.charAt(j)) * (11 - j);
    }

    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(10))) {
      return false;
    }
    return true;
  }

  function logar(e) {
    e.preventDefault();
    if (password === "725!4595" || password === "crudrusp2") {
      getData();
      //getOptin();
      // debuggerLogs();
      setLogado(true);
    } else {
      toast.error("Senha inválida");
    }
  }

  function getDias() {
    var reduced = [];
    data.forEach((item) => {
      var duplicated =
        reduced.findIndex((redItem) => {
          const itemDate = new Date(item.data);
          const redItemDate = new Date(redItem.data);

          return (
            `${itemDate.getDate()}/${itemDate.getMonth() + 1}` ===
            `${redItemDate.getDate()}/${redItemDate.getMonth() + 1}`
          );
        }) > -1;

      if (!duplicated) {
        reduced.push(item);
      }
    });
    return reduced;
  }

  function formatData(date) {
    const itemDate = new Date(date);
    return `${itemDate.getDate()}/${itemDate.getMonth() + 1}`;
  }

  function filtroPordata() {
    const returnData = [];

    //const selectDate = new Date(getDias()[itemActive].data);
    /* data.map(e => {
      const itemDate = new Date(e.data);
      if (`${itemDate.getDate()}/${(itemDate.getMonth() + 1)}` === `${selectDate.getDate()}/${(selectDate.getMonth() + 1)}`) {
        if (promotor === '') {
          returnData.push(e);
        } else if (e.promotor === promotor) {
          returnData.push(e);
        }
      }
    }); */
    return returnData;
  }

  function verificaCEPnoRS(cep = "") {
    cep = cep.replace(/\D/g, "");

    const faixasRS = [{ inicio: "90000000", fim: "99999999" }];
    for (const faixa of faixasRS) {
      if (cep >= faixa.inicio && cep <= faixa.fim) {
        return true;
      }
    }

    return false;
  }

  async function exportToExcelGeral() {
    const dataDownload = [];
    const resultsDownload = [];
    setLoading(true);

    for (let index = 0; index < dias.length; index += 5) {
      const dataInit = dias[index];
      const dataEnd = dias[Math.min(index + 4, dias.length - 1)];

      dataDownload.push({
        inicio: dataInit,
        final: dataEnd,
      });
    }
    const validDownload = true;
    for (let index = 0; index < dataDownload.length; index++) {
      const results = await getItensDownload(dataDownload[index]);
      if (results) {
        results.map((envia) => {
          resultsDownload.push(envia);
        });
      } else {
        validDownload = false;
        toast.error("Erro ao processar Download");
        break;
      }
    }
    if (validDownload) {
      exportToExcel(resultsDownload);
    }
    setLoading(false);
  }
  async function getItensDownload(itens) {
    const dateInit = new Date(itens.inicio);
    const dateEnd = new Date(itens.final);

    try {
      const response = await Firestore.collection(globalContext.dataEvent.bd)
        .where(
          "date",
          ">",
          new Date(
            dateInit.getFullYear(),
            dateInit.getMonth(),
            dateInit.getDate() + 1
          )
        )
        .where(
          "date",
          "<=",
          new Date(
            dateEnd.getFullYear(),
            dateEnd.getMonth(),
            dateEnd.getDate() + 2
          )
        )
        .orderBy("date")
        .get();

      const dataResponse = response.docs
        .map((e) => {
          const myData = e.data();
          if (validarCPF(myData.cpf)) {
            const fireBaseTime = new Date(
              myData.date.seconds * 1000 + myData.date.nanoseconds / 1000000
            );
            return {
              id: e.id,
              nome: myData.nome,
              cpf: myData.cpf,
              email: myData.email,
              telefone: myData.telefone,
              cep: myData.cep,
              termo: myData.termos ? "SIM" : "NÃO",
              contato: myData.aceiteContato ? "SIM" : "NÃO",
              endereco: `${myData.logradouro} - ${myData.numero} - ${myData.complemento}`,
              cidade: myData.cidade,
              estado: myData.estado,
              clienteBB: myData.correntista,
              conta: myData.conta,
              agencia: myData.agencia,
              clienteSeg: myData.brasilseg,
              seguros: myData.brasilsed_01,
              promotor: myData.promotor,
              data: fireBaseTime.toDateString(),
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      const reduced = dataResponse.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.cpf === item.cpf)
      );

      return reduced;
    } catch (error) {
      console.error("An error occurred:", error);
      return false; // Retorna false em caso de erro
    }
  }

  function exportToExcel(resultsDownload = false) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Registros");
    worksheet.columns = [
      { header: "ID", key: "id" },
      { header: "NOME", key: "nome" },
      { header: "CPF", key: "cpf" },
      { header: "E-MAIL", key: "email" },
      { header: "TELEFONE", key: "telefone" },
      { header: "CEP", key: "cep" },
      // { header: 'É RS?', key: 'eRS' },
      { header: "ENDEREÇO", key: "endereco" },
      { header: "CIDADE", key: "cidade" },
      { header: "Estado", key: "estado" },
      { header: "DATA", key: "data" },
      { header: "É CLIENTE BB?", key: "clienteBB" },
      { header: "CONTA", key: "conta" },
      { header: "AGENCIA", key: "agencia" },
      { header: "É CLIENTE BB SEG?", key: "clienteSeg" },
      /* { header: 'QUAIS SEGUROS', key: 'seguros' }, */
      { header: "PROMOTOR", key: "promotor" },
      { header: "ACEITA CONTATO", key: "contato" },
      { header: "ACEITA TERMO", key: "termo" },
    ];
    const dataInsert = resultsDownload ? resultsDownload : data;
    dataInsert.forEach((item) => {
      const formattedItem = {
        ...item,
        data: moment(item.data).format("DD/MM/YYYY"),
      };
      worksheet.addRow(formattedItem);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "registros.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  }

  function exportToExcelOptin(type = "") {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Registros");
    debugger;
    worksheet.columns = [
      { header: "NOME", key: "nome" },
      { header: "CPF", key: "cpf" },
      { header: "E-MAIL", key: "email" },
      { header: "TELEFONE", key: "telefone" },
      { header: "CEP", key: "cep" },
      // { header: 'É RS?', key: 'eRS' },
      { header: "ENDEREÇO", key: "endereco" },
      { header: "CIDADE", key: "cidade" },
      { header: "DATA", key: "data" },
      { header: "É CLIENTE BB?", key: "clienteBB" },
      { header: "CONTA", key: "conta" },
      { header: "AGENCIA", key: "agencia" },
      { header: "É CLIENTE BB SEG?", key: "clienteSeg" },
      /* { header: 'QUAIS SEGUROS', key: 'seguros' }, */
      { header: "PROMOTOR", key: "promotor" },
      { header: "ACEITA CONTATO", key: "contato" },
      { header: "ACEITA TERMO", key: "termo" },
    ];
    optinIn[type].forEach((item) => {
      worksheet.addRow(item);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "registros.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  }

  if (loading) {
    return <div>carregando...</div>;
  }

  return (
    <div className="main-admin">
      {!logado ? (
        <div className="login">
          <div className="content">
            <form onSubmit={logar}>
              <label htmlFor="senha">Senha</label>
              <input
                type="password"
                placeholder="Senha"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
              <button>Entrar</button>
            </form>
          </div>
        </div>
      ) : (
        <div className="registrados">
          <h2>Relatórios</h2>
          {loading ? (
            <div className="carregando">Carregando...</div>
          ) : (
            <div>
              <div className="downloads">
                <button onClick={() => exportToExcelGeral()}>
                  Download Excel - GERAL
                </button>
                {/* <button onClick={() => exportToExcelOptin('agrishow')}>Download Opt-in Tecnoshow</button>
                <button onClick={() => exportToExcelOptin('tecnoshow')}>Download Opt-in Agrishow</button> */}
              </div>
              <nav>
                <ul>
                  {dias.map((e) => (
                    <li
                      onClick={() => setDiaSelected(e)}
                      className={
                        e === moment(diaSelected).format("YYYY-MM-DD") &&
                        "active"
                      }
                    >
                      {moment.utc(e).format("DD/MM")}
                    </li>
                  ))}
                </ul>
              </nav>
              {/*  <div className="select">
                <label htmlFor="promotor">Promotor</label>
                <select
                  value={promotor}
                  onChange={(e) => setPromotor(e.target.value)}
                >
                  <option value="">Todos os promotores</option>
                  <option value={1}>Promotor 01</option>
                  <option value={2}>Promotor 02</option>
                  <option value={3}>Promotor 03</option>
                  <option value={4}>Promotor 04</option>
                  <option value={5}>Promotor 05;</option>
                  <option value={6}>Promotor 06</option>
                  <option value={7}>Promotor 07</option>
                  <option value={8}>Promotor 08</option>
                  <option value={9}>Promotor 09</option>
                  <option value={10}>Promotor 10</option>
                  <option value={11}>Promotor 11</option>
                  <option value={12}>Promotor 12</option>
                  <option value={13}>Promotor 13</option>
                  <option value={14}>Promotor 14</option>
                  <option value={15}>Promotor 15</option>
                  <option value={16}>Promotor 16</option>
                  <option value={17}>Promotor 17</option>
                  <option value={18}>Promotor 18</option>
                  <option value={19}>Promotor 19</option>
                  <option value={20}>Promotor 20</option>
                  <option value={21}>Promotor 21</option>
                  <option value={22}>Promotor 22</option>
                  <option value={23}>Promotor 23</option>
                  <option value={24}>Promotor 24</option>
                  <option value={25}>Promotor 25</option>
                  <option value={26}>Promotor 26</option>
                  <option value={27}>Promotor 27</option>
                  <option value={28}>Promotor 28</option>
                  <option value={29}>Promotor 29</option>
                  <option value={20}>Promotor 20</option>
                  <option value={30}>Promotor 30</option>
                  <option value={31}>Promotor 31</option>
                  <option value={32}>Promotor 32</option>
                  <option value={33}>Promotor 33</option>
                  <option value={34}>Promotor 34</option>
                  <option value={35}>Promotor 35</option>
                  <option value={36}>Promotor 36</option>
                  <option value={37}>Promotor 37</option>
                  <option value={38}>Promotor 38</option>
                  <option value={39}>Promotor 39</option>
                  <option value={40}>Promotor 40</option>
                </select>
              </div> */}
              <h2>
                Total de registros:{" "}
                {data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </h2>
              <button onClick={() => exportToExcel()}>Download Excel</button>
              <div className="tabela">
                <table>
                  <thead>
                    <tr>
                      <th>NOME</th>
                      <th>CPF</th>
                      <th>E-MAIL</th>
                      <th>TELEFONE</th>
                      <th>CEP</th>
                      {/*<th>É RS?</th>*/}
                      <th>ENDEREÇO</th>
                      <th>CIDADE</th>
                      <th>Estado</th>
                      <th>É CLIENTE BB?</th>
                      <th>CONTA</th>
                      <th>AGENCIA</th>
                      <th>Possui BB Seguros?</th>
                      {/* <th>QUAIS SEGUROS</th> */}
                      <th>PROMOTOR</th>
                      <th>Aceita Contato?</th>
                      <th>Aceita Termos?</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((e) => (
                      <tr>
                        <td>{e.nome}</td>
                        <td>{e.cpf}</td>
                        <td>{e.email}</td>
                        <td>{e.telefone}</td>
                        <td>{e.cep}</td>
                        {/* <td>{e.eRS}</td> */}
                        <td>{e.endereco}</td>
                        <td>{e.cidade}</td>
                        <td>{e.estado}</td>
                        <td>{e.clienteBB}</td>
                        <td>{e.conta}</td>
                        <td>{e.agencia}</td>
                        <td>{e.clienteSeg}</td>
                        {/* <td>{e.seguros}</td> */}
                        <td>{e.promotor}</td>
                        <td>{e.contato}</td>
                        <td>{e.termo}</td>
                        <td>{moment.utc(e.data).format("DD/MM/YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
