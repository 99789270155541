import React from "react";
import "./index.scss";
import OwlCarousel from 'react-owl-carousel';
import TecnoShowImage1 from '../../assets/img/tecnoshow/tecnoshow4.jpg'
import TecnoShowImage2 from '../../assets/img/tecnoshow/tecnoshow5.jpg'
import TecnoShowImage3 from '../../assets/img/tecnoshow/tecnoshow6.jpg'

export default function PageTecnoshow() {
  return (
    <div className="page-interna page-tecnoshow">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Por onde passamos</h2>
            <h3>TECNOSHOW COMIGO 2023</h3>
            <p>Trazendo sempre inovação e conhecimento, a Tecnoshow realiza em 2023 mais uma edição da Feira que conquistou o agronegócio brasileiro.</p>
            <p>Todos os anos, dezenas de expositores se reúnem para apresentar e lançar novidades em tecnologia, máquinas e equipamentos agropecuários, plots agrícolas, animais, educação ambiental e mais.</p>
            <p>Realizada no Centro Tecnológico COMIGO (CTC), localizado em Rio Verde, Goiás, a edição de 2022 contou com mais de 600 expositores, recebendo cerca de 128 mil pessoas durante os dias de evento.</p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >
            <div class='item'>
              <iframe src="https://www.youtube.com/embed/KWYgkUMbG2Q?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class='item'>
              <img src={TecnoShowImage1} />
            </div>
            <div class='item'>
              <img src={TecnoShowImage2} />
            </div>
            <div class='item'>
              <img src={TecnoShowImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}