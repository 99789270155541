import React, { useState } from "react";
import "../index.scss";

export default function Step06(props) {
  const [exibirFrame, setexibirFrame] = useState('regulamento');
  var query = window.location.search.slice(1);
  function validNext() {
    // if (props.form.seguro_copavel === null || props.form.termos === null) {
    //   return true;
    // } else if (props.form.seguro_copavel && props.form.protocolo_seguro_copavel === "") {
    //   return true
    // }
    if (props.form.termos === null || props.form.promotor == 0) {
      return true;
    }
    return false;
  }
  return (
    <>
      <h2>Regulamento</h2>
      {/* <div className="form-group">
        <span>
          <input type="radio" id="sim" name="radio-group"
            value={props.form.seguro_copavel}
            onChange={() => props.setform({ ...props.form, seguro_copavel: true })}
          />
          <label for="sim">Sim</label>
        </span>
        <span>
          <input type="radio" id="nao" name="radio-group"
            value={props.form.protocolo_seguro_copavel}
            onChange={() => props.setform({ ...props.form, seguro_copavel: false })}
          />
          <label for="nao">Não</label>
        </span>
      </div>
      <h2>Se sim,</h2>
      <div className="form-group">
        <input
          type="text"
          placeholder="Informe o protocolo do contrato"
          value={props.form.protocolo_seguro_copavel}
          disabled={!props.form.seguro_copavel}
          onChange={(value) => props.setform({ ...props.form, protocolo_seguro_copavel: value.target.value })}
        />
      </div> */}
      <div className="actions actions-regulamento">
        <button className={exibirFrame !== 'regulamento' && 'active'} onClick={() => setexibirFrame('regulamento')}>Regulamento</button>
        <button className={exibirFrame !== 'lgpd' && 'active'} onClick={() => setexibirFrame('lgpd')}>LGPD</button>
      </div>
      <div id="pdf">
        {exibirFrame === 'regulamento' ?
          <iframe frameborder="1" scrolling="auto" className="pdf" src='https://colheitadepremiosbbseguros.com.br/regulamento2024.pdf'></iframe>
          :
          <iframe frameborder="1" scrolling="auto" className="pdf" src='https://colheitadepremiosbbseguros.com.br/lgpd.pdf' ></iframe>
        }
      </div>
      {/* <p>De 1 a 20. Nós premiamos os promotores que mais gerarem cadastros durante o dia. No final, junto dessas infos do regulamento e LGPD, precisamos inserir uma guia "Qual o número do promotor?" para gerar um relatório de quantos lead cada promotor gerou durante o dia. Serão 15 promotores, mas pedimos de 1 a 20 em caso de mudança de número em algum momento da feira</p> */}
      {(query === 'promotor') &&
        <div className="form-group">
          <select
            value={props.form.promotor}
            onChange={(value) => props.setform({ ...props.form, promotor: value.target.value })}>
            <option value={0}>Selecione promotor</option>
            <option value={1}>Promotor 01</option>
            <option value={2}>Promotor 02</option>
            <option value={3}>Promotor 03</option>
            <option value={4}>Promotor 04</option>
            <option value={5}>Promotor 05;</option>
            <option value={6}>Promotor 06</option>
            <option value={7}>Promotor 07</option>
            <option value={8}>Promotor 08</option>
            <option value={9}>Promotor 09</option>
            <option value={10}>Promotor 10</option>
            <option value={11}>Promotor 11</option>
            <option value={12}>Promotor 12</option>
            <option value={13}>Promotor 13</option>
            <option value={14}>Promotor 14</option>
            <option value={15}>Promotor 15</option>
            <option value={16}>Promotor 16</option>
            <option value={17}>Promotor 17</option>
            <option value={18}>Promotor 18</option>
            <option value={19}>Promotor 19</option>
            <option value={20}>Promotor 20</option>
            <option value={21}>Promotor 21</option>
            <option value={22}>Promotor 22</option>
            <option value={23}>Promotor 23</option>
            <option value={24}>Promotor 24</option>
            <option value={25}>Promotor 25</option>
            {/* <option value={26}>Promotor 26</option>
            <option value={27}>Promotor 27</option>
            <option value={28}>Promotor 28</option>
            <option value={29}>Promotor 29</option>
            <option value={30}>Promotor 30</option>
            <option value={31}>Promotor 31</option>
            <option value={32}>Promotor 32</option>
            <option value={33}>Promotor 33</option>
            <option value={34}>Promotor 34</option>
            <option value={35}>Promotor 35</option>
            <option value={36}>Promotor 36</option>
            <option value={37}>Promotor 37</option>
            <option value={38}>Promotor 38</option>
            <option value={39}>Promotor 39</option>
            <option value={40}>Promotor 40</option> */}
          </select>
        </div>
      }
      <div className="form-group">
        <span>
          <input type="radio" id="radio-group-term" name="radio-group-term"
            value={props.form.termos}
            onChange={() => props.setform({ ...props.form, termos: true })}
          />
          <label for="radio-group-term">li e concordo com o regulamento e a política LGPD.</label>
        </span>
      </div>

      <div className="actions">
        <button onClick={() => props.setStep(4)} >Voltar</button>
        {(query === 'promotor') ?
          <button disabled={validNext()} onClick={() => props.finish()} >{/* Ir para pesquisa */}Finalizar Cadastro</button>
          :
          <button disabled={props.form.termos === null} onClick={() => props.finish()} >Finalizar Cadastro</button>
        }
      </div>
    </>
  );
}
