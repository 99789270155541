import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyC9EQy0LEZ9mISi7haNcYrAIw6rjZZN5rc",
  authDomain: "bb-colheita-premios.firebaseapp.com",
  projectId: "bb-colheita-premios",
  storageBucket: "bb-colheita-premios.appspot.com",
  messagingSenderId: "1073021518063",
  appId: "1:1073021518063:web:d119040987158551ee5b1a",
  measurementId: "G-W94YPZBY94"
};

firebase.initializeApp(firebaseConfig);
export const admin = firebase.admin;
export const auth = firebase.auth;
export const Firestore = firebase.firestore();
export const Storage = firebase.storage();
