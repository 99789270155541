import React from "react";
import "./index.scss";
import OwlCarousel from 'react-owl-carousel';
import ShowRuralImage from '../../assets/img/showRural2023/showRural2023.png'
import ShowRuralImage2 from '../../assets/img/showRural2023/showRural2023_2.png'

export default function PageShowRural2023() {
  return (
    <div className="page-interna page-tecnoshow">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">Por onde passamos 2024</h2>
            <h3>SHOW RURAL COOPAVEL</h3>
            <p>A feira que aconteceu entre os dia 05 e 09 de fevereiro, é um dos principais eventos do calendário do agronegócio brasileiro. É realizada no Paraná, estado com relevante participação na produção agrícola nacional, cujas principais culturas são trigo, milho, soja e café,  marcando a abertura do calendário das feiras agro no país.
            </p>
            <p>A BB Seguros esteve presente  mais uma vez no Show Rural Coopavel, no estande do Banco do Brasil, palco para o início da promoção “Colheita de Prêmios”, da BB Seguros, que neste ano sorteará uma picape Ram Rampage  Laramie 0 km.
            </p>

          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >
            <div class='item'>
              <img src={ShowRuralImage} />
            </div>
            <div class='item'>
              <img src={ShowRuralImage2} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}