import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";

// Pages
import HomePage from "../pages/home";
import PageNotFound from "../pages/notFound";

// Components
import HeaderDefault from "../components/header";
import FooterDefault from "../components/footer";
import PageRegulamento from "../pages/regulamento";
import PageTecnoshow from "../pages/tecnoshow";
import PageCoopavel from "../pages/porOndePassamos/coopavel";
import PageCotrijal from "../pages/porOndePassamos/cotrijal";
import PageAgrishow from "../pages/oQueVemPorAi/agrishow";
import PageExpointer from "../pages/oQueVemPorAi/expointer";
import AdminPage from "../pages/admin";
import CodeAdmin from "../pages/code";
import DistribuirPontos from "../pages/distribuir";
import ReceberNotificacao from "../pages/receberNotificacao";
import PageShowRural from "../pages/showRural";
import PageShowRural2023 from "../pages/showRural2023";
import PageOndePassaremos from "../pages/showRural";
import PageProximosEventos from "../pages/ProximosEventos";
import PageTecnoshow2024 from "../pages/Tecnoshow2024";
import PageAgrishow2024 from "../pages/Agrishow2024";

const CustomRoute = ({ isPrivate, pageBlue, ...rest }) => {
  const globalContext = useGlobalContext();

  if (isPrivate && !globalContext.user) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={pageBlue ? 'page-blue' : ''}>
      {!rest.hideHeader && <HeaderDefault classCss={rest.classCss} />}
      <Route {...rest} />
      <FooterDefault />
    </div>
  );
};

export default function Routes() {
  return (
    <Switch>
      {/* <CustomRoute exact path="/login" pageBlue component={Login} /> */}
      {/* Utilizar o atributo isPrivate para exigir usuario logado para continuar */}
      <CustomRoute exact path="/" classCss="inverter" component={HomePage} />
      <CustomRoute exact path="/regulamento" pageBlue component={PageRegulamento} />
      <CustomRoute exact path="/tecnoshow" pageBlue component={PageTecnoshow} />
      <CustomRoute exact path="/coopavel" pageBlue component={PageCoopavel} />
      <CustomRoute exact path="/cotrijal" pageBlue component={PageCotrijal} />
      <CustomRoute exact path="/agrishow" pageBlue component={PageAgrishow} />
      <CustomRoute exact path="/expointer" pageBlue component={PageExpointer} />

      <CustomRoute exact path="/showrural2024" pageBlue component={PageShowRural2023} />
      <CustomRoute exact path="/expodireto" pageBlue component={PageOndePassaremos} />
      <CustomRoute exact path="/tecnoshow2024" pageBlue component={PageTecnoshow2024} />
      <CustomRoute exact path="/agrishow2024" pageBlue component={PageAgrishow2024} />

      <CustomRoute exact path="/eventos/agrobrasilia" pageBlue>
        <PageProximosEventos title={"AgroBrasilia"}>
          <p>
            Uma feira de tecnologia e negócios voltada para empreendedores rurais de diversos portes e segmentos, e a BB Seguros não poderia deixar de participar. Estávamos presentes no estande do Banco do Brasil, com nossos produtos e a promoção “Colheita de Prêmios”, da BB Seguros, que neste ano sorteará uma picape Ram Rampage Laramie 0km.
          </p>
          <p>
            A feira AgroBrasília aconteceu entre os dias 21 a 25 maio de 2024 em Brasília - DF
          </p>
        </PageProximosEventos>
      </CustomRoute>
      <CustomRoute exact path="/eventos/expointer" pageBlue>
        <PageProximosEventos title={"Expointer"}>
          <p>
            É uma feira agropecuária de renome nacional e internacional, reconhecida como a maior exposição de animais da América Latina. Mais uma vez, a BB Seguros esteve presente no estande do Banco do Brasil, fornecendo informações sobre nossos produtos e a promoção “Colheita de Prêmios”, que este ano sorteará uma picape Ram Rampage Laramie 0km.
          </p>
          <p>
            A Expointer aconteceu entre os dias 29 de agosto e 01 de setembro de 2024, em Porto Alegre, capital do Rio Grande do Sul.
          </p>
        </PageProximosEventos>
      </CustomRoute>
      <CustomRoute exact path="/eventos/bahiafarmshow" pageBlue>
        <PageProximosEventos title={"Bahia Farm Show"}>
          <p>
            Um evento dedicado à inovação, que reúne soluções para uma ampla variedade de culturas e tamanhos de propriedades. Além de atrair produtores e empreendedores, o evento também é prestigiado por importantes líderes políticos e instituições de todo o país. A BB Seguros esteve presente no estande do Banco do Brasil, onde destacamos nossos produtos e a promoção “Colheita de Prêmios”. Este ano, a promoção sorteará uma picape Ram Rampage Laramie 0km.
          </p>
          <p>
            A Bahia Farm Show ocorreu entre os dias 11 e 15 de junho de 2024, em Luis Eduardo Magalhães.
          </p>
        </PageProximosEventos>
      </CustomRoute>

      <CustomRoute exact path="/admin" pageBlue component={AdminPage} />
      <CustomRoute exact path="/distribuir" pageBlue component={DistribuirPontos} />
      <CustomRoute exact path="/code" pageBlue component={CodeAdmin} />
      <CustomRoute exact path="/recebernotificacao/:email/:base" hideHeader pageBlue component={ReceberNotificacao} />
      <CustomRoute path="*" pageBlue component={PageNotFound} />
    </Switch>
  );
}
