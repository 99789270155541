import React from "react";
import "./index.scss";
import OwlCarousel from 'react-owl-carousel';
import ExpoInterImage1 from '../../../assets/img/expointer/expointer1.jpg'
import ExpoInterImage2 from '../../../assets/img/expointer/expointer2.jpg'
import ExpoInterImage3 from '../../../assets/img/expointer/expointer3.jpg'
import ReactPlayer from "react-player";

export default function PageExpointer() {
  return (
    <div className="page-interna page-expointer">
      <div className="content">
        <div className="left">
          <div>
            {/* <h2 className="title">O QUE VEM POR AÍ</h2> */}
            <h3>EXPOINTER 2023</h3>
            <p>Na Expointer, o visitante encontra inúmeras opções de entretenimento. São mais de 400 eventos e atrações que acontecem na cidade de Esteio, Rio Grande do Sul, nos nove dias de feira.</p>
            <p>Entre as atrações estão exposições e leilões de mais de 150 animais, desfile de campeões, show de máquinas, feira de agricultura familiar, palestras, exposição de artesanatos e outros.</p>
            <p>Com o tema “A força do agro nos conecta”, a edição de 2023 acontece de 26 de agosto a 03 de setembro no Parque Estadual de Exposições Assis Brasil, localizado no km 13 BR-116.</p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className='owl-theme' margin={50} nav options items={1} >
            <div class='item'>
              <iframe src="https://www.youtube.com/embed/ahPozY36q9c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class='item'>
              <img src={ExpoInterImage1} />
            </div>
            <div class='item'>
              <img src={ExpoInterImage2} />
            </div>
            <div class='item'>
              <img src={ExpoInterImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}