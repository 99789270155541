import React, { useEffect, useState } from "react";
import "./index.scss";

const cupons = [
  {
    "Cupons": "1-92465, 1-34436, 1-91419, 1-46071, 1-36342, 1-31289, 1-25462, 1-44006, 1-76249, 1-60625, 1-47034, 1-62448, 1-88134, 1-78516"
  },
  {
    "Cupons": "1-48484, 1-95565, 1-20864, 1-80320, 1-90490, 1-49745, 1-53120, 1-18748, 1-62347, 1-78790, 1-61884, 1-90303, 1-25520, 1-23623"
  },
  {
    "Cupons": "1-27554, 1-37303, 1-19614, 1-11605, 1-19284, 1-78174, 1-26443, 1-97444, 1-23621, 1-74837, 1-15040, 1-34569, 1-18676, 1-87063"
  },
  {
    "Cupons": "1-88787, 1-83289, 1-61197, 1-29889, 1-16437, 1-23347, 1-56050, 1-73570, 1-73686, 1-25051, 1-29937, 1-72787, 1-19826, 1-89962"
  },
  {
    "Cupons": "1-76658, 1-49014, 1-79727, 1-77269, 1-15380, 1-59963, 1-51899, 1-57915, 1-72033, 1-88587, 1-23961, 1-47993, 1-58792, 1-43471"
  },
  {
    "Cupons": "1-75177, 1-64426, 1-33933, 1-14105, 1-39849, 1-16733, 1-56327, 1-28396, 1-31560, 1-18685, 1-80874, 1-56226, 1-31640, 1-25565"
  },
  {
    "Cupons": "1-66830, 1-53131, 1-72240, 1-21515, 1-72022, 1-26375, 1-99941, 1-48889, 1-69156, 1-23230, 1-79164, 1-44651, 1-77571, 1-92814"
  },
  {
    "Cupons": "1-16644, 1-10228, 1-17577, 1-92336, 1-56357, 1-94818, 1-20440, 1-55091, 1-93596, 1-87667, 1-26092, 1-80826, 1-42757, 1-12935"
  },
  {
    "Cupons": "1-51776, 1-23243, 1-13898, 1-31414, 1-59086, 1-95490, 1-49935, 1-42900, 1-96724, 1-39763, 1-59317, 1-14450, 1-75272, 1-69006"
  },
  {
    "Cupons": "1-51998, 1-46607, 1-37257, 1-55218, 1-14379, 1-52083, 1-69476, 1-91048, 1-45762, 1-26978, 1-95700, 1-19716, 1-57378, 1-87949"
  },
  {
    "Cupons": "1-26603, 1-22551, 1-83810, 1-19076, 1-82853, 1-16438, 1-63064, 1-10341, 1-48971, 1-38602, 1-28445, 1-59606, 1-13036, 1-22325"
  },
  {
    "Cupons": "1-54948, 1-16844, 1-98644, 1-65069, 1-61203, 1-99283, 1-73889, 1-29756, 1-83580, 1-10455, 1-64085, 1-59797, 1-25948, 1-27357"
  },
  {
    "Cupons": "1-81417, 1-62675, 1-12625, 1-16608, 1-91260, 1-20863, 1-83411, 1-10285, 1-42666, 1-99185, 1-79442, 1-43031, 1-49076, 1-13620, 1-19829, 1-36936, 1-36180, 1-66394, 1-95224, 1-30192, 1-27642, 1-46028, 1-87345, 1-94403, 1-61758, 1-51708, 1-30956, 1-55337, 1-30896, 1-85208, 1-43987, 1-22710, 1-66877, 1-42478, 1-31203, 1-71473, 1-50911, 1-56956, 1-73231, 1-95811, 1-68322, 1-98456, 1-24665, 1-48082, 1-23474, 1-16246, 1-88696, 1-11558, 1-58348, 1-99983, 1-15438, 1-84289, 1-76207, 1-74399, 1-14339, 1-38247, 1-68364, 1-42675, 1-49114, 1-79236, 1-43851, 1-71364, 1-64606, 1-82447, 1-86058, 1-69982, 1-30082, 1-35077, 1-77889, 1-78184, 1-47895, 1-34465, 1-82502, 1-14601, 1-22327, 1-22516, 1-35790, 1-10513, 1-34336, 1-59918, 1-48677, 1-83004, 1-17939, 1-25640, 1-19718, 1-13458, 1-70114, 1-65266, 1-64804, 1-57510, 1-73998, 1-25091, 1-90555, 1-48155, 1-66305, 1-96367, 1-15118, 1-99812, 1-99103, 1-75593, 1-32088, 1-47219, 1-33818, 1-33151"
  },
  {
    "Cupons": "1-60269, 1-32595, 1-71217, 1-42119, 1-40117, 1-53366, 1-18633, 1-63848, 1-59117, 1-33100, 1-63184, 1-94519, 1-76179, 1-12014"
  },
  {
    "Cupons": "1-29721, 1-83885, 1-45558, 1-44177, 1-78447, 1-22778, 1-13551, 1-68755, 1-43464, 1-69759, 1-54892, 1-77292, 1-62168, 1-22669"
  },
  {
    "Cupons": "1-80361, 1-21913, 1-88662, 1-81629, 1-61345, 1-42466, 1-31855, 1-11450, 1-38269, 1-10792, 1-79166, 1-71467, 1-57748, 1-89967"
  },
  {
    "Cupons": "1-12681, 1-68563, 1-19083, 1-63324, 1-78335, 1-63789, 1-94338, 1-15964, 1-57226, 1-73606, 1-10642, 1-81935, 1-29075, 1-17579, 1-83376, 1-37595, 1-16400, 1-68531, 1-96077, 1-99209, 1-50610, 1-64561, 1-34525, 1-78312, 1-54884, 1-56155, 1-49870, 1-68119, 1-94168, 1-84994, 1-90523, 1-40381, 1-92148, 1-28959, 1-15875, 1-67061, 1-55167, 1-10095, 1-61894, 1-99028, 1-63715, 1-81439, 1-96433, 1-33341"
  },
  {
    "Cupons": "1-97705, 1-27207, 1-74203, 1-72795, 1-30835, 1-80324, 1-36647, 1-75132, 1-28684, 1-55605, 1-38273, 1-35830, 1-37965, 1-43527"
  },
  {
    "Cupons": "1-68173, 1-55019, 1-93760, 1-70402, 1-77465, 1-49665, 1-94558, 1-18954, 1-46271, 1-81283, 1-77553, 1-96904, 1-35585, 1-48443"
  },
  {
    "Cupons": "1-97037, 1-65419, 1-31324, 1-45197"
  },
  {
    "Cupons": "1-81320, 1-85665, 1-51855, 1-25088, 1-99009, 1-13727, 1-42601, 1-86934, 1-85984, 1-54422, 1-34422, 1-20470, 1-57397, 1-27147"
  },
  {
    "Cupons": "1-92471, 1-42418, 1-30149, 1-64515, 1-11480, 1-48762, 1-15498, 1-74013, 1-74092, 1-35500, 1-11279, 1-10965, 1-26439, 1-24245"
  },
  {
    "Cupons": "1-52540, 1-90496, 1-67110, 1-43116, 1-28200, 1-55146, 1-99090, 1-79478, 1-20300, 1-37252, 1-94178, 1-44786, 1-41807, 1-98774"
  },
  {
    "Cupons": "1-61441, 1-35514, 1-59981, 1-79331, 1-61668, 1-53284, 1-91149, 1-10365, 1-54182, 1-67461, 1-25117, 1-12279, 1-16561, 1-83647"
  },
  {
    "Cupons": "1-63073, 1-81423, 1-32529, 1-51717, 1-61704, 1-15265, 1-14405, 1-39782, 1-99294, 1-18574, 1-98483, 1-98092, 1-96962, 1-98452, 1-51888, 1-90268, 1-92085, 1-16283, 1-99596, 1-96399, 1-78680, 1-25873, 1-62098, 1-35097, 1-61049, 1-55346, 1-28645, 1-59684, 1-24877, 1-32742, 1-97631, 1-35264, 1-92271, 1-88310, 1-37423, 1-10900, 1-56297, 1-17343, 1-67593, 1-41461, 1-81963, 1-88616, 1-65336, 1-28461"
  },
  {
    "Cupons": "1-45194, 1-48573, 1-18198, 1-93283, 1-17546, 1-94686, 1-83514, 1-68325, 1-85130, 1-60307, 1-90349, 1-41665, 1-47254, 1-68799"
  },
  {
    "Cupons": "1-63621, 1-16933, 1-79023, 1-47662, 1-34167, 1-21319, 1-52470, 1-78182, 1-29486, 1-55304, 1-44999, 1-53826, 1-29556, 1-40852, 1-55891, 1-76040, 1-41749, 1-60158, 1-25540, 1-77276, 1-97613, 1-28884, 1-19548, 1-71389, 1-20642, 1-96368, 1-13696, 1-38638, 1-34846, 1-90462, 1-78957, 1-16183, 1-92345, 1-59273, 1-69049, 1-27080, 1-44677, 1-28451, 1-32359, 1-32423, 1-85530, 1-68462, 1-93914, 1-32654, 1-35308, 1-71730, 1-32421, 1-80831, 1-43944, 1-77545, 1-47767, 1-56118, 1-82771, 1-89017, 1-35154, 1-88748, 1-12659, 1-72940, 1-98755, 1-64044, 1-84066, 1-86027, 1-74351, 1-69159, 1-60234, 1-24600, 1-14848, 1-27145, 1-91591, 1-55476, 1-62455, 1-73899, 1-50209, 1-61601, 1-36561, 1-90858, 1-51531, 1-12618, 1-82500, 1-47027, 1-37533, 1-39474, 1-79628, 1-20268, 1-10794, 1-42337, 1-70539, 1-81978, 1-50145, 1-88822, 1-14411, 1-74858, 1-76550, 1-12456, 1-16472, 1-92185, 1-45229, 1-80341, 1-60199, 1-76414, 1-69751, 1-41256, 1-45269, 1-17830, 1-58718, 1-83496, 1-20609, 1-94827, 1-73955, 1-80226, 1-36425, 1-15749, 1-41228, 1-41079, 1-81641, 1-72301, 1-18076, 1-28002, 1-62987, 1-63077, 1-72157, 1-24030, 1-74108, 1-97318, 1-66989, 1-69325, 1-31068, 1-86286, 1-79452, 1-81881, 1-33638, 1-60997, 1-53494, 1-37268"
  },
  {
    "Cupons": "1-62956, 1-19550, 1-59300, 1-31394, 1-98191, 1-65676, 1-78868, 1-31159, 1-30923, 1-64943, 1-40802, 1-42628, 1-85125, 1-23600"
  },
  {
    "Cupons": "1-51834, 1-89959, 1-65354, 1-52440, 1-58849, 1-80613, 1-89129, 1-83654, 1-77717, 1-35454, 1-36606, 1-38583, 1-88981, 1-68128, 1-42372, 1-90457, 1-30083, 1-58691, 1-78876, 1-16004, 1-34470, 1-14398, 1-26482, 1-10312, 1-95317, 1-28061, 1-67570, 1-52742, 1-66663, 1-24725, 1-68575, 1-70900, 1-84356, 1-36752, 1-88808, 1-80553, 1-75422, 1-85201, 1-75482, 1-94149, 1-58471, 1-38350, 1-65308, 1-83942"
  },
  {
    "Cupons": "1-48768, 1-27744, 1-89503, 1-52896, 1-79481, 1-62116, 1-93761, 1-96873, 1-95142, 1-64548, 1-68821, 1-42326, 1-16463, 1-75976"
  },
  {
    "Cupons": "1-63020, 1-68609, 1-62026, 1-30954, 1-56305, 1-96801, 1-98358, 1-47037, 1-67540, 1-40526, 1-87329, 1-67213, 1-19793, 1-90777"
  },
  {
    "Cupons": "1-96541, 1-53037, 1-82084, 1-87593"
  },
  {
    "Cupons": "1-53605, 1-58579, 1-82685, 1-33025"
  },
  {
    "Cupons": "1-75365, 1-81352, 1-22492, 1-65411"
  },
  {
    "Cupons": "1-39545, 1-82761, 1-68919, 1-64263, 1-60670, 1-70376, 1-83048, 1-62256, 1-18576, 1-66510, 1-49871, 1-59938, 1-17609, 1-32282"
  },
  {
    "Cupons": "1-70079, 1-82104, 1-93364, 1-55998"
  },
  {
    "Cupons": "1-20959, 1-11040, 1-12383, 1-13899, 1-24601, 1-75690, 1-59651, 1-97082, 1-22924, 1-56500, 1-87380, 1-87875, 1-48504, 1-60762"
  },
  {
    "Cupons": "1-89827, 1-12352, 1-70251, 1-68019, 1-34593, 1-90049, 1-83807, 1-76814, 1-91665, 1-81953, 1-87014, 1-18803, 1-18843, 1-16499"
  },
  {
    "Cupons": "1-57502, 1-74308, 1-50168, 1-90686, 1-91393, 1-54372, 1-43354, 1-68578, 1-54847, 1-27745, 1-37987, 1-81663, 1-39177, 1-61810"
  },
  {
    "Cupons": "1-32620, 1-61997, 1-52921, 1-70678, 1-18687, 1-45859, 1-79798, 1-76223, 1-70445, 1-70318, 1-66653, 1-75325, 1-33608, 1-15966"
  },
  {
    "Cupons": "1-40497, 1-98275, 1-52307, 1-27247, 1-68366, 1-39643, 1-23327, 1-37382, 1-67390, 1-24535, 1-12560, 1-87568, 1-53976, 1-24123"
  },
  {
    "Cupons": "1-77300, 1-50947, 1-27166, 1-69957, 1-95562, 1-67404, 1-46970, 1-82460, 1-17434, 1-66111, 1-53784, 1-90140, 1-36871, 1-52065, 1-37062, 1-31572, 1-81926, 1-31272, 1-37773, 1-27557, 1-35254, 1-32618, 1-33348, 1-81179, 1-30568, 1-49824, 1-40217, 1-21024, 1-19407, 1-93953, 1-80522, 1-38263, 1-85023, 1-29783, 1-75950, 1-39961, 1-96914, 1-73698, 1-17613, 1-85340, 1-10322, 1-39863, 1-38610, 1-69453, 1-58196, 1-71265, 1-71007, 1-81840, 1-74453, 1-99880, 1-41145, 1-27266, 1-17006, 1-10530, 1-74986, 1-31543, 1-30820, 1-49773, 1-79258, 1-11847, 1-83373, 1-38137, 1-39818, 1-81771, 1-88276, 1-57902, 1-78135, 1-88288, 1-43543, 1-59791, 1-89133, 1-14373, 1-47389, 1-94804, 1-46747, 1-20716, 1-61539, 1-63793, 1-35249, 1-31617, 1-86074, 1-52464, 1-43258, 1-19660, 1-58668, 1-10148, 1-46909, 1-10113, 1-80299, 1-68057, 1-12856, 1-92712, 1-35073, 1-32268, 1-35756, 1-20117, 1-12789, 1-43745, 1-68172, 1-10529, 1-80475, 1-24281, 1-10504, 1-16477"
  },
  {
    "Cupons": "1-51057, 1-50711, 1-39415, 1-44351, 1-45239, 1-63568, 1-84824, 1-45746, 1-88112, 1-53044, 1-36191"
  },
  {
    "Cupons": "1-30598, 1-22083, 1-80774, 1-32677, 1-44203, 1-33759, 1-50818, 1-67657, 1-43452, 1-88548, 1-98662, 1-67815, 1-22657, 1-10844"
  },
  {
    "Cupons": "1-75942, 1-79868, 1-15689, 1-87649, 1-73097, 1-53905, 1-99911, 1-94361, 1-41655, 1-94865, 1-73466, 1-62475, 1-91691, 1-20209"
  },
  {
    "Cupons": "1-94441, 1-94721, 1-71358, 1-91636, 1-90636, 1-10941, 1-36791, 1-83314, 1-37231, 1-55757, 1-86320, 1-39318, 1-38804, 1-31968"
  },
  {
    "Cupons": "1-20653, 1-81558, 1-90760, 1-51780, 1-40745, 1-42074, 1-23078, 1-79429, 1-64957, 1-64618, 1-95484, 1-64964, 1-28487, 1-85874"
  },
  {
    "Cupons": "1-50355, 1-41547, 1-60680, 1-28995, 1-40051, 1-87859, 1-10988, 1-50740, 1-56938, 1-56408, 1-56551, 1-63251, 1-69645, 1-10810, 1-23203, 1-63590, 1-27780, 1-51830, 1-75459, 1-44080, 1-94495, 1-48571, 1-74670, 1-15779, 1-91253, 1-59364, 1-68460, 1-11549, 1-64747, 1-36571, 1-55395, 1-40934, 1-35067, 1-69735, 1-98809, 1-22211, 1-89554, 1-48217, 1-20597, 1-76881, 1-46391, 1-49068, 1-16212, 1-53033"
  },
  {
    "Cupons": "1-88201, 1-21659, 1-88682, 1-41622, 1-41572, 1-53245, 1-87768, 1-43907, 1-14340, 1-13405, 1-34580, 1-61672, 1-69933, 1-31471, 1-38860, 1-37746, 1-46476, 1-80677, 1-38495, 1-23393, 1-74354, 1-87610, 1-37875, 1-28617, 1-81574, 1-90861, 1-23649, 1-19765, 1-92790, 1-27638, 1-55089, 1-49325, 1-41346, 1-50412, 1-55908, 1-42762, 1-41053, 1-25411, 1-43110, 1-94587, 1-84146, 1-74366, 1-12302, 1-49125"
  },
  {
    "Cupons": "1-64384, 1-70660, 1-84249, 1-67690, 1-33956, 1-44962, 1-74695, 1-13915, 1-47353, 1-78065, 1-49188, 1-19672, 1-47912, 1-88271"
  },
  {
    "Cupons": "1-81975, 1-84644, 1-27489, 1-55690, 1-30898, 1-52630, 1-76566, 1-45690, 1-70453, 1-90748, 1-55792, 1-12030, 1-25423, 1-44369, 1-53335, 1-20845, 1-65502, 1-70213, 1-30517, 1-67054, 1-43107, 1-81722, 1-77962, 1-74898, 1-53675, 1-74751, 1-12417, 1-93899, 1-20240, 1-55989, 1-32197, 1-70477, 1-19313, 1-92777, 1-63923, 1-89086, 1-66883, 1-22750, 1-40176, 1-89264, 1-59545, 1-19215, 1-33320, 1-79137"
  },
  {
    "Cupons": "1-27044, 1-66351, 1-35928, 1-33155, 1-42986, 1-68657, 1-82770, 1-56373, 1-58916, 1-62346, 1-25968, 1-16047, 1-84830, 1-80746"
  },
  {
    "Cupons": "1-79130, 1-86085, 1-12524, 1-83767, 1-75540, 1-67324, 1-58178, 1-73494, 1-56854, 1-53961, 1-97076, 1-85912, 1-82828, 1-63159"
  },
  {
    "Cupons": "1-87670, 1-65593, 1-91885, 1-97328, 1-55763, 1-46015, 1-58671, 1-76023, 1-80788, 1-22928, 1-85621, 1-89815, 1-38403, 1-87350"
  },
  {
    "Cupons": "1-99498, 1-57559, 1-65155, 1-91629, 1-55150, 1-56881, 1-37782, 1-83047, 1-69549, 1-45206, 1-68642, 1-11641, 1-15672, 1-89171, 1-67215, 1-48944, 1-47086, 1-25527, 1-90526, 1-61682, 1-65856, 1-85536, 1-94142, 1-35445, 1-59668, 1-29842, 1-27428, 1-34013, 1-61463, 1-70752, 1-79334, 1-39802, 1-39120, 1-90168, 1-39501, 1-34306, 1-87941, 1-78350, 1-41214, 1-56285, 1-59607, 1-82680, 1-65746, 1-73004"
  },
  {
    "Cupons": "1-98098, 1-34401, 1-57778, 1-22141, 1-77242, 1-42286, 1-55060, 1-28833, 1-57446, 1-78520, 1-44240, 1-42651, 1-36797, 1-72719"
  },
  {
    "Cupons": "1-35211, 1-61749, 1-60835, 1-25234, 1-65142, 1-35034, 1-84049, 1-20869, 1-70300, 1-93581, 1-64495, 1-24881, 1-47866, 1-13497"
  },
  {
    "Cupons": "1-85068, 1-47541, 1-97979, 1-82328, 1-86865, 1-77185, 1-83199, 1-75614, 1-61157, 1-26205, 1-65983, 1-80235, 1-53285, 1-28463"
  },
  {
    "Cupons": "1-88026, 1-17500, 1-19289, 1-23827, 1-83408, 1-76561, 1-91809, 1-23707, 1-46855, 1-31845, 1-63352, 1-83056, 1-14938, 1-30951"
  },
  {
    "Cupons": "1-77848, 1-58843, 1-99647, 1-25547, 1-49593, 1-71341, 1-84076, 1-37501, 1-27074, 1-52411, 1-40369, 1-89332, 1-62758, 1-17370"
  },
  {
    "Cupons": "1-93910, 1-26893, 1-56717, 1-31676, 1-51621, 1-74194, 1-46471, 1-65680, 1-43596, 1-12652, 1-45222, 1-12207, 1-99522, 1-55284"
  },
  {
    "Cupons": "1-80132, 1-45894, 1-98951, 1-72633, 1-65572, 1-45252, 1-38793, 1-15322, 1-65774, 1-73413, 1-75936, 1-78321, 1-57665, 1-69073"
  },
  {
    "Cupons": "1-53733, 1-96995, 1-13328, 1-86636"
  },
  {
    "Cupons": "1-97113, 1-55173, 1-16154, 1-43279, 1-49201, 1-48029, 1-72744, 1-83775, 1-59740, 1-39629, 1-23931, 1-38741, 1-19830, 1-18420"
  },
  {
    "Cupons": "1-34233, 1-38510, 1-73589, 1-54357, 1-70063, 1-17597, 1-35574, 1-61802, 1-66919, 1-49611, 1-10318, 1-95786, 1-56808, 1-50748"
  },
  {
    "Cupons": "1-85055, 1-27075, 1-35707, 1-76588, 1-34798, 1-17178, 1-37058, 1-19683, 1-88966, 1-27077, 1-13888, 1-14004, 1-31732, 1-76788"
  },
  {
    "Cupons": "1-13472, 1-58866, 1-97594, 1-84203"
  },
  {
    "Cupons": "1-63459, 1-70819, 1-27186, 1-81524"
  },
  {
    "Cupons": "1-22990, 1-39521, 1-46238, 1-42000"
  },
  {
    "Cupons": "1-92985, 1-33186, 1-94531, 1-13123"
  },
  {
    "Cupons": "1-83546, 1-52871, 1-47270, 1-76678"
  },
  {
    "Cupons": "1-82011"
  },
  {
    "Cupons": "1-14959"
  },
  {
    "Cupons": "1-63889, 1-16657, 1-28155, 1-48401"
  },
  {
    "Cupons": "1-27485, 1-65450, 1-75844, 1-87720, 1-45980, 1-36643, 1-15360, 1-33966, 1-63092, 1-91895, 1-43766, 1-27048, 1-79140, 1-17990"
  },
  {
    "Cupons": "1-68830, 1-86448, 1-77636, 1-69582"
  },
  {
    "Cupons": "1-78239"
  },
  {
    "Cupons": "1-30808, 1-19159, 1-80225, 1-35654, 1-52157, 1-79569, 1-12688, 1-41161, 1-47077, 1-53004, 1-35052, 1-59661, 1-93819, 1-67825, 1-49209, 1-25532, 1-37389, 1-89588, 1-51750, 1-74360, 1-14675, 1-44208, 1-57011, 1-14704, 1-65297, 1-17163, 1-95332, 1-77849, 1-98962, 1-98192, 1-51154, 1-69686, 1-92845, 1-52219, 1-52673, 1-60067, 1-97800, 1-73806, 1-38183, 1-36262, 1-28142, 1-11978, 1-45805, 1-30929"
  },
  {
    "Cupons": "1-92441"
  },
  {
    "Cupons": "1-26447"
  },
  {
    "Cupons": "1-91537"
  },
  {
    "Cupons": "1-70695"
  },
  {
    "Cupons": "1-81090, 1-39971, 1-63780, 1-85690"
  },
  {
    "Cupons": "1-54444, 1-65488, 1-89100, 1-18358"
  },
  {
    "Cupons": "1-77124, 1-41586, 1-55203, 1-58623"
  },
  {
    "Cupons": "1-31588"
  },
  {
    "Cupons": "1-83693, 1-38731, 1-31927, 1-52782"
  },
  {
    "Cupons": "1-35871"
  },
  {
    "Cupons": "1-92886"
  },
  {
    "Cupons": "1-58365, 1-83840, 1-25641, 1-82264"
  },
  {
    "Cupons": "1-91564"
  },
  {
    "Cupons": "1-15397"
  },
  {
    "Cupons": "1-73381, 1-33189, 1-73591, 1-72537"
  },
  {
    "Cupons": "1-68022"
  },
  {
    "Cupons": "1-49176, 1-21658, 1-67617, 1-18755"
  },
  {
    "Cupons": "1-61866, 1-57454, 1-12933, 1-67426, 1-54080, 1-31765, 1-59484, 1-31043, 1-99491, 1-91377, 1-52969, 1-85317, 1-34315, 1-18343"
  },
  {
    "Cupons": "1-18418, 1-84752, 1-54733, 1-71931"
  },
  {
    "Cupons": "1-34056, 1-42369, 1-82558, 1-31106"
  },
  {
    "Cupons": "1-55594"
  },
  {
    "Cupons": "1-56019"
  },
  {
    "Cupons": "1-98674"
  },
  {
    "Cupons": "1-46234"
  },
  {
    "Cupons": "1-72501, 1-11224, 1-50354, 1-49382, 1-90469, 1-10761, 1-45575, 1-80544, 1-75727, 1-72663, 1-98122, 1-53893, 1-49115, 1-29851"
  },
  {
    "Cupons": "1-44185, 1-29902, 1-42159, 1-93579"
  },
  {
    "Cupons": "1-49183, 1-54266, 1-14901, 1-28848"
  },
  {
    "Cupons": "1-95278"
  },
  {
    "Cupons": "1-20134, 1-49892, 1-60316, 1-48937"
  },
  {
    "Cupons": "1-51663"
  },
  {
    "Cupons": "1-89769, 1-92975, 1-22915, 1-45514"
  },
  {
    "Cupons": "1-69661, 1-95603, 1-48363, 1-35575"
  },
  {
    "Cupons": "1-91389, 1-77256, 1-48232, 1-69313, 1-50171, 1-81810, 1-95178, 1-87566, 1-99915, 1-40171, 1-73308, 1-21290, 1-25504, 1-80505"
  },
  {
    "Cupons": "1-66338"
  },
  {
    "Cupons": "1-93660, 1-74318, 1-74551, 1-12737"
  },
  {
    "Cupons": "1-80127"
  },
  {
    "Cupons": "1-75190"
  },
  {
    "Cupons": "1-67175, 1-28914, 1-89998, 1-37220, 1-79664, 1-17611, 1-16696, 1-57874, 1-26663, 1-56722, 1-65808, 1-48839, 1-67187, 1-83912"
  },
  {
    "Cupons": "1-77977"
  },
  {
    "Cupons": "1-98369, 1-66286, 1-65884, 1-65205, 1-23126, 1-93240, 1-93860, 1-41859, 1-64274, 1-55076, 1-18962, 1-71741, 1-60102, 1-44140"
  },
  {
    "Cupons": "1-30319, 1-27323, 1-68720, 1-41808"
  },
  {
    "Cupons": "1-99001, 1-53025, 1-94475, 1-14492, 1-85699, 1-22361, 1-99161, 1-27719, 1-25784, 1-23463, 1-29276, 1-92166, 1-32813, 1-21464"
  },
  {
    "Cupons": "1-12385, 1-67273, 1-34780, 1-46017"
  },
  {
    "Cupons": "1-99531"
  },
  {
    "Cupons": "1-66494"
  },
  {
    "Cupons": "1-27509"
  },
  {
    "Cupons": "1-52615, 1-28539, 1-37282, 1-75030"
  },
  {
    "Cupons": "1-99657"
  },
  {
    "Cupons": "1-91695, 1-18907, 1-88401, 1-59034"
  },
  {
    "Cupons": "1-47135, 1-11424, 1-13155, 1-35016"
  },
  {
    "Cupons": "1-52386"
  },
  {
    "Cupons": "1-18315, 1-97067, 1-49553, 1-32092"
  },
  {
    "Cupons": "1-14057, 1-69341, 1-70471, 1-66572"
  },
  {
    "Cupons": "1-89340"
  },
  {
    "Cupons": "1-25838, 1-82121, 1-84387, 1-39301, 1-62543, 1-41704, 1-71742, 1-13825, 1-80545, 1-53834, 1-84159, 1-78320, 1-13708, 1-78613"
  },
  {
    "Cupons": "1-92430"
  },
  {
    "Cupons": "1-59566"
  },
  {
    "Cupons": "1-58303, 1-47125, 1-44043, 1-54850, 1-62777, 1-36415, 1-60486, 1-94775, 1-28808, 1-78221, 1-80983, 1-46537, 1-50991, 1-53011"
  },
  {
    "Cupons": "1-59937, 1-58487, 1-33890, 1-94207, 1-28779, 1-24845, 1-88720, 1-22021, 1-54732, 1-18612, 1-73645, 1-36170, 1-85248, 1-24217"
  },
  {
    "Cupons": "1-60055"
  },
  {
    "Cupons": "1-60370, 1-32982, 1-38825, 1-97033, 1-16753, 1-77584, 1-58719, 1-42391, 1-78765, 1-25534, 1-54131, 1-80426, 1-29282, 1-40818"
  },
  {
    "Cupons": "1-59689, 1-10173, 1-58685, 1-33279"
  },
  {
    "Cupons": "1-10853, 1-24276, 1-29256, 1-64682"
  },
  {
    "Cupons": "1-16460, 1-24801, 1-76435, 1-91856, 1-99240, 1-45855, 1-57427, 1-70862, 1-35175, 1-38460, 1-55670, 1-27551, 1-85507, 1-46288"
  },
  {
    "Cupons": "1-26926, 1-43299, 1-43975, 1-37311, 1-15236, 1-26506, 1-34135, 1-72972, 1-21405, 1-80926, 1-17062, 1-96168, 1-48854, 1-86608"
  },
  {
    "Cupons": "1-30233, 1-58893, 1-67900, 1-17175"
  },
  {
    "Cupons": "1-85846"
  },
  {
    "Cupons": "1-80894"
  },
  {
    "Cupons": "1-96769, 1-75919, 1-86884, 1-39111"
  },
  {
    "Cupons": "1-49336, 1-36265, 1-17345, 1-82000"
  },
  {
    "Cupons": "1-87895, 1-25337, 1-88058, 1-73498"
  },
  {
    "Cupons": "1-98877"
  },
  {
    "Cupons": "1-95579"
  },
  {
    "Cupons": "1-51010, 1-89527, 1-92326, 1-91442"
  },
  {
    "Cupons": "1-56491, 1-57615, 1-56273, 1-75123"
  },
  {
    "Cupons": "1-94224"
  },
  {
    "Cupons": "1-43701, 1-44939, 1-79435, 1-44158, 1-41090, 1-35008, 1-85404, 1-96146, 1-33789, 1-99032, 1-73315, 1-39940, 1-76314, 1-33343"
  },
  {
    "Cupons": "1-63584, 1-23248, 1-82078, 1-27944"
  },
  {
    "Cupons": "1-83524, 1-51098, 1-46801, 1-84025, 1-68089, 1-98584, 1-34079, 1-75158, 1-36190, 1-81529, 1-83676, 1-90329, 1-29283, 1-38934"
  },
  {
    "Cupons": "1-73979, 1-88983, 1-85272, 1-75392"
  },
  {
    "Cupons": "1-11702"
  },
  {
    "Cupons": "1-69676"
  },
  {
    "Cupons": "1-83117"
  },
  {
    "Cupons": "1-57663, 1-29016, 1-60812, 1-42026, 1-60373, 1-21398, 1-83883, 1-61328, 1-85711, 1-97173, 1-40898, 1-46208, 1-41765, 1-36939"
  },
  {
    "Cupons": "1-15496, 1-77712, 1-57622, 1-23863, 1-98218, 1-95473, 1-50164, 1-77577, 1-67195, 1-92474, 1-25911, 1-73276, 1-25389, 1-97201"
  },
  {
    "Cupons": "1-92978, 1-96302, 1-85650, 1-56680"
  },
  {
    "Cupons": "1-45756"
  },
  {
    "Cupons": "1-76533"
  },
  {
    "Cupons": "1-62230, 1-18342, 1-25366, 1-74489"
  },
  {
    "Cupons": "1-62089, 1-70988, 1-37059, 1-88397"
  },
  {
    "Cupons": "1-57728"
  },
  {
    "Cupons": "1-54589, 1-48619, 1-17979, 1-95787"
  },
  {
    "Cupons": "1-42603"
  },
  {
    "Cupons": "1-65529"
  },
  {
    "Cupons": "1-27870"
  },
  {
    "Cupons": "1-36723"
  },
  {
    "Cupons": "1-64676, 1-78664, 1-47098, 1-98196, 1-61071, 1-29383, 1-58019, 1-95660, 1-52872, 1-99782, 1-91887, 1-50079, 1-23480, 1-17987"
  },
  {
    "Cupons": "1-66652"
  },
  {
    "Cupons": "1-95489, 1-15371, 1-44687, 1-42494"
  },
  {
    "Cupons": "1-60138"
  },
  {
    "Cupons": "1-50351, 1-40098, 1-58234, 1-76187, 1-45700, 1-85568, 1-59744, 1-28088, 1-18253, 1-40058, 1-23837, 1-21909, 1-20523, 1-54274"
  },
  {
    "Cupons": "1-43081, 1-13979, 1-31854, 1-71998"
  },
  {
    "Cupons": "1-15631, 1-27471, 1-71922, 1-76021"
  },
  {
    "Cupons": "1-16428"
  },
  {
    "Cupons": "1-64256, 1-29341, 1-74231, 1-21537"
  },
  {
    "Cupons": "1-12796"
  },
  {
    "Cupons": "1-73639, 1-46148, 1-89112, 1-36185, 1-32477, 1-45830, 1-95958, 1-56448, 1-69470, 1-60420, 1-18435, 1-84797, 1-68392, 1-35739"
  },
  {
    "Cupons": "1-14636"
  },
  {
    "Cupons": "1-13781"
  },
  {
    "Cupons": "1-78422, 1-41885, 1-58049, 1-83256, 1-92742, 1-71918, 1-73564, 1-97066, 1-21834, 1-83916, 1-13809, 1-29582, 1-16456, 1-42404"
  },
  {
    "Cupons": "1-19979"
  },
  {
    "Cupons": "1-41343"
  },
  {
    "Cupons": "1-46921, 1-71810, 1-35822, 1-95999, 1-43641, 1-23674, 1-17015, 1-41890, 1-11427, 1-29088, 1-95021, 1-62440, 1-59858, 1-62958"
  },
  {
    "Cupons": "1-23194, 1-55966, 1-80134, 1-31524, 1-15085, 1-64114, 1-21176, 1-31930, 1-85562, 1-52155, 1-61692, 1-84668, 1-88122, 1-82331"
  },
  {
    "Cupons": "1-76029, 1-32162, 1-49471, 1-89382, 1-40313, 1-27604, 1-19963, 1-38539, 1-22597, 1-54755, 1-49151"
  },
  {
    "Cupons": "1-74680, 1-30723, 1-68958, 1-86202"
  },
  {
    "Cupons": "1-81562"
  },
  {
    "Cupons": "1-67077"
  },
  {
    "Cupons": "1-44341, 1-75996, 1-41579, 1-83617"
  },
  {
    "Cupons": "1-61272, 1-55081, 1-94885, 1-57629"
  },
  {
    "Cupons": "1-38394"
  },
  {
    "Cupons": "1-58104, 1-43931, 1-12552, 1-95381"
  },
  {
    "Cupons": "1-93472"
  },
  {
    "Cupons": "1-37488"
  },
  {
    "Cupons": "1-34788, 1-22825, 1-64756, 1-57617"
  },
  {
    "Cupons": "1-81445"
  },
  {
    "Cupons": "1-72065, 1-58818, 1-42940, 1-45035"
  },
  {
    "Cupons": "1-41941, 1-38615, 1-52450, 1-79922"
  },
  {
    "Cupons": "1-14778"
  },
  {
    "Cupons": "1-14274"
  },
  {
    "Cupons": "1-78400"
  },
  {
    "Cupons": "1-29411, 1-89537, 1-72197, 1-85826"
  },
  {
    "Cupons": "1-63213, 1-31456, 1-54141, 1-38520"
  },
  {
    "Cupons": "1-66086, 1-87143, 1-50391, 1-45886"
  },
  {
    "Cupons": "1-50713, 1-63246, 1-23389, 1-39569, 1-46769, 1-61038, 1-59603, 1-20536, 1-61752, 1-73816, 1-92146, 1-73235, 1-37131, 1-42801"
  },
  {
    "Cupons": "1-96946, 1-55000, 1-27734, 1-30817"
  },
  {
    "Cupons": "1-48992, 1-33043, 1-67770, 1-22816, 1-65231, 1-65781, 1-60946, 1-45111, 1-74325, 1-47033, 1-89598, 1-72702, 1-91207, 1-20021"
  },
  {
    "Cupons": "1-20285"
  },
  {
    "Cupons": "1-63442, 1-15183, 1-86414, 1-79011, 1-95691, 1-98709, 1-96680, 1-92216, 1-65403, 1-64558, 1-12134, 1-73067, 1-79395, 1-94673"
  },
  {
    "Cupons": "1-16841, 1-15571, 1-44550, 1-28930"
  },
  {
    "Cupons": "1-27983"
  },
  {
    "Cupons": "1-81351"
  },
  {
    "Cupons": "1-97935"
  },
  {
    "Cupons": "1-59804, 1-56362, 1-49856, 1-67666, 1-78050, 1-51322, 1-65398, 1-68806, 1-40820, 1-24848, 1-50543, 1-88894, 1-64984, 1-30366"
  },
  {
    "Cupons": "1-34106"
  },
  {
    "Cupons": "1-98582, 1-93119, 1-51165, 1-36693, 1-45761, 1-92156, 1-34064, 1-91844, 1-89030, 1-56009, 1-73797, 1-55661, 1-99411, 1-10790"
  },
  {
    "Cupons": "1-25767"
  },
  {
    "Cupons": "1-50944, 1-91709, 1-95504, 1-78544"
  },
  {
    "Cupons": "1-82188, 1-14948, 1-48865, 1-83578, 1-73996, 1-10202, 1-97690, 1-71825, 1-47650, 1-24111, 1-13522, 1-31033, 1-93390, 1-62656"
  },
  {
    "Cupons": "1-58865, 1-57423, 1-12553, 1-45027"
  },
  {
    "Cupons": "1-33491, 1-15154, 1-16893, 1-85581"
  },
  {
    "Cupons": "1-76718"
  },
  {
    "Cupons": "1-72492, 1-95543, 1-93749, 1-90420"
  },
  {
    "Cupons": "1-19077, 1-10111, 1-62357, 1-21762, 1-25843, 1-84240, 1-41347, 1-69739, 1-20965, 1-26807, 1-16169, 1-76918, 1-61587, 1-59113"
  },
  {
    "Cupons": "1-62223, 1-36741, 1-86063, 1-99335"
  },
  {
    "Cupons": "1-97922, 1-11944, 1-84393, 1-74814"
  },
  {
    "Cupons": "1-35366, 1-13373, 1-53659, 1-16750"
  },
  {
    "Cupons": "1-27889"
  },
  {
    "Cupons": "1-50648"
  },
  {
    "Cupons": "1-40008"
  },
  {
    "Cupons": "1-45010"
  },
  {
    "Cupons": "1-77625, 1-33859, 1-54861, 1-82511"
  },
  {
    "Cupons": "1-34596"
  },
  {
    "Cupons": "1-60417"
  },
  {
    "Cupons": "1-98804, 1-49826, 1-18446, 1-96326"
  },
  {
    "Cupons": "1-47450, 1-12680, 1-94250, 1-47926"
  },
  {
    "Cupons": "1-61212"
  },
  {
    "Cupons": "1-35793"
  },
  {
    "Cupons": "1-66128, 1-35566, 1-68206, 1-99857, 1-23664, 1-98965, 1-68065, 1-27732, 1-50383, 1-10658, 1-77488"
  },
  {
    "Cupons": "1-93793"
  },
  {
    "Cupons": "1-42397"
  },
  {
    "Cupons": "1-10590"
  },
  {
    "Cupons": "1-33494"
  },
  {
    "Cupons": "1-47913"
  },
  {
    "Cupons": "1-17966"
  },
  {
    "Cupons": "1-72804, 1-54457, 1-51586, 1-53330"
  },
  {
    "Cupons": "1-32005, 1-68239, 1-50085, 1-11733"
  },
  {
    "Cupons": "1-61638"
  },
  {
    "Cupons": "1-16698, 1-89468, 1-69849, 1-15271"
  },
  {
    "Cupons": "1-29362"
  },
  {
    "Cupons": "1-44910"
  },
  {
    "Cupons": "1-57775, 1-81671, 1-69411, 1-24625"
  },
  {
    "Cupons": "1-13307, 1-68055, 1-31424, 1-81041, 1-89874, 1-94051, 1-98051, 1-41798, 1-93482, 1-52172, 1-76938"
  },
  {
    "Cupons": "1-60796, 1-60819, 1-94465, 1-14574"
  },
  {
    "Cupons": "1-49484"
  },
  {
    "Cupons": "1-42419"
  },
  {
    "Cupons": "1-94838"
  },
  {
    "Cupons": "1-74050, 1-88054, 1-86645, 1-17043"
  },
  {
    "Cupons": "1-64015"
  },
  {
    "Cupons": "1-56171"
  },
  {
    "Cupons": "1-98830, 1-28493, 1-60657, 1-61771, 1-42514, 1-47549, 1-95312, 1-43313, 1-95720, 1-80734, 1-95735"
  },
  {
    "Cupons": "1-71304, 1-62891, 1-59481, 1-26103, 1-30554, 1-87789, 1-61845, 1-93178, 1-22075, 1-76936, 1-19328, 1-91054, 1-87504, 1-85301"
  },
  {
    "Cupons": "1-70173"
  },
  {
    "Cupons": "1-87295, 1-50591, 1-41570, 1-66173"
  },
  {
    "Cupons": "1-37419, 1-24872, 1-90762, 1-17907"
  },
  {
    "Cupons": "1-53871"
  },
  {
    "Cupons": "1-93110, 1-50847, 1-79767, 1-48360, 1-58744, 1-37244, 1-54337, 1-23945, 1-26131, 1-77012, 1-20289, 1-94790, 1-41017, 1-82840"
  },
  {
    "Cupons": "1-66224, 1-24157, 1-94666, 1-51445, 1-12140, 1-87573, 1-13543, 1-24135, 1-10345, 1-81710, 1-18944, 1-38315, 1-95213, 1-33946"
  },
  {
    "Cupons": "1-16993, 1-84935, 1-78485, 1-84518, 1-62955, 1-33751, 1-89898, 1-37240, 1-77518, 1-51934, 1-46573, 1-46410, 1-26019, 1-36835"
  },
  {
    "Cupons": "1-25964, 1-46289, 1-34411, 1-74777"
  },
  {
    "Cupons": "1-10229"
  },
  {
    "Cupons": "1-43291, 1-84736, 1-26746, 1-48520"
  },
  {
    "Cupons": "1-49861"
  },
  {
    "Cupons": "1-78434"
  },
  {
    "Cupons": "1-22133, 1-21715, 1-12241, 1-30816"
  },
  {
    "Cupons": "1-64743, 1-42174, 1-72553, 1-79674"
  },
  {
    "Cupons": "1-49036, 1-43166, 1-97853, 1-96559"
  },
  {
    "Cupons": "1-68240"
  },
  {
    "Cupons": "1-72646, 1-10368, 1-26191, 1-72384"
  },
  {
    "Cupons": "1-16514, 1-64691, 1-98273, 1-19269"
  },
  {
    "Cupons": "1-26878, 1-69841, 1-92583, 1-63024"
  },
  {
    "Cupons": "1-88014, 1-87007, 1-67841, 1-14823"
  },
  {
    "Cupons": "1-41245, 1-22682, 1-63431, 1-92756, 1-76526, 1-85343, 1-20434, 1-84235, 1-56490, 1-37760, 1-24496, 1-42405, 1-21648, 1-95745"
  },
  {
    "Cupons": "1-49236"
  },
  {
    "Cupons": "1-79439"
  },
  {
    "Cupons": "1-23095, 1-58439, 1-15592, 1-55377, 1-94313, 1-19845, 1-47240, 1-35185, 1-83694, 1-59523, 1-28316"
  },
  {
    "Cupons": "1-35921"
  },
  {
    "Cupons": "1-12767"
  },
  {
    "Cupons": "1-54911, 1-43058, 1-40137, 1-68750"
  },
  {
    "Cupons": "1-48067, 1-63955, 1-33490, 1-25473"
  },
  {
    "Cupons": "1-21166, 1-46755, 1-51579, 1-63464, 1-88362, 1-61571, 1-44865, 1-48429, 1-42381, 1-81264, 1-63626, 1-80114, 1-26872, 1-33801"
  },
  {
    "Cupons": "1-73757"
  },
  {
    "Cupons": "1-32727, 1-33842, 1-71630, 1-39879, 1-39399, 1-68511, 1-69271, 1-82825, 1-20979, 1-20640, 1-88636, 1-50896, 1-94641, 1-42308"
  },
  {
    "Cupons": "1-32979"
  },
  {
    "Cupons": "1-62481, 1-25764, 1-77090, 1-94198"
  },
  {
    "Cupons": "1-59476, 1-83365, 1-30207, 1-94280"
  },
  {
    "Cupons": "1-26912"
  },
  {
    "Cupons": "1-78702"
  },
  {
    "Cupons": "1-77374"
  },
  {
    "Cupons": "1-55975, 1-71195, 1-45750, 1-24945"
  },
  {
    "Cupons": "1-55912, 1-24404, 1-41877, 1-89903"
  },
  {
    "Cupons": "1-75589, 1-91508, 1-59423, 1-83959, 1-71055, 1-22288, 1-31967, 1-91486, 1-64461, 1-37812, 1-85893, 1-15994, 1-36033, 1-27510"
  },
  {
    "Cupons": "1-29116, 1-88479, 1-13839, 1-81381"
  },
  {
    "Cupons": "1-86594"
  },
  {
    "Cupons": "1-70119"
  },
  {
    "Cupons": "1-31333"
  },
  {
    "Cupons": "1-56058"
  },
  {
    "Cupons": "1-83659"
  },
  {
    "Cupons": "1-65009"
  },
  {
    "Cupons": "1-62788"
  },
  {
    "Cupons": "1-95362"
  },
  {
    "Cupons": "1-47492"
  },
  {
    "Cupons": "1-52286, 1-27285, 1-19942, 1-71437"
  },
  {
    "Cupons": "1-40990"
  },
  {
    "Cupons": "1-17186"
  },
  {
    "Cupons": "1-26260, 1-26861, 1-49663, 1-67081"
  },
  {
    "Cupons": "1-96500, 1-10078, 1-77062, 1-90215"
  },
  {
    "Cupons": "1-50241, 1-61616, 1-28528, 1-15568"
  },
  {
    "Cupons": "1-92194"
  },
  {
    "Cupons": "1-12841, 1-56086, 1-99423, 1-89481, 1-76215, 1-63949, 1-87555, 1-62668, 1-80821, 1-52678, 1-56502, 1-95078, 1-96515, 1-87151"
  },
  {
    "Cupons": "1-97200, 1-27462, 1-44895, 1-71065"
  },
  {
    "Cupons": "1-17924, 1-17350, 1-10477, 1-37327, 1-50277, 1-63279, 1-51555, 1-31956, 1-68652, 1-95841, 1-67397, 1-82452, 1-58258, 1-92973"
  },
  {
    "Cupons": "1-75515"
  },
  {
    "Cupons": "1-91646, 1-37592, 1-75332, 1-60219, 1-32259, 1-50495, 1-32128, 1-41208, 1-31266, 1-65099, 1-93984, 1-84623, 1-46004, 1-13433"
  },
  {
    "Cupons": "1-64104, 1-66508, 1-58151, 1-25280, 1-90353, 1-85114, 1-41357, 1-98123, 1-57884, 1-59737, 1-32597, 1-60961, 1-68757, 1-46207"
  },
  {
    "Cupons": "1-30043, 1-20908, 1-67973, 1-29432"
  },
  {
    "Cupons": "1-98077"
  },
  {
    "Cupons": "1-74172"
  },
  {
    "Cupons": "1-84742"
  },
  {
    "Cupons": "1-40244"
  },
  {
    "Cupons": "1-33483"
  },
  {
    "Cupons": "1-78986, 1-58434, 1-88047, 1-75974"
  },
  {
    "Cupons": "1-94620"
  },
  {
    "Cupons": "1-99355, 1-94840, 1-79914, 1-99879"
  },
  {
    "Cupons": "1-54180, 1-42779, 1-68400, 1-20599"
  },
  {
    "Cupons": "1-44435, 1-46506, 1-43625, 1-70166, 1-74078, 1-99368, 1-21908, 1-72179, 1-17564, 1-36974, 1-32636"
  },
  {
    "Cupons": "1-64101"
  },
  {
    "Cupons": "1-70655"
  },
  {
    "Cupons": "1-64234, 1-59053, 1-72819, 1-36767"
  },
  {
    "Cupons": "1-19580"
  },
  {
    "Cupons": "1-85322, 1-49073, 1-87095, 1-30205"
  },
  {
    "Cupons": "1-26088"
  },
  {
    "Cupons": "1-60056, 1-97107, 1-91273, 1-60767, 1-48652, 1-67760, 1-40063, 1-34104, 1-46552, 1-86868, 1-50239, 1-35194, 1-29467, 1-51771"
  },
  {
    "Cupons": "1-36632, 1-18346, 1-46762, 1-61423"
  },
  {
    "Cupons": "1-79623, 1-93882, 1-63153, 1-43986"
  },
  {
    "Cupons": "1-48987, 1-53193, 1-27306, 1-91645"
  },
  {
    "Cupons": "1-93491, 1-55211, 1-96934, 1-18543, 1-47154, 1-50563, 1-91891, 1-12663, 1-38398, 1-23578, 1-91919, 1-64781, 1-78450, 1-17110"
  },
  {
    "Cupons": "1-39284"
  },
  {
    "Cupons": "1-80973, 1-34884, 1-46687, 1-37940, 1-74554, 1-45509, 1-31566, 1-13806, 1-57893, 1-12667, 1-35978, 1-66279, 1-12755, 1-52882"
  },
  {
    "Cupons": "1-97873"
  },
  {
    "Cupons": "1-59712, 1-19570, 1-13993, 1-15767"
  },
  {
    "Cupons": "1-18113, 1-71014, 1-56960, 1-45326, 1-96658, 1-49556, 1-92554, 1-24726, 1-21206, 1-17717, 1-53602, 1-63728, 1-63311, 1-27245"
  },
  {
    "Cupons": "1-97389, 1-29532, 1-30814, 1-60999, 1-54221, 1-30961, 1-18326, 1-34030, 1-33451, 1-47245, 1-83275"
  },
  {
    "Cupons": "1-72565"
  },
  {
    "Cupons": "1-97303, 1-96648, 1-67119, 1-38013, 1-70290, 1-16793, 1-88157, 1-13054, 1-44543, 1-95215, 1-68667, 1-64974, 1-95217, 1-63884"
  },
  {
    "Cupons": "1-14806, 1-52439, 1-98117, 1-19237"
  },
  {
    "Cupons": "1-24737, 1-33257, 1-63942, 1-89748, 1-73462, 1-94111, 1-27161, 1-71198, 1-29316, 1-63201, 1-47468, 1-65926, 1-52774, 1-64432"
  },
  {
    "Cupons": "1-62819, 1-44830, 1-53253, 1-65046"
  },
  {
    "Cupons": "1-44985, 1-71552, 1-81995, 1-35325, 1-13377, 1-91624, 1-82547, 1-79103, 1-82007, 1-58754, 1-31255, 1-84309, 1-37643, 1-13865"
  },
  {
    "Cupons": "1-39143"
  },
  {
    "Cupons": "1-83785"
  },
  {
    "Cupons": "1-90969, 1-47094, 1-87807, 1-36224"
  },
  {
    "Cupons": "1-78914"
  },
  {
    "Cupons": "1-97181"
  },
  {
    "Cupons": "1-85821"
  },
  {
    "Cupons": "1-99539"
  },
  {
    "Cupons": "1-90054"
  },
  {
    "Cupons": "1-29897"
  },
  {
    "Cupons": "1-12739"
  },
  {
    "Cupons": "1-91519"
  },
  {
    "Cupons": "1-45822"
  },
  {
    "Cupons": "1-21573, 1-69600, 1-37828, 1-48061"
  },
  {
    "Cupons": "1-18671, 1-21711, 1-81081, 1-52272"
  },
  {
    "Cupons": "1-69954"
  },
  {
    "Cupons": "1-72228, 1-42829, 1-15537, 1-44703, 1-43395, 1-92561, 1-45754, 1-79613, 1-73600, 1-25798, 1-79671, 1-93716, 1-13276, 1-18796"
  },
  {
    "Cupons": "1-91764, 1-27584, 1-44601, 1-62852"
  },
  {
    "Cupons": "1-33963"
  },
  {
    "Cupons": "1-17413, 1-82116, 1-34113, 1-94217"
  },
  {
    "Cupons": "1-88818, 1-66712, 1-54571, 1-53879, 1-15517, 1-22185, 1-16465, 1-71377, 1-16110, 1-46419, 1-11659, 1-32991, 1-31104, 1-19714"
  },
  {
    "Cupons": "1-60434"
  },
  {
    "Cupons": "1-85947"
  },
  {
    "Cupons": "1-53789"
  },
  {
    "Cupons": "1-64288"
  },
  {
    "Cupons": "1-77530"
  },
  {
    "Cupons": "1-84424, 1-84602, 1-64514, 1-56531, 1-14387, 1-35932, 1-46186, 1-32790, 1-96869, 1-43827, 1-91363, 1-82688, 1-89125, 1-82051"
  },
  {
    "Cupons": "1-13914"
  },
  {
    "Cupons": "1-40765, 1-32115, 1-95594, 1-75487"
  },
  {
    "Cupons": "1-81687, 1-93981, 1-25890, 1-57411"
  },
  {
    "Cupons": "1-35581"
  },
  {
    "Cupons": "1-69138"
  },
  {
    "Cupons": "1-52217"
  },
  {
    "Cupons": "1-82478"
  },
  {
    "Cupons": "1-27389, 1-39449, 1-87778, 1-66190, 1-54645, 1-47671, 1-37603, 1-51933, 1-37242, 1-34995, 1-11180, 1-52510, 1-86275, 1-47060"
  },
  {
    "Cupons": "1-19510, 1-92676, 1-47548, 1-94226, 1-44044, 1-80315, 1-63619, 1-71176, 1-89846, 1-60506, 1-22410, 1-15562, 1-62052, 1-23314"
  },
  {
    "Cupons": "1-74878"
  },
  {
    "Cupons": "1-12025"
  },
  {
    "Cupons": "1-63267, 1-11504, 1-44243, 1-71624"
  },
  {
    "Cupons": "1-90583"
  },
  {
    "Cupons": "1-32131"
  },
  {
    "Cupons": "1-82735, 1-65684, 1-30406, 1-76545"
  },
  {
    "Cupons": "1-73279"
  },
  {
    "Cupons": "1-20431"
  },
  {
    "Cupons": "1-25213, 1-39386, 1-90988, 1-47063"
  },
  {
    "Cupons": "1-89823"
  },
  {
    "Cupons": "1-79529, 1-81174, 1-11857, 1-98173"
  },
  {
    "Cupons": "1-96285"
  },
  {
    "Cupons": "1-75937"
  },
  {
    "Cupons": "1-94557"
  },
  {
    "Cupons": "1-51287, 1-75001, 1-24387, 1-64590"
  },
  {
    "Cupons": "1-13668"
  },
  {
    "Cupons": "1-68043"
  },
  {
    "Cupons": "1-82510, 1-32960, 1-49785, 1-92429"
  },
  {
    "Cupons": "1-23199"
  },
  {
    "Cupons": "1-90546"
  },
  {
    "Cupons": "1-36600"
  },
  {
    "Cupons": "1-53693"
  },
  {
    "Cupons": "1-49536"
  },
  {
    "Cupons": "1-34821"
  },
  {
    "Cupons": "1-68833"
  },
  {
    "Cupons": "1-17225, 1-69421, 1-12960, 1-25679"
  },
  {
    "Cupons": "1-61320"
  },
  {
    "Cupons": "1-63564"
  },
  {
    "Cupons": "1-66246, 1-69209, 1-38617, 1-99843, 1-97087, 1-77995, 1-25860, 1-94266, 1-73076, 1-34060, 1-72367, 1-25589, 1-81368, 1-91922"
  },
  {
    "Cupons": "1-18434, 1-55635, 1-12839, 1-62066, 1-45950, 1-96018, 1-90366, 1-90840, 1-30844, 1-73387, 1-62888, 1-32411, 1-41339, 1-91631"
  },
  {
    "Cupons": "1-38881, 1-22648, 1-54228, 1-12972, 1-69892, 1-53579, 1-33591, 1-64797, 1-35281, 1-17288, 1-24721, 1-23936, 1-81308, 1-52579"
  },
  {
    "Cupons": "1-29091"
  },
  {
    "Cupons": "1-74879, 1-22796, 1-48347, 1-98419"
  },
  {
    "Cupons": "1-77845"
  },
  {
    "Cupons": "1-72020, 1-14505, 1-98232, 1-82759, 1-10405, 1-80990, 1-40345, 1-99912, 1-10862, 1-38232, 1-66506, 1-84276, 1-45417, 1-93406"
  },
  {
    "Cupons": "1-49544"
  },
  {
    "Cupons": "1-10632, 1-84344, 1-10926, 1-17708, 1-27238, 1-40293, 1-63782, 1-47719, 1-82830, 1-86733, 1-77640, 1-23228, 1-34255, 1-70284"
  },
  {
    "Cupons": "1-80256, 1-61479, 1-39851, 1-69272"
  },
  {
    "Cupons": "1-12664"
  },
  {
    "Cupons": "1-83642"
  },
  {
    "Cupons": "1-69362"
  },
  {
    "Cupons": "1-22326, 1-97362, 1-76119, 1-95651, 1-36074, 1-24557, 1-67959, 1-36263, 1-90001, 1-59812, 1-64327, 1-60679, 1-88954, 1-47740"
  },
  {
    "Cupons": "1-51027, 1-11684, 1-97434, 1-81513, 1-91028, 1-91549, 1-73851, 1-21827, 1-69113, 1-92141, 1-98882, 1-86769, 1-89350, 1-28361"
  },
  {
    "Cupons": "1-34747"
  },
  {
    "Cupons": "1-47316, 1-37801, 1-39844, 1-18154"
  },
  {
    "Cupons": "1-74825, 1-63364, 1-30917, 1-24781"
  },
  {
    "Cupons": "1-10039, 1-20533, 1-98147, 1-66619, 1-97758, 1-76501, 1-98658, 1-11862, 1-41379, 1-67885, 1-34895, 1-88936, 1-81801, 1-50822"
  },
  {
    "Cupons": "1-90811"
  },
  {
    "Cupons": "1-91353"
  },
  {
    "Cupons": "1-49727, 1-40686, 1-98953, 1-24604, 1-48135, 1-73753, 1-61514, 1-80862, 1-96431, 1-76945, 1-63755, 1-98721, 1-71043, 1-12611"
  },
  {
    "Cupons": "1-49232"
  },
  {
    "Cupons": "1-64200, 1-49656, 1-85969, 1-33223, 1-89692, 1-49598, 1-23298, 1-85284, 1-32453, 1-92351, 1-50122, 1-97231, 1-85997, 1-45650"
  },
  {
    "Cupons": "1-34287"
  },
  {
    "Cupons": "1-30198, 1-30068, 1-89953, 1-94713, 1-42630, 1-22171, 1-57558, 1-42269, 1-59785, 1-65699, 1-74042, 1-15646, 1-30985, 1-70487, 1-36442, 1-46190, 1-73928, 1-76411, 1-77212, 1-86094, 1-90788, 1-24119, 1-43460, 1-43562, 1-99500, 1-12585, 1-36993, 1-72707, 1-31347, 1-89904, 1-37650, 1-27998, 1-81681, 1-92347, 1-36075, 1-71378, 1-33096, 1-23204, 1-99026, 1-26657, 1-27875, 1-93350, 1-70968, 1-86190, 1-86449, 1-47288, 1-58244, 1-25775, 1-82488, 1-55155, 1-65955, 1-58493, 1-73669, 1-45191, 1-53498, 1-30794, 1-89970, 1-45819, 1-41672, 1-77558, 1-66983, 1-16755, 1-75081, 1-70266, 1-40195, 1-87943, 1-42133, 1-51008, 1-94880, 1-49617, 1-58064, 1-41679, 1-72640, 1-56259"
  },
  {
    "Cupons": "1-38670, 1-23505, 1-67450, 1-39358"
  },
  {
    "Cupons": "1-76713"
  },
  {
    "Cupons": "1-94169, 1-31173, 1-87458, 1-63228, 1-88314, 1-32585, 1-80751, 1-64382, 1-53424, 1-70960, 1-34280, 1-64965, 1-45790, 1-92923"
  },
  {
    "Cupons": "1-53827, 1-25470, 1-44231, 1-82880"
  },
  {
    "Cupons": "1-16384, 1-85908, 1-89587, 1-22121"
  },
  {
    "Cupons": "1-60501, 1-32448, 1-89740, 1-38454, 1-25771, 1-24110, 1-89442, 1-99201, 1-90394, 1-99887, 1-16593, 1-86324, 1-68344, 1-99272"
  },
  {
    "Cupons": "1-16366"
  },
  {
    "Cupons": "1-26437"
  },
  {
    "Cupons": "1-63483"
  },
  {
    "Cupons": "1-50957"
  },
  {
    "Cupons": "1-85172"
  },
  {
    "Cupons": "1-57977, 1-17427, 1-25723, 1-73448"
  },
  {
    "Cupons": "1-95803"
  },
  {
    "Cupons": "1-47293"
  },
  {
    "Cupons": "1-59845, 1-31167, 1-88689, 1-57030, 1-51932, 1-83766, 1-43815, 1-48853, 1-56368, 1-60407, 1-20621, 1-19141, 1-77682, 1-35385"
  },
  {
    "Cupons": "1-84325"
  },
  {
    "Cupons": "1-95065"
  },
  {
    "Cupons": "1-80657"
  },
  {
    "Cupons": "1-94513"
  },
  {
    "Cupons": "1-37275, 1-37084, 1-12098, 1-30377"
  },
  {
    "Cupons": "1-95605"
  },
  {
    "Cupons": "1-72924"
  },
  {
    "Cupons": "1-14584"
  },
  {
    "Cupons": "1-33726, 1-92212, 1-39468, 1-92938"
  },
  {
    "Cupons": "1-17933"
  },
  {
    "Cupons": "1-17002"
  },
  {
    "Cupons": "1-30607"
  },
  {
    "Cupons": "1-43311"
  },
  {
    "Cupons": "1-45406"
  },
  {
    "Cupons": "1-21645, 1-58641, 1-69090, 1-66692"
  },
  {
    "Cupons": "1-10182, 1-83030, 1-72693, 1-40284, 1-27441, 1-90396, 1-41012, 1-53818, 1-49088, 1-52228, 1-23206, 1-27079, 1-45473, 1-72152"
  },
  {
    "Cupons": "1-70841, 1-83997, 1-10037, 1-45669, 1-82382, 1-52202, 1-32873, 1-40177, 1-93556, 1-84475, 1-48912, 1-12046, 1-42608, 1-88423"
  },
  {
    "Cupons": "1-26145"
  },
  {
    "Cupons": "1-47456, 1-28075, 1-47546, 1-25112, 1-73906, 1-36126, 1-25433, 1-67028, 1-36029, 1-36569, 1-22237, 1-87951, 1-84860, 1-23575"
  },
  {
    "Cupons": "1-71798"
  },
  {
    "Cupons": "1-41675"
  },
  {
    "Cupons": "1-36604"
  },
  {
    "Cupons": "1-94335, 1-81376, 1-25619, 1-32242, 1-54824, 1-61105, 1-60570, 1-67538, 1-12026, 1-12981, 1-60241"
  },
  {
    "Cupons": "1-85513"
  },
  {
    "Cupons": "1-34714"
  },
  {
    "Cupons": "1-67998"
  },
  {
    "Cupons": "1-25428"
  },
  {
    "Cupons": "1-88883"
  },
  {
    "Cupons": "1-95596"
  },
  {
    "Cupons": "1-65055, 1-94485, 1-71789, 1-64967, 1-12845, 1-78650, 1-18361, 1-67103, 1-50033, 1-82539, 1-51472, 1-50061, 1-14126, 1-35046"
  },
  {
    "Cupons": "1-36519, 1-23953, 1-15284, 1-56060, 1-13969, 1-12610, 1-78392, 1-96020, 1-17882, 1-42534, 1-44130, 1-24679, 1-65530, 1-39456"
  },
  {
    "Cupons": "1-42333"
  },
  {
    "Cupons": "1-58504, 1-43905, 1-82272, 1-53547, 1-33453, 1-57892, 1-12102, 1-76642, 1-25344, 1-67478, 1-31352"
  },
  {
    "Cupons": "1-38018"
  },
  {
    "Cupons": "1-77405, 1-58051, 1-40584, 1-60299, 1-89008, 1-72859, 1-42035, 1-71712, 1-87971, 1-80097, 1-85235, 1-92811, 1-51452, 1-95523"
  },
  {
    "Cupons": "1-17401, 1-82724, 1-30757, 1-32905, 1-79512, 1-65724, 1-99305, 1-90226, 1-88923, 1-12429, 1-68303"
  },
  {
    "Cupons": "1-46007"
  },
  {
    "Cupons": "1-78371, 1-29635, 1-90426, 1-77210, 1-15023, 1-88730, 1-49819, 1-73483, 1-58395, 1-58360, 1-76080, 1-17063, 1-37530, 1-55086"
  },
  {
    "Cupons": "1-74810"
  },
  {
    "Cupons": "1-41924, 1-31871, 1-43600, 1-20191, 1-82803, 1-57810, 1-37531, 1-70551, 1-21925, 1-28109, 1-96126, 1-33367, 1-10026, 1-28504"
  },
  {
    "Cupons": "1-71423"
  },
  {
    "Cupons": "1-69644"
  },
  {
    "Cupons": "1-55655, 1-17326, 1-89651, 1-86175"
  },
  {
    "Cupons": "1-56778, 1-10032, 1-86551, 1-33547"
  },
  {
    "Cupons": "1-60201, 1-24316, 1-22117, 1-91763, 1-14752, 1-25716, 1-30173, 1-41914, 1-73307, 1-36650, 1-24895, 1-94067, 1-49679, 1-52933"
  },
  {
    "Cupons": "1-25492"
  },
  {
    "Cupons": "1-12641"
  },
  {
    "Cupons": "1-79037"
  },
  {
    "Cupons": "1-52820"
  },
  {
    "Cupons": "1-70793, 1-68775, 1-24266, 1-15860, 1-30962, 1-45766, 1-59962, 1-29737, 1-32891, 1-57111, 1-89138, 1-15567, 1-48153, 1-37972"
  },
  {
    "Cupons": "1-53356"
  },
  {
    "Cupons": "1-62538, 1-34319, 1-21632, 1-47049"
  },
  {
    "Cupons": "1-66052"
  },
  {
    "Cupons": "1-35319, 1-86229, 1-92857, 1-99904, 1-78738, 1-37776, 1-87436, 1-86691, 1-41617, 1-62327, 1-12432, 1-49123, 1-12982, 1-86554"
  },
  {
    "Cupons": "1-13740, 1-92187, 1-44658, 1-92099"
  },
  {
    "Cupons": "1-23993"
  },
  {
    "Cupons": "1-58403, 1-88286, 1-79920, 1-63844, 1-69259, 1-88679, 1-18027, 1-98932, 1-16088, 1-64954, 1-62449, 1-18331, 1-65994, 1-54096"
  },
  {
    "Cupons": "1-70496"
  },
  {
    "Cupons": "1-31598"
  },
  {
    "Cupons": "1-59528"
  },
  {
    "Cupons": "1-67136"
  },
  {
    "Cupons": "1-31704"
  },
  {
    "Cupons": "1-74604, 1-80597, 1-44198, 1-45854"
  },
  {
    "Cupons": "1-85323"
  },
  {
    "Cupons": "1-88331"
  },
  {
    "Cupons": "1-37805, 1-21147, 1-22375, 1-51398"
  },
  {
    "Cupons": "1-94184, 1-84456, 1-53563, 1-97036"
  },
  {
    "Cupons": "1-22768, 1-76442, 1-33459, 1-83553"
  },
  {
    "Cupons": "1-65149"
  },
  {
    "Cupons": "1-89240, 1-81049, 1-43575, 1-84457, 1-30076, 1-47576, 1-61595, 1-12227, 1-14962, 1-84951, 1-92477, 1-75340, 1-15686, 1-62100"
  },
  {
    "Cupons": "1-11934, 1-72315, 1-74867, 1-44943"
  },
  {
    "Cupons": "1-16785"
  },
  {
    "Cupons": "1-49486, 1-99629, 1-30559, 1-70850"
  },
  {
    "Cupons": "1-70624, 1-11198, 1-44622, 1-38759"
  },
  {
    "Cupons": "1-55100, 1-75720, 1-75688, 1-52917, 1-10582, 1-41858, 1-13376, 1-54631, 1-84557, 1-41618, 1-25783, 1-20348, 1-55097, 1-88544"
  },
  {
    "Cupons": "1-72576"
  },
  {
    "Cupons": "1-82832"
  },
  {
    "Cupons": "1-90479"
  },
  {
    "Cupons": "1-63424"
  },
  {
    "Cupons": "1-53793, 1-29533, 1-90734, 1-32211, 1-51380, 1-24396, 1-74715, 1-63597, 1-75895, 1-34906, 1-86360, 1-59123, 1-84837, 1-23335"
  },
  {
    "Cupons": "1-97114, 1-61066, 1-79393, 1-94035, 1-19990, 1-84382, 1-37121, 1-36997, 1-92852, 1-75264, 1-29878, 1-92600, 1-20350, 1-95600"
  },
  {
    "Cupons": "1-52981, 1-43930, 1-24307, 1-11409"
  },
  {
    "Cupons": "1-65568, 1-24434, 1-39144, 1-55326"
  },
  {
    "Cupons": "1-33715, 1-59339, 1-58372, 1-64402, 1-47533, 1-53832, 1-21286, 1-68623, 1-73177, 1-22289, 1-26503, 1-33542, 1-14184, 1-11213"
  },
  {
    "Cupons": "1-14664"
  },
  {
    "Cupons": "1-47987"
  },
  {
    "Cupons": "1-88753"
  },
  {
    "Cupons": "1-80621, 1-46367, 1-57399, 1-58625, 1-63630, 1-48409, 1-93535, 1-36896, 1-12450, 1-80546, 1-34622, 1-44799, 1-82714, 1-27925"
  },
  {
    "Cupons": "1-79457"
  },
  {
    "Cupons": "1-32248, 1-14700, 1-85512, 1-25294"
  },
  {
    "Cupons": "1-30201"
  },
  {
    "Cupons": "1-14532"
  },
  {
    "Cupons": "1-64091, 1-41612, 1-88907, 1-57548"
  },
  {
    "Cupons": "1-58966"
  },
  {
    "Cupons": "1-97884"
  },
  {
    "Cupons": "1-74660"
  },
  {
    "Cupons": "1-89639, 1-31080, 1-38908, 1-44111"
  },
  {
    "Cupons": "1-43971, 1-92954, 1-14506, 1-43270"
  },
  {
    "Cupons": "1-44559"
  },
  {
    "Cupons": "1-25048, 1-45060, 1-72600, 1-60541, 1-39485, 1-17164, 1-38215, 1-67235, 1-33948, 1-65176, 1-30709, 1-10084, 1-76695, 1-77560"
  },
  {
    "Cupons": "1-25021"
  },
  {
    "Cupons": "1-98078"
  },
  {
    "Cupons": "1-23096"
  },
  {
    "Cupons": "1-31450"
  },
  {
    "Cupons": "1-12749"
  },
  {
    "Cupons": "1-23607, 1-52204, 1-50592, 1-10999, 1-21497, 1-16556, 1-58260, 1-89358, 1-46222, 1-27558, 1-90475, 1-10006, 1-91945, 1-23033"
  },
  {
    "Cupons": "1-11069, 1-12577, 1-17330, 1-89334"
  },
  {
    "Cupons": "1-54671"
  },
  {
    "Cupons": "1-91219"
  },
  {
    "Cupons": "1-31267"
  },
  {
    "Cupons": "1-95988"
  },
  {
    "Cupons": "1-95111"
  },
  {
    "Cupons": "1-16121"
  },
  {
    "Cupons": "1-36105"
  },
  {
    "Cupons": "1-81985"
  },
  {
    "Cupons": "1-93602"
  },
  {
    "Cupons": "1-25598"
  },
  {
    "Cupons": "1-32824"
  },
  {
    "Cupons": "1-36267, 1-70583, 1-81781, 1-87008"
  },
  {
    "Cupons": "1-86427"
  },
  {
    "Cupons": "1-39163, 1-64961, 1-96808, 1-32990, 1-46672, 1-27450, 1-37586, 1-63809, 1-88243, 1-96457, 1-64713"
  },
  {
    "Cupons": "1-51770"
  },
  {
    "Cupons": "1-83542, 1-42317, 1-99996, 1-23814, 1-52454, 1-40796, 1-16140, 1-45764, 1-78237, 1-63000, 1-56983, 1-96019, 1-72145, 1-27187"
  },
  {
    "Cupons": "1-49376"
  },
  {
    "Cupons": "1-60305"
  },
  {
    "Cupons": "1-96325"
  },
  {
    "Cupons": "1-10637"
  },
  {
    "Cupons": "1-92668"
  },
  {
    "Cupons": "1-40174"
  },
  {
    "Cupons": "1-62890, 1-79391, 1-40422, 1-18957, 1-48696, 1-26781, 1-10997, 1-67254, 1-26389, 1-50353, 1-78866"
  },
  {
    "Cupons": "1-25665, 1-92136, 1-68263, 1-30038"
  },
  {
    "Cupons": "1-83651, 1-58377, 1-41352, 1-38349, 1-96276, 1-31384, 1-47028, 1-14968, 1-49457, 1-72114, 1-74420, 1-73808, 1-15721, 1-14562"
  },
  {
    "Cupons": "1-77332"
  },
  {
    "Cupons": "1-95933, 1-80164, 1-91642, 1-40662, 1-99490, 1-34403, 1-15648, 1-69520, 1-93323, 1-22071, 1-19858, 1-27733, 1-34196, 1-88039"
  },
  {
    "Cupons": "1-43501"
  },
  {
    "Cupons": "1-34059"
  },
  {
    "Cupons": "1-11506"
  },
  {
    "Cupons": "1-68304"
  },
  {
    "Cupons": "1-25811"
  },
  {
    "Cupons": "1-91304"
  },
  {
    "Cupons": "1-12043, 1-62008, 1-17197, 1-44373, 1-29414, 1-29872, 1-11842, 1-68981, 1-32106, 1-24422, 1-94921, 1-55228, 1-42055, 1-78191"
  },
  {
    "Cupons": "1-88555, 1-10226, 1-17706, 1-80974, 1-96063, 1-78627, 1-33561, 1-96319, 1-71116, 1-77634, 1-57462, 1-16991, 1-19084, 1-72949"
  },
  {
    "Cupons": "1-49807"
  },
  {
    "Cupons": "1-44292"
  },
  {
    "Cupons": "1-89033, 1-43636, 1-77653, 1-33816"
  },
  {
    "Cupons": "1-58416"
  },
  {
    "Cupons": "1-81686, 1-56062, 1-75796, 1-36193, 1-12423, 1-67480, 1-83404, 1-80395, 1-74204, 1-69384, 1-29007, 1-28063, 1-68059, 1-22759"
  },
  {
    "Cupons": "1-14245"
  },
  {
    "Cupons": "1-86035, 1-31092, 1-38110, 1-51500"
  },
  {
    "Cupons": "1-65648, 1-76541, 1-51497, 1-95615, 1-13510, 1-93950, 1-96652, 1-21807, 1-23561, 1-98011, 1-94232, 1-24722, 1-19743, 1-25299"
  },
  {
    "Cupons": "1-26258"
  },
  {
    "Cupons": "1-18439, 1-20111, 1-17074, 1-17442, 1-14702, 1-69948, 1-93566, 1-51898, 1-24988, 1-72840, 1-12262, 1-19358, 1-20691, 1-98702"
  },
  {
    "Cupons": "1-39552"
  },
  {
    "Cupons": "1-33484"
  },
  {
    "Cupons": "1-53853"
  },
  {
    "Cupons": "1-94248"
  },
  {
    "Cupons": "1-18631"
  },
  {
    "Cupons": "1-58681"
  },
  {
    "Cupons": "1-40173"
  },
  {
    "Cupons": "1-33160"
  },
  {
    "Cupons": "1-48680, 1-61891, 1-64728, 1-70153"
  },
  {
    "Cupons": "1-93578"
  },
  {
    "Cupons": "1-72293"
  },
  {
    "Cupons": "1-65393, 1-35287, 1-97251, 1-22868, 1-39942, 1-13250, 1-97332, 1-80584, 1-15075, 1-84207, 1-69710, 1-45765, 1-21654, 1-48855"
  },
  {
    "Cupons": "1-49550, 1-83596, 1-67961, 1-92602, 1-19154, 1-31187, 1-94404, 1-55527, 1-52269, 1-32936, 1-15012"
  },
  {
    "Cupons": "1-21661"
  },
  {
    "Cupons": "1-27866"
  },
  {
    "Cupons": "1-86678"
  },
  {
    "Cupons": "1-58464"
  },
  {
    "Cupons": "1-18994"
  },
  {
    "Cupons": "1-76146"
  },
  {
    "Cupons": "1-95547"
  },
  {
    "Cupons": "1-83876, 1-52541, 1-74485, 1-22500, 1-49041, 1-91181, 1-32942, 1-39598, 1-13722, 1-26328, 1-20227"
  },
  {
    "Cupons": "1-46500, 1-42659, 1-42860, 1-44920, 1-23997, 1-74224, 1-65866, 1-73691, 1-11775, 1-27699, 1-49063, 1-96016, 1-32295, 1-73992"
  },
  {
    "Cupons": "1-20250"
  },
  {
    "Cupons": "1-36357"
  },
  {
    "Cupons": "1-44513"
  },
  {
    "Cupons": "1-76778"
  },
  {
    "Cupons": "1-84104, 1-17231, 1-76044, 1-24941, 1-65918, 1-83368, 1-39728, 1-64164, 1-94257, 1-80691, 1-88638, 1-90938, 1-95333, 1-23632"
  },
  {
    "Cupons": "1-72839"
  },
  {
    "Cupons": "1-44657"
  },
  {
    "Cupons": "1-18382"
  },
  {
    "Cupons": "1-21989"
  },
  {
    "Cupons": "1-15608"
  },
  {
    "Cupons": "1-66946"
  },
  {
    "Cupons": "1-66585"
  },
  {
    "Cupons": "1-94012"
  },
  {
    "Cupons": "1-41180"
  },
  {
    "Cupons": "1-86849"
  },
  {
    "Cupons": "1-44178"
  },
  {
    "Cupons": "1-10209, 1-25478, 1-93982, 1-43576, 1-22242, 1-32672, 1-76487, 1-29971, 1-50745, 1-75710, 1-59299, 1-83252, 1-31989, 1-95009"
  },
  {
    "Cupons": "1-94293"
  },
  {
    "Cupons": "1-77996, 1-91724, 1-58922, 1-20148, 1-39220, 1-15065, 1-43076, 1-36345, 1-68948, 1-78147, 1-14094, 1-12911, 1-18753, 1-88012"
  },
  {
    "Cupons": "1-22061, 1-42330, 1-98746, 1-95469, 1-82924, 1-79371, 1-85703, 1-46025, 1-22424, 1-67821, 1-57837, 1-66208, 1-47540, 1-83050"
  },
  {
    "Cupons": "1-23114"
  },
  {
    "Cupons": "1-68482"
  },
  {
    "Cupons": "1-94616"
  },
  {
    "Cupons": "1-27498"
  },
  {
    "Cupons": "1-86922"
  },
  {
    "Cupons": "1-97278"
  },
  {
    "Cupons": "1-95516, 1-35832, 1-52637, 1-51690"
  },
  {
    "Cupons": "1-51023"
  },
  {
    "Cupons": "1-90177"
  },
  {
    "Cupons": "1-78123, 1-42734, 1-12307, 1-15068"
  },
  {
    "Cupons": "1-44016"
  },
  {
    "Cupons": "1-23850"
  },
  {
    "Cupons": "1-53873"
  },
  {
    "Cupons": "1-83895"
  },
  {
    "Cupons": "1-69246"
  },
  {
    "Cupons": "1-83392"
  },
  {
    "Cupons": "1-42265, 1-37165, 1-62273, 1-33828, 1-14934, 1-29708, 1-12532, 1-36920, 1-66362, 1-68126, 1-32844, 1-97367, 1-75278, 1-45420"
  },
  {
    "Cupons": "1-96922"
  },
  {
    "Cupons": "1-43507, 1-42408, 1-98247, 1-37362"
  },
  {
    "Cupons": "1-72232"
  },
  {
    "Cupons": "1-55207, 1-83902, 1-97713, 1-91264"
  },
  {
    "Cupons": "1-25186"
  },
  {
    "Cupons": "1-48740, 1-17290, 1-84461, 1-12795"
  },
  {
    "Cupons": "1-75170"
  },
  {
    "Cupons": "1-49482"
  },
  {
    "Cupons": "1-93097"
  },
  {
    "Cupons": "1-49145"
  },
  {
    "Cupons": "1-93804"
  },
  {
    "Cupons": "1-93636"
  },
  {
    "Cupons": "1-95173, 1-63957, 1-78965, 1-90829, 1-79298, 1-75098, 1-44581, 1-93599, 1-53172, 1-75750, 1-52864"
  },
  {
    "Cupons": "1-15378"
  },
  {
    "Cupons": "1-32435"
  },
  {
    "Cupons": "1-47609"
  },
  {
    "Cupons": "1-56754, 1-45332, 1-27930, 1-50074"
  },
  {
    "Cupons": "1-52326"
  },
  {
    "Cupons": "1-89758"
  },
  {
    "Cupons": "1-43655"
  },
  {
    "Cupons": "1-40529, 1-33471, 1-70650, 1-14652"
  },
  {
    "Cupons": "1-30647, 1-98544, 1-86900, 1-44917, 1-10534, 1-32590, 1-30018, 1-99432, 1-61768, 1-64615, 1-18968, 1-15120, 1-95364, 1-22119"
  },
  {
    "Cupons": "1-77806"
  },
  {
    "Cupons": "1-56466, 1-23703, 1-72118, 1-59532"
  },
  {
    "Cupons": "1-94993"
  },
  {
    "Cupons": "1-81655"
  },
  {
    "Cupons": "1-46642"
  },
  {
    "Cupons": "1-70370, 1-13908, 1-25522, 1-64483"
  },
  {
    "Cupons": "1-88673"
  },
  {
    "Cupons": "1-45899"
  },
  {
    "Cupons": "1-46733"
  },
  {
    "Cupons": "1-90293"
  },
  {
    "Cupons": "1-26342, 1-31286, 1-66537, 1-22409"
  },
  {
    "Cupons": "1-96406"
  },
  {
    "Cupons": "1-69736"
  },
  {
    "Cupons": "1-38634"
  },
  {
    "Cupons": "1-85221, 1-73641, 1-73905, 1-13192, 1-81412, 1-39506, 1-12535, 1-30904, 1-77410, 1-49340, 1-56246, 1-89661, 1-95793, 1-68041"
  },
  {
    "Cupons": "1-84901"
  },
  {
    "Cupons": "1-94063, 1-95164, 1-67736, 1-73312"
  },
  {
    "Cupons": "1-82628"
  },
  {
    "Cupons": "1-75695"
  },
  {
    "Cupons": "1-94324"
  },
  {
    "Cupons": "1-28358"
  },
  {
    "Cupons": "1-99211"
  },
  {
    "Cupons": "1-95586"
  },
  {
    "Cupons": "1-10721"
  },
  {
    "Cupons": "1-60446"
  },
  {
    "Cupons": "1-80520, 1-14438, 1-83445, 1-19051, 1-60332, 1-60238, 1-22078, 1-17690, 1-66825, 1-10010, 1-62212"
  },
  {
    "Cupons": "1-18492, 1-11850, 1-39151, 1-63933, 1-73359, 1-27910, 1-39280, 1-49566, 1-72277, 1-28453, 1-56330"
  },
  {
    "Cupons": "1-80847"
  },
  {
    "Cupons": "1-86178"
  },
  {
    "Cupons": "1-25591, 1-79915, 1-50864, 1-10677"
  },
  {
    "Cupons": "1-40356"
  },
  {
    "Cupons": "1-66786, 1-45243, 1-11086, 1-40123, 1-28578, 1-55404, 1-52032, 1-86707, 1-19619, 1-78181, 1-78872, 1-52369, 1-72127, 1-62339"
  },
  {
    "Cupons": "1-27083"
  },
  {
    "Cupons": "1-92398"
  },
  {
    "Cupons": "1-44900"
  },
  {
    "Cupons": "1-67095"
  },
  {
    "Cupons": "1-20682, 1-78552, 1-20865, 1-13920, 1-83984, 1-56348, 1-35653, 1-34784, 1-17122, 1-14891, 1-82934, 1-69467, 1-40489, 1-33422"
  },
  {
    "Cupons": "1-12179, 1-54473, 1-64623, 1-28929"
  },
  {
    "Cupons": "1-19464"
  },
  {
    "Cupons": "1-21950"
  },
  {
    "Cupons": "1-84966"
  },
  {
    "Cupons": "1-26786"
  },
  {
    "Cupons": "1-51466"
  },
  {
    "Cupons": "1-84659"
  },
  {
    "Cupons": "1-98442"
  },
  {
    "Cupons": "1-71076, 1-76827, 1-68670, 1-34027, 1-31421, 1-23157, 1-69061, 1-83269, 1-52448, 1-92408, 1-47827, 1-88626, 1-77513, 1-25467"
  },
  {
    "Cupons": "1-96595, 1-57003, 1-20798, 1-50996"
  },
  {
    "Cupons": "1-34249"
  },
  {
    "Cupons": "1-61943"
  },
  {
    "Cupons": "1-58211, 1-14981, 1-69130, 1-57760"
  },
  {
    "Cupons": "1-61953"
  },
  {
    "Cupons": "1-63777"
  },
  {
    "Cupons": "1-80012, 1-62908, 1-12069, 1-69471, 1-65950, 1-36769, 1-93078, 1-58846, 1-55426, 1-30348, 1-29797, 1-18916, 1-26230, 1-94990"
  },
  {
    "Cupons": "1-16533, 1-20068, 1-29175, 1-67994, 1-96802, 1-54708, 1-70053, 1-12913, 1-16681, 1-81633, 1-23306, 1-33875, 1-85991, 1-19926"
  },
  {
    "Cupons": "1-59474"
  },
  {
    "Cupons": "1-56483"
  },
  {
    "Cupons": "1-86696, 1-47000, 1-62241, 1-23008"
  },
  {
    "Cupons": "1-69003, 1-92112, 1-17800, 1-71646, 1-86557, 1-71588, 1-38473, 1-45292, 1-77922, 1-83486, 1-22538, 1-46417, 1-83461, 1-91457, 1-44099, 1-70589, 1-81625, 1-86885, 1-18502, 1-84890, 1-83244, 1-93065, 1-13056, 1-48277, 1-35981, 1-46364, 1-86193, 1-41777, 1-61186, 1-40640, 1-85265, 1-71235, 1-84253, 1-77891, 1-74952, 1-47857, 1-71983, 1-28571, 1-70197, 1-41800, 1-32073, 1-68621, 1-61015, 1-34446, 1-64924, 1-50605, 1-51133, 1-85367, 1-46961, 1-96703, 1-58939, 1-27841, 1-38752, 1-41552, 1-47384, 1-93985, 1-43089, 1-63151, 1-84545, 1-46619, 1-43446, 1-51037, 1-21485, 1-96636, 1-21450, 1-96657, 1-72531, 1-50115, 1-61606, 1-32680, 1-88475, 1-52225, 1-19597, 1-74863"
  },
  {
    "Cupons": "1-96957"
  },
  {
    "Cupons": "1-81551"
  },
  {
    "Cupons": "1-47179"
  },
  {
    "Cupons": "1-29215, 1-49953, 1-37815, 1-98356, 1-80828, 1-11947, 1-63604, 1-13213, 1-21194, 1-52208, 1-93892"
  },
  {
    "Cupons": "1-63703, 1-99017, 1-11886, 1-70821"
  },
  {
    "Cupons": "1-67781, 1-97018, 1-27901, 1-69231, 1-36850, 1-26691, 1-85215, 1-71156, 1-32995, 1-85800, 1-95432, 1-49763, 1-30878, 1-24487"
  },
  {
    "Cupons": "1-91857, 1-98343, 1-96770, 1-84357, 1-65792, 1-53677, 1-87053, 1-38357, 1-23731, 1-92992, 1-48728, 1-53001, 1-76408, 1-72362"
  },
  {
    "Cupons": "1-31542, 1-31743, 1-71397, 1-87169"
  },
  {
    "Cupons": "1-57183, 1-94920, 1-18972, 1-36221, 1-86801, 1-91143, 1-64337, 1-83013, 1-81220, 1-37013, 1-40339, 1-24549, 1-28969, 1-34426"
  },
  {
    "Cupons": "1-15152"
  },
  {
    "Cupons": "1-18695"
  },
  {
    "Cupons": "1-89276, 1-31464, 1-73206, 1-92796, 1-49848, 1-75082, 1-31966, 1-64168, 1-83792, 1-40336, 1-38444, 1-83415, 1-58305, 1-93190"
  },
  {
    "Cupons": "1-79824, 1-42613, 1-88034, 1-13775, 1-73740, 1-70158, 1-70989, 1-70134, 1-93118, 1-29115, 1-35169, 1-92473, 1-19760, 1-39798"
  },
  {
    "Cupons": "1-47359, 1-35106, 1-45925, 1-20984, 1-19779, 1-13533, 1-16241, 1-79627, 1-24720, 1-10715, 1-86056, 1-68537, 1-68071, 1-65641"
  },
  {
    "Cupons": "1-75726"
  },
  {
    "Cupons": "1-80415, 1-34075, 1-93320, 1-80616"
  },
  {
    "Cupons": "1-16027"
  },
  {
    "Cupons": "1-13370, 1-45545, 1-21506, 1-94888, 1-43977, 1-84801, 1-85472, 1-79696, 1-28466, 1-38712, 1-74190"
  },
  {
    "Cupons": "1-66004"
  },
  {
    "Cupons": "1-92355"
  },
  {
    "Cupons": "1-26779"
  },
  {
    "Cupons": "1-24527, 1-46169, 1-80155, 1-94008"
  },
  {
    "Cupons": "1-68113"
  },
  {
    "Cupons": "1-38328"
  },
  {
    "Cupons": "1-74230, 1-21622, 1-85662, 1-79789, 1-79505, 1-25197, 1-81808, 1-35926, 1-62822, 1-13917, 1-57681, 1-95020, 1-89310, 1-25129"
  },
  {
    "Cupons": "1-85388"
  },
  {
    "Cupons": "1-64148, 1-26957, 1-20419, 1-26296"
  },
  {
    "Cupons": "1-94110, 1-29514, 1-52549, 1-95410, 1-26500, 1-16404, 1-52960, 1-71129, 1-66793, 1-50376, 1-34605"
  },
  {
    "Cupons": "1-54858, 1-40668, 1-20947, 1-65399, 1-88653, 1-13735, 1-57738, 1-91881, 1-41466, 1-43839, 1-74464, 1-58252, 1-64171, 1-30781"
  },
  {
    "Cupons": "1-93547"
  },
  {
    "Cupons": "1-82356"
  },
  {
    "Cupons": "1-64161"
  },
  {
    "Cupons": "1-74476"
  },
  {
    "Cupons": "1-49825, 1-94396, 1-69104, 1-26303"
  },
  {
    "Cupons": "1-52324"
  },
  {
    "Cupons": "1-98716"
  },
  {
    "Cupons": "1-31625"
  },
  {
    "Cupons": "1-65646, 1-46643, 1-73286, 1-90924, 1-23593, 1-60938, 1-84999, 1-16291, 1-35724, 1-47501, 1-34922, 1-99989, 1-69242, 1-74845"
  },
  {
    "Cupons": "1-31596"
  },
  {
    "Cupons": "1-24301"
  },
  {
    "Cupons": "1-95356"
  },
  {
    "Cupons": "1-23140"
  },
  {
    "Cupons": "1-45791, 1-37028, 1-34234, 1-26710, 1-58202, 1-16630, 1-19386, 1-60281, 1-99864, 1-18081, 1-97312, 1-40496, 1-61417, 1-47889"
  },
  {
    "Cupons": "1-98580, 1-87823, 1-52053, 1-34151"
  },
  {
    "Cupons": "1-58774"
  },
  {
    "Cupons": "1-46426, 1-55014, 1-71090, 1-82670"
  },
  {
    "Cupons": "1-72695"
  },
  {
    "Cupons": "1-76558"
  },
  {
    "Cupons": "1-70023"
  },
  {
    "Cupons": "1-28632, 1-67163, 1-10268, 1-55846"
  },
  {
    "Cupons": "1-68969"
  },
  {
    "Cupons": "1-46236, 1-40949, 1-81728, 1-92381, 1-43486, 1-47136, 1-91726, 1-77203, 1-78015, 1-89605, 1-30023"
  },
  {
    "Cupons": "1-15622, 1-45175, 1-74025, 1-79990"
  },
  {
    "Cupons": "1-51490"
  },
  {
    "Cupons": "1-90754"
  },
  {
    "Cupons": "1-56910"
  },
  {
    "Cupons": "1-88855, 1-82112, 1-29166, 1-73935, 1-67661, 1-25154, 1-22886, 1-96118, 1-13037, 1-95815, 1-89502"
  },
  {
    "Cupons": "1-67924"
  },
  {
    "Cupons": "1-96232, 1-51592, 1-96781, 1-83890"
  },
  {
    "Cupons": "1-91029"
  },
  {
    "Cupons": "1-86851"
  },
  {
    "Cupons": "1-16765"
  },
  {
    "Cupons": "1-16272"
  },
  {
    "Cupons": "1-90914"
  },
  {
    "Cupons": "1-94017"
  },
  {
    "Cupons": "1-67682"
  },
  {
    "Cupons": "1-96530, 1-29381, 1-29475, 1-60687"
  },
  {
    "Cupons": "1-35109, 1-23175, 1-48257, 1-71638, 1-11660, 1-60021, 1-55658, 1-57416, 1-49226, 1-39982, 1-65400, 1-92588, 1-19812, 1-56845"
  },
  {
    "Cupons": "1-97640, 1-89682, 1-50188, 1-10925, 1-20140, 1-10615, 1-19531, 1-53509, 1-84956, 1-40236, 1-81794, 1-34011, 1-88645, 1-54288, 1-33952, 1-93449, 1-49003, 1-78113, 1-84710, 1-48365, 1-41010, 1-33035, 1-17998, 1-37766, 1-72585, 1-85706, 1-46536, 1-99581, 1-25066, 1-77594, 1-28032, 1-41682, 1-64119, 1-91698, 1-40019, 1-33277, 1-93270, 1-67380, 1-35338, 1-50826, 1-67639, 1-44063, 1-91779, 1-67471, 1-46280, 1-52184, 1-71344, 1-79269, 1-77857, 1-46898, 1-13614, 1-34910, 1-91692, 1-13110, 1-70262, 1-54921, 1-24563, 1-79900, 1-77078, 1-46412, 1-96142, 1-64916, 1-36042, 1-18249, 1-19897, 1-78427, 1-39566, 1-74618, 1-51528, 1-27856, 1-70735, 1-79216, 1-34734, 1-34873"
  },
  {
    "Cupons": "1-94258"
  },
  {
    "Cupons": "1-78674"
  },
  {
    "Cupons": "1-45784"
  },
  {
    "Cupons": "1-45368"
  },
  {
    "Cupons": "1-78728"
  },
  {
    "Cupons": "1-48676, 1-84157, 1-85479, 1-57724, 1-24055, 1-33583, 1-50584, 1-15109, 1-20683, 1-98315, 1-58996, 1-32215, 1-34523, 1-37856"
  },
  {
    "Cupons": "1-25835"
  },
  {
    "Cupons": "1-70865, 1-22719, 1-35139, 1-92590, 1-72903, 1-82829, 1-37388, 1-54250, 1-39549, 1-10960, 1-33505, 1-94812, 1-70656, 1-75232"
  },
  {
    "Cupons": "1-19307"
  },
  {
    "Cupons": "1-52445"
  },
  {
    "Cupons": "1-89828, 1-66689, 1-98429, 1-51289"
  },
  {
    "Cupons": "1-57242"
  },
  {
    "Cupons": "1-87963"
  },
  {
    "Cupons": "1-50020"
  },
  {
    "Cupons": "1-30594"
  },
  {
    "Cupons": "1-54875"
  },
  {
    "Cupons": "1-43653, 1-19351, 1-81552, 1-31729, 1-10417, 1-59425, 1-19810, 1-80429, 1-99049, 1-99798, 1-56957, 1-70913, 1-15644, 1-74982"
  },
  {
    "Cupons": "1-78648, 1-65416, 1-39988, 1-54477, 1-90242, 1-98060, 1-94590, 1-87190, 1-64281, 1-82184, 1-26074, 1-76252, 1-41279, 1-25561"
  },
  {
    "Cupons": "1-87643, 1-61497, 1-65291, 1-58880"
  },
  {
    "Cupons": "1-14621, 1-92329, 1-53328, 1-34863"
  },
  {
    "Cupons": "1-17778, 1-89335, 1-10613, 1-86644"
  },
  {
    "Cupons": "1-72457, 1-12951, 1-68800, 1-81094, 1-71079, 1-23696, 1-47528, 1-84004, 1-48862, 1-34338, 1-56669, 1-26032, 1-33220, 1-61858"
  },
  {
    "Cupons": "1-69951, 1-56582, 1-47762, 1-87843, 1-58679, 1-73002, 1-91679, 1-83629, 1-63258, 1-57731, 1-52831, 1-44037, 1-13319, 1-47305"
  },
  {
    "Cupons": "1-15423"
  },
  {
    "Cupons": "1-70908, 1-77429, 1-77570, 1-76935"
  },
  {
    "Cupons": "1-55231, 1-76597, 1-26818, 1-39661"
  },
  {
    "Cupons": "1-22170, 1-65285, 1-31069, 1-95052, 1-55896, 1-93635, 1-37398, 1-65438, 1-57125, 1-27752, 1-38496"
  },
  {
    "Cupons": "1-98271"
  },
  {
    "Cupons": "1-77055"
  },
  {
    "Cupons": "1-60157, 1-67295, 1-95057, 1-86773, 1-99168, 1-91276, 1-72438, 1-95016, 1-35840, 1-86996, 1-71447, 1-75015, 1-24432, 1-22151"
  },
  {
    "Cupons": "1-30354"
  },
  {
    "Cupons": "1-22938"
  },
  {
    "Cupons": "1-36833"
  },
  {
    "Cupons": "1-48030, 1-39002, 1-94741, 1-41716"
  },
  {
    "Cupons": "1-79580, 1-91075, 1-72126, 1-69945"
  },
  {
    "Cupons": "1-25195, 1-17144, 1-91790, 1-48896"
  },
  {
    "Cupons": "1-42881, 1-33125, 1-79791, 1-88140, 1-42804, 1-40643, 1-64098, 1-78694, 1-50754, 1-10553, 1-94722, 1-79790, 1-71904, 1-44138"
  },
  {
    "Cupons": "1-55999"
  },
  {
    "Cupons": "1-13607"
  },
  {
    "Cupons": "1-14929, 1-74133, 1-29050, 1-88303"
  },
  {
    "Cupons": "1-77930"
  },
  {
    "Cupons": "1-33637, 1-25701, 1-15758, 1-35236"
  },
  {
    "Cupons": "1-86039, 1-75258, 1-95268, 1-32384"
  },
  {
    "Cupons": "1-24008, 1-66378, 1-27004, 1-78964"
  },
  {
    "Cupons": "1-55542"
  },
  {
    "Cupons": "1-23408, 1-73597, 1-72239, 1-88810, 1-53315, 1-17502, 1-79503, 1-72542, 1-59784, 1-52876, 1-75901, 1-90115, 1-52772, 1-66383"
  },
  {
    "Cupons": "1-97390, 1-33180, 1-44299, 1-34808"
  },
  {
    "Cupons": "1-87006"
  },
  {
    "Cupons": "1-59131"
  },
  {
    "Cupons": "1-54052, 1-42254, 1-53382, 1-53414"
  },
  {
    "Cupons": "1-37181"
  },
  {
    "Cupons": "1-95028"
  },
  {
    "Cupons": "1-70048, 1-98534, 1-85972, 1-79908"
  },
  {
    "Cupons": "1-43909"
  },
  {
    "Cupons": "1-30330, 1-92210, 1-86376, 1-33947, 1-49368, 1-19195, 1-85827, 1-56711, 1-59648, 1-31284, 1-13112, 1-67257, 1-36215, 1-79028"
  },
  {
    "Cupons": "1-30931, 1-20033, 1-25905, 1-73632"
  },
  {
    "Cupons": "1-26454, 1-59081, 1-38595, 1-97767, 1-95306, 1-35838, 1-23728, 1-48085, 1-20762, 1-29268, 1-82676, 1-73351, 1-24767, 1-49029"
  },
  {
    "Cupons": "1-83087"
  },
  {
    "Cupons": "1-30969"
  },
  {
    "Cupons": "1-29237"
  },
  {
    "Cupons": "1-36795"
  },
  {
    "Cupons": "1-66847"
  },
  {
    "Cupons": "1-48729, 1-37528, 1-97978, 1-37457, 1-76856, 1-70808, 1-17267, 1-92730, 1-55134, 1-57524, 1-94948"
  },
  {
    "Cupons": "1-33205, 1-78479, 1-43542, 1-94483"
  },
  {
    "Cupons": "1-98467, 1-41205, 1-11431, 1-18287, 1-83908, 1-66017, 1-87755, 1-24924, 1-46897, 1-31993, 1-34960, 1-78047, 1-18933, 1-93014"
  },
  {
    "Cupons": "1-55768"
  },
  {
    "Cupons": "1-50341, 1-56055, 1-44356, 1-71919"
  },
  {
    "Cupons": "1-46923, 1-91293, 1-40946, 1-72298, 1-79091, 1-11500, 1-25500, 1-14132, 1-42742, 1-11145, 1-94593, 1-57141, 1-50445, 1-83722"
  },
  {
    "Cupons": "1-62737, 1-66928, 1-65371, 1-25603"
  },
  {
    "Cupons": "1-65863, 1-24009, 1-39257, 1-43998, 1-87683, 1-72316, 1-39691, 1-92937, 1-27310, 1-86387, 1-95310"
  },
  {
    "Cupons": "1-19746"
  },
  {
    "Cupons": "1-86401"
  },
  {
    "Cupons": "1-19223, 1-41958, 1-85034, 1-55937, 1-38537, 1-80411, 1-91627, 1-38440, 1-44623, 1-37110, 1-29544, 1-26577, 1-11985, 1-27496"
  },
  {
    "Cupons": "1-90130"
  },
  {
    "Cupons": "1-54399"
  },
  {
    "Cupons": "1-90925, 1-37816, 1-23685, 1-63692"
  },
  {
    "Cupons": "1-50772"
  },
  {
    "Cupons": "1-33387"
  },
  {
    "Cupons": "1-93967, 1-70162, 1-23067, 1-71336, 1-19961, 1-62747, 1-62674, 1-69817, 1-81048, 1-94690, 1-70136, 1-12300, 1-86792, 1-44990"
  },
  {
    "Cupons": "1-40305, 1-93150, 1-59560, 1-35544"
  },
  {
    "Cupons": "1-97999, 1-13724, 1-58006, 1-70779"
  },
  {
    "Cupons": "1-33413"
  },
  {
    "Cupons": "1-14289, 1-95974, 1-88937, 1-90765, 1-68200, 1-99918, 1-72378, 1-93357, 1-68253, 1-68935, 1-27421, 1-93966, 1-20325, 1-88893"
  },
  {
    "Cupons": "1-40143"
  },
  {
    "Cupons": "1-64375"
  },
  {
    "Cupons": "1-44787"
  },
  {
    "Cupons": "1-80989"
  },
  {
    "Cupons": "1-59389, 1-84300, 1-73642, 1-17786, 1-68663, 1-28148, 1-14461, 1-42584, 1-51844, 1-66861, 1-69723, 1-72609, 1-50416, 1-49121"
  },
  {
    "Cupons": "1-14147"
  },
  {
    "Cupons": "1-85857"
  },
  {
    "Cupons": "1-58793, 1-36085, 1-71550, 1-14861, 1-10868, 1-20773, 1-58363, 1-47692, 1-12364, 1-81191, 1-66068, 1-65841, 1-79132, 1-40902"
  },
  {
    "Cupons": "1-14228"
  },
  {
    "Cupons": "1-13111"
  },
  {
    "Cupons": "1-19953"
  },
  {
    "Cupons": "1-22878"
  },
  {
    "Cupons": "1-89037, 1-62895, 1-69192, 1-47980, 1-44232, 1-12247, 1-10748, 1-10518, 1-10159, 1-45098, 1-99810, 1-30440, 1-81432, 1-23672"
  },
  {
    "Cupons": "1-83063"
  },
  {
    "Cupons": "1-69371"
  },
  {
    "Cupons": "1-71590"
  },
  {
    "Cupons": "1-77017"
  },
  {
    "Cupons": "1-41523"
  },
  {
    "Cupons": "1-50960"
  },
  {
    "Cupons": "1-99041, 1-26292, 1-20719, 1-44466, 1-67360, 1-90658, 1-72365, 1-91163, 1-32165, 1-77916, 1-47237, 1-20530, 1-70720, 1-19271"
  },
  {
    "Cupons": "1-29547, 1-31021, 1-29956, 1-38214"
  },
  {
    "Cupons": "1-22808, 1-60086, 1-56233, 1-80898, 1-84434, 1-16885, 1-64622, 1-77158, 1-71680, 1-58557, 1-33461"
  },
  {
    "Cupons": "1-83622, 1-91312, 1-34781, 1-41688"
  },
  {
    "Cupons": "1-80386, 1-87928, 1-30841, 1-93680, 1-30860, 1-19259, 1-95307, 1-39772, 1-64625, 1-78584, 1-74501"
  },
  {
    "Cupons": "1-75798, 1-44645, 1-89375, 1-38891"
  },
  {
    "Cupons": "1-89876"
  },
  {
    "Cupons": "1-35010, 1-92093, 1-90390, 1-16051"
  },
  {
    "Cupons": "1-78273"
  },
  {
    "Cupons": "1-89235, 1-30172, 1-85631, 1-58200, 1-32501, 1-48456, 1-39363, 1-26793, 1-96647, 1-67151, 1-92101, 1-93117, 1-84745, 1-53412"
  },
  {
    "Cupons": "1-36409"
  },
  {
    "Cupons": "1-85717, 1-43624, 1-30408, 1-29921"
  },
  {
    "Cupons": "1-29368, 1-20978, 1-30121, 1-30153"
  },
  {
    "Cupons": "1-70339"
  },
  {
    "Cupons": "1-23025"
  },
  {
    "Cupons": "1-76644"
  },
  {
    "Cupons": "1-92294, 1-55829, 1-14781, 1-26268, 1-96753, 1-55292, 1-72672, 1-38994, 1-40995, 1-47100, 1-15099, 1-86985, 1-33247, 1-38972"
  },
  {
    "Cupons": "1-60459, 1-13226, 1-55672, 1-46492"
  },
  {
    "Cupons": "1-62922"
  },
  {
    "Cupons": "1-88762"
  },
  {
    "Cupons": "1-45501"
  },
  {
    "Cupons": "1-74504"
  },
  {
    "Cupons": "1-16884"
  },
  {
    "Cupons": "1-71315, 1-37802, 1-20796, 1-88036, 1-43340, 1-43818, 1-39372, 1-67726, 1-23567, 1-93764, 1-11580"
  },
  {
    "Cupons": "1-78131, 1-46766, 1-48842, 1-19128, 1-44885, 1-97924, 1-50999, 1-47497, 1-31363, 1-14686, 1-58496, 1-34123, 1-56826, 1-37882"
  },
  {
    "Cupons": "1-35588, 1-58804, 1-71753, 1-85242, 1-16821, 1-67954, 1-56025, 1-69243, 1-63348, 1-85518, 1-75338, 1-33723, 1-32168, 1-55458"
  },
  {
    "Cupons": "1-16473, 1-76826, 1-18398, 1-94954"
  },
  {
    "Cupons": "1-56008, 1-88513, 1-85580, 1-52133, 1-69402, 1-28157, 1-76822, 1-71320, 1-23041, 1-12648, 1-14181, 1-73853, 1-77138, 1-26644"
  },
  {
    "Cupons": "1-68862, 1-71833, 1-90955, 1-57945"
  },
  {
    "Cupons": "1-81232, 1-96517, 1-83151, 1-23081, 1-95233, 1-69118, 1-67645, 1-17586, 1-79607, 1-73467, 1-21594, 1-46786, 1-47470, 1-37347"
  },
  {
    "Cupons": "1-72974, 1-54953, 1-46429, 1-40893"
  },
  {
    "Cupons": "1-41405, 1-46176, 1-97909, 1-63162, 1-89088, 1-64252, 1-82276, 1-81854, 1-29456, 1-59089, 1-28386, 1-81115, 1-61850, 1-51432"
  },
  {
    "Cupons": "1-80387"
  },
  {
    "Cupons": "1-58215, 1-49524, 1-25458, 1-92078, 1-35974, 1-78796, 1-65223, 1-19871, 1-85558, 1-49635, 1-49186, 1-41681, 1-21158, 1-16020"
  },
  {
    "Cupons": "1-90022, 1-40338, 1-41669, 1-73367"
  },
  {
    "Cupons": "1-12946, 1-22740, 1-15718, 1-59899, 1-55261, 1-13801, 1-68287, 1-80463, 1-56414, 1-80908, 1-80351"
  },
  {
    "Cupons": "1-90497, 1-70598, 1-61799, 1-85968, 1-80558, 1-49301, 1-69741, 1-98594, 1-22812, 1-22395, 1-89672, 1-52894, 1-56656, 1-21335"
  },
  {
    "Cupons": "1-82627, 1-54069, 1-12529, 1-65734, 1-25233, 1-92649, 1-12448, 1-73118, 1-89539, 1-69010, 1-82254, 1-73261, 1-35695, 1-57489"
  },
  {
    "Cupons": "1-53485, 1-62354, 1-11222, 1-38091, 1-80244, 1-77500, 1-33535, 1-78435, 1-13721, 1-25746, 1-45525, 1-81490, 1-29224, 1-65764"
  },
  {
    "Cupons": "1-83763, 1-54374, 1-18007, 1-78362"
  },
  {
    "Cupons": "1-91323, 1-16130, 1-57509, 1-88438, 1-78159, 1-77463, 1-53941, 1-62482, 1-71460, 1-32002, 1-24312, 1-68772, 1-25442, 1-70442"
  },
  {
    "Cupons": "1-52417"
  },
  {
    "Cupons": "1-99031, 1-38755, 1-10108, 1-21396"
  },
  {
    "Cupons": "1-99862, 1-27061, 1-82193, 1-26489, 1-28552, 1-77032, 1-93008, 1-23171, 1-15690, 1-34448, 1-74544, 1-89226, 1-74623, 1-39006"
  },
  {
    "Cupons": "1-81002, 1-40813, 1-87460, 1-71032, 1-82653, 1-43298, 1-29443, 1-13697, 1-55031, 1-34930, 1-46078, 1-83528, 1-31822, 1-15998"
  },
  {
    "Cupons": "1-78944, 1-33467, 1-99343, 1-86555"
  },
  {
    "Cupons": "1-42171, 1-66637, 1-88877, 1-90146"
  },
  {
    "Cupons": "1-67903"
  },
  {
    "Cupons": "1-25652, 1-93987, 1-52708, 1-84180, 1-91368, 1-16457, 1-21803, 1-72627, 1-41278, 1-28699, 1-80879, 1-59012, 1-51687, 1-83646"
  },
  {
    "Cupons": "1-89889, 1-37764, 1-74277, 1-29644"
  },
  {
    "Cupons": "1-23358, 1-58852, 1-76194, 1-16001"
  },
  {
    "Cupons": "1-68865, 1-31608, 1-55892, 1-33139"
  },
  {
    "Cupons": "1-73278"
  },
  {
    "Cupons": "1-17941, 1-87503, 1-64947, 1-86173"
  },
  {
    "Cupons": "1-74345"
  },
  {
    "Cupons": "1-94314"
  },
  {
    "Cupons": "1-85797, 1-39535, 1-25230, 1-98221, 1-15103, 1-20937, 1-35298, 1-28226, 1-90482, 1-20163, 1-32267, 1-43074, 1-49641, 1-15923"
  },
  {
    "Cupons": "1-67608, 1-44775, 1-68413, 1-48651, 1-48753, 1-57930, 1-63097, 1-30427, 1-72038, 1-80477, 1-75851, 1-44589, 1-30655, 1-99386"
  },
  {
    "Cupons": "1-75894"
  },
  {
    "Cupons": "1-71926"
  },
  {
    "Cupons": "1-91544, 1-68519, 1-41494, 1-14153"
  },
  {
    "Cupons": "1-98202, 1-75767, 1-94966, 1-69698, 1-55512, 1-22066, 1-70967, 1-65862, 1-51838, 1-25980, 1-66123, 1-54103, 1-61984, 1-43511"
  },
  {
    "Cupons": "1-71752, 1-55939, 1-54591, 1-95924"
  },
  {
    "Cupons": "1-62665, 1-63839, 1-48824, 1-77727, 1-73059, 1-66583, 1-46458, 1-96778, 1-94959, 1-49132, 1-47778"
  },
  {
    "Cupons": "1-32048"
  },
  {
    "Cupons": "1-53061, 1-57869, 1-54840, 1-16289, 1-40606, 1-62393, 1-42989, 1-90301, 1-34716, 1-90729, 1-86467, 1-89236, 1-38554, 1-87486"
  },
  {
    "Cupons": "1-95997, 1-86033, 1-22914, 1-46079"
  },
  {
    "Cupons": "1-31479, 1-23749, 1-88273, 1-65567"
  },
  {
    "Cupons": "1-77884, 1-94113, 1-59995, 1-69879, 1-63598, 1-14424, 1-67857, 1-86255, 1-14316, 1-14755, 1-54710, 1-64568, 1-73608, 1-88844"
  },
  {
    "Cupons": "1-46749"
  },
  {
    "Cupons": "1-13166"
  },
  {
    "Cupons": "1-80699, 1-75057, 1-46304, 1-46374, 1-90010, 1-29347, 1-95462, 1-88441, 1-29437, 1-51259, 1-26199, 1-91232, 1-33725, 1-46627"
  },
  {
    "Cupons": "1-40787"
  },
  {
    "Cupons": "1-61789, 1-71906, 1-94427, 1-36806, 1-28100, 1-75241, 1-32132, 1-70785, 1-82213, 1-85429, 1-95194"
  },
  {
    "Cupons": "1-51789"
  },
  {
    "Cupons": "1-93659"
  },
  {
    "Cupons": "1-49911"
  },
  {
    "Cupons": "1-86844"
  },
  {
    "Cupons": "1-19294, 1-21696, 1-98254, 1-90160"
  },
  {
    "Cupons": "1-13220"
  },
  {
    "Cupons": "1-51249"
  },
  {
    "Cupons": "1-12612, 1-67469, 1-25015, 1-36007"
  },
  {
    "Cupons": "1-63475"
  },
  {
    "Cupons": "1-14116"
  },
  {
    "Cupons": "1-89213"
  },
  {
    "Cupons": "1-14037"
  },
  {
    "Cupons": "1-98421"
  },
  {
    "Cupons": "1-42934"
  },
  {
    "Cupons": "1-15204"
  },
  {
    "Cupons": "1-86049"
  },
  {
    "Cupons": "1-74462, 1-49638, 1-21084, 1-14741, 1-75291, 1-10433, 1-97872, 1-15813, 1-53554, 1-85060, 1-57715, 1-65318, 1-90220, 1-43398"
  },
  {
    "Cupons": "1-51961"
  },
  {
    "Cupons": "1-50973"
  },
  {
    "Cupons": "1-65235"
  },
  {
    "Cupons": "1-52724"
  },
  {
    "Cupons": "1-89929"
  },
  {
    "Cupons": "1-90243"
  },
  {
    "Cupons": "1-26523"
  },
  {
    "Cupons": "1-24218, 1-82777, 1-30783, 1-89199"
  },
  {
    "Cupons": "1-41874"
  },
  {
    "Cupons": "1-98779"
  },
  {
    "Cupons": "1-25556"
  },
  {
    "Cupons": "1-23592"
  },
  {
    "Cupons": "1-52696"
  },
  {
    "Cupons": "1-37845, 1-96687, 1-33235, 1-60995"
  },
  {
    "Cupons": "1-46060"
  },
  {
    "Cupons": "1-64213"
  },
  {
    "Cupons": "1-47250"
  },
  {
    "Cupons": "1-35614"
  },
  {
    "Cupons": "1-76208, 1-99135, 1-28000, 1-15774"
  },
  {
    "Cupons": "1-86316"
  },
  {
    "Cupons": "1-11160"
  },
  {
    "Cupons": "1-63154, 1-19838, 1-60141, 1-80077, 1-68706, 1-42766, 1-79814, 1-29986, 1-44777, 1-18452, 1-89050, 1-57610, 1-71325, 1-49023"
  },
  {
    "Cupons": "1-48091, 1-45151, 1-89723, 1-17229"
  },
  {
    "Cupons": "1-78002"
  },
  {
    "Cupons": "1-72147, 1-93163, 1-66234, 1-10436"
  },
  {
    "Cupons": "1-49995"
  },
  {
    "Cupons": "1-18334, 1-69872, 1-61665, 1-12195, 1-71323, 1-81603, 1-51995, 1-54745, 1-26209, 1-67012, 1-16663"
  },
  {
    "Cupons": "1-72474, 1-76218, 1-60198, 1-13828"
  },
  {
    "Cupons": "1-83299, 1-24630, 1-44468, 1-64848, 1-71985, 1-56563, 1-38431, 1-38639, 1-48584, 1-69628, 1-90780, 1-49734, 1-31718, 1-51467"
  },
  {
    "Cupons": "1-63102"
  },
  {
    "Cupons": "1-12418, 1-17035, 1-72418, 1-57640"
  },
  {
    "Cupons": "1-61307, 1-64714, 1-36186, 1-49684"
  },
  {
    "Cupons": "1-17032"
  },
  {
    "Cupons": "1-61842"
  },
  {
    "Cupons": "1-15698"
  },
  {
    "Cupons": "1-68453, 1-84272, 1-39660, 1-53310"
  },
  {
    "Cupons": "1-78977"
  },
  {
    "Cupons": "1-29350"
  },
  {
    "Cupons": "1-62624, 1-16555, 1-53239, 1-63891"
  },
  {
    "Cupons": "1-28635, 1-59054, 1-51168, 1-29771, 1-85681, 1-11583, 1-70523, 1-91352, 1-56292, 1-42058, 1-56804"
  },
  {
    "Cupons": "1-56925, 1-60423, 1-35316, 1-53821"
  },
  {
    "Cupons": "1-36123"
  },
  {
    "Cupons": "1-25762"
  },
  {
    "Cupons": "1-39436, 1-66759, 1-19628, 1-31282"
  },
  {
    "Cupons": "1-80837"
  },
  {
    "Cupons": "1-47076, 1-83467, 1-14482, 1-98940"
  },
  {
    "Cupons": "1-70636, 1-97317, 1-31192, 1-86712"
  },
  {
    "Cupons": "1-70501"
  },
  {
    "Cupons": "1-57756"
  },
  {
    "Cupons": "1-24253"
  },
  {
    "Cupons": "1-12108, 1-36622, 1-54423, 1-15482"
  },
  {
    "Cupons": "1-65068, 1-91839, 1-79354, 1-93434"
  },
  {
    "Cupons": "1-92331, 1-42891, 1-93682, 1-15250"
  },
  {
    "Cupons": "1-21228"
  },
  {
    "Cupons": "1-18152"
  },
  {
    "Cupons": "1-27252"
  },
  {
    "Cupons": "1-82879, 1-55253, 1-65152, 1-77575, 1-50725, 1-54894, 1-58023, 1-39548, 1-25044, 1-31144, 1-83907, 1-99072, 1-51534, 1-25399"
  },
  {
    "Cupons": "1-31937"
  },
  {
    "Cupons": "1-90102"
  },
  {
    "Cupons": "1-71775"
  },
  {
    "Cupons": "1-86834, 1-52370, 1-81140, 1-59309"
  },
  {
    "Cupons": "1-12115"
  },
  {
    "Cupons": "1-35698"
  },
  {
    "Cupons": "1-56107"
  },
  {
    "Cupons": "1-12492, 1-33777, 1-19364, 1-13417"
  },
  {
    "Cupons": "1-36061, 1-31153, 1-89882, 1-99359"
  },
  {
    "Cupons": "1-34480, 1-67221, 1-32807, 1-84815"
  },
  {
    "Cupons": "1-50963, 1-29975, 1-97934, 1-17355, 1-14292, 1-19022, 1-68118, 1-45852, 1-39746, 1-18826, 1-68739, 1-99538, 1-95023, 1-52488"
  },
  {
    "Cupons": "1-20751, 1-40880, 1-52024, 1-53983"
  },
  {
    "Cupons": "1-50098"
  },
  {
    "Cupons": "1-65528, 1-87148, 1-58514, 1-88308"
  },
  {
    "Cupons": "1-21691, 1-97148, 1-26218, 1-39362"
  },
  {
    "Cupons": "1-85538"
  },
  {
    "Cupons": "1-10257"
  },
  {
    "Cupons": "1-74152, 1-64355, 1-60454, 1-43950"
  },
  {
    "Cupons": "1-51858, 1-51762, 1-63196, 1-43780"
  },
  {
    "Cupons": "1-83427, 1-82629, 1-14512, 1-56100, 1-83039, 1-50980, 1-44758, 1-79667, 1-49595, 1-72360, 1-12335, 1-55675, 1-66124, 1-57946"
  },
  {
    "Cupons": "1-23043, 1-87210, 1-41835, 1-29195, 1-78038, 1-26252, 1-39864, 1-74789, 1-10255, 1-32402, 1-92169, 1-80696, 1-91638, 1-71143"
  },
  {
    "Cupons": "1-13997"
  },
  {
    "Cupons": "1-20726"
  },
  {
    "Cupons": "1-23859"
  },
  {
    "Cupons": "1-13659, 1-94951, 1-62739, 1-96323"
  },
  {
    "Cupons": "1-99752"
  },
  {
    "Cupons": "1-80885, 1-58031, 1-81354, 1-38794, 1-49249, 1-69285, 1-62837, 1-13122, 1-39007, 1-87552, 1-72460, 1-81573, 1-40272, 1-20423"
  },
  {
    "Cupons": "1-17385"
  },
  {
    "Cupons": "1-31839"
  },
  {
    "Cupons": "1-46877, 1-52191, 1-77576, 1-87407, 1-16679, 1-51273, 1-52041, 1-73674, 1-22484, 1-41388, 1-88547"
  },
  {
    "Cupons": "1-80025"
  },
  {
    "Cupons": "1-53343"
  },
  {
    "Cupons": "1-90312, 1-27317, 1-81061, 1-19523"
  },
  {
    "Cupons": "1-80797"
  },
  {
    "Cupons": "1-74278"
  },
  {
    "Cupons": "1-59463"
  },
  {
    "Cupons": "1-10824"
  },
  {
    "Cupons": "1-54955"
  },
  {
    "Cupons": "1-82684"
  },
  {
    "Cupons": "1-63845"
  },
  {
    "Cupons": "1-20665, 1-77543, 1-37206, 1-37699, 1-86886, 1-86302, 1-38463, 1-57167, 1-14711, 1-86981, 1-10230, 1-44836, 1-41057, 1-15949"
  },
  {
    "Cupons": "1-60967"
  },
  {
    "Cupons": "1-29421, 1-40977, 1-84222, 1-27256, 1-18897, 1-92035, 1-15534, 1-81450, 1-18376, 1-85611, 1-90900, 1-59965, 1-50242, 1-16874"
  },
  {
    "Cupons": "1-15613"
  },
  {
    "Cupons": "1-21181, 1-95896, 1-81869, 1-51530, 1-81019, 1-66739, 1-52482, 1-38820, 1-10647, 1-53072, 1-29027"
  },
  {
    "Cupons": "1-52164, 1-22520, 1-87680, 1-83920, 1-16117, 1-34595, 1-32519, 1-69066, 1-65732, 1-79940, 1-48536, 1-91818, 1-23954, 1-96106"
  },
  {
    "Cupons": "1-11768"
  },
  {
    "Cupons": "1-39093"
  },
  {
    "Cupons": "1-79622, 1-51141, 1-81570, 1-24268"
  },
  {
    "Cupons": "1-42956"
  },
  {
    "Cupons": "1-27890"
  },
  {
    "Cupons": "1-86520"
  },
  {
    "Cupons": "1-84130"
  },
  {
    "Cupons": "1-41403"
  },
  {
    "Cupons": "1-97115, 1-11356, 1-58125, 1-90418, 1-71615, 1-16409, 1-30193, 1-91447, 1-29219, 1-56392, 1-44800"
  },
  {
    "Cupons": "1-93713, 1-19145, 1-21487, 1-30456, 1-38308, 1-29576, 1-45328, 1-15980, 1-49404, 1-39357, 1-78789, 1-12074, 1-15864, 1-41059"
  },
  {
    "Cupons": "1-25985"
  },
  {
    "Cupons": "1-96381, 1-73242, 1-43025, 1-26430"
  },
  {
    "Cupons": "1-99106, 1-79799, 1-78416, 1-21972, 1-98325, 1-37525, 1-58088, 1-19357, 1-18989, 1-76578, 1-66019, 1-53968, 1-23295, 1-92008"
  },
  {
    "Cupons": "1-92321"
  },
  {
    "Cupons": "1-16085"
  },
  {
    "Cupons": "1-80045"
  },
  {
    "Cupons": "1-19737, 1-26840, 1-69124, 1-61617, 1-43536, 1-47367, 1-10399, 1-62073, 1-64094, 1-74459, 1-67159"
  },
  {
    "Cupons": "1-63858"
  },
  {
    "Cupons": "1-15797, 1-55972, 1-94434, 1-78729"
  },
  {
    "Cupons": "1-76274"
  },
  {
    "Cupons": "1-11344"
  },
  {
    "Cupons": "1-76924, 1-63202, 1-96236, 1-35396"
  },
  {
    "Cupons": "1-77056"
  },
  {
    "Cupons": "1-55274, 1-91574, 1-69924, 1-28298"
  },
  {
    "Cupons": "1-56683"
  },
  {
    "Cupons": "1-41407"
  },
  {
    "Cupons": "1-78540"
  },
  {
    "Cupons": "1-38164"
  },
  {
    "Cupons": "1-53950"
  },
  {
    "Cupons": "1-39130"
  },
  {
    "Cupons": "1-31031, 1-93866, 1-45373, 1-72899"
  },
  {
    "Cupons": "1-93500"
  },
  {
    "Cupons": "1-22430, 1-44844, 1-56736, 1-62943"
  },
  {
    "Cupons": "1-98425"
  },
  {
    "Cupons": "1-82842"
  },
  {
    "Cupons": "1-78652, 1-19278, 1-40409, 1-35296, 1-74928, 1-85664, 1-76619, 1-76983, 1-28232, 1-40748, 1-17480"
  },
  {
    "Cupons": "1-45445"
  },
  {
    "Cupons": "1-95439"
  },
  {
    "Cupons": "1-55495"
  },
  {
    "Cupons": "1-98179, 1-68018, 1-70917, 1-53036"
  },
  {
    "Cupons": "1-80333"
  },
  {
    "Cupons": "1-69310, 1-57335, 1-82909, 1-25341, 1-80192, 1-13160, 1-56225, 1-13621, 1-23451, 1-11248, 1-89648, 1-25415, 1-45483, 1-88460"
  },
  {
    "Cupons": "1-11816"
  },
  {
    "Cupons": "1-34459"
  },
  {
    "Cupons": "1-93285"
  },
  {
    "Cupons": "1-14725, 1-57478, 1-43006, 1-44273"
  },
  {
    "Cupons": "1-96174, 1-44600, 1-81489, 1-85617"
  },
  {
    "Cupons": "1-48908, 1-72922, 1-19817, 1-99156, 1-46626, 1-33647, 1-93638, 1-98486, 1-92931, 1-31111, 1-68135"
  },
  {
    "Cupons": "1-30691"
  },
  {
    "Cupons": "1-10874"
  },
  {
    "Cupons": "1-72724"
  },
  {
    "Cupons": "1-93645"
  },
  {
    "Cupons": "1-13808"
  },
  {
    "Cupons": "1-71095, 1-30809, 1-50260, 1-21411, 1-33546, 1-74529, 1-56565, 1-50450, 1-28303, 1-94939, 1-30958"
  },
  {
    "Cupons": "1-65736"
  },
  {
    "Cupons": "1-85477"
  },
  {
    "Cupons": "1-74106"
  },
  {
    "Cupons": "1-53527"
  },
  {
    "Cupons": "1-94187"
  },
  {
    "Cupons": "1-99700"
  },
  {
    "Cupons": "1-56383"
  },
  {
    "Cupons": "1-66872, 1-73202, 1-31565, 1-50456"
  },
  {
    "Cupons": "1-81306"
  },
  {
    "Cupons": "1-62753"
  },
  {
    "Cupons": "1-59376, 1-70950, 1-66399, 1-16645, 1-70268, 1-46213, 1-41955, 1-71545, 1-53967, 1-55510, 1-62633"
  },
  {
    "Cupons": "1-52406"
  },
  {
    "Cupons": "1-29086"
  },
  {
    "Cupons": "1-64610"
  },
  {
    "Cupons": "1-58600"
  },
  {
    "Cupons": "1-71041"
  },
  {
    "Cupons": "1-20885"
  },
  {
    "Cupons": "1-50977"
  },
  {
    "Cupons": "1-16701, 1-69959, 1-75466, 1-70566, 1-28701, 1-30498, 1-72842, 1-84621, 1-99435, 1-25383, 1-13726, 1-40850, 1-48345, 1-12251"
  },
  {
    "Cupons": "1-99485"
  },
  {
    "Cupons": "1-93805"
  },
  {
    "Cupons": "1-47111, 1-69322, 1-17885, 1-96379"
  },
  {
    "Cupons": "1-75208"
  },
  {
    "Cupons": "1-41817"
  },
  {
    "Cupons": "1-71082"
  },
  {
    "Cupons": "1-62889, 1-60352, 1-44708, 1-54007"
  },
  {
    "Cupons": "1-79445, 1-64349, 1-16538, 1-43693"
  },
  {
    "Cupons": "1-10736, 1-20968, 1-91104, 1-48156, 1-74870, 1-54965, 1-71465, 1-83786, 1-61314, 1-59337, 1-59710, 1-81643, 1-85170, 1-30964"
  },
  {
    "Cupons": "1-21856, 1-30717, 1-47709, 1-36757, 1-74804, 1-67401, 1-76628, 1-89643, 1-16918, 1-26849, 1-63712"
  },
  {
    "Cupons": "1-57376, 1-19771, 1-36673, 1-84804, 1-22906, 1-21198, 1-10714, 1-57084, 1-82887, 1-70297, 1-15857, 1-96469, 1-23213, 1-53145"
  },
  {
    "Cupons": "1-22684, 1-73025, 1-88728, 1-56609"
  },
  {
    "Cupons": "1-71536"
  },
  {
    "Cupons": "1-36050, 1-84032, 1-97396, 1-24438"
  },
  {
    "Cupons": "1-64794"
  },
  {
    "Cupons": "1-23017"
  },
  {
    "Cupons": "1-35990, 1-13841, 1-17709, 1-31826"
  },
  {
    "Cupons": "1-56935"
  },
  {
    "Cupons": "1-64150, 1-49623, 1-97458, 1-74531"
  },
  {
    "Cupons": "1-55540, 1-62390, 1-87725, 1-12029"
  },
  {
    "Cupons": "1-12508, 1-84477, 1-64657, 1-49163"
  },
  {
    "Cupons": "1-60966"
  },
  {
    "Cupons": "1-14898"
  },
  {
    "Cupons": "1-94588"
  },
  {
    "Cupons": "1-79118"
  },
  {
    "Cupons": "1-55075"
  },
  {
    "Cupons": "1-19725"
  },
  {
    "Cupons": "1-13632"
  },
  {
    "Cupons": "1-15123"
  },
  {
    "Cupons": "1-13539"
  },
  {
    "Cupons": "1-82976"
  },
  {
    "Cupons": "1-19091, 1-15486, 1-21513, 1-88625, 1-12143, 1-35001, 1-92660, 1-18795, 1-68973, 1-68764, 1-24146"
  },
  {
    "Cupons": "1-33531"
  },
  {
    "Cupons": "1-99434"
  },
  {
    "Cupons": "1-33727"
  },
  {
    "Cupons": "1-41086, 1-21743, 1-57396, 1-12786"
  },
  {
    "Cupons": "1-90855"
  },
  {
    "Cupons": "1-20349, 1-17211, 1-70047, 1-36314, 1-19415, 1-14258, 1-50683, 1-41380, 1-24061, 1-96893, 1-61546, 1-51605, 1-28669, 1-79702"
  },
  {
    "Cupons": "1-57752, 1-79160, 1-16453, 1-86633, 1-61100, 1-23328, 1-27876, 1-88078, 1-86969, 1-13489, 1-68187, 1-63898, 1-89412, 1-30105"
  },
  {
    "Cupons": "1-39312"
  },
  {
    "Cupons": "1-38112, 1-91562, 1-72215, 1-18114"
  },
  {
    "Cupons": "1-39812, 1-19395, 1-54157, 1-99063, 1-71112, 1-75298, 1-55860, 1-43882, 1-73337, 1-88232, 1-89348, 1-85504, 1-24566, 1-52550"
  },
  {
    "Cupons": "1-97176"
  },
  {
    "Cupons": "1-87307, 1-22495, 1-21015, 1-18895, 1-15351, 1-11447, 1-54220, 1-23032, 1-72973, 1-11693, 1-10232, 1-41634, 1-89576, 1-35942"
  },
  {
    "Cupons": "1-48253"
  },
  {
    "Cupons": "1-76196, 1-98851, 1-17309, 1-99876, 1-89906, 1-13837, 1-31611, 1-97159, 1-66482, 1-74732, 1-58854, 1-63548, 1-35607, 1-62109"
  },
  {
    "Cupons": "1-77673"
  },
  {
    "Cupons": "1-27727"
  },
  {
    "Cupons": "1-53279"
  },
  {
    "Cupons": "1-34341, 1-41443, 1-46323, 1-76313"
  },
  {
    "Cupons": "1-97637"
  },
  {
    "Cupons": "1-80483, 1-23890, 1-39109, 1-96856, 1-71426, 1-14688, 1-78972, 1-22387, 1-82802, 1-21076, 1-68360, 1-99805, 1-43458, 1-86579"
  },
  {
    "Cupons": "1-62220"
  },
  {
    "Cupons": "1-86928"
  },
  {
    "Cupons": "1-48564"
  },
  {
    "Cupons": "1-70072, 1-91418, 1-70734, 1-39124, 1-69821, 1-72015, 1-71363, 1-96421, 1-19205, 1-11154, 1-48430"
  },
  {
    "Cupons": "1-47088"
  },
  {
    "Cupons": "1-86020, 1-91994, 1-26905, 1-33187"
  },
  {
    "Cupons": "1-18430"
  },
  {
    "Cupons": "1-97709"
  },
  {
    "Cupons": "1-97630, 1-55237, 1-55290, 1-80792"
  },
  {
    "Cupons": "1-90027"
  },
  {
    "Cupons": "1-28406"
  },
  {
    "Cupons": "1-62246, 1-50554, 1-90991, 1-50548"
  },
  {
    "Cupons": "1-71466"
  },
  {
    "Cupons": "1-86487, 1-18697, 1-14519, 1-52747"
  },
  {
    "Cupons": "1-91226"
  },
  {
    "Cupons": "1-34934"
  },
  {
    "Cupons": "1-37883, 1-49651, 1-34853, 1-12651"
  },
  {
    "Cupons": "1-34711, 1-26895, 1-26678, 1-71406"
  },
  {
    "Cupons": "1-43941"
  },
  {
    "Cupons": "1-54661, 1-87083, 1-40140, 1-85247"
  },
  {
    "Cupons": "1-43959"
  },
  {
    "Cupons": "1-11829, 1-76874, 1-34198, 1-78545"
  },
  {
    "Cupons": "1-88991, 1-33049, 1-53842, 1-43043"
  },
  {
    "Cupons": "1-25572"
  },
  {
    "Cupons": "1-63833"
  },
  {
    "Cupons": "1-46846"
  },
  {
    "Cupons": "1-79717"
  },
  {
    "Cupons": "1-55631, 1-93388, 1-46969, 1-62325"
  },
  {
    "Cupons": "1-32721, 1-24711, 1-62436, 1-66035, 1-85952, 1-49204, 1-10860, 1-88424, 1-18581, 1-53219, 1-72453"
  },
  {
    "Cupons": "1-91300, 1-66355, 1-18365, 1-52626, 1-75721, 1-30571, 1-17764, 1-89434, 1-38342, 1-66211, 1-78483, 1-19804, 1-65616, 1-29422"
  },
  {
    "Cupons": "1-96104"
  },
  {
    "Cupons": "1-66214, 1-99481, 1-46998, 1-22918"
  },
  {
    "Cupons": "1-69567"
  },
  {
    "Cupons": "1-51382, 1-86530, 1-80438, 1-99679"
  },
  {
    "Cupons": "1-71478, 1-36626, 1-16721, 1-48688"
  },
  {
    "Cupons": "1-76245, 1-49401, 1-75835, 1-64144"
  },
  {
    "Cupons": "1-84748, 1-86206, 1-29246, 1-84613"
  },
  {
    "Cupons": "1-34159, 1-32407, 1-51007, 1-52825, 1-84637, 1-13937, 1-93051, 1-58402, 1-99229, 1-25919, 1-30040"
  },
  {
    "Cupons": "1-42512"
  },
  {
    "Cupons": "1-25058, 1-93495, 1-45028, 1-51975, 1-49938, 1-54346, 1-67910, 1-47826, 1-56614, 1-40277, 1-68439"
  },
  {
    "Cupons": "1-97233, 1-59807, 1-60205, 1-77978, 1-98104, 1-15137, 1-29805, 1-97446, 1-30114, 1-29904, 1-78890, 1-96988, 1-45887, 1-68619"
  },
  {
    "Cupons": "1-54154, 1-21248, 1-94598, 1-40653, 1-18889, 1-50922, 1-28305, 1-42130, 1-96403, 1-57651, 1-83397, 1-64363, 1-77800, 1-37478"
  },
  {
    "Cupons": "1-82486, 1-46076, 1-12003, 1-57341, 1-88470, 1-11026, 1-21791, 1-80241, 1-12576, 1-17421, 1-30182"
  },
  {
    "Cupons": "1-56407, 1-74344, 1-28700, 1-85103"
  },
  {
    "Cupons": "1-22280, 1-80633, 1-19555, 1-75605, 1-48964, 1-67881, 1-27290, 1-94789, 1-21565, 1-88309, 1-29313, 1-40891, 1-38559, 1-86108"
  },
  {
    "Cupons": "1-96405"
  },
  {
    "Cupons": "1-98156, 1-53474, 1-16345, 1-97229, 1-42485, 1-34759, 1-43350, 1-21839, 1-83987, 1-48656, 1-97589"
  },
  {
    "Cupons": "1-55791"
  },
  {
    "Cupons": "1-99645, 1-16446, 1-42854, 1-92974, 1-62258, 1-23553, 1-34997, 1-52168, 1-16697, 1-26392, 1-17196, 1-90434, 1-90607, 1-11806"
  },
  {
    "Cupons": "1-48678"
  },
  {
    "Cupons": "1-19702, 1-42353, 1-92633, 1-68646, 1-45566, 1-53552, 1-92362, 1-36429, 1-86339, 1-43798, 1-65789"
  },
  {
    "Cupons": "1-96694"
  },
  {
    "Cupons": "1-37887, 1-82472, 1-81218, 1-91800, 1-40735, 1-37826, 1-58822, 1-63927, 1-35884, 1-35379, 1-41590"
  },
  {
    "Cupons": "1-10376, 1-82731, 1-37188, 1-20580, 1-68841, 1-11948, 1-43735, 1-41583, 1-31156, 1-58236, 1-94766, 1-87119, 1-10707, 1-93300"
  },
  {
    "Cupons": "1-87144"
  },
  {
    "Cupons": "1-55380"
  },
  {
    "Cupons": "1-87984, 1-10018, 1-69871, 1-41666, 1-93209, 1-92135, 1-29254, 1-76022, 1-14579, 1-56418, 1-77279"
  },
  {
    "Cupons": "1-81464"
  },
  {
    "Cupons": "1-80036, 1-97590, 1-62916, 1-78554"
  },
  {
    "Cupons": "1-32830, 1-96209, 1-62280, 1-71196, 1-23184, 1-26495, 1-96171, 1-13504, 1-19457, 1-81610, 1-22052, 1-81213, 1-50881, 1-31858"
  },
  {
    "Cupons": "1-58781, 1-86281, 1-45590, 1-87424"
  },
  {
    "Cupons": "1-71407, 1-62088, 1-83067, 1-48687, 1-27405, 1-72369, 1-58612, 1-89431, 1-70775, 1-58481, 1-92903"
  },
  {
    "Cupons": "1-94889, 1-96685, 1-46917, 1-24333"
  },
  {
    "Cupons": "1-66283, 1-91184, 1-38530, 1-42114"
  },
  {
    "Cupons": "1-34171"
  },
  {
    "Cupons": "1-22827"
  },
  {
    "Cupons": "1-67186, 1-65260, 1-96167, 1-11591"
  },
  {
    "Cupons": "1-98598"
  },
  {
    "Cupons": "1-57130, 1-43219, 1-43052, 1-44888, 1-87987, 1-51962, 1-32768, 1-59953, 1-78225, 1-27202, 1-23264, 1-12343, 1-28555, 1-71283"
  },
  {
    "Cupons": "1-19883, 1-62075, 1-96509, 1-48783"
  },
  {
    "Cupons": "1-44831, 1-63718, 1-22583, 1-39186, 1-12453, 1-41451, 1-18634, 1-79422, 1-29222, 1-98820, 1-15660, 1-53146, 1-10772, 1-41750"
  },
  {
    "Cupons": "1-64493, 1-64428, 1-95428, 1-13557"
  },
  {
    "Cupons": "1-40261"
  },
  {
    "Cupons": "1-62333, 1-93462, 1-23084, 1-51498, 1-68716, 1-75360, 1-35558, 1-56890, 1-57948, 1-72448, 1-26241, 1-57105, 1-20821, 1-94871"
  },
  {
    "Cupons": "1-16277"
  },
  {
    "Cupons": "1-90041"
  },
  {
    "Cupons": "1-18548"
  },
  {
    "Cupons": "1-37098"
  },
  {
    "Cupons": "1-24052"
  },
  {
    "Cupons": "1-84724"
  },
  {
    "Cupons": "1-87125"
  },
  {
    "Cupons": "1-63633, 1-67335, 1-67199, 1-75682"
  },
  {
    "Cupons": "1-23179, 1-93952, 1-56230, 1-37436, 1-37351, 1-66536, 1-84164, 1-14598, 1-33078, 1-76114, 1-63688, 1-43825, 1-51188, 1-83463"
  },
  {
    "Cupons": "1-51417"
  },
  {
    "Cupons": "1-32179, 1-80492, 1-37840, 1-25053"
  },
  {
    "Cupons": "1-62755, 1-53544, 1-64501, 1-26362, 1-66522, 1-25334, 1-84138, 1-15492, 1-31000, 1-85825, 1-85951"
  },
  {
    "Cupons": "1-66884"
  },
  {
    "Cupons": "1-29890"
  },
  {
    "Cupons": "1-11759"
  },
  {
    "Cupons": "1-19544, 1-43461, 1-41264, 1-12967, 1-64859, 1-11982, 1-67810, 1-38778, 1-19875, 1-77578, 1-10106, 1-11218, 1-78449, 1-80610"
  },
  {
    "Cupons": "1-49318"
  },
  {
    "Cupons": "1-31507"
  },
  {
    "Cupons": "1-48525"
  },
  {
    "Cupons": "1-99607"
  },
  {
    "Cupons": "1-62657"
  },
  {
    "Cupons": "1-49956"
  },
  {
    "Cupons": "1-84579"
  },
  {
    "Cupons": "1-72224, 1-41906, 1-45522, 1-40826"
  },
  {
    "Cupons": "1-66149"
  },
  {
    "Cupons": "1-32849"
  },
  {
    "Cupons": "1-53760"
  },
  {
    "Cupons": "1-62049"
  },
  {
    "Cupons": "1-65433, 1-20075, 1-74383, 1-49615, 1-28179, 1-84667, 1-74155, 1-95328, 1-16164, 1-23022, 1-70529, 1-79858, 1-27200, 1-68357"
  },
  {
    "Cupons": "1-42898, 1-95609, 1-29505, 1-85992, 1-74752, 1-98037, 1-72009, 1-13442, 1-79911, 1-45381, 1-88724, 1-42454, 1-36015, 1-33943"
  },
  {
    "Cupons": "1-63829"
  },
  {
    "Cupons": "1-23077"
  },
  {
    "Cupons": "1-72637, 1-11259, 1-39795, 1-80582, 1-91685, 1-44663, 1-86243, 1-41937, 1-11709, 1-49069, 1-39466, 1-27913, 1-13424, 1-84488"
  },
  {
    "Cupons": "1-86076, 1-75122, 1-54037, 1-66265, 1-85995, 1-91659, 1-86966, 1-96774, 1-89385, 1-76797, 1-18528, 1-57333, 1-12634, 1-10467"
  },
  {
    "Cupons": "1-87944"
  },
  {
    "Cupons": "1-12984"
  },
  {
    "Cupons": "1-84340"
  },
  {
    "Cupons": "1-82137, 1-24357, 1-36851, 1-15310"
  },
  {
    "Cupons": "1-68153, 1-99753, 1-43684, 1-44366, 1-77388, 1-68568, 1-27654, 1-14275, 1-22923, 1-50062, 1-74545, 1-96596, 1-99273, 1-49431"
  },
  {
    "Cupons": "1-11337"
  },
  {
    "Cupons": "1-35506, 1-23963, 1-64559, 1-86124, 1-43765, 1-36334, 1-92924, 1-27858, 1-25678, 1-80231, 1-82172, 1-72910, 1-50732, 1-55120"
  },
  {
    "Cupons": "1-54079"
  },
  {
    "Cupons": "1-35036, 1-52738, 1-51652, 1-62886, 1-76299, 1-65191, 1-27439, 1-43263, 1-78238, 1-74433, 1-19841, 1-35573, 1-32004, 1-11363"
  },
  {
    "Cupons": "1-45171"
  },
  {
    "Cupons": "1-46958"
  },
  {
    "Cupons": "1-81114"
  },
  {
    "Cupons": "1-38709, 1-70468, 1-87800, 1-87363"
  },
  {
    "Cupons": "1-90221, 1-47385, 1-60019, 1-35286, 1-82020, 1-81730, 1-26288, 1-95512, 1-24624, 1-61956, 1-48132, 1-34905, 1-71190, 1-17182"
  },
  {
    "Cupons": "1-84440, 1-28751, 1-48621, 1-77672, 1-12691, 1-76180, 1-20788, 1-74769, 1-82782, 1-94000, 1-58991, 1-72589, 1-51686, 1-76306"
  },
  {
    "Cupons": "1-85943, 1-15196, 1-53074, 1-58702, 1-29171, 1-28518, 1-26158, 1-22234, 1-59625, 1-82933, 1-13869, 1-91750, 1-43303, 1-64720"
  },
  {
    "Cupons": "1-51406"
  },
  {
    "Cupons": "1-53057"
  },
  {
    "Cupons": "1-96084, 1-29785, 1-65717, 1-94969"
  },
  {
    "Cupons": "1-66468"
  },
  {
    "Cupons": "1-17102"
  },
  {
    "Cupons": "1-29586, 1-20358, 1-22973, 1-53856"
  },
  {
    "Cupons": "1-87047"
  },
  {
    "Cupons": "1-46634, 1-41920, 1-41342, 1-15390, 1-66308, 1-74991, 1-24864, 1-36608, 1-63192, 1-12371, 1-74195, 1-77903, 1-87022, 1-34329"
  },
  {
    "Cupons": "1-74957"
  },
  {
    "Cupons": "1-98925, 1-93561, 1-52187, 1-65414, 1-97096, 1-17912, 1-29551, 1-36862, 1-49899, 1-73388, 1-45477, 1-50776, 1-51754, 1-78751"
  },
  {
    "Cupons": "1-36857"
  },
  {
    "Cupons": "1-92175"
  },
  {
    "Cupons": "1-15560, 1-73810, 1-87093, 1-32955"
  },
  {
    "Cupons": "1-27927"
  },
  {
    "Cupons": "1-83341, 1-21233, 1-61491, 1-43075"
  },
  {
    "Cupons": "1-18901"
  },
  {
    "Cupons": "1-37904"
  },
  {
    "Cupons": "1-62512"
  },
  {
    "Cupons": "1-68541"
  },
  {
    "Cupons": "1-26783, 1-84178, 1-95095, 1-40481"
  },
  {
    "Cupons": "1-88916, 1-67097, 1-31568, 1-97998, 1-87035, 1-35057, 1-94381, 1-35937, 1-35856, 1-38633, 1-78118, 1-77102, 1-42658, 1-44819"
  },
  {
    "Cupons": "1-21435"
  },
  {
    "Cupons": "1-32850"
  },
  {
    "Cupons": "1-47395"
  },
  {
    "Cupons": "1-50984"
  },
  {
    "Cupons": "1-62937"
  },
  {
    "Cupons": "1-63242"
  },
  {
    "Cupons": "1-18522, 1-75072, 1-90691, 1-92898, 1-53573, 1-20801, 1-42011, 1-65188, 1-23908, 1-62646, 1-19241"
  },
  {
    "Cupons": "1-26725, 1-33231, 1-15985, 1-54532, 1-28951, 1-85764, 1-69097, 1-52946, 1-26659, 1-43152, 1-97749, 1-58879, 1-71047, 1-15407"
  },
  {
    "Cupons": "1-63830"
  },
  {
    "Cupons": "1-35906, 1-79802, 1-98349, 1-71966, 1-10017, 1-49756, 1-16636, 1-78410, 1-36916, 1-41708, 1-25883, 1-20967, 1-86411, 1-41254"
  },
  {
    "Cupons": "1-85315"
  },
  {
    "Cupons": "1-40124, 1-26692, 1-22096, 1-46035, 1-65070, 1-28294, 1-54104, 1-56791, 1-71058, 1-51193, 1-69873"
  },
  {
    "Cupons": "1-94437"
  },
  {
    "Cupons": "1-81200"
  },
  {
    "Cupons": "1-46199"
  },
  {
    "Cupons": "1-26249"
  },
  {
    "Cupons": "1-89676"
  },
  {
    "Cupons": "1-28248, 1-40220, 1-13045, 1-22636"
  },
  {
    "Cupons": "1-32952, 1-65469, 1-92184, 1-84717, 1-40613, 1-94515, 1-13171, 1-36986, 1-12445, 1-76748, 1-23594, 1-48369, 1-98107, 1-51739"
  },
  {
    "Cupons": "1-98673"
  },
  {
    "Cupons": "1-27398"
  },
  {
    "Cupons": "1-96177, 1-91462, 1-96738, 1-41788"
  },
  {
    "Cupons": "1-15555"
  },
  {
    "Cupons": "1-84007"
  },
  {
    "Cupons": "1-27761"
  },
  {
    "Cupons": "1-36533"
  },
  {
    "Cupons": "1-33337, 1-80504, 1-52245, 1-17249, 1-23844, 1-25880, 1-75530, 1-39543, 1-42817, 1-21355, 1-52413, 1-68615, 1-58004, 1-57403"
  },
  {
    "Cupons": "1-50463, 1-82438, 1-74365, 1-91451, 1-11854, 1-44816, 1-10110, 1-77057, 1-31494, 1-27679, 1-75687"
  },
  {
    "Cupons": "1-20189"
  },
  {
    "Cupons": "1-49601, 1-38401, 1-71661, 1-59732, 1-41897, 1-14151, 1-73035, 1-82967, 1-59971, 1-95494, 1-93673, 1-54872, 1-42519, 1-60167"
  },
  {
    "Cupons": "1-61646"
  },
  {
    "Cupons": "1-87986"
  },
  {
    "Cupons": "1-23026"
  },
  {
    "Cupons": "1-20461, 1-64592, 1-91737, 1-47145"
  },
  {
    "Cupons": "1-73322, 1-70525, 1-93855, 1-15582, 1-73545, 1-97331, 1-95981, 1-82987, 1-56710, 1-22996, 1-37678, 1-28435, 1-51196, 1-14065"
  },
  {
    "Cupons": "1-52527"
  },
  {
    "Cupons": "1-30786"
  },
  {
    "Cupons": "1-15318"
  },
  {
    "Cupons": "1-79423, 1-89816, 1-56748, 1-96502"
  },
  {
    "Cupons": "1-87334"
  },
  {
    "Cupons": "1-83024"
  },
  {
    "Cupons": "1-89307, 1-95563, 1-80944, 1-95464"
  },
  {
    "Cupons": "1-94671"
  },
  {
    "Cupons": "1-18882, 1-33613, 1-63118, 1-32512, 1-21992, 1-45256, 1-73269, 1-96798, 1-10624, 1-51051, 1-87713, 1-97144, 1-99194, 1-55381"
  },
  {
    "Cupons": "1-30307, 1-62462, 1-26966, 1-96952"
  },
  {
    "Cupons": "1-13605"
  },
  {
    "Cupons": "1-83279"
  },
  {
    "Cupons": "1-14734"
  },
  {
    "Cupons": "1-26790, 1-41513, 1-47812, 1-78733"
  },
  {
    "Cupons": "1-35854"
  },
  {
    "Cupons": "1-25309"
  },
  {
    "Cupons": "1-38632"
  },
  {
    "Cupons": "1-81718, 1-82002, 1-76744, 1-29678, 1-66401, 1-11630, 1-24849, 1-68593, 1-32025, 1-72618, 1-86280, 1-77147, 1-33792, 1-27500"
  },
  {
    "Cupons": "1-68931, 1-28707, 1-38236, 1-65202, 1-83320, 1-41986, 1-15733, 1-47893, 1-86864, 1-64703, 1-46277, 1-78720, 1-35168, 1-12742"
  },
  {
    "Cupons": "1-70449"
  },
  {
    "Cupons": "1-36003, 1-65947, 1-59415, 1-39131, 1-49208, 1-41067, 1-75579, 1-52472, 1-39625, 1-43749, 1-38717, 1-24134, 1-79012, 1-51297"
  },
  {
    "Cupons": "1-46513"
  },
  {
    "Cupons": "1-20175, 1-50362, 1-87354, 1-39876"
  },
  {
    "Cupons": "1-52559, 1-76436, 1-14793, 1-30921, 1-63443, 1-68395, 1-50905, 1-59550, 1-36252, 1-40374, 1-96815, 1-87580, 1-93506, 1-39792"
  },
  {
    "Cupons": "1-13303"
  },
  {
    "Cupons": "1-91043"
  },
  {
    "Cupons": "1-93166, 1-37639, 1-55096, 1-49443, 1-14457, 1-69346, 1-68998, 1-38628, 1-65678, 1-90454, 1-92206"
  },
  {
    "Cupons": "1-61856, 1-27613, 1-28773, 1-66036"
  },
  {
    "Cupons": "1-55486"
  },
  {
    "Cupons": "1-13061, 1-22029, 1-35958, 1-90892, 1-90402, 1-98132, 1-45520, 1-60245, 1-44980, 1-50410, 1-63804"
  },
  {
    "Cupons": "1-48816, 1-11939, 1-39885, 1-19361"
  },
  {
    "Cupons": "1-33909"
  },
  {
    "Cupons": "1-90084"
  },
  {
    "Cupons": "1-95394"
  },
  {
    "Cupons": "1-87816"
  },
  {
    "Cupons": "1-80103"
  },
  {
    "Cupons": "1-95323, 1-15662, 1-96548, 1-32541, 1-12588, 1-97282, 1-80806, 1-68339, 1-85319, 1-98178, 1-31252"
  },
  {
    "Cupons": "1-97024"
  },
  {
    "Cupons": "1-90644"
  },
  {
    "Cupons": "1-49247"
  },
  {
    "Cupons": "1-40482"
  },
  {
    "Cupons": "1-41317, 1-51783, 1-32038, 1-32935, 1-87314, 1-51485, 1-67790, 1-72222, 1-88105, 1-32723, 1-77182"
  },
  {
    "Cupons": "1-59212"
  },
  {
    "Cupons": "1-26261"
  },
  {
    "Cupons": "1-89786"
  },
  {
    "Cupons": "1-57595, 1-59927, 1-89580, 1-82333"
  },
  {
    "Cupons": "1-89360"
  },
  {
    "Cupons": "1-90785, 1-48586, 1-60933, 1-65264, 1-35985, 1-45675, 1-23718, 1-82890, 1-22911, 1-77038, 1-99984, 1-25150, 1-80312, 1-53572"
  },
  {
    "Cupons": "1-56891"
  },
  {
    "Cupons": "1-86491, 1-81230, 1-77444, 1-74937, 1-33775, 1-95008, 1-47981, 1-65535, 1-29323, 1-72407, 1-30913"
  },
  {
    "Cupons": "1-82845, 1-30625, 1-88317, 1-86395, 1-22987, 1-72050, 1-96203, 1-90637, 1-91489, 1-38800, 1-80790"
  },
  {
    "Cupons": "1-63480"
  },
  {
    "Cupons": "1-75962"
  },
  {
    "Cupons": "1-44573, 1-87679, 1-58263, 1-61776, 1-77586, 1-81125, 1-81802, 1-84854, 1-42047, 1-28763, 1-56602, 1-26777, 1-61377, 1-54866"
  },
  {
    "Cupons": "1-77814, 1-36658, 1-94120, 1-83530"
  },
  {
    "Cupons": "1-67514, 1-24998, 1-59327, 1-98398, 1-63705, 1-44235, 1-89439, 1-47185, 1-50964, 1-80508, 1-46711, 1-76587, 1-82872, 1-68784"
  },
  {
    "Cupons": "1-45374, 1-60358, 1-37529, 1-23937, 1-65941, 1-36758, 1-40639, 1-92607, 1-72377, 1-54982, 1-25765"
  },
  {
    "Cupons": "1-57193, 1-53649, 1-84879, 1-40939"
  },
  {
    "Cupons": "1-91166"
  },
  {
    "Cupons": "1-23279"
  },
  {
    "Cupons": "1-20582, 1-37270, 1-35399, 1-80855, 1-74297, 1-76926, 1-40781, 1-60328, 1-15220, 1-38086, 1-21498, 1-55012, 1-34081, 1-35936"
  },
  {
    "Cupons": "1-93369, 1-32294, 1-68962, 1-13852"
  },
  {
    "Cupons": "1-85596, 1-13239, 1-15209, 1-95568"
  },
  {
    "Cupons": "1-45436"
  },
  {
    "Cupons": "1-57228, 1-79883, 1-72784, 1-63726, 1-33364, 1-93423, 1-18084, 1-55073, 1-94896, 1-68051, 1-39532"
  },
  {
    "Cupons": "1-71647"
  },
  {
    "Cupons": "1-17266"
  },
  {
    "Cupons": "1-63407"
  },
  {
    "Cupons": "1-46381"
  },
  {
    "Cupons": "1-11507"
  },
  {
    "Cupons": "1-90752, 1-24558, 1-17565, 1-80406, 1-12217, 1-57077, 1-44899, 1-20295, 1-12324, 1-17107, 1-10890, 1-97920, 1-42851, 1-80106"
  },
  {
    "Cupons": "1-64612"
  },
  {
    "Cupons": "1-14809, 1-10696, 1-16869, 1-32494, 1-70390, 1-93968, 1-14285, 1-93808, 1-48263, 1-59622, 1-86907"
  },
  {
    "Cupons": "1-61121"
  },
  {
    "Cupons": "1-39678"
  },
  {
    "Cupons": "1-30195"
  },
  {
    "Cupons": "1-91511"
  },
  {
    "Cupons": "1-61985, 1-13437, 1-80110, 1-82878"
  },
  {
    "Cupons": "1-48867, 1-23341, 1-62680, 1-22319"
  },
  {
    "Cupons": "1-75751"
  },
  {
    "Cupons": "1-81606"
  },
  {
    "Cupons": "1-66174"
  },
  {
    "Cupons": "1-67962"
  },
  {
    "Cupons": "1-38076"
  },
  {
    "Cupons": "1-21025, 1-88603, 1-16566, 1-57894, 1-78690, 1-29548, 1-11044, 1-45006, 1-84599, 1-82171, 1-12804, 1-12891, 1-76923, 1-16097"
  },
  {
    "Cupons": "1-41445"
  },
  {
    "Cupons": "1-17068, 1-89126, 1-21682, 1-98188"
  },
  {
    "Cupons": "1-85134, 1-12875, 1-58432, 1-65832"
  },
  {
    "Cupons": "1-28327"
  },
  {
    "Cupons": "1-48222, 1-27170, 1-45706, 1-77657"
  },
  {
    "Cupons": "1-66662"
  },
  {
    "Cupons": "1-93629"
  },
  {
    "Cupons": "1-30059"
  },
  {
    "Cupons": "1-54189, 1-21005, 1-88841, 1-55899"
  },
  {
    "Cupons": "1-50434"
  },
  {
    "Cupons": "1-70608, 1-81350, 1-69967, 1-35017"
  },
  {
    "Cupons": "1-34951"
  },
  {
    "Cupons": "1-85574"
  },
  {
    "Cupons": "1-98171"
  },
  {
    "Cupons": "1-82238"
  },
  {
    "Cupons": "1-99503, 1-70750, 1-62379, 1-35417, 1-88344, 1-49150, 1-26605, 1-46098, 1-39306, 1-63999, 1-82570, 1-74654, 1-89506, 1-52379"
  },
  {
    "Cupons": "1-88813, 1-64966, 1-21039, 1-11488"
  },
  {
    "Cupons": "1-75321"
  },
  {
    "Cupons": "1-68489"
  },
  {
    "Cupons": "1-23617"
  },
  {
    "Cupons": "1-59326"
  },
  {
    "Cupons": "1-62033"
  },
  {
    "Cupons": "1-96113, 1-93956, 1-75991, 1-55528, 1-58688, 1-88290, 1-95079, 1-17152, 1-16984, 1-75398, 1-54311, 1-29142, 1-63783, 1-54935"
  },
  {
    "Cupons": "1-87756, 1-86370, 1-11109, 1-78279"
  },
  {
    "Cupons": "1-48686"
  },
  {
    "Cupons": "1-61283"
  },
  {
    "Cupons": "1-48016"
  },
  {
    "Cupons": "1-65990"
  },
  {
    "Cupons": "1-48530"
  },
  {
    "Cupons": "1-66165"
  },
  {
    "Cupons": "1-53100, 1-92023, 1-16070, 1-74164, 1-15597, 1-33882, 1-21202, 1-97489, 1-26309, 1-36572, 1-39069, 1-10983, 1-41038, 1-55868, 1-12104, 1-74931, 1-18623, 1-34916, 1-78052, 1-52897, 1-52137, 1-61068, 1-55500, 1-23315, 1-72445, 1-98404, 1-17396, 1-96309, 1-74819, 1-89336, 1-76792, 1-30095, 1-88384, 1-56332, 1-65016, 1-23870, 1-35882, 1-90318, 1-28319, 1-37101, 1-75218, 1-37563, 1-22556, 1-88519, 1-85029, 1-66416, 1-14937, 1-46725, 1-99131, 1-71603, 1-69264, 1-79555, 1-44773, 1-19480, 1-48787, 1-40703, 1-56478, 1-79496, 1-63248, 1-81797, 1-92084, 1-71598, 1-57345, 1-33971, 1-42884, 1-63400, 1-99245, 1-56525, 1-60692, 1-59751, 1-32328, 1-31181, 1-59438, 1-77314"
  },
  {
    "Cupons": "1-60525"
  },
  {
    "Cupons": "1-31417"
  },
  {
    "Cupons": "1-70974, 1-77877, 1-99339, 1-65628, 1-87542, 1-35512, 1-89224, 1-33188, 1-92353, 1-11209, 1-62934"
  },
  {
    "Cupons": "1-76373, 1-46740, 1-80354, 1-25171, 1-67114, 1-15063, 1-35134, 1-84463, 1-14423, 1-57821, 1-43757, 1-39416, 1-64525, 1-70309"
  },
  {
    "Cupons": "1-10576"
  },
  {
    "Cupons": "1-81674"
  },
  {
    "Cupons": "1-97180"
  },
  {
    "Cupons": "1-89497"
  },
  {
    "Cupons": "1-12799"
  },
  {
    "Cupons": "1-46209"
  },
  {
    "Cupons": "1-57038, 1-44850, 1-48932, 1-41410, 1-52016, 1-43973, 1-48864, 1-85289, 1-34006, 1-15141, 1-16670"
  },
  {
    "Cupons": "1-45634, 1-53117, 1-19429, 1-49425"
  },
  {
    "Cupons": "1-48954"
  },
  {
    "Cupons": "1-20797, 1-71037, 1-43401, 1-59332"
  },
  {
    "Cupons": "1-98103"
  },
  {
    "Cupons": "1-79992"
  },
  {
    "Cupons": "1-49134"
  },
  {
    "Cupons": "1-75528"
  },
  {
    "Cupons": "1-50572, 1-30520, 1-64261, 1-64977, 1-84494, 1-70992, 1-99727, 1-18261, 1-31148, 1-32022, 1-74891, 1-22053, 1-60728, 1-24447"
  },
  {
    "Cupons": "1-51870"
  },
  {
    "Cupons": "1-43847, 1-55641, 1-61325, 1-16871"
  },
  {
    "Cupons": "1-16560"
  },
  {
    "Cupons": "1-99875"
  },
  {
    "Cupons": "1-54610"
  },
  {
    "Cupons": "1-95031"
  },
  {
    "Cupons": "1-96625"
  },
  {
    "Cupons": "1-59643"
  },
  {
    "Cupons": "1-41395"
  },
  {
    "Cupons": "1-97361"
  },
  {
    "Cupons": "1-68746, 1-61952, 1-12124, 1-64016, 1-17415, 1-93767, 1-68454, 1-93112, 1-19540, 1-57269, 1-91476, 1-93086, 1-54291, 1-34235"
  },
  {
    "Cupons": "1-98969"
  },
  {
    "Cupons": "1-81088, 1-26203, 1-10727, 1-55160, 1-41108, 1-61080, 1-81549, 1-10747, 1-40141, 1-71458, 1-49417, 1-21067, 1-59400, 1-42211"
  },
  {
    "Cupons": "1-57401"
  },
  {
    "Cupons": "1-88149, 1-23492, 1-52483, 1-14490"
  },
  {
    "Cupons": "1-34943"
  },
  {
    "Cupons": "1-20176"
  },
  {
    "Cupons": "1-97815"
  },
  {
    "Cupons": "1-42501"
  },
  {
    "Cupons": "1-39954"
  },
  {
    "Cupons": "1-50266, 1-97463, 1-23035, 1-49400"
  },
  {
    "Cupons": "1-74609"
  },
  {
    "Cupons": "1-98081, 1-66596, 1-89074, 1-83923, 1-46867, 1-67784, 1-15422, 1-62647, 1-62528, 1-64736, 1-93649, 1-15276, 1-89465, 1-94607"
  },
  {
    "Cupons": "1-53190"
  },
  {
    "Cupons": "1-10301"
  },
  {
    "Cupons": "1-71008"
  },
  {
    "Cupons": "1-62218"
  },
  {
    "Cupons": "1-22743, 1-20520, 1-48120, 1-97342"
  },
  {
    "Cupons": "1-69527"
  },
  {
    "Cupons": "1-82173"
  },
  {
    "Cupons": "1-36483, 1-74249, 1-80348, 1-67243, 1-92530, 1-43968, 1-96883, 1-33109, 1-75324, 1-46701, 1-31164"
  },
  {
    "Cupons": "1-93790, 1-93853, 1-94183, 1-69331, 1-23353, 1-53376, 1-94589, 1-33163, 1-60868, 1-95070, 1-83372, 1-74026, 1-18888, 1-19114"
  },
  {
    "Cupons": "1-47881, 1-82106, 1-41251, 1-88688"
  },
  {
    "Cupons": "1-34186"
  },
  {
    "Cupons": "1-43465, 1-48934, 1-90113, 1-95314, 1-23497, 1-72710, 1-48952, 1-34918, 1-32753, 1-27363, 1-42910, 1-34607, 1-89496, 1-31534"
  },
  {
    "Cupons": "1-51055, 1-93278, 1-42798, 1-78385"
  },
  {
    "Cupons": "1-79730"
  },
  {
    "Cupons": "1-26894"
  },
  {
    "Cupons": "1-55414, 1-23990, 1-92514, 1-23835, 1-70369, 1-45469, 1-50520, 1-45795, 1-69658, 1-51418, 1-41830, 1-98751, 1-37297, 1-17497"
  },
  {
    "Cupons": "1-21878"
  },
  {
    "Cupons": "1-27633, 1-86113, 1-23245, 1-45618, 1-16377, 1-39042, 1-88922, 1-22856, 1-29726, 1-98420, 1-58918"
  },
  {
    "Cupons": "1-41217"
  },
  {
    "Cupons": "1-94551"
  },
  {
    "Cupons": "1-41402, 1-97888, 1-22016, 1-17361"
  },
  {
    "Cupons": "1-60073"
  },
  {
    "Cupons": "1-32533, 1-91960, 1-29592, 1-59219, 1-41929, 1-83457, 1-59016, 1-91120, 1-20627, 1-46330, 1-60553"
  },
  {
    "Cupons": "1-71213"
  },
  {
    "Cupons": "1-59492"
  },
  {
    "Cupons": "1-92928"
  },
  {
    "Cupons": "1-17891"
  },
  {
    "Cupons": "1-66311"
  },
  {
    "Cupons": "1-87956"
  },
  {
    "Cupons": "1-56518"
  },
  {
    "Cupons": "1-74431"
  },
  {
    "Cupons": "1-59237, 1-96831, 1-96375, 1-39793"
  },
  {
    "Cupons": "1-66920, 1-70473, 1-68923, 1-79929"
  },
  {
    "Cupons": "1-73440"
  },
  {
    "Cupons": "1-12065"
  },
  {
    "Cupons": "1-97526"
  },
  {
    "Cupons": "1-88891"
  },
  {
    "Cupons": "1-54719, 1-68629, 1-59277, 1-51952, 1-35529, 1-79325, 1-84814, 1-42747, 1-93341, 1-65938, 1-17888"
  },
  {
    "Cupons": "1-54323"
  },
  {
    "Cupons": "1-76884, 1-28903, 1-28629, 1-46435"
  },
  {
    "Cupons": "1-89136"
  },
  {
    "Cupons": "1-43667"
  },
  {
    "Cupons": "1-79586"
  },
  {
    "Cupons": "1-11215"
  },
  {
    "Cupons": "1-79228"
  },
  {
    "Cupons": "1-72375, 1-76948, 1-81598, 1-77755, 1-91626, 1-45840, 1-50837, 1-46783, 1-45940, 1-23845, 1-27086, 1-37384, 1-74725, 1-14269"
  },
  {
    "Cupons": "1-40559, 1-42976, 1-36559, 1-28108, 1-72911, 1-88773, 1-61633, 1-15156, 1-56844, 1-59601, 1-84952, 1-51800, 1-84731, 1-36382, 1-65556, 1-51538, 1-11729, 1-31867, 1-61451, 1-49984, 1-70707, 1-61613, 1-31998, 1-94976, 1-62166, 1-81533, 1-19999, 1-57928, 1-84394, 1-24680, 1-58254, 1-38206, 1-15747, 1-40400, 1-70942, 1-13090, 1-51616, 1-88414, 1-34425, 1-38142, 1-42787, 1-74730, 1-95825, 1-73205, 1-47386, 1-99529, 1-37749, 1-32021, 1-30230, 1-62293, 1-92026, 1-72614, 1-49373, 1-37653, 1-67210, 1-94728, 1-89867, 1-11582, 1-32709, 1-55442, 1-99736, 1-24398, 1-20407, 1-98334, 1-78883, 1-31480, 1-47055, 1-51402, 1-74968, 1-95582, 1-15748, 1-50299, 1-64033, 1-57078"
  },
  {
    "Cupons": "1-89067, 1-39989, 1-97937, 1-65210"
  },
  {
    "Cupons": "1-94646"
  },
  {
    "Cupons": "1-58337"
  },
  {
    "Cupons": "1-28598"
  },
  {
    "Cupons": "1-11092"
  },
  {
    "Cupons": "1-51429"
  },
  {
    "Cupons": "1-39054"
  },
  {
    "Cupons": "1-40742, 1-33042, 1-70187, 1-36838"
  },
  {
    "Cupons": "1-41373, 1-91596, 1-55370, 1-75090"
  },
  {
    "Cupons": "1-51283"
  },
  {
    "Cupons": "1-87122, 1-82837, 1-56334, 1-37288, 1-25248, 1-23892, 1-18838, 1-15811, 1-68679, 1-52924, 1-74806, 1-97035, 1-19508, 1-21235"
  },
  {
    "Cupons": "1-73112"
  },
  {
    "Cupons": "1-62438"
  },
  {
    "Cupons": "1-73932"
  },
  {
    "Cupons": "1-56731, 1-67929, 1-30908, 1-89419, 1-15510, 1-24780, 1-12718, 1-10770, 1-68785, 1-92808, 1-55372, 1-77013, 1-20406, 1-42241"
  },
  {
    "Cupons": "1-75857, 1-40611, 1-47263, 1-26113"
  },
  {
    "Cupons": "1-40750"
  },
  {
    "Cupons": "1-13349"
  },
  {
    "Cupons": "1-17251"
  },
  {
    "Cupons": "1-71094, 1-40960, 1-81091, 1-42305, 1-69555, 1-53273, 1-49165, 1-37185, 1-81672, 1-54118, 1-20280"
  },
  {
    "Cupons": "1-83801, 1-23303, 1-94952, 1-17300, 1-49811, 1-47622, 1-85645, 1-31888, 1-83845, 1-49510, 1-51052, 1-45673, 1-66062, 1-13129"
  },
  {
    "Cupons": "1-73446"
  },
  {
    "Cupons": "1-57113, 1-23739, 1-29994, 1-36899"
  },
  {
    "Cupons": "1-92945, 1-14365, 1-69526, 1-16666"
  },
  {
    "Cupons": "1-28257, 1-23472, 1-33131, 1-85954"
  },
  {
    "Cupons": "1-69182"
  },
  {
    "Cupons": "1-51655"
  },
  {
    "Cupons": "1-53455, 1-21496, 1-41349, 1-24208, 1-86622, 1-61323, 1-14425, 1-26890, 1-12129, 1-54937, 1-65851, 1-83821, 1-27754, 1-49658"
  },
  {
    "Cupons": "1-91654, 1-32011, 1-84605, 1-65900, 1-30867, 1-21821, 1-28920, 1-41873, 1-93940, 1-11229, 1-73517, 1-50971, 1-24964, 1-53519"
  },
  {
    "Cupons": "1-51463"
  },
  {
    "Cupons": "1-48905"
  },
  {
    "Cupons": "1-32711, 1-29589, 1-80037, 1-87408, 1-89720, 1-79288, 1-74367, 1-85488, 1-62227, 1-55872, 1-32916, 1-47380, 1-15561, 1-82713"
  },
  {
    "Cupons": "1-25617, 1-27892, 1-11552, 1-48045"
  },
  {
    "Cupons": "1-70163"
  },
  {
    "Cupons": "1-77037, 1-85010, 1-13836, 1-59970, 1-83788, 1-22546, 1-47965, 1-15714, 1-64836, 1-15844, 1-88566, 1-52787, 1-91810, 1-10971, 1-53575, 1-35557, 1-55318, 1-65290, 1-83980, 1-44081, 1-44207, 1-60789, 1-64272, 1-43168, 1-84473, 1-61824, 1-55762, 1-93627, 1-42045, 1-18624, 1-88023, 1-57387, 1-39518, 1-61180, 1-41504, 1-83620, 1-49048, 1-48527, 1-52954, 1-65476, 1-31645, 1-31443, 1-81349, 1-18087, 1-89295, 1-88892, 1-51675, 1-52263, 1-45084, 1-35604, 1-69495, 1-96955, 1-67850, 1-26549, 1-55468, 1-98729, 1-36261, 1-74676, 1-13354, 1-97006, 1-44154, 1-29356, 1-81474, 1-38044, 1-37035, 1-93986, 1-57473, 1-35731, 1-28956, 1-73243, 1-66430, 1-24378, 1-18222, 1-55618"
  },
  {
    "Cupons": "1-54518"
  },
  {
    "Cupons": "1-91081, 1-31668, 1-54089, 1-94170, 1-22582, 1-10734, 1-22286, 1-57252, 1-74967, 1-16418, 1-79000, 1-46140, 1-38289, 1-79188"
  },
  {
    "Cupons": "1-80135, 1-34347, 1-35615, 1-34574, 1-54275, 1-37806, 1-25583, 1-27251, 1-10283, 1-84655, 1-54753, 1-16583, 1-51313, 1-69492"
  },
  {
    "Cupons": "1-87217"
  },
  {
    "Cupons": "1-77642, 1-44792, 1-34157, 1-96143, 1-62699, 1-24384, 1-20724, 1-68532, 1-83460, 1-20092, 1-89722, 1-10343, 1-82674, 1-60814"
  },
  {
    "Cupons": "1-82400, 1-28372, 1-27940, 1-43438, 1-60535, 1-41569, 1-32932, 1-51985, 1-72750, 1-13667, 1-72775, 1-15989, 1-43859, 1-55489"
  },
  {
    "Cupons": "1-84175"
  },
  {
    "Cupons": "1-36282"
  },
  {
    "Cupons": "1-19421"
  },
  {
    "Cupons": "1-16987, 1-30335, 1-88055, 1-55752, 1-21422, 1-53207, 1-53419, 1-34723, 1-87908, 1-55299, 1-78143, 1-76648, 1-28401, 1-48434"
  },
  {
    "Cupons": "1-49096, 1-49329, 1-57664, 1-89631"
  },
  {
    "Cupons": "1-40197"
  },
  {
    "Cupons": "1-41103"
  },
  {
    "Cupons": "1-33949"
  },
  {
    "Cupons": "1-42457"
  },
  {
    "Cupons": "1-82050, 1-46158, 1-82032, 1-32988, 1-66489, 1-78607, 1-48284, 1-21277, 1-79370, 1-99658, 1-81819, 1-19465, 1-80936, 1-64177"
  },
  {
    "Cupons": "1-91307"
  },
  {
    "Cupons": "1-21258, 1-42164, 1-66336, 1-66287, 1-88972, 1-23410, 1-62282, 1-69238, 1-97039, 1-71737, 1-13516, 1-37294, 1-27794, 1-59176"
  },
  {
    "Cupons": "1-54094, 1-77749, 1-34212, 1-91154, 1-23493, 1-41680, 1-56393, 1-39162, 1-83261, 1-83760, 1-17858, 1-28019, 1-84852, 1-80174"
  },
  {
    "Cupons": "1-60564"
  },
  {
    "Cupons": "1-17649, 1-54186, 1-11253, 1-46538"
  },
  {
    "Cupons": "1-39871"
  },
  {
    "Cupons": "1-38572"
  },
  {
    "Cupons": "1-52887"
  },
  {
    "Cupons": "1-38219, 1-46859, 1-71346, 1-68069"
  },
  {
    "Cupons": "1-53810"
  },
  {
    "Cupons": "1-88082"
  },
  {
    "Cupons": "1-83038"
  },
  {
    "Cupons": "1-44072"
  },
  {
    "Cupons": "1-35193"
  },
  {
    "Cupons": "1-33781"
  },
  {
    "Cupons": "1-26782"
  },
  {
    "Cupons": "1-96943, 1-58168, 1-61367, 1-47702, 1-22221, 1-20725, 1-44475, 1-59459, 1-93786, 1-31831, 1-29788, 1-64351, 1-71261, 1-86079"
  },
  {
    "Cupons": "1-28085"
  },
  {
    "Cupons": "1-26700"
  },
  {
    "Cupons": "1-83337, 1-75055, 1-13977, 1-44170"
  },
  {
    "Cupons": "1-60794"
  },
  {
    "Cupons": "1-39888, 1-64048, 1-75954, 1-62204"
  },
  {
    "Cupons": "1-49207, 1-23916, 1-80955, 1-14753"
  },
  {
    "Cupons": "1-33999, 1-90645, 1-99711, 1-97411, 1-22596, 1-25220, 1-11989, 1-37594, 1-49024, 1-64049, 1-84017, 1-82052, 1-81371, 1-17048"
  },
  {
    "Cupons": "1-92024, 1-13125, 1-21294, 1-86703"
  },
  {
    "Cupons": "1-65813"
  },
  {
    "Cupons": "1-34244"
  },
  {
    "Cupons": "1-55410"
  },
  {
    "Cupons": "1-78466"
  },
  {
    "Cupons": "1-18405"
  },
  {
    "Cupons": "1-99560"
  },
  {
    "Cupons": "1-74020"
  },
  {
    "Cupons": "1-69771, 1-50415, 1-89338, 1-96990"
  },
  {
    "Cupons": "1-56858"
  },
  {
    "Cupons": "1-63164"
  },
  {
    "Cupons": "1-66371"
  },
  {
    "Cupons": "1-48419"
  },
  {
    "Cupons": "1-80455, 1-39528, 1-78783, 1-16036"
  },
  {
    "Cupons": "1-34912"
  },
  {
    "Cupons": "1-18128"
  },
  {
    "Cupons": "1-30945"
  },
  {
    "Cupons": "1-81396"
  },
  {
    "Cupons": "1-42054, 1-65008, 1-83410, 1-39564"
  },
  {
    "Cupons": "1-26943, 1-95226, 1-45255, 1-12384, 1-11258, 1-13574, 1-15967, 1-61736, 1-57816, 1-14989, 1-70443, 1-62724, 1-70059, 1-71087"
  },
  {
    "Cupons": "1-39374"
  },
  {
    "Cupons": "1-24495, 1-28046, 1-68163, 1-60805, 1-70418, 1-30760, 1-92058, 1-49100, 1-61016, 1-19770, 1-72926, 1-72324, 1-32397, 1-99662"
  },
  {
    "Cupons": "1-94191, 1-17746, 1-54644, 1-13521"
  },
  {
    "Cupons": "1-71415, 1-80589, 1-81104, 1-74390"
  },
  {
    "Cupons": "1-75782"
  },
  {
    "Cupons": "1-40314"
  },
  {
    "Cupons": "1-31539, 1-75625, 1-41372, 1-21946"
  },
  {
    "Cupons": "1-87002, 1-59576, 1-44782, 1-27382"
  },
  {
    "Cupons": "1-89778"
  },
  {
    "Cupons": "1-50221, 1-93779, 1-74689, 1-71947"
  },
  {
    "Cupons": "1-40065, 1-19783, 1-32443, 1-81515"
  },
  {
    "Cupons": "1-43865, 1-18959, 1-41686, 1-60627"
  },
  {
    "Cupons": "1-86756"
  },
  {
    "Cupons": "1-26177"
  },
  {
    "Cupons": "1-96484"
  },
  {
    "Cupons": "1-13159, 1-57194, 1-93915, 1-53863"
  },
  {
    "Cupons": "1-50786"
  },
  {
    "Cupons": "1-93748, 1-38169, 1-87018, 1-25910, 1-51342, 1-52437, 1-47261, 1-45891, 1-73743, 1-83868, 1-39507, 1-83850, 1-66961, 1-62666"
  },
  {
    "Cupons": "1-10511"
  },
  {
    "Cupons": "1-68977, 1-75312, 1-62897, 1-94988, 1-24364, 1-56283, 1-71976, 1-79230, 1-77040, 1-81544, 1-55715, 1-46481, 1-61456, 1-79078"
  },
  {
    "Cupons": "1-28425, 1-85745, 1-57920, 1-70238, 1-64477, 1-94621, 1-21490, 1-35227, 1-93189, 1-89189, 1-12578, 1-15270, 1-58012, 1-55125"
  },
  {
    "Cupons": "1-98897, 1-46988, 1-61389, 1-91173, 1-86317, 1-72194, 1-51334, 1-89956, 1-82738, 1-40757, 1-96930, 1-84943, 1-69593, 1-92029"
  },
  {
    "Cupons": "1-72770"
  },
  {
    "Cupons": "1-34982"
  },
  {
    "Cupons": "1-52662, 1-29549, 1-24359, 1-72262, 1-33614, 1-29309, 1-94864, 1-86509, 1-24092, 1-41932, 1-45440, 1-47119, 1-27140, 1-91678"
  },
  {
    "Cupons": "1-15142, 1-88417, 1-98347, 1-11369, 1-21222, 1-86011, 1-79585, 1-60383, 1-88283, 1-58203, 1-70476, 1-72786, 1-43553, 1-92274"
  },
  {
    "Cupons": "1-69630, 1-87782, 1-19319, 1-67419, 1-52474, 1-39377, 1-65623, 1-96513, 1-62734, 1-27545, 1-43623, 1-43896, 1-57784, 1-57305"
  },
  {
    "Cupons": "1-31757, 1-61379, 1-56451, 1-90013"
  },
  {
    "Cupons": "1-18409"
  },
  {
    "Cupons": "1-59512"
  },
  {
    "Cupons": "1-48880, 1-86929, 1-82070, 1-14511, 1-43570, 1-41409, 1-26682, 1-66472, 1-86331, 1-82404, 1-20782, 1-37234, 1-24151, 1-43333"
  },
  {
    "Cupons": "1-67249"
  },
  {
    "Cupons": "1-84426"
  },
  {
    "Cupons": "1-86850"
  },
  {
    "Cupons": "1-75951, 1-52427, 1-77054, 1-73937"
  },
  {
    "Cupons": "1-75882"
  },
  {
    "Cupons": "1-70857, 1-84593, 1-97697, 1-14764, 1-27142, 1-90004, 1-68313, 1-91738, 1-28831, 1-21922, 1-21465"
  },
  {
    "Cupons": "1-41325, 1-16452, 1-76844, 1-96718, 1-33817, 1-62159, 1-77782, 1-52207, 1-55062, 1-30907, 1-27519, 1-55157, 1-70632, 1-51611"
  },
  {
    "Cupons": "1-40838, 1-26642, 1-99369, 1-22482"
  },
  {
    "Cupons": "1-46795"
  },
  {
    "Cupons": "1-14883, 1-71683, 1-87981, 1-82056"
  },
  {
    "Cupons": "1-84011, 1-21578, 1-44517, 1-25425, 1-63030, 1-75105, 1-52508, 1-24126, 1-13651, 1-91279, 1-34003"
  },
  {
    "Cupons": "1-42279"
  },
  {
    "Cupons": "1-43918, 1-96193, 1-28717, 1-77662, 1-25546, 1-66721, 1-93497, 1-98920, 1-69344, 1-29058, 1-45141, 1-56409, 1-15419, 1-10601"
  },
  {
    "Cupons": "1-36127, 1-72117, 1-64451, 1-71121, 1-20303, 1-37598, 1-99623, 1-63618, 1-57967, 1-67218, 1-25713, 1-79583, 1-13071, 1-75229"
  },
  {
    "Cupons": "1-82977"
  },
  {
    "Cupons": "1-60892, 1-41814, 1-47597, 1-63883, 1-68418, 1-36903, 1-66361, 1-20670, 1-14440, 1-49823, 1-43549, 1-91821, 1-93651, 1-31428"
  },
  {
    "Cupons": "1-19136"
  },
  {
    "Cupons": "1-15435"
  },
  {
    "Cupons": "1-81315"
  },
  {
    "Cupons": "1-86625"
  },
  {
    "Cupons": "1-85838, 1-73379, 1-78501, 1-72588, 1-31577, 1-90173, 1-19232, 1-65258, 1-43646, 1-85828, 1-40623, 1-38372, 1-18162, 1-39298"
  },
  {
    "Cupons": "1-93052"
  },
  {
    "Cupons": "1-20499, 1-54059, 1-55840, 1-37907"
  },
  {
    "Cupons": "1-83082, 1-61273, 1-55328, 1-27120"
  },
  {
    "Cupons": "1-74378, 1-44515, 1-13430, 1-72574, 1-63111, 1-56885, 1-54251, 1-20482, 1-99363, 1-20137, 1-62207, 1-82303, 1-17652, 1-10289"
  },
  {
    "Cupons": "1-13057"
  },
  {
    "Cupons": "1-41320"
  },
  {
    "Cupons": "1-91984"
  },
  {
    "Cupons": "1-38750"
  },
  {
    "Cupons": "1-40383, 1-11514, 1-10562, 1-50804, 1-40823, 1-59691, 1-60297, 1-99972, 1-35368, 1-20922, 1-84167, 1-31217, 1-38849, 1-26293"
  },
  {
    "Cupons": "1-82132"
  },
  {
    "Cupons": "1-10353, 1-69448, 1-86287, 1-56944"
  },
  {
    "Cupons": "1-84869, 1-89625, 1-86698, 1-25790"
  },
  {
    "Cupons": "1-65384"
  },
  {
    "Cupons": "1-90080"
  },
  {
    "Cupons": "1-84297, 1-41002, 1-18844, 1-90641, 1-90735, 1-78620, 1-62867, 1-89963, 1-13821, 1-95978, 1-73748, 1-73111, 1-83348, 1-77853"
  },
  {
    "Cupons": "1-72898, 1-72666, 1-47986, 1-19020, 1-46503, 1-89438, 1-88617, 1-41224, 1-89247, 1-16646, 1-60532"
  },
  {
    "Cupons": "1-96835"
  },
  {
    "Cupons": "1-33923"
  },
  {
    "Cupons": "1-39666"
  },
  {
    "Cupons": "1-14162"
  },
  {
    "Cupons": "1-63814"
  },
  {
    "Cupons": "1-59876, 1-91162, 1-16709, 1-48175, 1-42895, 1-84071, 1-16976, 1-16099, 1-36107, 1-92279, 1-20238, 1-65313, 1-75598, 1-50142, 1-12738, 1-85399, 1-16303, 1-35411, 1-63750, 1-36612, 1-76428, 1-75220, 1-36913, 1-58805, 1-65860, 1-42029, 1-52296, 1-18234, 1-23337, 1-46857, 1-82349, 1-19889, 1-95234, 1-24934, 1-47753, 1-49242, 1-90310, 1-12440, 1-92593, 1-72473, 1-21463, 1-55652, 1-98692, 1-71725, 1-56164, 1-34677, 1-27482, 1-89629, 1-38435, 1-66198, 1-50531, 1-89192, 1-32627, 1-97162, 1-20971, 1-10266, 1-56291, 1-84120, 1-55115, 1-10303, 1-96751, 1-60211, 1-32637, 1-92388, 1-39681, 1-86297, 1-88868, 1-25103, 1-47773, 1-94304, 1-65310, 1-10821, 1-59249, 1-42495, 1-18414, 1-30595, 1-72764, 1-44987, 1-77832, 1-15406, 1-49554, 1-84584, 1-63575, 1-89214, 1-75368, 1-44862, 1-39908, 1-49202, 1-71740, 1-66453, 1-55790, 1-90551, 1-33141, 1-60867, 1-52150, 1-30374, 1-67875, 1-74706, 1-44896, 1-34548, 1-21531, 1-32604, 1-33492, 1-60499, 1-60748, 1-94015, 1-70918, 1-28514, 1-95005, 1-50562, 1-11315, 1-68475, 1-71376, 1-77997, 1-70042, 1-52492, 1-79327, 1-89646, 1-65164, 1-11988, 1-14240, 1-70510, 1-12944, 1-64573, 1-87938, 1-87915, 1-43193, 1-36830, 1-31886, 1-28267, 1-73616, 1-51360, 1-83599, 1-49304"
  },
  {
    "Cupons": "1-48036, 1-18710, 1-60791, 1-70211, 1-18947, 1-84619, 1-90272, 1-83053, 1-12719, 1-58700, 1-80268"
  },
  {
    "Cupons": "1-46581, 1-31307, 1-21443, 1-57614"
  },
  {
    "Cupons": "1-96667"
  },
  {
    "Cupons": "1-29818"
  },
  {
    "Cupons": "1-56931"
  },
  {
    "Cupons": "1-98764, 1-58733, 1-17580, 1-11236, 1-39150, 1-77301, 1-71281, 1-20802, 1-38897, 1-92097, 1-50472"
  },
  {
    "Cupons": "1-94845"
  },
  {
    "Cupons": "1-32439"
  },
  {
    "Cupons": "1-77020, 1-78736, 1-60656, 1-47342, 1-97504, 1-89097, 1-18636, 1-69429, 1-65368, 1-33911, 1-68442, 1-50949, 1-63330, 1-79819"
  },
  {
    "Cupons": "1-14524"
  },
  {
    "Cupons": "1-70286"
  },
  {
    "Cupons": "1-34019"
  },
  {
    "Cupons": "1-70809, 1-37918, 1-72037, 1-46841"
  },
  {
    "Cupons": "1-46369, 1-58426, 1-70144, 1-48084"
  },
  {
    "Cupons": "1-70282, 1-96848, 1-55849, 1-36713, 1-17961, 1-96135, 1-42588, 1-27304, 1-72076, 1-50735, 1-69358, 1-63500, 1-64377, 1-30910"
  },
  {
    "Cupons": "1-67544, 1-40905, 1-68819, 1-31302, 1-38665, 1-73610, 1-47214, 1-83380, 1-97952, 1-33392, 1-89584, 1-50992, 1-97393, 1-20124"
  },
  {
    "Cupons": "1-50523"
  },
  {
    "Cupons": "1-81914, 1-73773, 1-25846, 1-12518, 1-78043, 1-63695, 1-68361, 1-86363, 1-74239, 1-12705, 1-59108, 1-57852, 1-70826, 1-55210"
  },
  {
    "Cupons": "1-11574, 1-78975, 1-80803, 1-31884, 1-24352, 1-99653, 1-63082, 1-35609, 1-10286, 1-60171, 1-90528, 1-93740, 1-65545, 1-74007"
  },
  {
    "Cupons": "1-66267"
  },
  {
    "Cupons": "1-17263, 1-42202, 1-14520, 1-55614"
  },
  {
    "Cupons": "1-62248, 1-47481, 1-98812, 1-31541, 1-58621, 1-36529, 1-23527, 1-83719, 1-83479, 1-74697, 1-57054, 1-16956, 1-24091, 1-37865"
  },
  {
    "Cupons": "1-66598"
  },
  {
    "Cupons": "1-59669"
  },
  {
    "Cupons": "1-99388"
  },
  {
    "Cupons": "1-59834"
  },
  {
    "Cupons": "1-52064"
  },
  {
    "Cupons": "1-36826"
  },
  {
    "Cupons": "1-70033"
  },
  {
    "Cupons": "1-43842, 1-73100, 1-80213, 1-52490"
  },
  {
    "Cupons": "1-82762, 1-17341, 1-62544, 1-14479"
  },
  {
    "Cupons": "1-62012, 1-27123, 1-98675, 1-89059, 1-39642, 1-85812, 1-68665, 1-82567, 1-49047, 1-84197, 1-53271, 1-77451, 1-49976, 1-76534"
  },
  {
    "Cupons": "1-20284"
  },
  {
    "Cupons": "1-89130, 1-71446, 1-40563, 1-10149, 1-75426, 1-20096, 1-12600, 1-76325, 1-98355, 1-16197, 1-61196, 1-29762, 1-23928, 1-40624"
  },
  {
    "Cupons": "1-69616"
  },
  {
    "Cupons": "1-26738, 1-71402, 1-14046, 1-79641, 1-99574, 1-14344, 1-35883, 1-43007, 1-10946, 1-10703, 1-24589, 1-67886, 1-52377, 1-15208"
  },
  {
    "Cupons": "1-66606, 1-36873, 1-75433, 1-52332, 1-30882, 1-65526, 1-18171, 1-31492, 1-37877, 1-18605, 1-52525, 1-44847, 1-56099, 1-81380"
  },
  {
    "Cupons": "1-31963, 1-20107, 1-49375, 1-32447, 1-88532, 1-62972, 1-92369, 1-16258, 1-88552, 1-42410, 1-83432, 1-85086, 1-69725, 1-13936"
  },
  {
    "Cupons": "1-65895"
  },
  {
    "Cupons": "1-86379"
  },
  {
    "Cupons": "1-88909"
  },
  {
    "Cupons": "1-95778"
  },
  {
    "Cupons": "1-14810"
  },
  {
    "Cupons": "1-57244, 1-61868, 1-23029, 1-33982"
  },
  {
    "Cupons": "1-24853"
  },
  {
    "Cupons": "1-81899"
  },
  {
    "Cupons": "1-92749, 1-23484, 1-23461, 1-87847"
  },
  {
    "Cupons": "1-91703, 1-78900, 1-60613, 1-13372"
  },
  {
    "Cupons": "1-12516"
  },
  {
    "Cupons": "1-25406"
  },
  {
    "Cupons": "1-23318"
  },
  {
    "Cupons": "1-24186, 1-50528, 1-95723, 1-73930"
  },
  {
    "Cupons": "1-57201"
  },
  {
    "Cupons": "1-53952, 1-61158, 1-38040, 1-11368"
  },
  {
    "Cupons": "1-17103"
  },
  {
    "Cupons": "1-88516, 1-54665, 1-40226, 1-59006, 1-87403, 1-56867, 1-27474, 1-97276, 1-48579, 1-92454, 1-48918, 1-65330, 1-77352, 1-87180"
  },
  {
    "Cupons": "1-37455"
  },
  {
    "Cupons": "1-67073"
  },
  {
    "Cupons": "1-63881, 1-65842, 1-31795, 1-48334"
  },
  {
    "Cupons": "1-73846"
  },
  {
    "Cupons": "1-27320"
  },
  {
    "Cupons": "1-92227"
  },
  {
    "Cupons": "1-42075, 1-41754, 1-41862, 1-24984, 1-39261, 1-59368, 1-98862, 1-86504, 1-98609, 1-58228, 1-85123, 1-28357, 1-73228, 1-99807"
  },
  {
    "Cupons": "1-89383, 1-97741, 1-39376, 1-62044"
  },
  {
    "Cupons": "1-35461, 1-35074, 1-33361, 1-46021, 1-98373, 1-42366, 1-59506, 1-15977, 1-47899, 1-45900, 1-26528, 1-19503, 1-29087, 1-60512"
  },
  {
    "Cupons": "1-72334, 1-41820, 1-61240, 1-42869"
  },
  {
    "Cupons": "1-95139"
  },
  {
    "Cupons": "1-61089"
  },
  {
    "Cupons": "1-80193, 1-30735, 1-15461, 1-39353"
  },
  {
    "Cupons": "1-62912, 1-44817, 1-53388, 1-44897, 1-77323, 1-97023, 1-93582, 1-30496, 1-63115, 1-14984, 1-85982, 1-69999, 1-73617, 1-71485"
  },
  {
    "Cupons": "1-85938"
  },
  {
    "Cupons": "1-14750"
  },
  {
    "Cupons": "1-50137"
  },
  {
    "Cupons": "1-87989, 1-90346, 1-82843, 1-50366, 1-30612, 1-23218, 1-71297, 1-95894, 1-21973, 1-38281, 1-49252, 1-97492, 1-41710, 1-41535"
  },
  {
    "Cupons": "1-69779, 1-54479, 1-92785, 1-61421, 1-60274, 1-47602, 1-15193, 1-74533, 1-48008, 1-58931, 1-22558, 1-41836, 1-98505, 1-42491"
  },
  {
    "Cupons": "1-53609"
  },
  {
    "Cupons": "1-92955"
  },
  {
    "Cupons": "1-94528"
  },
  {
    "Cupons": "1-46658"
  },
  {
    "Cupons": "1-49668"
  },
  {
    "Cupons": "1-42553, 1-88838, 1-49947, 1-33449, 1-84452, 1-57578, 1-77894, 1-75924, 1-85161, 1-81211, 1-33864, 1-35501, 1-31564, 1-79016"
  },
  {
    "Cupons": "1-99452, 1-78253, 1-49904, 1-61189"
  },
  {
    "Cupons": "1-14894"
  },
  {
    "Cupons": "1-71632"
  },
  {
    "Cupons": "1-72496"
  },
  {
    "Cupons": "1-49463"
  },
  {
    "Cupons": "1-46111, 1-58563, 1-56955, 1-13576, 1-49475, 1-33596, 1-44742, 1-99088, 1-67052, 1-36420, 1-47320, 1-14732, 1-80843, 1-34165"
  },
  {
    "Cupons": "1-14776, 1-78406, 1-87228, 1-50656"
  },
  {
    "Cupons": "1-89719"
  },
  {
    "Cupons": "1-40462"
  },
  {
    "Cupons": "1-28739"
  },
  {
    "Cupons": "1-36548, 1-46407, 1-37025, 1-67062"
  },
  {
    "Cupons": "1-62741"
  },
  {
    "Cupons": "1-87818"
  },
  {
    "Cupons": "1-21694"
  },
  {
    "Cupons": "1-98110"
  },
  {
    "Cupons": "1-49350, 1-52044, 1-10288, 1-54460, 1-14687, 1-62138, 1-21944, 1-29545, 1-64027, 1-36578, 1-22162, 1-90305, 1-99351, 1-17342"
  },
  {
    "Cupons": "1-28185, 1-34693, 1-92672, 1-24832, 1-32829, 1-96843, 1-16724, 1-67246, 1-92304, 1-78679, 1-28838"
  },
  {
    "Cupons": "1-72599, 1-33581, 1-28932, 1-39821, 1-54919, 1-12481, 1-16153, 1-72715, 1-94917, 1-13336, 1-52249"
  },
  {
    "Cupons": "1-25736"
  },
  {
    "Cupons": "1-59168"
  },
  {
    "Cupons": "1-23108, 1-74080, 1-82697, 1-89203, 1-85118, 1-42447, 1-48758, 1-73455, 1-64086, 1-82039, 1-48344, 1-17659, 1-77547, 1-42225"
  },
  {
    "Cupons": "1-53017"
  },
  {
    "Cupons": "1-12765"
  },
  {
    "Cupons": "1-13823"
  },
  {
    "Cupons": "1-74193, 1-88098, 1-95949, 1-35737"
  },
  {
    "Cupons": "1-43603, 1-40044, 1-82554, 1-34935, 1-49646, 1-46971, 1-88723, 1-77708, 1-33132, 1-80180, 1-92927, 1-32444, 1-90340, 1-76255"
  },
  {
    "Cupons": "1-69654, 1-37975, 1-84789, 1-60685"
  },
  {
    "Cupons": "1-46127"
  },
  {
    "Cupons": "1-98915"
  },
  {
    "Cupons": "1-87700"
  },
  {
    "Cupons": "1-21419, 1-83332, 1-25212, 1-30350, 1-76393, 1-43911, 1-61762, 1-88325, 1-46955, 1-51973, 1-78481"
  },
  {
    "Cupons": "1-56371, 1-35122, 1-89254, 1-68404, 1-62058, 1-32118, 1-98414, 1-34980, 1-58389, 1-88373, 1-47765, 1-71766, 1-90939, 1-26751"
  },
  {
    "Cupons": "1-47172, 1-70060, 1-21493, 1-30932"
  },
  {
    "Cupons": "1-50510, 1-13039, 1-34483, 1-42729"
  },
  {
    "Cupons": "1-98056"
  },
  {
    "Cupons": "1-46494, 1-75677, 1-88792, 1-67847, 1-87208, 1-69025, 1-13410, 1-79840, 1-36119, 1-44090, 1-58158, 1-45422, 1-28653, 1-48151"
  },
  {
    "Cupons": "1-30238"
  },
  {
    "Cupons": "1-83817, 1-45798, 1-88777, 1-93937, 1-81186, 1-70232, 1-57375, 1-17952, 1-56049, 1-77723, 1-95110, 1-34820, 1-45494, 1-97575"
  },
  {
    "Cupons": "1-37061, 1-71912, 1-49287, 1-79742, 1-45741, 1-40679, 1-66179, 1-11251, 1-17531, 1-53981, 1-77809, 1-10486, 1-40148, 1-15246"
  },
  {
    "Cupons": "1-97285"
  },
  {
    "Cupons": "1-11556, 1-85074, 1-51645, 1-36923, 1-31798, 1-64260, 1-84723, 1-47011, 1-28511, 1-92669, 1-99353, 1-46810, 1-53476, 1-13463"
  },
  {
    "Cupons": "1-96173"
  },
  {
    "Cupons": "1-12510"
  },
  {
    "Cupons": "1-18729, 1-38723, 1-54373, 1-15708, 1-12665, 1-80052, 1-42552, 1-94765, 1-90397, 1-36366, 1-51856, 1-20216, 1-23508, 1-74109"
  },
  {
    "Cupons": "1-40691"
  },
  {
    "Cupons": "1-89976"
  },
  {
    "Cupons": "1-64995"
  },
  {
    "Cupons": "1-33127, 1-42578, 1-53780, 1-96693, 1-27814, 1-28072, 1-36047, 1-87867, 1-28593, 1-42467, 1-31517, 1-38348, 1-29291, 1-92703"
  },
  {
    "Cupons": "1-29957, 1-56010, 1-11669, 1-11009"
  },
  {
    "Cupons": "1-20254, 1-20844, 1-80725, 1-11599, 1-37576, 1-45968, 1-94644, 1-79495, 1-19180, 1-67681, 1-98923, 1-14296, 1-13374, 1-22465"
  },
  {
    "Cupons": "1-72459"
  },
  {
    "Cupons": "1-71636, 1-65953, 1-28194, 1-55919, 1-97750, 1-36373, 1-40572, 1-73429, 1-36908, 1-69754, 1-65599"
  },
  {
    "Cupons": "1-32207, 1-44216, 1-25346, 1-74553, 1-49231, 1-48782, 1-99626, 1-90826, 1-84983, 1-22631, 1-14284, 1-80517, 1-31205, 1-88721"
  },
  {
    "Cupons": "1-28133, 1-81275, 1-28335, 1-57925, 1-35969, 1-90184, 1-16248, 1-62932, 1-51162, 1-53346, 1-99774, 1-28775, 1-91917, 1-36397"
  },
  {
    "Cupons": "1-92458"
  },
  {
    "Cupons": "1-48702"
  },
  {
    "Cupons": "1-76711"
  },
  {
    "Cupons": "1-65104"
  },
  {
    "Cupons": "1-67267"
  },
  {
    "Cupons": "1-21733, 1-36550, 1-99924, 1-51459, 1-44700, 1-56258, 1-32757, 1-90736, 1-41462, 1-17568, 1-30967"
  },
  {
    "Cupons": "1-16624"
  },
  {
    "Cupons": "1-92437"
  },
  {
    "Cupons": "1-80519"
  },
  {
    "Cupons": "1-56975, 1-54470, 1-63122, 1-70027, 1-61206, 1-98533, 1-81425, 1-79792, 1-82798, 1-86071, 1-19253, 1-91066, 1-89577, 1-98637"
  },
  {
    "Cupons": "1-19342, 1-52111, 1-23192, 1-36648, 1-90895, 1-93829, 1-17374, 1-58764, 1-49191, 1-19394, 1-40194, 1-64290, 1-58444, 1-21322"
  },
  {
    "Cupons": "1-26723"
  },
  {
    "Cupons": "1-87906"
  },
  {
    "Cupons": "1-83094, 1-31362, 1-13265, 1-58183, 1-75770, 1-93702, 1-76705, 1-48311, 1-11059, 1-67721, 1-96450, 1-84607, 1-58958, 1-14556"
  },
  {
    "Cupons": "1-64422"
  },
  {
    "Cupons": "1-48945, 1-14500, 1-12543, 1-73020, 1-91930, 1-94733, 1-25989, 1-96915, 1-29243, 1-89379, 1-78269"
  },
  {
    "Cupons": "1-74738, 1-73254, 1-42090, 1-22523"
  },
  {
    "Cupons": "1-32363, 1-97889, 1-94480, 1-34021, 1-55668, 1-61950, 1-58637, 1-50951, 1-24653, 1-12097, 1-76572, 1-56016, 1-15290, 1-52660"
  },
  {
    "Cupons": "1-42749, 1-91072, 1-94834, 1-69114, 1-35192, 1-78078, 1-50192, 1-62104, 1-37493, 1-88934, 1-24007, 1-60441, 1-17631, 1-97438"
  },
  {
    "Cupons": "1-34023"
  },
  {
    "Cupons": "1-76267"
  },
  {
    "Cupons": "1-43227"
  },
  {
    "Cupons": "1-23215, 1-34945, 1-58412, 1-89195"
  },
  {
    "Cupons": "1-46037, 1-48267, 1-81256, 1-43165, 1-77725, 1-38588, 1-93942, 1-48395, 1-62428, 1-86790, 1-22100"
  },
  {
    "Cupons": "1-48488"
  },
  {
    "Cupons": "1-85573, 1-17621, 1-22674, 1-55158, 1-37034, 1-14868, 1-77328, 1-72530, 1-50547, 1-85230, 1-73443, 1-52078, 1-43024, 1-95527"
  },
  {
    "Cupons": "1-59130, 1-95598, 1-53926, 1-19113, 1-80435, 1-16521, 1-14673, 1-24739, 1-99472, 1-42664, 1-33261, 1-80373, 1-11576, 1-68485"
  },
  {
    "Cupons": "1-86972, 1-90133, 1-30197, 1-75858, 1-31694, 1-48761, 1-28675, 1-72739, 1-16039, 1-29909, 1-91435, 1-86845, 1-84298, 1-55029"
  },
  {
    "Cupons": "1-29204, 1-31249, 1-95315, 1-31314"
  },
  {
    "Cupons": "1-27250, 1-42617, 1-38854, 1-10506, 1-53349, 1-91433, 1-96944, 1-36013, 1-26052, 1-26062, 1-67692, 1-43434, 1-71345, 1-50333"
  },
  {
    "Cupons": "1-25208"
  },
  {
    "Cupons": "1-36729"
  },
  {
    "Cupons": "1-48166"
  },
  {
    "Cupons": "1-21154, 1-73565, 1-44334, 1-14841, 1-91246, 1-69449, 1-94715, 1-32602, 1-79267, 1-41799, 1-75840, 1-60292, 1-37716, 1-92264"
  },
  {
    "Cupons": "1-10548"
  },
  {
    "Cupons": "1-56820, 1-51407, 1-70990, 1-13147"
  },
  {
    "Cupons": "1-26060"
  },
  {
    "Cupons": "1-56923"
  },
  {
    "Cupons": "1-27504, 1-14029, 1-12007, 1-23444"
  },
  {
    "Cupons": "1-88509, 1-34092, 1-25592, 1-69126, 1-56108, 1-91214, 1-15324, 1-73350, 1-34762, 1-46498, 1-22396, 1-57369, 1-54009, 1-81583"
  },
  {
    "Cupons": "1-11907"
  },
  {
    "Cupons": "1-80020, 1-74671, 1-85202, 1-62839, 1-97949, 1-68645, 1-95502, 1-30997, 1-65577, 1-54871, 1-34439, 1-43454, 1-68136, 1-77065"
  },
  {
    "Cupons": "1-44785, 1-71469, 1-67578, 1-32042"
  },
  {
    "Cupons": "1-80073, 1-24923, 1-47299, 1-58276, 1-76865, 1-79701, 1-87337, 1-99638, 1-60087, 1-76768, 1-64335, 1-31225, 1-95646, 1-65682"
  },
  {
    "Cupons": "1-61580"
  },
  {
    "Cupons": "1-96611, 1-39503, 1-32760, 1-58713"
  },
  {
    "Cupons": "1-56206, 1-64497, 1-73346, 1-27823, 1-54046, 1-37962, 1-63454, 1-65674, 1-15465, 1-48023, 1-10498, 1-93250, 1-85492, 1-38488"
  },
  {
    "Cupons": "1-97085, 1-11207, 1-58929, 1-86134"
  },
  {
    "Cupons": "1-92505"
  },
  {
    "Cupons": "1-78162, 1-18120, 1-44368, 1-66985"
  },
  {
    "Cupons": "1-94656"
  },
  {
    "Cupons": "1-66376"
  },
  {
    "Cupons": "1-51462"
  },
  {
    "Cupons": "1-89105"
  },
  {
    "Cupons": "1-32987, 1-38234, 1-64202, 1-86019"
  },
  {
    "Cupons": "1-23083, 1-23977, 1-14478, 1-42345"
  },
  {
    "Cupons": "1-23969, 1-89447, 1-54003, 1-73131"
  },
  {
    "Cupons": "1-13408"
  },
  {
    "Cupons": "1-74456"
  },
  {
    "Cupons": "1-56685, 1-41131, 1-18963, 1-79983"
  },
  {
    "Cupons": "1-72985, 1-10836, 1-22625, 1-39345, 1-61135, 1-63760, 1-52681, 1-62237, 1-96001, 1-10827, 1-77379, 1-21689, 1-32645, 1-53123"
  },
  {
    "Cupons": "1-90602"
  },
  {
    "Cupons": "1-15364"
  },
  {
    "Cupons": "1-64909"
  },
  {
    "Cupons": "1-51123"
  },
  {
    "Cupons": "1-97981"
  },
  {
    "Cupons": "1-19239"
  },
  {
    "Cupons": "1-19183, 1-92799, 1-76409, 1-47260"
  },
  {
    "Cupons": "1-37754, 1-23433, 1-37337, 1-21638, 1-28254, 1-13797, 1-54462, 1-27899, 1-66829, 1-20248, 1-59518, 1-68100, 1-27144, 1-86319"
  },
  {
    "Cupons": "1-59936"
  },
  {
    "Cupons": "1-49067, 1-77120, 1-81267, 1-86510, 1-12472, 1-81585, 1-69917, 1-88905, 1-40964, 1-97668, 1-45109, 1-46050, 1-69398, 1-68072"
  },
  {
    "Cupons": "1-20792, 1-79979, 1-58005, 1-71563, 1-33051, 1-10550, 1-14832, 1-41140, 1-58205, 1-55783, 1-51348"
  },
  {
    "Cupons": "1-94762, 1-85795, 1-67997, 1-46830, 1-60945, 1-45901, 1-27212, 1-97209, 1-53583, 1-80488, 1-80221, 1-72203, 1-81999, 1-57264"
  },
  {
    "Cupons": "1-25493, 1-43551, 1-83687, 1-81441"
  },
  {
    "Cupons": "1-44484, 1-19079, 1-28488, 1-66177, 1-64373, 1-75043, 1-91710, 1-36449, 1-41227, 1-22751, 1-82149, 1-75238, 1-99863, 1-53028"
  },
  {
    "Cupons": "1-33445"
  },
  {
    "Cupons": "1-72253"
  },
  {
    "Cupons": "1-34162"
  },
  {
    "Cupons": "1-89456, 1-49394, 1-30275, 1-23034, 1-32922, 1-70014, 1-57482, 1-20462, 1-24226, 1-58172, 1-91619, 1-94442, 1-69497, 1-93879"
  },
  {
    "Cupons": "1-89397"
  },
  {
    "Cupons": "1-87754, 1-55680, 1-94174, 1-70326"
  },
  {
    "Cupons": "1-33093"
  },
  {
    "Cupons": "1-89443, 1-86478, 1-79207, 1-60954"
  },
  {
    "Cupons": "1-22457"
  },
  {
    "Cupons": "1-91620"
  },
  {
    "Cupons": "1-84764, 1-89132, 1-12256, 1-61387"
  },
  {
    "Cupons": "1-98001"
  },
  {
    "Cupons": "1-85329"
  },
  {
    "Cupons": "1-21528, 1-24692, 1-68188, 1-30210, 1-61550, 1-72683, 1-81659, 1-57926, 1-23763, 1-13264, 1-42988, 1-38799, 1-30678, 1-50103"
  },
  {
    "Cupons": "1-10823, 1-45684, 1-40971, 1-32346, 1-98288, 1-83752, 1-19298, 1-76077, 1-43678, 1-11462, 1-24639, 1-63534, 1-45312, 1-84768"
  },
  {
    "Cupons": "1-99426, 1-88577, 1-26278, 1-64963, 1-76281, 1-41467, 1-91622, 1-60873, 1-91719, 1-38208, 1-28505, 1-11835, 1-87325, 1-17059"
  },
  {
    "Cupons": "1-62323"
  },
  {
    "Cupons": "1-67039, 1-88545, 1-69236, 1-82205, 1-45574, 1-75021, 1-93699, 1-27370, 1-53016, 1-67916, 1-51494, 1-39097, 1-81725, 1-73535"
  },
  {
    "Cupons": "1-72219, 1-56426, 1-17508, 1-38122, 1-27938, 1-65718, 1-30504, 1-64870, 1-24230, 1-22438, 1-92592, 1-12565, 1-95481, 1-61622"
  },
  {
    "Cupons": "1-10000, 1-68773, 1-85240, 1-40845, 1-99909, 1-15563, 1-68587, 1-55667, 1-17738, 1-77495, 1-80153"
  },
  {
    "Cupons": "1-43198"
  },
  {
    "Cupons": "1-97595, 1-95289, 1-98527, 1-78420"
  },
  {
    "Cupons": "1-33276, 1-54537, 1-21106, 1-80086, 1-50220, 1-78289, 1-35738, 1-58499, 1-70617, 1-86639, 1-42323, 1-79600, 1-96969, 1-18000"
  },
  {
    "Cupons": "1-50379"
  },
  {
    "Cupons": "1-54913"
  },
  {
    "Cupons": "1-91454, 1-27366, 1-92204, 1-34220, 1-84446, 1-32225, 1-54472, 1-22791, 1-55991, 1-58114, 1-26289, 1-69028, 1-16142, 1-70818"
  },
  {
    "Cupons": "1-67806, 1-19024, 1-81768, 1-80985"
  },
  {
    "Cupons": "1-89400"
  },
  {
    "Cupons": "1-86787"
  },
  {
    "Cupons": "1-73542, 1-23940, 1-82953, 1-96601"
  },
  {
    "Cupons": "1-82080"
  },
  {
    "Cupons": "1-52663"
  },
  {
    "Cupons": "1-46977"
  },
  {
    "Cupons": "1-14693, 1-91139, 1-53979, 1-71988"
  },
  {
    "Cupons": "1-96198"
  },
  {
    "Cupons": "1-15090"
  },
  {
    "Cupons": "1-35337, 1-65320, 1-89401, 1-16746, 1-27735, 1-14250, 1-80056, 1-99246, 1-66683, 1-22965, 1-35593, 1-99787, 1-64112, 1-69558"
  },
  {
    "Cupons": "1-41495"
  },
  {
    "Cupons": "1-82263"
  },
  {
    "Cupons": "1-57987, 1-70886, 1-82705, 1-87318"
  },
  {
    "Cupons": "1-41715"
  },
  {
    "Cupons": "1-92962"
  },
  {
    "Cupons": "1-99920, 1-48921, 1-67224, 1-90417"
  },
  {
    "Cupons": "1-68550"
  },
  {
    "Cupons": "1-44565"
  },
  {
    "Cupons": "1-46365, 1-45772, 1-27464, 1-16672"
  },
  {
    "Cupons": "1-94023"
  },
  {
    "Cupons": "1-56103"
  },
  {
    "Cupons": "1-81639, 1-90271, 1-10304, 1-36701"
  },
  {
    "Cupons": "1-88561, 1-67378, 1-63351, 1-98566"
  },
  {
    "Cupons": "1-72096, 1-87706, 1-70562, 1-68260, 1-43013, 1-88785, 1-20598, 1-10554, 1-60682, 1-19295, 1-11783, 1-26040, 1-25124, 1-90577"
  },
  {
    "Cupons": "1-55502, 1-26195, 1-82329, 1-59674, 1-13406, 1-84845, 1-67883, 1-50127, 1-10446, 1-77629, 1-20942, 1-30850, 1-91390, 1-54169"
  },
  {
    "Cupons": "1-75012, 1-48626, 1-36291, 1-45723"
  },
  {
    "Cupons": "1-42775"
  },
  {
    "Cupons": "1-42373"
  },
  {
    "Cupons": "1-26765"
  },
  {
    "Cupons": "1-78196, 1-53804, 1-17781, 1-22632, 1-11933, 1-78252, 1-30096, 1-79728, 1-21692, 1-44536, 1-29849, 1-67680, 1-10585, 1-39610"
  },
  {
    "Cupons": "1-41127, 1-97674, 1-81031, 1-75285"
  },
  {
    "Cupons": "1-18517, 1-62416, 1-59018, 1-68515"
  },
  {
    "Cupons": "1-83550, 1-13282, 1-72233, 1-36275"
  },
  {
    "Cupons": "1-15243"
  },
  {
    "Cupons": "1-35020"
  },
  {
    "Cupons": "1-14368, 1-47929, 1-94032, 1-85028, 1-17024, 1-39618, 1-74989, 1-13152, 1-52996, 1-90399, 1-20156"
  },
  {
    "Cupons": "1-55546, 1-36226, 1-64948, 1-43034"
  },
  {
    "Cupons": "1-71757, 1-25722, 1-88444, 1-54296"
  },
  {
    "Cupons": "1-30480"
  },
  {
    "Cupons": "1-52770"
  },
  {
    "Cupons": "1-61574, 1-54558, 1-24799, 1-81105"
  },
  {
    "Cupons": "1-96892"
  },
  {
    "Cupons": "1-44378, 1-30704, 1-15073, 1-20309"
  },
  {
    "Cupons": "1-69554"
  },
  {
    "Cupons": "1-33586"
  },
  {
    "Cupons": "1-14032"
  },
  {
    "Cupons": "1-80692, 1-37835, 1-29300, 1-21636"
  },
  {
    "Cupons": "1-81991"
  },
  {
    "Cupons": "1-67598"
  },
  {
    "Cupons": "1-30045"
  },
  {
    "Cupons": "1-33240, 1-56857, 1-62341, 1-83297, 1-42295, 1-64642, 1-31088, 1-34864, 1-96782, 1-57115, 1-69486, 1-66422, 1-28743, 1-53858"
  },
  {
    "Cupons": "1-94995"
  },
  {
    "Cupons": "1-82423, 1-44726, 1-64990, 1-60514"
  },
  {
    "Cupons": "1-96886"
  },
  {
    "Cupons": "1-97090"
  },
  {
    "Cupons": "1-16075"
  },
  {
    "Cupons": "1-39152"
  },
  {
    "Cupons": "1-29720"
  },
  {
    "Cupons": "1-72014, 1-76250, 1-21921, 1-60488, 1-27842, 1-28329, 1-74923, 1-84693, 1-73122, 1-19811, 1-82907"
  },
  {
    "Cupons": "1-27355"
  },
  {
    "Cupons": "1-43407, 1-40834, 1-96675, 1-59570"
  },
  {
    "Cupons": "1-61528"
  },
  {
    "Cupons": "1-38442, 1-74358, 1-45313, 1-75496"
  },
  {
    "Cupons": "1-94251, 1-21722, 1-27716, 1-50498, 1-98756, 1-22413, 1-30309, 1-11626, 1-20944, 1-34965, 1-24273, 1-76964, 1-30492, 1-57730"
  },
  {
    "Cupons": "1-27512, 1-16211, 1-34300, 1-34356"
  },
  {
    "Cupons": "1-38817"
  },
  {
    "Cupons": "1-19329, 1-67638, 1-29894, 1-40612, 1-60879, 1-24195, 1-11196, 1-37709, 1-65861, 1-72597, 1-10154, 1-76621, 1-89515, 1-80656"
  },
  {
    "Cupons": "1-62167, 1-38968, 1-79244, 1-96162"
  },
  {
    "Cupons": "1-88593"
  },
  {
    "Cupons": "1-67232, 1-72237, 1-36251, 1-31703, 1-25312, 1-15757, 1-58413, 1-20535, 1-90844, 1-98853, 1-78591, 1-48885, 1-89549, 1-53590"
  },
  {
    "Cupons": "1-93003, 1-49880, 1-68637, 1-58825"
  },
  {
    "Cupons": "1-21121"
  },
  {
    "Cupons": "1-68037, 1-37665, 1-45148, 1-44605, 1-71631, 1-38586, 1-41975, 1-92036, 1-35424, 1-44864, 1-62063, 1-63996, 1-61177, 1-41019"
  },
  {
    "Cupons": "1-16536, 1-93672, 1-36454, 1-89096, 1-73802, 1-49211, 1-26294, 1-13344, 1-67660, 1-67019, 1-72414, 1-39909, 1-20418, 1-78421"
  },
  {
    "Cupons": "1-31134"
  },
  {
    "Cupons": "1-83484"
  },
  {
    "Cupons": "1-53828"
  },
  {
    "Cupons": "1-95622, 1-44017, 1-34899, 1-23141, 1-37839, 1-15565, 1-81139, 1-49922, 1-80408, 1-94895, 1-99039"
  },
  {
    "Cupons": "1-42995, 1-61047, 1-85730, 1-83042, 1-27548, 1-58576, 1-38777, 1-68640, 1-84467, 1-96286, 1-90465, 1-44097, 1-97188, 1-21291"
  },
  {
    "Cupons": "1-16015"
  },
  {
    "Cupons": "1-38322"
  },
  {
    "Cupons": "1-69064"
  },
  {
    "Cupons": "1-91064, 1-67579, 1-33913, 1-45108"
  },
  {
    "Cupons": "1-43835, 1-59979, 1-83091, 1-92019"
  },
  {
    "Cupons": "1-99754"
  },
  {
    "Cupons": "1-18226"
  },
  {
    "Cupons": "1-31648"
  },
  {
    "Cupons": "1-94521"
  },
  {
    "Cupons": "1-39140, 1-24694, 1-84375, 1-34616"
  },
  {
    "Cupons": "1-69670"
  },
  {
    "Cupons": "1-56706, 1-98446, 1-53477, 1-16168"
  },
  {
    "Cupons": "1-93849"
  },
  {
    "Cupons": "1-92262, 1-23154, 1-23665, 1-90728"
  },
  {
    "Cupons": "1-87891, 1-85924, 1-37613, 1-93321, 1-60401, 1-19581, 1-58502, 1-87207, 1-36663, 1-62717, 1-28342, 1-55058, 1-17100, 1-24791"
  },
  {
    "Cupons": "1-98245"
  },
  {
    "Cupons": "1-93183, 1-85545, 1-52652, 1-99407"
  },
  {
    "Cupons": "1-47191"
  },
  {
    "Cupons": "1-97627, 1-86452, 1-23773, 1-96820"
  },
  {
    "Cupons": "1-86730, 1-40361, 1-41883, 1-43880"
  },
  {
    "Cupons": "1-90082, 1-73010, 1-63377, 1-61360, 1-30635, 1-36396, 1-91878, 1-35209, 1-80959, 1-73692, 1-40326"
  },
  {
    "Cupons": "1-14002, 1-53108, 1-35095, 1-18927, 1-91220, 1-34698, 1-54706, 1-40079, 1-50815, 1-58539, 1-22279, 1-99680, 1-92783, 1-12327"
  },
  {
    "Cupons": "1-68234"
  },
  {
    "Cupons": "1-63797"
  },
  {
    "Cupons": "1-51042, 1-16403, 1-71738, 1-14585"
  },
  {
    "Cupons": "1-80436, 1-53672, 1-23378, 1-19560"
  },
  {
    "Cupons": "1-45696, 1-37445, 1-46864, 1-85950"
  },
  {
    "Cupons": "1-54707, 1-92906, 1-37157, 1-75236"
  },
  {
    "Cupons": "1-18890, 1-23098, 1-88389, 1-42065, 1-43370, 1-29329, 1-30078, 1-81012, 1-90058, 1-25683, 1-18499, 1-77693, 1-93196, 1-43071"
  },
  {
    "Cupons": "1-46356"
  },
  {
    "Cupons": "1-93770"
  },
  {
    "Cupons": "1-72046, 1-37086, 1-95100, 1-98518"
  },
  {
    "Cupons": "1-17304, 1-34573, 1-23128, 1-73370, 1-34191, 1-38894, 1-67931, 1-83621, 1-32234, 1-35288, 1-89080, 1-92067, 1-42874, 1-46016"
  },
  {
    "Cupons": "1-73782, 1-88793, 1-30044, 1-56729"
  },
  {
    "Cupons": "1-73831, 1-37043, 1-70459, 1-70673"
  },
  {
    "Cupons": "1-68990"
  },
  {
    "Cupons": "1-58565"
  },
  {
    "Cupons": "1-64279"
  },
  {
    "Cupons": "1-18654, 1-50183, 1-82639, 1-14270"
  },
  {
    "Cupons": "1-64596"
  },
  {
    "Cupons": "1-49840"
  },
  {
    "Cupons": "1-38501, 1-64231, 1-34604, 1-43022, 1-91244, 1-97088, 1-87323, 1-20553, 1-31283, 1-25943, 1-43322, 1-86856, 1-98941, 1-50654"
  },
  {
    "Cupons": "1-67482"
  },
  {
    "Cupons": "1-82288"
  },
  {
    "Cupons": "1-20572, 1-70392, 1-48690, 1-76263"
  },
  {
    "Cupons": "1-45587"
  },
  {
    "Cupons": "1-62270"
  },
  {
    "Cupons": "1-87676, 1-62744, 1-20986, 1-74574"
  },
  {
    "Cupons": "1-75202"
  },
  {
    "Cupons": "1-76535"
  },
  {
    "Cupons": "1-16546"
  },
  {
    "Cupons": "1-86577, 1-58902, 1-68849, 1-91400"
  },
  {
    "Cupons": "1-59716"
  },
  {
    "Cupons": "1-54666, 1-30696, 1-49801, 1-40763, 1-22415, 1-51453, 1-68601, 1-54111, 1-85736, 1-17279, 1-71908, 1-25033, 1-37204, 1-42577"
  },
  {
    "Cupons": "1-19102, 1-64396, 1-35753, 1-60390"
  },
  {
    "Cupons": "1-99223"
  },
  {
    "Cupons": "1-56581, 1-29400, 1-27799, 1-25054, 1-74735, 1-89089, 1-47026, 1-20945, 1-12186, 1-32170, 1-57042, 1-16511, 1-45344, 1-58525"
  },
  {
    "Cupons": "1-58532"
  },
  {
    "Cupons": "1-43515"
  },
  {
    "Cupons": "1-99203"
  },
  {
    "Cupons": "1-28497"
  },
  {
    "Cupons": "1-36815, 1-83691, 1-99080, 1-77108"
  },
  {
    "Cupons": "1-49015, 1-24095, 1-44928, 1-69633"
  },
  {
    "Cupons": "1-97657, 1-77043, 1-14023, 1-14635"
  },
  {
    "Cupons": "1-89044"
  },
  {
    "Cupons": "1-55691"
  },
  {
    "Cupons": "1-64357"
  },
  {
    "Cupons": "1-70194, 1-70463, 1-12113, 1-80772, 1-86508, 1-44126, 1-47271, 1-61467, 1-32870, 1-17390, 1-94059, 1-50327, 1-42452, 1-15703"
  },
  {
    "Cupons": "1-66003"
  },
  {
    "Cupons": "1-77864, 1-13714, 1-57498, 1-79058"
  },
  {
    "Cupons": "1-13145"
  },
  {
    "Cupons": "1-10220"
  },
  {
    "Cupons": "1-60517"
  },
  {
    "Cupons": "1-99957"
  },
  {
    "Cupons": "1-62034, 1-79723, 1-12229, 1-72918, 1-36367, 1-59530, 1-76489, 1-72149, 1-36150, 1-13496, 1-55140, 1-43140, 1-59639, 1-75091"
  },
  {
    "Cupons": "1-28351, 1-74066, 1-61522, 1-85755, 1-21325, 1-30294, 1-16649, 1-89773, 1-45308, 1-79173, 1-12548, 1-42167, 1-33607, 1-61021"
  },
  {
    "Cupons": "1-31737"
  },
  {
    "Cupons": "1-20014"
  },
  {
    "Cupons": "1-89891"
  },
  {
    "Cupons": "1-62976"
  },
  {
    "Cupons": "1-53173"
  },
  {
    "Cupons": "1-38205"
  },
  {
    "Cupons": "1-20938"
  },
  {
    "Cupons": "1-64505"
  },
  {
    "Cupons": "1-89746, 1-87286, 1-62844, 1-51676"
  },
  {
    "Cupons": "1-52776"
  },
  {
    "Cupons": "1-69024"
  },
  {
    "Cupons": "1-57828"
  },
  {
    "Cupons": "1-98464, 1-42524, 1-57826, 1-82274, 1-77778, 1-78204, 1-10311, 1-41310, 1-11925, 1-25339, 1-93203, 1-34247, 1-45306, 1-14625"
  },
  {
    "Cupons": "1-19928, 1-48914, 1-54576, 1-48338, 1-69482, 1-37163, 1-88368, 1-69539, 1-25750, 1-24906, 1-76031, 1-79283, 1-11856, 1-10045"
  },
  {
    "Cupons": "1-96543"
  },
  {
    "Cupons": "1-79110"
  },
  {
    "Cupons": "1-65778"
  },
  {
    "Cupons": "1-54496"
  },
  {
    "Cupons": "1-48606"
  },
  {
    "Cupons": "1-40772"
  },
  {
    "Cupons": "1-13618, 1-55589, 1-11112, 1-26879"
  },
  {
    "Cupons": "1-76050, 1-35149, 1-97013, 1-46947"
  },
  {
    "Cupons": "1-23111"
  },
  {
    "Cupons": "1-17118, 1-93476, 1-41867, 1-99331"
  },
  {
    "Cupons": "1-64640, 1-89817, 1-73737, 1-38678, 1-85552, 1-97121, 1-59306, 1-63707, 1-89568, 1-57537, 1-36009, 1-58126, 1-87070, 1-43739"
  },
  {
    "Cupons": "1-38917"
  },
  {
    "Cupons": "1-52426"
  },
  {
    "Cupons": "1-74812"
  },
  {
    "Cupons": "1-92568, 1-78724, 1-56859, 1-97484, 1-53267, 1-31437, 1-47348, 1-64808, 1-53319, 1-33823, 1-34175, 1-67102, 1-56719, 1-26607"
  },
  {
    "Cupons": "1-27436, 1-58357, 1-75361, 1-22480"
  },
  {
    "Cupons": "1-42442"
  },
  {
    "Cupons": "1-74668, 1-92867, 1-90405, 1-16230, 1-11916, 1-51623, 1-30656, 1-77209, 1-70154, 1-56020, 1-62878"
  },
  {
    "Cupons": "1-34124"
  },
  {
    "Cupons": "1-58467, 1-47904, 1-54278, 1-93529"
  },
  {
    "Cupons": "1-13154, 1-73295, 1-45642, 1-16215"
  },
  {
    "Cupons": "1-18260"
  },
  {
    "Cupons": "1-83829"
  },
  {
    "Cupons": "1-46327"
  },
  {
    "Cupons": "1-91140"
  },
  {
    "Cupons": "1-93560"
  },
  {
    "Cupons": "1-94298"
  },
  {
    "Cupons": "1-86028"
  },
  {
    "Cupons": "1-31573"
  },
  {
    "Cupons": "1-25451, 1-46154, 1-66807, 1-54234, 1-50462, 1-36577, 1-63969, 1-95345, 1-53153, 1-37991, 1-95093, 1-34004, 1-20120, 1-84897"
  },
  {
    "Cupons": "1-22024"
  },
  {
    "Cupons": "1-79725, 1-83611, 1-80753, 1-27262"
  },
  {
    "Cupons": "1-35494, 1-92102, 1-85750, 1-13020"
  },
  {
    "Cupons": "1-67549"
  },
  {
    "Cupons": "1-93708"
  },
  {
    "Cupons": "1-86682"
  },
  {
    "Cupons": "1-26349"
  },
  {
    "Cupons": "1-56012"
  },
  {
    "Cupons": "1-37562, 1-31265, 1-48535, 1-86586"
  },
  {
    "Cupons": "1-84114"
  },
  {
    "Cupons": "1-74716"
  },
  {
    "Cupons": "1-71157, 1-64242, 1-77975, 1-67676, 1-73230, 1-45551, 1-95895, 1-28347, 1-75683, 1-75673, 1-75463, 1-33641, 1-57796, 1-59892"
  },
  {
    "Cupons": "1-15264, 1-47189, 1-49034, 1-62919"
  },
  {
    "Cupons": "1-40769, 1-39820, 1-83290, 1-21581"
  },
  {
    "Cupons": "1-21445"
  },
  {
    "Cupons": "1-95258"
  },
  {
    "Cupons": "1-67918, 1-47531, 1-65868, 1-60313"
  },
  {
    "Cupons": "1-53763, 1-31673, 1-84961, 1-97774, 1-29525, 1-58003, 1-96602, 1-92238, 1-62703, 1-46399, 1-97153, 1-59288, 1-41624, 1-48007"
  },
  {
    "Cupons": "1-76522"
  },
  {
    "Cupons": "1-15077, 1-63516, 1-30602, 1-51254"
  },
  {
    "Cupons": "1-29216"
  },
  {
    "Cupons": "1-99506"
  },
  {
    "Cupons": "1-95964, 1-83566, 1-24037, 1-19552, 1-82982, 1-44841, 1-18855, 1-85543, 1-10116, 1-40846, 1-11318, 1-99453, 1-22149, 1-61763"
  },
  {
    "Cupons": "1-60469"
  },
  {
    "Cupons": "1-62101"
  },
  {
    "Cupons": "1-32154, 1-26075, 1-73910, 1-50049, 1-19773, 1-94054, 1-68556, 1-25868, 1-81273, 1-96890, 1-86070, 1-72349, 1-38884, 1-33544"
  },
  {
    "Cupons": "1-68328, 1-99401, 1-26353, 1-96872"
  },
  {
    "Cupons": "1-17318"
  },
  {
    "Cupons": "1-55746, 1-35253, 1-38439, 1-73546, 1-19365, 1-11685, 1-41324, 1-92393, 1-86656, 1-87215, 1-46184, 1-43627, 1-94060, 1-67766"
  },
  {
    "Cupons": "1-13094, 1-56496, 1-58977, 1-31018"
  },
  {
    "Cupons": "1-33676"
  },
  {
    "Cupons": "1-24213"
  },
  {
    "Cupons": "1-29875, 1-51248, 1-46952, 1-85535"
  },
  {
    "Cupons": "1-49046"
  },
  {
    "Cupons": "1-41902"
  },
  {
    "Cupons": "1-98282, 1-24365, 1-84992, 1-42043"
  },
  {
    "Cupons": "1-34816"
  },
  {
    "Cupons": "1-56507"
  },
  {
    "Cupons": "1-67352"
  },
  {
    "Cupons": "1-36714"
  },
  {
    "Cupons": "1-87776"
  },
  {
    "Cupons": "1-13684"
  },
  {
    "Cupons": "1-10963"
  },
  {
    "Cupons": "1-87069"
  },
  {
    "Cupons": "1-16306"
  },
  {
    "Cupons": "1-67121, 1-32634, 1-17236, 1-19137"
  },
  {
    "Cupons": "1-69696"
  },
  {
    "Cupons": "1-18920, 1-26407, 1-59272, 1-19363"
  },
  {
    "Cupons": "1-95816"
  },
  {
    "Cupons": "1-65410"
  },
  {
    "Cupons": "1-94510"
  },
  {
    "Cupons": "1-78332, 1-28566, 1-32098, 1-73730"
  },
  {
    "Cupons": "1-67640"
  },
  {
    "Cupons": "1-32685"
  },
  {
    "Cupons": "1-35116"
  },
  {
    "Cupons": "1-51087"
  },
  {
    "Cupons": "1-88350"
  },
  {
    "Cupons": "1-73449"
  },
  {
    "Cupons": "1-91258"
  },
  {
    "Cupons": "1-23371"
  },
  {
    "Cupons": "1-60033"
  },
  {
    "Cupons": "1-94158, 1-33685, 1-83613, 1-49419"
  },
  {
    "Cupons": "1-51905"
  },
  {
    "Cupons": "1-99610"
  },
  {
    "Cupons": "1-63801, 1-97984, 1-43351, 1-97843"
  },
  {
    "Cupons": "1-81853, 1-12747, 1-59577, 1-91630"
  },
  {
    "Cupons": "1-68374"
  },
  {
    "Cupons": "1-20601, 1-54300, 1-22464, 1-55734, 1-67312, 1-59224, 1-73805, 1-91289, 1-30242, 1-27793, 1-61000, 1-18992, 1-23115, 1-80724"
  },
  {
    "Cupons": "1-74839, 1-91989, 1-83774, 1-89242"
  },
  {
    "Cupons": "1-23129, 1-13340, 1-85778, 1-98597"
  },
  {
    "Cupons": "1-92733"
  },
  {
    "Cupons": "1-57580, 1-74099, 1-19494, 1-63052, 1-64594, 1-45562, 1-83593, 1-76085, 1-14453, 1-11418, 1-97218, 1-33225, 1-71131, 1-19844"
  },
  {
    "Cupons": "1-37313"
  },
  {
    "Cupons": "1-87316"
  },
  {
    "Cupons": "1-65209, 1-83451, 1-58440, 1-30836"
  },
  {
    "Cupons": "1-68074"
  },
  {
    "Cupons": "1-80057"
  },
  {
    "Cupons": "1-59356"
  },
  {
    "Cupons": "1-64509, 1-62452, 1-42778, 1-27459"
  },
  {
    "Cupons": "1-65891, 1-55201, 1-55123, 1-45837"
  },
  {
    "Cupons": "1-13540"
  },
  {
    "Cupons": "1-64427"
  },
  {
    "Cupons": "1-10557"
  },
  {
    "Cupons": "1-88917"
  },
  {
    "Cupons": "1-24222"
  },
  {
    "Cupons": "1-61469"
  },
  {
    "Cupons": "1-91509, 1-93918, 1-62759, 1-25092, 1-98380, 1-54375, 1-27023, 1-18829, 1-37370, 1-40944, 1-39964, 1-43245, 1-77588, 1-52479"
  },
  {
    "Cupons": "1-85779, 1-24905, 1-75355, 1-81077, 1-49328, 1-65552, 1-61695, 1-72028, 1-31102, 1-82523, 1-87547, 1-18585, 1-13326, 1-83985"
  },
  {
    "Cupons": "1-11794, 1-48744, 1-97632, 1-27933"
  },
  {
    "Cupons": "1-31073"
  },
  {
    "Cupons": "1-17025"
  },
  {
    "Cupons": "1-50457"
  },
  {
    "Cupons": "1-16979, 1-16115, 1-73987, 1-66185"
  },
  {
    "Cupons": "1-10178, 1-23902, 1-84144, 1-20777"
  },
  {
    "Cupons": "1-53426, 1-23296, 1-40853, 1-69195, 1-90992, 1-15113, 1-19144, 1-20249, 1-83459, 1-57055, 1-77879"
  },
  {
    "Cupons": "1-31861"
  },
  {
    "Cupons": "1-10412"
  },
  {
    "Cupons": "1-77287, 1-79615, 1-73480, 1-30388"
  },
  {
    "Cupons": "1-25933, 1-29865, 1-66010, 1-15268, 1-15215, 1-47012, 1-67459, 1-84534, 1-80877, 1-60229, 1-59267, 1-49930, 1-21038, 1-46196"
  },
  {
    "Cupons": "1-46649"
  },
  {
    "Cupons": "1-87142"
  },
  {
    "Cupons": "1-29936, 1-92843, 1-22176, 1-25847"
  },
  {
    "Cupons": "1-42493"
  },
  {
    "Cupons": "1-71709"
  },
  {
    "Cupons": "1-53596"
  },
  {
    "Cupons": "1-54223"
  },
  {
    "Cupons": "1-71618"
  },
  {
    "Cupons": "1-80296"
  },
  {
    "Cupons": "1-88747, 1-47516, 1-12201, 1-61637"
  },
  {
    "Cupons": "1-66389"
  },
  {
    "Cupons": "1-31401, 1-17105, 1-96231, 1-39873"
  },
  {
    "Cupons": "1-15557"
  },
  {
    "Cupons": "1-90086"
  },
  {
    "Cupons": "1-28470"
  },
  {
    "Cupons": "1-78072"
  },
  {
    "Cupons": "1-28756"
  },
  {
    "Cupons": "1-63060, 1-43982, 1-69335, 1-97506"
  },
  {
    "Cupons": "1-53577"
  },
  {
    "Cupons": "1-52548, 1-58844, 1-77951, 1-70698"
  },
  {
    "Cupons": "1-18497"
  },
  {
    "Cupons": "1-78651"
  },
  {
    "Cupons": "1-51084"
  },
  {
    "Cupons": "1-89042"
  },
  {
    "Cupons": "1-30763"
  },
  {
    "Cupons": "1-74838"
  },
  {
    "Cupons": "1-87703"
  },
  {
    "Cupons": "1-99338"
  },
  {
    "Cupons": "1-17883"
  },
  {
    "Cupons": "1-88146, 1-98620, 1-14268, 1-80468"
  },
  {
    "Cupons": "1-55704"
  },
  {
    "Cupons": "1-89273"
  },
  {
    "Cupons": "1-76450"
  },
  {
    "Cupons": "1-25441"
  },
  {
    "Cupons": "1-12907"
  },
  {
    "Cupons": "1-74994"
  },
  {
    "Cupons": "1-49323, 1-28222, 1-79676, 1-14549"
  },
  {
    "Cupons": "1-59110, 1-79999, 1-12778, 1-55303, 1-68719, 1-44595, 1-44283, 1-53902, 1-38918, 1-49600, 1-74250, 1-32521, 1-91230, 1-23647"
  },
  {
    "Cupons": "1-98629"
  },
  {
    "Cupons": "1-17220, 1-18129, 1-75066, 1-31328"
  },
  {
    "Cupons": "1-46338, 1-99098, 1-31717, 1-81436"
  },
  {
    "Cupons": "1-28037"
  },
  {
    "Cupons": "1-89726"
  },
  {
    "Cupons": "1-37438"
  },
  {
    "Cupons": "1-29530, 1-49526, 1-17646, 1-55743, 1-92096, 1-43999, 1-83666, 1-70560, 1-47639, 1-97490, 1-38622, 1-14580, 1-96945, 1-44206"
  },
  {
    "Cupons": "1-88128"
  },
  {
    "Cupons": "1-86101, 1-60493, 1-91514, 1-46608, 1-74326, 1-10240, 1-24070, 1-49889, 1-67804, 1-38471, 1-14554, 1-35559, 1-90592, 1-24847"
  },
  {
    "Cupons": "1-60662, 1-47072, 1-82726, 1-95749"
  },
  {
    "Cupons": "1-81083, 1-72908, 1-25086, 1-78308"
  },
  {
    "Cupons": "1-49670"
  },
  {
    "Cupons": "1-61710, 1-90544, 1-92598, 1-31388"
  },
  {
    "Cupons": "1-65786, 1-89349, 1-77967, 1-35013"
  },
  {
    "Cupons": "1-16957, 1-57682, 1-73687, 1-38654, 1-80982, 1-91528, 1-38491, 1-36457, 1-95096, 1-22228, 1-24907, 1-40317, 1-34376, 1-38157"
  },
  {
    "Cupons": "1-58928, 1-80474, 1-24289, 1-80638"
  },
  {
    "Cupons": "1-65603, 1-40571, 1-22528, 1-96768, 1-78000, 1-13991, 1-94262, 1-42544, 1-64197, 1-66558, 1-93391, 1-72023, 1-70719, 1-38026"
  },
  {
    "Cupons": "1-98971"
  },
  {
    "Cupons": "1-38888, 1-85300, 1-76968, 1-30246"
  },
  {
    "Cupons": "1-35857"
  },
  {
    "Cupons": "1-63270"
  },
  {
    "Cupons": "1-27038"
  },
  {
    "Cupons": "1-67888, 1-10609, 1-88194, 1-46622, 1-76445, 1-35687, 1-69404, 1-96219, 1-61981, 1-92181, 1-38858, 1-17066, 1-72846, 1-39960"
  },
  {
    "Cupons": "1-47210"
  },
  {
    "Cupons": "1-58917, 1-61266, 1-89981, 1-77685"
  },
  {
    "Cupons": "1-52889"
  },
  {
    "Cupons": "1-74761, 1-79857, 1-85070, 1-81188, 1-23794, 1-21185, 1-39031, 1-38931, 1-23654, 1-18111, 1-44630, 1-24683, 1-63457, 1-56782"
  },
  {
    "Cupons": "1-22846"
  },
  {
    "Cupons": "1-84502, 1-28366, 1-43711, 1-53779"
  },
  {
    "Cupons": "1-82698, 1-29218, 1-96572, 1-13658"
  },
  {
    "Cupons": "1-26466"
  },
  {
    "Cupons": "1-51760, 1-22936, 1-94287, 1-45341, 1-30681, 1-64666, 1-72103, 1-20846, 1-62594, 1-56770, 1-88188, 1-10431, 1-42556, 1-65831"
  },
  {
    "Cupons": "1-37731, 1-17422, 1-51924, 1-49351, 1-85330, 1-63166, 1-53242, 1-43433, 1-42218, 1-40832, 1-78653, 1-17873, 1-15821, 1-95813"
  },
  {
    "Cupons": "1-51619"
  },
  {
    "Cupons": "1-88043"
  },
  {
    "Cupons": "1-83992, 1-46229, 1-87829, 1-61944, 1-13246, 1-60452, 1-75970, 1-92578, 1-63025, 1-41362, 1-76286, 1-42520, 1-55190, 1-53922"
  },
  {
    "Cupons": "1-21882, 1-93677, 1-76466, 1-97315, 1-69457, 1-86755, 1-94125, 1-30731, 1-85179, 1-95303, 1-17485, 1-44018, 1-38116, 1-43314"
  },
  {
    "Cupons": "1-35412"
  },
  {
    "Cupons": "1-76946, 1-18663, 1-70012, 1-91236"
  },
  {
    "Cupons": "1-60314, 1-75626, 1-40447, 1-34740"
  },
  {
    "Cupons": "1-10359, 1-45531, 1-75637, 1-87383"
  },
  {
    "Cupons": "1-78530"
  },
  {
    "Cupons": "1-97401, 1-77915, 1-57705, 1-23321, 1-65356, 1-31311, 1-33456, 1-99993, 1-96280, 1-73660, 1-41760, 1-35850, 1-24605, 1-31339"
  },
  {
    "Cupons": "1-43622"
  },
  {
    "Cupons": "1-37292, 1-47637, 1-66517, 1-87620"
  },
  {
    "Cupons": "1-81008"
  },
  {
    "Cupons": "1-63155"
  },
  {
    "Cupons": "1-86418"
  },
  {
    "Cupons": "1-37443"
  },
  {
    "Cupons": "1-87785"
  },
  {
    "Cupons": "1-54114, 1-66118, 1-84141, 1-19246, 1-26892, 1-47652, 1-36637, 1-95676, 1-60641, 1-32184, 1-59161, 1-19784, 1-75739, 1-71763"
  },
  {
    "Cupons": "1-53695, 1-89429, 1-22893, 1-87041"
  },
  {
    "Cupons": "1-93280"
  },
  {
    "Cupons": "1-57405, 1-35681, 1-81843, 1-49496"
  },
  {
    "Cupons": "1-57327, 1-81979, 1-38391, 1-27665"
  },
  {
    "Cupons": "1-21764"
  },
  {
    "Cupons": "1-81024"
  },
  {
    "Cupons": "1-54361, 1-79700, 1-18519, 1-55719, 1-46320, 1-84068, 1-43173, 1-90344, 1-61082, 1-56374, 1-66932, 1-64336, 1-69574, 1-36674"
  },
  {
    "Cupons": "1-53936, 1-50037, 1-48772, 1-45432"
  },
  {
    "Cupons": "1-51523, 1-66381, 1-29744, 1-70958, 1-68213, 1-87260, 1-95340, 1-12145, 1-31746, 1-65116, 1-29104, 1-86242, 1-76065, 1-51620"
  },
  {
    "Cupons": "1-70830, 1-61621, 1-19142, 1-20024, 1-23904, 1-18620, 1-90212, 1-60876, 1-21162, 1-34101, 1-58169, 1-18281, 1-65878, 1-30576"
  },
  {
    "Cupons": "1-97290, 1-35188, 1-55749, 1-44810"
  },
  {
    "Cupons": "1-45568"
  },
  {
    "Cupons": "1-72592"
  },
  {
    "Cupons": "1-65701, 1-21184, 1-53489, 1-56667"
  },
  {
    "Cupons": "1-40225"
  },
  {
    "Cupons": "1-26116, 1-22498, 1-59129, 1-83308"
  },
  {
    "Cupons": "1-40554"
  },
  {
    "Cupons": "1-25307"
  },
  {
    "Cupons": "1-87708"
  },
  {
    "Cupons": "1-49961"
  },
  {
    "Cupons": "1-98402"
  },
  {
    "Cupons": "1-20858, 1-57199, 1-88821, 1-31330"
  },
  {
    "Cupons": "1-20154, 1-98732, 1-46916, 1-17583, 1-40434, 1-95348, 1-44526, 1-83761, 1-35667, 1-17814, 1-68009, 1-66997, 1-74253, 1-90423"
  },
  {
    "Cupons": "1-40693"
  },
  {
    "Cupons": "1-60064"
  },
  {
    "Cupons": "1-29179"
  },
  {
    "Cupons": "1-26034, 1-17009, 1-81588, 1-70787"
  },
  {
    "Cupons": "1-27282, 1-10395, 1-29445, 1-18969, 1-43675, 1-57286, 1-74427, 1-49960, 1-67986, 1-60853, 1-97953, 1-19865, 1-34289, 1-80007"
  },
  {
    "Cupons": "1-79753, 1-59015, 1-86909, 1-80217, 1-47627, 1-59442, 1-76003, 1-99236, 1-66178, 1-87468, 1-47690, 1-83983, 1-60510, 1-18801"
  },
  {
    "Cupons": "1-58346, 1-92764, 1-42096, 1-62051"
  },
  {
    "Cupons": "1-11987"
  },
  {
    "Cupons": "1-46038, 1-10957, 1-93040, 1-58627, 1-79317, 1-25168, 1-83276, 1-77278, 1-79306, 1-70348, 1-59201, 1-75046, 1-32933, 1-29046"
  },
  {
    "Cupons": "1-58397, 1-60254, 1-11788, 1-12605"
  },
  {
    "Cupons": "1-50312, 1-14380, 1-78776, 1-31309"
  },
  {
    "Cupons": "1-23356, 1-94320, 1-70531, 1-40714"
  },
  {
    "Cupons": "1-75295"
  },
  {
    "Cupons": "1-25232"
  },
  {
    "Cupons": "1-84739, 1-47619, 1-99665, 1-97882"
  },
  {
    "Cupons": "1-16134, 1-79678, 1-31196, 1-40030, 1-70306, 1-49902, 1-77357, 1-60862, 1-21384, 1-57850, 1-27677, 1-32623, 1-55732, 1-63325"
  },
  {
    "Cupons": "1-98183, 1-97447, 1-96956, 1-79169"
  },
  {
    "Cupons": "1-62365, 1-98200, 1-13274, 1-39334"
  },
  {
    "Cupons": "1-60626, 1-41550, 1-80853, 1-92581, 1-23106, 1-74569, 1-62835, 1-35864, 1-35564, 1-45051, 1-28131, 1-74662, 1-91033, 1-52545"
  },
  {
    "Cupons": "1-91690"
  },
  {
    "Cupons": "1-78016, 1-50967, 1-44277, 1-29451, 1-55620, 1-50337, 1-95703, 1-41268, 1-63422, 1-34553, 1-69145, 1-34744, 1-33406, 1-66127"
  },
  {
    "Cupons": "1-61848"
  },
  {
    "Cupons": "1-26887"
  },
  {
    "Cupons": "1-45042"
  },
  {
    "Cupons": "1-91583, 1-86137, 1-48291, 1-56757"
  },
  {
    "Cupons": "1-78007"
  },
  {
    "Cupons": "1-69044, 1-39648, 1-40198, 1-93222, 1-23407, 1-54112, 1-59693, 1-99045, 1-41185, 1-44346, 1-86550, 1-69096, 1-13688, 1-26402"
  },
  {
    "Cupons": "1-36243"
  },
  {
    "Cupons": "1-28917, 1-86925, 1-35216, 1-95196"
  },
  {
    "Cupons": "1-24813"
  },
  {
    "Cupons": "1-78378, 1-95736, 1-86365, 1-80098"
  },
  {
    "Cupons": "1-76878, 1-58512, 1-13311, 1-36727, 1-33017, 1-54465, 1-38760, 1-24736, 1-84743, 1-42809, 1-96062, 1-75700, 1-33920, 1-77479"
  },
  {
    "Cupons": "1-35388, 1-64352, 1-76804, 1-57359"
  },
  {
    "Cupons": "1-82529"
  },
  {
    "Cupons": "1-15314"
  },
  {
    "Cupons": "1-53277, 1-29092, 1-65647, 1-74799, 1-86092, 1-24498, 1-35161, 1-89531, 1-48310, 1-17402, 1-73613"
  },
  {
    "Cupons": "1-57970, 1-21053, 1-69764, 1-48252, 1-65072, 1-10091, 1-52938, 1-97649, 1-58950, 1-42609, 1-31780, 1-25002, 1-80328, 1-16963"
  },
  {
    "Cupons": "1-66093, 1-87892, 1-84271, 1-57439"
  },
  {
    "Cupons": "1-37726"
  },
  {
    "Cupons": "1-89770"
  },
  {
    "Cupons": "1-45503, 1-57908, 1-89910, 1-90225, 1-88395, 1-54642, 1-56417, 1-25446, 1-97044, 1-45204, 1-33458, 1-49950, 1-19763, 1-11958"
  },
  {
    "Cupons": "1-39167"
  },
  {
    "Cupons": "1-99796"
  },
  {
    "Cupons": "1-32071, 1-11544, 1-96954, 1-42301"
  },
  {
    "Cupons": "1-50765"
  },
  {
    "Cupons": "1-10327, 1-17794, 1-19742, 1-40581"
  },
  {
    "Cupons": "1-14684, 1-26056, 1-82990, 1-41588"
  },
  {
    "Cupons": "1-18544"
  },
  {
    "Cupons": "1-66597"
  },
  {
    "Cupons": "1-46151"
  },
  {
    "Cupons": "1-69299, 1-79576, 1-57116, 1-23057"
  },
  {
    "Cupons": "1-79231, 1-95004, 1-56870, 1-11411"
  },
  {
    "Cupons": "1-15237"
  },
  {
    "Cupons": "1-19766, 1-15107, 1-67946, 1-21438"
  },
  {
    "Cupons": "1-63261, 1-80665, 1-13685, 1-81608, 1-31532, 1-12244, 1-26832, 1-41802, 1-26667, 1-39869, 1-59597, 1-75596, 1-79942, 1-23307"
  },
  {
    "Cupons": "1-45132"
  },
  {
    "Cupons": "1-68927"
  },
  {
    "Cupons": "1-31453"
  },
  {
    "Cupons": "1-60821, 1-10445, 1-70110, 1-84791"
  },
  {
    "Cupons": "1-83057, 1-60959, 1-29444, 1-99027"
  },
  {
    "Cupons": "1-59529"
  },
  {
    "Cupons": "1-36124, 1-46772, 1-97798, 1-89736"
  },
  {
    "Cupons": "1-40013, 1-77591, 1-84023, 1-61584"
  },
  {
    "Cupons": "1-58590, 1-38011, 1-75966, 1-54054, 1-10400, 1-96643, 1-10987, 1-43253, 1-10188, 1-52418, 1-14860, 1-79959, 1-11445, 1-64265"
  },
  {
    "Cupons": "1-61403, 1-38082, 1-40035, 1-73391"
  },
  {
    "Cupons": "1-26465"
  },
  {
    "Cupons": "1-96262"
  },
  {
    "Cupons": "1-54382"
  },
  {
    "Cupons": "1-48726, 1-90347, 1-21554, 1-43211"
  },
  {
    "Cupons": "1-91986, 1-31483, 1-38933, 1-97183, 1-80472, 1-60898, 1-64153, 1-66686, 1-58079, 1-42782, 1-76431, 1-59202, 1-32575, 1-32278"
  },
  {
    "Cupons": "1-80305"
  },
  {
    "Cupons": "1-69801, 1-20202, 1-98482, 1-54363, 1-36135, 1-64115, 1-20709, 1-77808, 1-12206, 1-91254, 1-80371, 1-14188, 1-19355, 1-85173"
  },
  {
    "Cupons": "1-76754"
  },
  {
    "Cupons": "1-39819"
  },
  {
    "Cupons": "1-35780, 1-30755, 1-75186, 1-81762, 1-44552, 1-15102, 1-22080, 1-42083, 1-46844, 1-95769, 1-53354, 1-78126, 1-13996, 1-43806"
  },
  {
    "Cupons": "1-38962"
  },
  {
    "Cupons": "1-33672, 1-32758, 1-29794, 1-83591"
  },
  {
    "Cupons": "1-36459"
  },
  {
    "Cupons": "1-45920"
  },
  {
    "Cupons": "1-60449, 1-49289, 1-14824, 1-70683, 1-73827, 1-41899, 1-91455, 1-12805, 1-77835, 1-17289, 1-95580"
  },
  {
    "Cupons": "1-17246, 1-92433, 1-57216, 1-78908"
  },
  {
    "Cupons": "1-98591, 1-45608, 1-99372, 1-27349, 1-64895, 1-36060, 1-88888, 1-54727, 1-75804, 1-82679, 1-67891, 1-83574, 1-26630, 1-81407"
  },
  {
    "Cupons": "1-69884, 1-82298, 1-92584, 1-61532, 1-14831, 1-49696, 1-83892, 1-93498, 1-90034, 1-33840, 1-59402"
  },
  {
    "Cupons": "1-68415"
  },
  {
    "Cupons": "1-41486, 1-80121, 1-59093, 1-91128"
  },
  {
    "Cupons": "1-31994, 1-40337, 1-80283, 1-79129, 1-78009, 1-95152, 1-71526, 1-64538, 1-77794, 1-94132, 1-53341, 1-74301, 1-58628, 1-61336"
  },
  {
    "Cupons": "1-40680"
  },
  {
    "Cupons": "1-31025"
  },
  {
    "Cupons": "1-38818"
  },
  {
    "Cupons": "1-82058"
  },
  {
    "Cupons": "1-49200"
  },
  {
    "Cupons": "1-29740"
  },
  {
    "Cupons": "1-34645, 1-14510, 1-35627, 1-35164, 1-65454, 1-18168, 1-43748, 1-86036, 1-71018, 1-37820, 1-59705, 1-42523, 1-39389, 1-96783"
  },
  {
    "Cupons": "1-86667"
  },
  {
    "Cupons": "1-51385, 1-93371, 1-77648, 1-83270"
  },
  {
    "Cupons": "1-97936"
  },
  {
    "Cupons": "1-22469, 1-92757, 1-23524, 1-56003, 1-69380, 1-97343, 1-16574, 1-21831, 1-60652, 1-39612, 1-32772, 1-59478, 1-62551, 1-32097"
  },
  {
    "Cupons": "1-50552, 1-63356, 1-43427, 1-29595, 1-18447, 1-16723, 1-83482, 1-85920, 1-59574, 1-16664, 1-69648, 1-95957, 1-29284, 1-45576"
  },
  {
    "Cupons": "1-72042, 1-39426, 1-17237, 1-86062, 1-24205, 1-83136, 1-13420, 1-96093, 1-59314, 1-67634, 1-54349, 1-34131, 1-64766, 1-16988"
  },
  {
    "Cupons": "1-37893, 1-91865, 1-89749, 1-57669"
  },
  {
    "Cupons": "1-40294, 1-68607, 1-26142, 1-21128, 1-80524, 1-41489, 1-66102, 1-52851, 1-53512, 1-94623, 1-51907, 1-94202, 1-28450, 1-99232"
  },
  {
    "Cupons": "1-78281"
  },
  {
    "Cupons": "1-81357"
  },
  {
    "Cupons": "1-81493"
  },
  {
    "Cupons": "1-38405"
  },
  {
    "Cupons": "1-44025"
  },
  {
    "Cupons": "1-65690, 1-14614, 1-31223, 1-76259, 1-67558, 1-50521, 1-74181, 1-37581, 1-87594, 1-33764, 1-51815"
  },
  {
    "Cupons": "1-37329, 1-61402, 1-94517, 1-10475, 1-10476, 1-64338, 1-93822, 1-76774, 1-26226, 1-92560, 1-44061, 1-59223, 1-48462, 1-30438"
  },
  {
    "Cupons": "1-17831"
  },
  {
    "Cupons": "1-40498, 1-99174, 1-45692, 1-88584, 1-63984, 1-29139, 1-84466, 1-38526, 1-26541, 1-68756, 1-68493, 1-76637, 1-77083, 1-55175"
  },
  {
    "Cupons": "1-70013"
  },
  {
    "Cupons": "1-30384, 1-70837, 1-41926, 1-60677, 1-91845, 1-35747, 1-37591, 1-53111, 1-79682, 1-19591, 1-18407, 1-13654, 1-55473, 1-17117"
  },
  {
    "Cupons": "1-56653"
  },
  {
    "Cupons": "1-35923, 1-33692, 1-69536, 1-62805, 1-12013, 1-22260, 1-78417, 1-19789, 1-95914, 1-67164, 1-37860, 1-57168, 1-38493, 1-59925"
  },
  {
    "Cupons": "1-93993, 1-82584, 1-43188, 1-66034, 1-66779, 1-76367, 1-85101, 1-82509, 1-53702, 1-39707, 1-14497, 1-11152, 1-46891, 1-25604"
  },
  {
    "Cupons": "1-90610"
  },
  {
    "Cupons": "1-51866"
  },
  {
    "Cupons": "1-98227"
  },
  {
    "Cupons": "1-36034"
  },
  {
    "Cupons": "1-89893, 1-53487, 1-45838, 1-83163"
  },
  {
    "Cupons": "1-20343, 1-34651, 1-86354, 1-32369, 1-67117, 1-55714, 1-20425, 1-13108, 1-24224, 1-50542, 1-36172, 1-37569, 1-40308, 1-35217"
  },
  {
    "Cupons": "1-23912"
  },
  {
    "Cupons": "1-40836, 1-66164, 1-51719, 1-64414, 1-22525, 1-21035, 1-46726, 1-26846, 1-36527, 1-26053, 1-10786, 1-79606, 1-95396, 1-31232"
  },
  {
    "Cupons": "1-31125, 1-59200, 1-55400, 1-97899"
  },
  {
    "Cupons": "1-53652, 1-97168, 1-84088, 1-35699, 1-86239, 1-59645, 1-70010, 1-88646, 1-66801, 1-97927, 1-98565, 1-72877, 1-50131, 1-99489"
  },
  {
    "Cupons": "1-70667"
  },
  {
    "Cupons": "1-60940"
  },
  {
    "Cupons": "1-86034"
  },
  {
    "Cupons": "1-50156"
  },
  {
    "Cupons": "1-21474, 1-47693, 1-10798, 1-52056"
  },
  {
    "Cupons": "1-27600"
  },
  {
    "Cupons": "1-14163"
  },
  {
    "Cupons": "1-97015"
  },
  {
    "Cupons": "1-64864, 1-92784, 1-35716, 1-26474"
  },
  {
    "Cupons": "1-34702, 1-24501, 1-24220, 1-33388"
  },
  {
    "Cupons": "1-75114, 1-93941, 1-73209, 1-12950"
  },
  {
    "Cupons": "1-22678, 1-60197, 1-88116, 1-28554, 1-49468, 1-43989, 1-90554, 1-95775, 1-16471, 1-87387, 1-61667, 1-56538, 1-21371, 1-74442"
  },
  {
    "Cupons": "1-85468, 1-16978, 1-81789, 1-76610"
  },
  {
    "Cupons": "1-26273"
  },
  {
    "Cupons": "1-92529"
  },
  {
    "Cupons": "1-85649"
  },
  {
    "Cupons": "1-41376"
  },
  {
    "Cupons": "1-57107"
  },
  {
    "Cupons": "1-63879"
  },
  {
    "Cupons": "1-57542"
  },
  {
    "Cupons": "1-54655, 1-27337, 1-70585, 1-44643, 1-96382, 1-91729, 1-59344, 1-42504, 1-14576, 1-59472, 1-89658, 1-61693, 1-63358, 1-32481"
  },
  {
    "Cupons": "1-85774, 1-34566, 1-36142, 1-43320"
  },
  {
    "Cupons": "1-44854"
  },
  {
    "Cupons": "1-40235"
  },
  {
    "Cupons": "1-50108, 1-20701, 1-72342, 1-50267, 1-69822, 1-66732, 1-12082, 1-21867, 1-44110, 1-38855, 1-75436, 1-57838, 1-47915, 1-60971"
  },
  {
    "Cupons": "1-16591, 1-62710, 1-96963, 1-86246, 1-45972, 1-29868, 1-92802, 1-50496, 1-70686, 1-23110, 1-79492, 1-55918, 1-59335, 1-74941"
  },
  {
    "Cupons": "1-91870, 1-39256, 1-76817, 1-93259, 1-59519, 1-42865, 1-85180, 1-89190, 1-83535, 1-46423, 1-53461"
  },
  {
    "Cupons": "1-11194, 1-17296, 1-31433, 1-93137"
  },
  {
    "Cupons": "1-11242"
  },
  {
    "Cupons": "1-50568"
  },
  {
    "Cupons": "1-86799, 1-35828, 1-65917, 1-97319"
  },
  {
    "Cupons": "1-31219"
  },
  {
    "Cupons": "1-25749"
  },
  {
    "Cupons": "1-15233, 1-93041, 1-31226, 1-73592, 1-84560, 1-39659, 1-30012, 1-19198, 1-62894, 1-82134, 1-31310, 1-31796, 1-29873, 1-98010"
  },
  {
    "Cupons": "1-45057"
  },
  {
    "Cupons": "1-71030"
  },
  {
    "Cupons": "1-53778, 1-76324, 1-16214, 1-94057"
  },
  {
    "Cupons": "1-30052, 1-23406, 1-33252, 1-22925"
  },
  {
    "Cupons": "1-35126"
  },
  {
    "Cupons": "1-94716, 1-21105, 1-77823, 1-61640, 1-88461, 1-46469, 1-36400, 1-96618, 1-62604, 1-48771, 1-52691, 1-72069, 1-89391, 1-10845"
  },
  {
    "Cupons": "1-46968, 1-28878, 1-44929, 1-27942, 1-61282, 1-95336, 1-86888, 1-14993, 1-37426, 1-67384, 1-67434, 1-22333, 1-68420, 1-35758"
  },
  {
    "Cupons": "1-23387, 1-87065, 1-48452, 1-15385"
  },
  {
    "Cupons": "1-17086"
  },
  {
    "Cupons": "1-41994"
  },
  {
    "Cupons": "1-29255, 1-46001, 1-56173, 1-36330, 1-31776, 1-93428, 1-86133, 1-52923, 1-64634, 1-83511, 1-89915, 1-15262, 1-14842, 1-70224"
  },
  {
    "Cupons": "1-85038, 1-81964, 1-96100, 1-70858, 1-87822, 1-91595, 1-22404, 1-30537, 1-51805, 1-79070, 1-78306, 1-34715, 1-79871, 1-36706"
  },
  {
    "Cupons": "1-73828"
  },
  {
    "Cupons": "1-88315, 1-94037, 1-53634, 1-58386, 1-70223, 1-23205, 1-45337, 1-90783, 1-14951, 1-21045, 1-34826, 1-67191, 1-63080, 1-39847"
  },
  {
    "Cupons": "1-53278, 1-70470, 1-19297, 1-93831"
  },
  {
    "Cupons": "1-40627"
  },
  {
    "Cupons": "1-25627, 1-69824, 1-44673, 1-90120"
  },
  {
    "Cupons": "1-31740, 1-17561, 1-63916, 1-40758"
  },
  {
    "Cupons": "1-98118"
  },
  {
    "Cupons": "1-36938, 1-96410, 1-98529, 1-65281"
  },
  {
    "Cupons": "1-68176"
  },
  {
    "Cupons": "1-77506"
  },
  {
    "Cupons": "1-72665, 1-66037, 1-19447, 1-26883, 1-86519, 1-49409, 1-95189, 1-71702, 1-98417, 1-31300, 1-49772, 1-12792, 1-61913, 1-27969"
  },
  {
    "Cupons": "1-12135"
  },
  {
    "Cupons": "1-75311"
  },
  {
    "Cupons": "1-58832, 1-12037, 1-70536, 1-26326, 1-13500, 1-17410, 1-23310, 1-44937, 1-18958, 1-65988, 1-54233, 1-86305, 1-13074, 1-56598"
  },
  {
    "Cupons": "1-28612, 1-19902, 1-93888, 1-75083, 1-68950, 1-36163, 1-54600, 1-45336, 1-77987, 1-62900, 1-40448, 1-41390, 1-26745, 1-24518"
  },
  {
    "Cupons": "1-87795"
  },
  {
    "Cupons": "1-14641, 1-30693, 1-70760, 1-48185"
  },
  {
    "Cupons": "1-11631"
  },
  {
    "Cupons": "1-42993"
  },
  {
    "Cupons": "1-62369, 1-20013, 1-55613, 1-29021"
  },
  {
    "Cupons": "1-39637, 1-37464, 1-66024, 1-70565"
  },
  {
    "Cupons": "1-54867, 1-84193, 1-53708, 1-73457, 1-99328, 1-97091, 1-79968, 1-61148, 1-43764, 1-40427, 1-90012, 1-39119, 1-60385, 1-30963"
  },
  {
    "Cupons": "1-98900, 1-83706, 1-81510, 1-86687"
  },
  {
    "Cupons": "1-57839"
  },
  {
    "Cupons": "1-25260, 1-99087, 1-12110, 1-53357, 1-14448, 1-24239, 1-56941, 1-71308, 1-95536, 1-33009, 1-52514, 1-27211, 1-15352, 1-98973"
  },
  {
    "Cupons": "1-57683, 1-80224, 1-68866, 1-16692, 1-77277, 1-44285, 1-80703, 1-23849, 1-51797, 1-32068, 1-39835, 1-21488, 1-48176, 1-71305"
  },
  {
    "Cupons": "1-76138, 1-16955, 1-43578, 1-42922, 1-42800, 1-96609, 1-55109, 1-85906, 1-62896, 1-82863, 1-96191"
  },
  {
    "Cupons": "1-12514, 1-50785, 1-78353, 1-47379"
  },
  {
    "Cupons": "1-49534, 1-73001, 1-96068, 1-88037"
  },
  {
    "Cupons": "1-60603"
  },
  {
    "Cupons": "1-62716, 1-65391, 1-28940, 1-80514"
  },
  {
    "Cupons": "1-46566"
  },
  {
    "Cupons": "1-92049"
  },
  {
    "Cupons": "1-59072, 1-59092, 1-53144, 1-61142"
  },
  {
    "Cupons": "1-95397"
  },
  {
    "Cupons": "1-57722, 1-70344, 1-12452, 1-74885, 1-59380, 1-80393, 1-98423, 1-25360, 1-30556, 1-18136, 1-64410, 1-70441, 1-58842, 1-31063"
  },
  {
    "Cupons": "1-72100, 1-50858, 1-35661, 1-38185, 1-11813, 1-68422, 1-17853, 1-38498, 1-28338, 1-61947, 1-62263, 1-92769, 1-90176, 1-80280"
  },
  {
    "Cupons": "1-96271, 1-80309, 1-66576, 1-28225"
  },
  {
    "Cupons": "1-31365, 1-87693, 1-18165, 1-59377, 1-46525, 1-98361, 1-26774, 1-84757, 1-37730, 1-75877, 1-97130, 1-46825, 1-81128, 1-53437"
  },
  {
    "Cupons": "1-10818"
  },
  {
    "Cupons": "1-39537"
  },
  {
    "Cupons": "1-99408, 1-49009, 1-33841, 1-21624"
  },
  {
    "Cupons": "1-98344"
  },
  {
    "Cupons": "1-47405"
  },
  {
    "Cupons": "1-62532"
  },
  {
    "Cupons": "1-32321, 1-85137, 1-56395, 1-35745, 1-31293, 1-96380, 1-64201, 1-40168, 1-16944, 1-83582, 1-65823, 1-38428, 1-69942, 1-98330"
  },
  {
    "Cupons": "1-23981"
  },
  {
    "Cupons": "1-71098"
  },
  {
    "Cupons": "1-34956, 1-85186, 1-91348, 1-96415"
  },
  {
    "Cupons": "1-36691"
  },
  {
    "Cupons": "1-18876, 1-19644, 1-81904, 1-51471, 1-70540, 1-93160, 1-40134, 1-15951, 1-89223, 1-71840, 1-32775, 1-77772, 1-34360, 1-65722"
  },
  {
    "Cupons": "1-20375, 1-49152, 1-45701, 1-34201, 1-80726, 1-99919, 1-54386, 1-11271, 1-61382, 1-24790, 1-79055"
  },
  {
    "Cupons": "1-38505"
  },
  {
    "Cupons": "1-16341, 1-55721, 1-83350, 1-82315"
  },
  {
    "Cupons": "1-18432, 1-96133, 1-37993, 1-76703, 1-52841, 1-46267, 1-10644, 1-94317, 1-30401, 1-94325, 1-72119, 1-26346, 1-20739, 1-44776"
  },
  {
    "Cupons": "1-79763"
  },
  {
    "Cupons": "1-46257, 1-74430, 1-58703, 1-47860, 1-71145, 1-42246, 1-87864, 1-65482, 1-20526, 1-17804, 1-19101, 1-97797, 1-69368, 1-13028"
  },
  {
    "Cupons": "1-44136, 1-59329, 1-89530, 1-47658, 1-49377, 1-45478, 1-92608, 1-29899, 1-12987, 1-57654, 1-50840"
  },
  {
    "Cupons": "1-36586, 1-82983, 1-19288, 1-68932, 1-69702, 1-85171, 1-42268, 1-26468, 1-72879, 1-15752, 1-61404, 1-42631, 1-65782, 1-53007"
  },
  {
    "Cupons": "1-92747"
  },
  {
    "Cupons": "1-20749"
  },
  {
    "Cupons": "1-62344, 1-79262, 1-98268, 1-33650"
  },
  {
    "Cupons": "1-83668"
  },
  {
    "Cupons": "1-52420, 1-91298, 1-34913, 1-23394, 1-86695, 1-83917, 1-59149, 1-38132, 1-91602, 1-73081, 1-32563, 1-37424, 1-70951, 1-53530"
  },
  {
    "Cupons": "1-87038, 1-23947, 1-45480, 1-70574, 1-85005, 1-36994, 1-99437, 1-94395, 1-28186, 1-84565, 1-96728, 1-17768, 1-36440, 1-39783"
  },
  {
    "Cupons": "1-41869, 1-17439, 1-58864, 1-34187, 1-75657, 1-25555, 1-42258, 1-65743, 1-41162, 1-38643, 1-93989, 1-26373, 1-99855, 1-67802"
  },
  {
    "Cupons": "1-96649, 1-59998, 1-55288, 1-86333, 1-64284, 1-85880, 1-13705, 1-28082, 1-56339, 1-58890, 1-89877, 1-71540, 1-38053, 1-51851"
  },
  {
    "Cupons": "1-21933, 1-35482, 1-15242, 1-46901, 1-85460, 1-62536, 1-82088, 1-30895, 1-25083, 1-38599, 1-73622, 1-21191, 1-81136, 1-48223"
  },
  {
    "Cupons": "1-50623, 1-30993, 1-86131, 1-73648"
  },
  {
    "Cupons": "1-38462, 1-24746, 1-65835, 1-37575, 1-42315, 1-25675, 1-65342, 1-80684, 1-40112, 1-93362, 1-54171, 1-87535, 1-11055, 1-84255, 1-58394, 1-82494, 1-19993, 1-52313, 1-55498, 1-44322, 1-62847, 1-21741, 1-13167, 1-96533, 1-54641, 1-63945, 1-30214, 1-94211, 1-12674, 1-37800, 1-32396, 1-88634, 1-16370, 1-11019, 1-15719, 1-60795, 1-26901, 1-16711, 1-41315, 1-44009, 1-97000, 1-45319, 1-55758, 1-25613, 1-55248, 1-41404, 1-66638, 1-66143, 1-17014, 1-79823, 1-52957, 1-98307, 1-63768, 1-55116, 1-64473, 1-85335, 1-93674, 1-46081, 1-66161, 1-67302, 1-19019, 1-33973, 1-63193, 1-17206, 1-53796, 1-27426, 1-82896, 1-41658, 1-63509, 1-70045, 1-77165, 1-51428, 1-55988, 1-33207"
  },
  {
    "Cupons": "1-23235, 1-45905, 1-99631, 1-36451, 1-35460, 1-80023, 1-34215, 1-45849, 1-63093, 1-49008, 1-24020, 1-44706, 1-18282, 1-93924"
  },
  {
    "Cupons": "1-45389"
  },
  {
    "Cupons": "1-14525, 1-33091, 1-12094, 1-89591"
  },
  {
    "Cupons": "1-32491"
  },
  {
    "Cupons": "1-89016, 1-72078, 1-88136, 1-40457, 1-39785, 1-66973, 1-23216, 1-17129, 1-17643, 1-89466, 1-55405, 1-95805, 1-60866, 1-16872"
  },
  {
    "Cupons": "1-24286, 1-68961, 1-66667, 1-39098, 1-84538, 1-38753, 1-82700, 1-53198, 1-87624, 1-37900, 1-54475"
  },
  {
    "Cupons": "1-67753"
  },
  {
    "Cupons": "1-19654, 1-53586, 1-82723, 1-28736, 1-73334, 1-37367, 1-48378, 1-39196, 1-31500, 1-36486, 1-70657, 1-28116, 1-52316, 1-20288"
  },
  {
    "Cupons": "1-98163, 1-17792, 1-96566, 1-81207"
  },
  {
    "Cupons": "1-41968, 1-51449, 1-62153, 1-24656, 1-83287, 1-81491, 1-59666, 1-16069, 1-51336, 1-64503, 1-93471, 1-25410, 1-41988, 1-52084"
  },
  {
    "Cupons": "1-29413, 1-90288, 1-53458, 1-73042"
  },
  {
    "Cupons": "1-59051"
  },
  {
    "Cupons": "1-50538, 1-85876, 1-71475, 1-24651"
  },
  {
    "Cupons": "1-92272, 1-14144, 1-17060, 1-97464"
  },
  {
    "Cupons": "1-38629, 1-93332, 1-80035, 1-44457, 1-90036, 1-88379, 1-19347, 1-39926, 1-77049, 1-44507, 1-28215, 1-42996, 1-91086, 1-55796"
  },
  {
    "Cupons": "1-18606, 1-23195, 1-36953, 1-50196, 1-38311, 1-85484, 1-63498, 1-72738, 1-32006, 1-94448, 1-38887"
  },
  {
    "Cupons": "1-31149, 1-42346, 1-20837, 1-88131"
  },
  {
    "Cupons": "1-41912, 1-65109, 1-61737, 1-85894"
  },
  {
    "Cupons": "1-54542, 1-73867, 1-56691, 1-73940, 1-32110, 1-79115, 1-91399, 1-83331, 1-27035, 1-16348, 1-76147, 1-28047, 1-56331, 1-88003"
  },
  {
    "Cupons": "1-63302, 1-71500, 1-91038, 1-33403, 1-24715, 1-24732, 1-17507, 1-44779, 1-79548, 1-54414, 1-23465, 1-10857, 1-97755, 1-14272"
  },
  {
    "Cupons": "1-25502, 1-28423, 1-44055, 1-41069, 1-87188, 1-57326, 1-25110, 1-71678, 1-87347, 1-96865, 1-49743, 1-29527, 1-96498, 1-43895"
  },
  {
    "Cupons": "1-10379"
  },
  {
    "Cupons": "1-18220"
  },
  {
    "Cupons": "1-94295, 1-46551, 1-50886, 1-66484, 1-83301, 1-58571, 1-51225, 1-59723, 1-96058, 1-33412, 1-84230, 1-53156, 1-66603, 1-76484"
  },
  {
    "Cupons": "1-68964"
  },
  {
    "Cupons": "1-94581"
  },
  {
    "Cupons": "1-46427, 1-76531, 1-48097, 1-67113, 1-73628, 1-76298, 1-34738, 1-67775, 1-74263, 1-75565, 1-27037, 1-22351, 1-94179, 1-11809"
  },
  {
    "Cupons": "1-59398"
  },
  {
    "Cupons": "1-88914"
  },
  {
    "Cupons": "1-75928"
  },
  {
    "Cupons": "1-98407, 1-87171, 1-81203, 1-24862, 1-35121, 1-27173, 1-32401, 1-97245, 1-64471, 1-89366, 1-63687, 1-99163, 1-47603, 1-35129"
  },
  {
    "Cupons": "1-28875, 1-48737, 1-77655, 1-17921"
  },
  {
    "Cupons": "1-97554, 1-62883, 1-13003, 1-62108"
  },
  {
    "Cupons": "1-57907"
  },
  {
    "Cupons": "1-43680, 1-10096, 1-93868, 1-81364, 1-61791, 1-20547, 1-81485, 1-79004, 1-28829, 1-31207, 1-69252, 1-36456, 1-94428, 1-16444"
  },
  {
    "Cupons": "1-89919, 1-94514, 1-37387, 1-31970"
  },
  {
    "Cupons": "1-52788, 1-91902, 1-83017, 1-45290, 1-21805, 1-63859, 1-66158, 1-91346, 1-44128, 1-49250, 1-60005, 1-63286, 1-32863, 1-39012"
  },
  {
    "Cupons": "1-66640"
  },
  {
    "Cupons": "1-82461, 1-71240, 1-53292, 1-52117"
  },
  {
    "Cupons": "1-54956, 1-85646, 1-91889, 1-97345, 1-71414, 1-91108, 1-17688, 1-99735, 1-72306, 1-26232, 1-20587, 1-34549, 1-86839, 1-60110"
  },
  {
    "Cupons": "1-93217, 1-32288, 1-57224, 1-37515"
  },
  {
    "Cupons": "1-52815"
  },
  {
    "Cupons": "1-71641"
  },
  {
    "Cupons": "1-43436"
  },
  {
    "Cupons": "1-18142, 1-93466, 1-97417, 1-44191, 1-20871, 1-62641, 1-37600, 1-15688, 1-60837, 1-77101, 1-19549"
  },
  {
    "Cupons": "1-33748"
  },
  {
    "Cupons": "1-64602"
  },
  {
    "Cupons": "1-94482"
  },
  {
    "Cupons": "1-27141"
  },
  {
    "Cupons": "1-38847"
  },
  {
    "Cupons": "1-93750"
  },
  {
    "Cupons": "1-14294, 1-40740, 1-60054, 1-15205"
  },
  {
    "Cupons": "1-49918"
  },
  {
    "Cupons": "1-35315, 1-31005, 1-77646, 1-56037"
  },
  {
    "Cupons": "1-91635, 1-62134, 1-88627, 1-69494, 1-97500, 1-89980, 1-70984, 1-33752, 1-84131, 1-59453, 1-34128, 1-49730, 1-98868, 1-30722"
  },
  {
    "Cupons": "1-13592"
  },
  {
    "Cupons": "1-79124"
  },
  {
    "Cupons": "1-49195"
  },
  {
    "Cupons": "1-11722, 1-41850, 1-26239, 1-63570"
  },
  {
    "Cupons": "1-14364"
  },
  {
    "Cupons": "1-24456, 1-32571, 1-96697, 1-91474"
  },
  {
    "Cupons": "1-83570"
  },
  {
    "Cupons": "1-40436"
  },
  {
    "Cupons": "1-22571, 1-52582, 1-37400, 1-74415"
  },
  {
    "Cupons": "1-19891"
  },
  {
    "Cupons": "1-42160, 1-65370, 1-70202, 1-44491"
  },
  {
    "Cupons": "1-54384"
  },
  {
    "Cupons": "1-21330, 1-87846, 1-77890, 1-36655"
  },
  {
    "Cupons": "1-76162"
  },
  {
    "Cupons": "1-78671, 1-55971, 1-21215, 1-85018, 1-75051, 1-86692, 1-55599, 1-88746, 1-98770, 1-68878, 1-32656, 1-17120, 1-87441, 1-51373"
  },
  {
    "Cupons": "1-73709, 1-15394, 1-86770, 1-44967"
  },
  {
    "Cupons": "1-27461, 1-54174, 1-86911, 1-44076, 1-16112, 1-73652, 1-65763, 1-27544, 1-47660, 1-85022, 1-29760, 1-36388, 1-40033, 1-91178"
  },
  {
    "Cupons": "1-99241"
  },
  {
    "Cupons": "1-62526"
  },
  {
    "Cupons": "1-29189, 1-49965, 1-11745, 1-32780"
  },
  {
    "Cupons": "1-21157, 1-83575, 1-79275, 1-92403, 1-39423, 1-49037, 1-96083, 1-45537, 1-54853, 1-93478, 1-48976, 1-17516, 1-86949, 1-12885"
  },
  {
    "Cupons": "1-89293"
  },
  {
    "Cupons": "1-68888"
  },
  {
    "Cupons": "1-20807"
  },
  {
    "Cupons": "1-74650, 1-95788, 1-69081, 1-26200"
  },
  {
    "Cupons": "1-78105, 1-48114, 1-96896, 1-41222"
  },
  {
    "Cupons": "1-50925"
  },
  {
    "Cupons": "1-21170"
  },
  {
    "Cupons": "1-73186, 1-27850, 1-49274, 1-94632, 1-21700, 1-59350, 1-65499, 1-38806, 1-32017, 1-20910, 1-25342"
  },
  {
    "Cupons": "1-25147, 1-44528, 1-40076, 1-66720, 1-49235, 1-20050, 1-69082, 1-98251, 1-74736, 1-62491, 1-43902"
  },
  {
    "Cupons": "1-26356"
  },
  {
    "Cupons": "1-55020, 1-93791, 1-23176, 1-64730"
  },
  {
    "Cupons": "1-54245, 1-96474, 1-70188, 1-14569"
  },
  {
    "Cupons": "1-12068"
  },
  {
    "Cupons": "1-86407"
  },
  {
    "Cupons": "1-19404"
  },
  {
    "Cupons": "1-35947"
  },
  {
    "Cupons": "1-40161, 1-78041, 1-73314, 1-33907"
  },
  {
    "Cupons": "1-64097"
  },
  {
    "Cupons": "1-41942, 1-47553, 1-80279, 1-36581, 1-51433, 1-57978, 1-97688, 1-46651, 1-63057, 1-47187, 1-41608, 1-64567, 1-78161, 1-51520"
  },
  {
    "Cupons": "1-48299"
  },
  {
    "Cupons": "1-43691, 1-57192, 1-31973, 1-54595, 1-22877, 1-59305, 1-76905, 1-54720, 1-85433, 1-11603, 1-53012, 1-31397, 1-11317, 1-98649"
  },
  {
    "Cupons": "1-55844, 1-40598, 1-38663, 1-62412"
  },
  {
    "Cupons": "1-71472"
  },
  {
    "Cupons": "1-11760, 1-74019, 1-93455, 1-70838"
  },
  {
    "Cupons": "1-37208, 1-85589, 1-91209, 1-71601, 1-59080, 1-11451, 1-97288, 1-72967, 1-61232, 1-84061, 1-31345, 1-17805, 1-21337, 1-89168"
  },
  {
    "Cupons": "1-58993, 1-61439, 1-15855, 1-41829"
  },
  {
    "Cupons": "1-58952"
  },
  {
    "Cupons": "1-71517, 1-55539, 1-80929, 1-56161, 1-13305, 1-72056, 1-26456, 1-19802, 1-75347, 1-40332, 1-93823, 1-11477, 1-80100, 1-81157"
  },
  {
    "Cupons": "1-23613, 1-85377, 1-51594, 1-37947"
  },
  {
    "Cupons": "1-96789, 1-35018, 1-24125, 1-32486"
  },
  {
    "Cupons": "1-68388"
  },
  {
    "Cupons": "1-65464, 1-83224, 1-26696, 1-51589"
  },
  {
    "Cupons": "1-97378"
  },
  {
    "Cupons": "1-38598, 1-81896, 1-17386, 1-27784"
  },
  {
    "Cupons": "1-50262, 1-67739, 1-43310, 1-28454, 1-29799, 1-55813, 1-51662, 1-12471, 1-24315, 1-78813, 1-15959"
  },
  {
    "Cupons": "1-59126, 1-83963, 1-95930, 1-40625, 1-85685, 1-96968, 1-91412, 1-91040, 1-12846, 1-81261, 1-20946, 1-54926, 1-50791, 1-67301"
  },
  {
    "Cupons": "1-22433"
  },
  {
    "Cupons": "1-97359"
  },
  {
    "Cupons": "1-47547, 1-38866, 1-35916, 1-85132"
  },
  {
    "Cupons": "1-41650"
  },
  {
    "Cupons": "1-83734"
  },
  {
    "Cupons": "1-65612"
  },
  {
    "Cupons": "1-45983"
  },
  {
    "Cupons": "1-43036, 1-67484, 1-34810, 1-55420"
  },
  {
    "Cupons": "1-15013, 1-15673, 1-65625, 1-57699, 1-25426, 1-47936, 1-33457, 1-64399, 1-78656, 1-61538, 1-36841, 1-73158, 1-12253, 1-90894"
  },
  {
    "Cupons": "1-43493"
  },
  {
    "Cupons": "1-18839, 1-25949, 1-19059, 1-37326"
  },
  {
    "Cupons": "1-13465"
  },
  {
    "Cupons": "1-52227"
  },
  {
    "Cupons": "1-71524"
  },
  {
    "Cupons": "1-58602"
  },
  {
    "Cupons": "1-25621, 1-40403, 1-54758, 1-99457"
  },
  {
    "Cupons": "1-53849, 1-45639, 1-85226, 1-67586, 1-71171, 1-31700, 1-85712, 1-37526, 1-63365, 1-40801, 1-28276, 1-94813, 1-27095, 1-15225"
  },
  {
    "Cupons": "1-73869"
  },
  {
    "Cupons": "1-95456"
  },
  {
    "Cupons": "1-21302, 1-62887, 1-74495, 1-92534"
  },
  {
    "Cupons": "1-40179"
  },
  {
    "Cupons": "1-54506"
  },
  {
    "Cupons": "1-48575"
  },
  {
    "Cupons": "1-79687, 1-85557, 1-26884, 1-25206, 1-66203, 1-14246, 1-78067, 1-54324, 1-34237, 1-55224, 1-54563, 1-91248, 1-77048, 1-35115"
  },
  {
    "Cupons": "1-18048"
  },
  {
    "Cupons": "1-26231, 1-57284, 1-74064, 1-92551"
  },
  {
    "Cupons": "1-99085, 1-52906, 1-33958, 1-27402"
  },
  {
    "Cupons": "1-70799"
  },
  {
    "Cupons": "1-88130, 1-53340, 1-77261, 1-63803"
  },
  {
    "Cupons": "1-35069"
  },
  {
    "Cupons": "1-69092, 1-55355, 1-51641, 1-29066, 1-38476, 1-25143, 1-45345, 1-60457, 1-62795, 1-21607, 1-26826"
  },
  {
    "Cupons": "1-89977"
  },
  {
    "Cupons": "1-56126"
  },
  {
    "Cupons": "1-22858"
  },
  {
    "Cupons": "1-15463, 1-46274, 1-87692, 1-49561, 1-33138, 1-25955, 1-97867, 1-76783, 1-37928, 1-36553, 1-38038, 1-26207, 1-88064, 1-38066"
  },
  {
    "Cupons": "1-86086, 1-26472, 1-72123, 1-57562"
  },
  {
    "Cupons": "1-30646"
  },
  {
    "Cupons": "1-19021, 1-59782, 1-39664, 1-29670, 1-97808, 1-31375, 1-65687, 1-64650, 1-96546, 1-62499, 1-90631"
  },
  {
    "Cupons": "1-85163"
  },
  {
    "Cupons": "1-46896, 1-73153, 1-36057, 1-72221, 1-28934, 1-62939, 1-77592, 1-37866, 1-21852, 1-44247, 1-55423, 1-11314, 1-92486, 1-61916"
  },
  {
    "Cupons": "1-11899"
  },
  {
    "Cupons": "1-99222"
  },
  {
    "Cupons": "1-65137"
  },
  {
    "Cupons": "1-12431, 1-70921, 1-18283, 1-30133, 1-36932, 1-74848, 1-91982, 1-74022, 1-78699, 1-32497, 1-51642"
  },
  {
    "Cupons": "1-43755, 1-79856, 1-83471, 1-36272"
  },
  {
    "Cupons": "1-33769"
  },
  {
    "Cupons": "1-68324, 1-57947, 1-85286, 1-86729, 1-55368, 1-85045, 1-25947, 1-93215, 1-93477, 1-90742, 1-87072, 1-21021, 1-43369, 1-65656"
  },
  {
    "Cupons": "1-33384, 1-98858, 1-72648, 1-48190"
  },
  {
    "Cupons": "1-29199, 1-36927, 1-18112, 1-48373, 1-47934, 1-80607, 1-69026, 1-86182, 1-22652, 1-72278, 1-61815, 1-55072, 1-12650, 1-62324"
  },
  {
    "Cupons": "1-97099"
  },
  {
    "Cupons": "1-66926"
  },
  {
    "Cupons": "1-98642"
  },
  {
    "Cupons": "1-47121, 1-53440, 1-98072, 1-37225, 1-93116, 1-88456, 1-53401, 1-65029, 1-95242, 1-85193, 1-99663, 1-17544, 1-50019, 1-12925"
  },
  {
    "Cupons": "1-51527, 1-88430, 1-66991, 1-70772"
  },
  {
    "Cupons": "1-51017, 1-34627, 1-17533, 1-44564"
  },
  {
    "Cupons": "1-74375"
  },
  {
    "Cupons": "1-55215"
  },
  {
    "Cupons": "1-15051"
  },
  {
    "Cupons": "1-12483, 1-34778, 1-16136, 1-76921"
  },
  {
    "Cupons": "1-93552"
  },
  {
    "Cupons": "1-59375"
  },
  {
    "Cupons": "1-50481, 1-60889, 1-78531, 1-84761"
  },
  {
    "Cupons": "1-11791"
  },
  {
    "Cupons": "1-10578, 1-82439, 1-43223, 1-47717, 1-60566, 1-17259, 1-26265, 1-77699, 1-45588, 1-57771, 1-93603, 1-81890, 1-77942, 1-19454"
  },
  {
    "Cupons": "1-36737, 1-90309, 1-94192, 1-40658"
  },
  {
    "Cupons": "1-28355"
  },
  {
    "Cupons": "1-78396, 1-80400, 1-15614, 1-49749"
  },
  {
    "Cupons": "1-25353"
  },
  {
    "Cupons": "1-74734, 1-91969, 1-13460, 1-56790"
  },
  {
    "Cupons": "1-70019"
  },
  {
    "Cupons": "1-52350"
  },
  {
    "Cupons": "1-99225, 1-55653, 1-47235, 1-47056"
  },
  {
    "Cupons": "1-25841"
  },
  {
    "Cupons": "1-61551, 1-62863, 1-55861, 1-16227, 1-28698, 1-26021, 1-37279, 1-81102, 1-15470, 1-21894, 1-10407"
  },
  {
    "Cupons": "1-95109, 1-39091, 1-75184, 1-98230, 1-42350, 1-75769, 1-47672, 1-99454, 1-23459, 1-55775, 1-57212, 1-72596, 1-89263, 1-61370"
  },
  {
    "Cupons": "1-70169, 1-17623, 1-52649, 1-20077"
  },
  {
    "Cupons": "1-70331"
  },
  {
    "Cupons": "1-34482, 1-28702, 1-40439, 1-56540"
  },
  {
    "Cupons": "1-86384, 1-99096, 1-10132, 1-86647"
  },
  {
    "Cupons": "1-75676, 1-15078, 1-85722, 1-39331"
  },
  {
    "Cupons": "1-67118, 1-98762, 1-64299, 1-61618"
  },
  {
    "Cupons": "1-75087"
  },
  {
    "Cupons": "1-72631"
  },
  {
    "Cupons": "1-84380"
  },
  {
    "Cupons": "1-35306, 1-19244, 1-71054, 1-15018"
  },
  {
    "Cupons": "1-39480"
  },
  {
    "Cupons": "1-24671"
  },
  {
    "Cupons": "1-75303, 1-26090, 1-13587, 1-40016, 1-95718, 1-63701, 1-78415, 1-45892, 1-76931, 1-73119, 1-94980, 1-40580, 1-16479, 1-69778"
  },
  {
    "Cupons": "1-37420"
  },
  {
    "Cupons": "1-45745, 1-86397, 1-23443, 1-11650, 1-25096, 1-20345, 1-88257, 1-88093, 1-44626, 1-37328, 1-53070, 1-49842, 1-85116, 1-49410"
  },
  {
    "Cupons": "1-99734"
  },
  {
    "Cupons": "1-90817"
  },
  {
    "Cupons": "1-91199"
  },
  {
    "Cupons": "1-92834, 1-16012, 1-77786, 1-67219, 1-44209, 1-29449, 1-57450, 1-64446, 1-49509, 1-78142, 1-13902"
  },
  {
    "Cupons": "1-64588"
  },
  {
    "Cupons": "1-68802, 1-27025, 1-28311, 1-99425"
  },
  {
    "Cupons": "1-39781, 1-21600, 1-64220, 1-54483, 1-25533, 1-29233, 1-17134, 1-94190, 1-40666, 1-57749, 1-68673, 1-63576, 1-23502, 1-72276"
  },
  {
    "Cupons": "1-17695"
  },
  {
    "Cupons": "1-62264"
  },
  {
    "Cupons": "1-78011, 1-35477, 1-22341, 1-57144, 1-10207, 1-13569, 1-27609, 1-56308, 1-47525, 1-97785, 1-59844, 1-64860, 1-23860, 1-92684"
  },
  {
    "Cupons": "1-66417"
  },
  {
    "Cupons": "1-15424"
  },
  {
    "Cupons": "1-35602, 1-87806, 1-53812, 1-69880"
  },
  {
    "Cupons": "1-89551, 1-56601, 1-74669, 1-76857, 1-39973, 1-35949, 1-42030, 1-47951, 1-69632, 1-46814, 1-18197, 1-40732, 1-81626, 1-81744"
  },
  {
    "Cupons": "1-47267"
  },
  {
    "Cupons": "1-44266, 1-39246, 1-57323, 1-67198, 1-58937, 1-76270, 1-95755, 1-84520, 1-71383, 1-50275, 1-10479"
  },
  {
    "Cupons": "1-83172"
  },
  {
    "Cupons": "1-56317"
  },
  {
    "Cupons": "1-57981, 1-70885, 1-16858, 1-73458, 1-47169, 1-39264, 1-52134, 1-15240, 1-80267, 1-28208, 1-64696"
  },
  {
    "Cupons": "1-63385"
  },
  {
    "Cupons": "1-49570, 1-43257, 1-79905, 1-27466, 1-56584, 1-70043, 1-25409, 1-77677, 1-84220, 1-66626, 1-16981, 1-61104, 1-87377, 1-59596"
  },
  {
    "Cupons": "1-19375"
  },
  {
    "Cupons": "1-14443"
  },
  {
    "Cupons": "1-83507, 1-15024, 1-21327, 1-62050"
  },
  {
    "Cupons": "1-69312, 1-86814, 1-74681, 1-46308, 1-67730, 1-70393, 1-67223, 1-19823, 1-63187, 1-49275, 1-59366, 1-81001, 1-59153, 1-10075"
  },
  {
    "Cupons": "1-83532, 1-24685, 1-39291, 1-66742"
  },
  {
    "Cupons": "1-28824, 1-38923, 1-33378, 1-37669"
  },
  {
    "Cupons": "1-94501, 1-12377, 1-65631, 1-49580"
  },
  {
    "Cupons": "1-12255"
  },
  {
    "Cupons": "1-42253, 1-47749, 1-90769, 1-27492, 1-55810, 1-48152, 1-38571, 1-84794, 1-39123, 1-59829, 1-65032, 1-58883, 1-77871, 1-16818"
  },
  {
    "Cupons": "1-35576"
  },
  {
    "Cupons": "1-43559"
  },
  {
    "Cupons": "1-14589"
  },
  {
    "Cupons": "1-44748"
  },
  {
    "Cupons": "1-44088"
  },
  {
    "Cupons": "1-21216"
  },
  {
    "Cupons": "1-47310, 1-50032, 1-55269, 1-90763"
  },
  {
    "Cupons": "1-28337"
  },
  {
    "Cupons": "1-97422"
  },
  {
    "Cupons": "1-29273, 1-93394, 1-53888, 1-99083, 1-53889, 1-62721, 1-82902, 1-95799, 1-64323, 1-46379, 1-69256, 1-11023, 1-79926, 1-82388"
  },
  {
    "Cupons": "1-97338"
  },
  {
    "Cupons": "1-38940, 1-62559, 1-35809, 1-94273, 1-22861, 1-70709, 1-69157, 1-82958, 1-91472, 1-88396, 1-64285, 1-42590, 1-89229, 1-42070"
  },
  {
    "Cupons": "1-20413"
  },
  {
    "Cupons": "1-45366, 1-12033, 1-50443, 1-10769"
  },
  {
    "Cupons": "1-60460, 1-60014, 1-42244, 1-81936, 1-48969, 1-11429, 1-85590, 1-12103, 1-24632, 1-11906, 1-87128, 1-61095, 1-74546, 1-26511"
  },
  {
    "Cupons": "1-42156, 1-69659, 1-12525, 1-67326"
  },
  {
    "Cupons": "1-81665"
  },
  {
    "Cupons": "1-10130"
  },
  {
    "Cupons": "1-31294"
  },
  {
    "Cupons": "1-86943"
  },
  {
    "Cupons": "1-23946, 1-63937, 1-31928, 1-37411, 1-18034, 1-56871, 1-15696, 1-48939, 1-25244, 1-66334, 1-21781, 1-48576, 1-30481, 1-15488"
  },
  {
    "Cupons": "1-17560"
  },
  {
    "Cupons": "1-43967, 1-48735, 1-33771, 1-69803"
  },
  {
    "Cupons": "1-67069, 1-96787, 1-35673, 1-87362, 1-65346, 1-18359, 1-26271, 1-27635, 1-23783, 1-80812, 1-70544, 1-81636, 1-61043, 1-37482"
  },
  {
    "Cupons": "1-44968"
  },
  {
    "Cupons": "1-70378, 1-89155, 1-54808, 1-16213"
  },
  {
    "Cupons": "1-82138"
  },
  {
    "Cupons": "1-46266"
  },
  {
    "Cupons": "1-26045"
  },
  {
    "Cupons": "1-95744"
  },
  {
    "Cupons": "1-72312"
  },
  {
    "Cupons": "1-92211"
  },
  {
    "Cupons": "1-17021, 1-23608, 1-37239, 1-75445, 1-61207, 1-61460, 1-32882, 1-45481, 1-80372, 1-60974, 1-51831, 1-46418, 1-15368, 1-26204"
  },
  {
    "Cupons": "1-43470"
  },
  {
    "Cupons": "1-54680, 1-96085, 1-72998, 1-20546, 1-29965, 1-80822, 1-92028, 1-91907, 1-45864, 1-14615, 1-10144, 1-18518, 1-47411, 1-13301"
  },
  {
    "Cupons": "1-79558"
  },
  {
    "Cupons": "1-11399"
  },
  {
    "Cupons": "1-98514"
  },
  {
    "Cupons": "1-38249, 1-18945, 1-25918, 1-77427"
  },
  {
    "Cupons": "1-54214, 1-52818, 1-21449, 1-69432, 1-51999, 1-37171, 1-36383, 1-92982, 1-74900, 1-13022, 1-80758, 1-22947, 1-57858, 1-52869"
  },
  {
    "Cupons": "1-24744, 1-17406, 1-64072, 1-46837"
  },
  {
    "Cupons": "1-73299"
  },
  {
    "Cupons": "1-33950"
  },
  {
    "Cupons": "1-19965, 1-79853, 1-48145, 1-27902, 1-56142, 1-66137, 1-40491, 1-23322, 1-82505, 1-85693, 1-72035, 1-28054, 1-44359, 1-77066"
  },
  {
    "Cupons": "1-44291, 1-50151, 1-21336, 1-57062, 1-16125, 1-80643, 1-70003, 1-39523, 1-66499, 1-10791, 1-81782, 1-62574, 1-96112, 1-55393"
  },
  {
    "Cupons": "1-38900, 1-46757, 1-43702, 1-86163, 1-55293, 1-16310, 1-40637, 1-62017, 1-41392, 1-88971, 1-18265, 1-66414, 1-25455, 1-31048"
  },
  {
    "Cupons": "1-93594"
  },
  {
    "Cupons": "1-13461, 1-12861, 1-11122, 1-13640"
  },
  {
    "Cupons": "1-41160"
  },
  {
    "Cupons": "1-31442, 1-79483, 1-81640, 1-52292, 1-23697, 1-68503, 1-38216, 1-91037, 1-14599, 1-80306, 1-43789, 1-58455, 1-42247, 1-41295"
  },
  {
    "Cupons": "1-44495"
  },
  {
    "Cupons": "1-10363, 1-82655, 1-28799, 1-56472, 1-67646, 1-38521, 1-86135, 1-86653, 1-59555, 1-76627, 1-90236, 1-94937, 1-69475, 1-65657"
  },
  {
    "Cupons": "1-11184, 1-36096, 1-12100, 1-80195"
  },
  {
    "Cupons": "1-93838"
  },
  {
    "Cupons": "1-36134"
  },
  {
    "Cupons": "1-44253"
  },
  {
    "Cupons": "1-52900, 1-23147, 1-23209, 1-69840, 1-57457, 1-50771, 1-45611, 1-33665, 1-53406, 1-41537, 1-29996, 1-80218, 1-10664, 1-18244"
  },
  {
    "Cupons": "1-58534"
  },
  {
    "Cupons": "1-20662"
  },
  {
    "Cupons": "1-86141"
  },
  {
    "Cupons": "1-30007, 1-71062, 1-31820, 1-30573"
  },
  {
    "Cupons": "1-69168, 1-95979, 1-87046, 1-68978, 1-83352, 1-67662, 1-97357, 1-77454, 1-45867, 1-80289, 1-90556"
  },
  {
    "Cupons": "1-72772"
  },
  {
    "Cupons": "1-51274"
  },
  {
    "Cupons": "1-78778"
  },
  {
    "Cupons": "1-16953, 1-69946, 1-63178, 1-17368, 1-65480, 1-27573, 1-63502, 1-56213, 1-32681, 1-44709, 1-60074, 1-32963, 1-74627, 1-52400"
  },
  {
    "Cupons": "1-16515"
  },
  {
    "Cupons": "1-46908"
  },
  {
    "Cupons": "1-71248, 1-95746, 1-44875, 1-34498, 1-23144, 1-37629, 1-65151, 1-42576, 1-42347, 1-82978, 1-71135, 1-73007, 1-27847, 1-89261"
  },
  {
    "Cupons": "1-21129, 1-81034, 1-55105, 1-93999, 1-61945, 1-95773, 1-64353, 1-38124, 1-32920, 1-48827, 1-75127, 1-95077, 1-31400, 1-33961"
  },
  {
    "Cupons": "1-14486"
  },
  {
    "Cupons": "1-99082"
  },
  {
    "Cupons": "1-82216"
  },
  {
    "Cupons": "1-53042, 1-91060, 1-55814, 1-87660, 1-29773, 1-96823, 1-65441, 1-74234, 1-94263, 1-30204, 1-14226, 1-97419, 1-80871, 1-72275"
  },
  {
    "Cupons": "1-79021"
  },
  {
    "Cupons": "1-94924, 1-85725, 1-39450, 1-17647"
  },
  {
    "Cupons": "1-47999"
  },
  {
    "Cupons": "1-66775, 1-22143, 1-73849, 1-35904, 1-68562, 1-66601, 1-44725, 1-51136, 1-93949, 1-29042, 1-40587"
  },
  {
    "Cupons": "1-17894, 1-50662, 1-92647, 1-40752"
  },
  {
    "Cupons": "1-33738"
  },
  {
    "Cupons": "1-25476"
  },
  {
    "Cupons": "1-76765"
  },
  {
    "Cupons": "1-12867"
  },
  {
    "Cupons": "1-39815"
  },
  {
    "Cupons": "1-30437"
  },
  {
    "Cupons": "1-74168"
  },
  {
    "Cupons": "1-68465"
  },
  {
    "Cupons": "1-95575, 1-93747, 1-53085, 1-94520, 1-19270, 1-52799, 1-41949, 1-96338, 1-49780, 1-62505, 1-87011, 1-46223, 1-20787, 1-16959"
  },
  {
    "Cupons": "1-46237"
  },
  {
    "Cupons": "1-70965"
  },
  {
    "Cupons": "1-48894"
  },
  {
    "Cupons": "1-21413"
  },
  {
    "Cupons": "1-95218, 1-19516, 1-38432, 1-18321"
  },
  {
    "Cupons": "1-67566"
  },
  {
    "Cupons": "1-26668, 1-18609, 1-77671, 1-33506"
  },
  {
    "Cupons": "1-28192"
  },
  {
    "Cupons": "1-47932, 1-37441, 1-69487, 1-22835"
  },
  {
    "Cupons": "1-27852, 1-97294, 1-88133, 1-87493"
  },
  {
    "Cupons": "1-51951"
  },
  {
    "Cupons": "1-40780"
  },
  {
    "Cupons": "1-87893"
  },
  {
    "Cupons": "1-33230"
  },
  {
    "Cupons": "1-43740"
  },
  {
    "Cupons": "1-73824, 1-92538, 1-42527, 1-90062"
  },
  {
    "Cupons": "1-85436, 1-34005, 1-29030, 1-71267, 1-99870, 1-12116, 1-16773, 1-29745, 1-82187, 1-70108, 1-70185, 1-36988, 1-53427, 1-96913"
  },
  {
    "Cupons": "1-20477, 1-52811, 1-39510, 1-62637, 1-15381, 1-60321, 1-24971, 1-90869, 1-62563, 1-28025, 1-31656, 1-23762, 1-40120, 1-95252"
  },
  {
    "Cupons": "1-91825, 1-76369, 1-24860, 1-39511, 1-26106, 1-98252, 1-45031, 1-99564, 1-55200, 1-60520, 1-37945, 1-65284, 1-85517, 1-29983"
  },
  {
    "Cupons": "1-14020, 1-21961, 1-85798, 1-12038, 1-89287, 1-18189, 1-36253, 1-42705, 1-69152, 1-71183, 1-89110, 1-52752, 1-60264, 1-22903"
  },
  {
    "Cupons": "1-96723, 1-34115, 1-12979, 1-34646"
  },
  {
    "Cupons": "1-24165"
  },
  {
    "Cupons": "1-88057"
  },
  {
    "Cupons": "1-14049, 1-86380, 1-94476, 1-47968, 1-28296, 1-73233, 1-10776, 1-63922, 1-10098, 1-59999, 1-48472, 1-21401, 1-86463, 1-88351"
  },
  {
    "Cupons": "1-83393"
  },
  {
    "Cupons": "1-71451, 1-10300, 1-86213, 1-39858"
  },
  {
    "Cupons": "1-52878"
  },
  {
    "Cupons": "1-58941, 1-90743, 1-78490, 1-99922"
  },
  {
    "Cupons": "1-64855"
  },
  {
    "Cupons": "1-78815"
  },
  {
    "Cupons": "1-15501, 1-76377, 1-75732, 1-23802"
  },
  {
    "Cupons": "1-33588"
  },
  {
    "Cupons": "1-17226"
  },
  {
    "Cupons": "1-23399, 1-31137, 1-69437, 1-52259"
  },
  {
    "Cupons": "1-15286"
  },
  {
    "Cupons": "1-52328"
  },
  {
    "Cupons": "1-17377, 1-12626, 1-46336, 1-29713"
  },
  {
    "Cupons": "1-77792"
  },
  {
    "Cupons": "1-71288, 1-80647, 1-82338, 1-40138, 1-53386, 1-75206, 1-42398, 1-61572, 1-96376, 1-35112, 1-22801, 1-61162, 1-55242, 1-40688"
  },
  {
    "Cupons": "1-30181, 1-82297, 1-48789, 1-24343"
  },
  {
    "Cupons": "1-50258"
  },
  {
    "Cupons": "1-63624"
  },
  {
    "Cupons": "1-72017"
  },
  {
    "Cupons": "1-68762, 1-97656, 1-70689, 1-79612, 1-74068, 1-76891, 1-53464, 1-76142, 1-29259, 1-83185, 1-99212, 1-52143, 1-59260, 1-81703"
  },
  {
    "Cupons": "1-14653, 1-44447, 1-21806, 1-17357, 1-81392, 1-28694, 1-81044, 1-38459, 1-86183, 1-38455, 1-54941, 1-55435, 1-78515, 1-29478"
  },
  {
    "Cupons": "1-21704"
  },
  {
    "Cupons": "1-34022, 1-27764, 1-36942, 1-21635"
  },
  {
    "Cupons": "1-43908"
  },
  {
    "Cupons": "1-46424, 1-67112, 1-97128, 1-13532"
  },
  {
    "Cupons": "1-68358"
  },
  {
    "Cupons": "1-63679, 1-33118, 1-47156, 1-24582"
  },
  {
    "Cupons": "1-48475, 1-53188, 1-58380, 1-25343, 1-91386, 1-22166, 1-31306, 1-55308, 1-15430, 1-46637, 1-17172, 1-70512, 1-28477, 1-30950"
  },
  {
    "Cupons": "1-43200, 1-86735, 1-10036, 1-82681, 1-15299, 1-63649, 1-96111, 1-23302, 1-10510, 1-83783, 1-42421"
  },
  {
    "Cupons": "1-56241, 1-62838, 1-80833, 1-72591"
  },
  {
    "Cupons": "1-48675"
  },
  {
    "Cupons": "1-24244, 1-96307, 1-89519, 1-16543"
  },
  {
    "Cupons": "1-83400"
  },
  {
    "Cupons": "1-67283"
  },
  {
    "Cupons": "1-57739, 1-53093, 1-32091, 1-58113"
  },
  {
    "Cupons": "1-11421"
  },
  {
    "Cupons": "1-17555, 1-21896, 1-33922, 1-30802, 1-93704, 1-55401, 1-88576, 1-18190, 1-34399, 1-23350, 1-44363, 1-23284, 1-37237, 1-40851"
  },
  {
    "Cupons": "1-89326, 1-39911, 1-68207, 1-39717"
  },
  {
    "Cupons": "1-60619, 1-26198, 1-22960, 1-88732, 1-66042, 1-29638, 1-67290, 1-45369, 1-70279, 1-78462, 1-18754, 1-84052, 1-71845, 1-29452"
  },
  {
    "Cupons": "1-52789"
  },
  {
    "Cupons": "1-91461"
  },
  {
    "Cupons": "1-75408, 1-79444, 1-90557, 1-23510"
  },
  {
    "Cupons": "1-61252"
  },
  {
    "Cupons": "1-77747, 1-34877, 1-75834, 1-50403"
  },
  {
    "Cupons": "1-29387, 1-83638, 1-14956, 1-47593, 1-91228, 1-40649, 1-59381, 1-71096, 1-37976, 1-26946, 1-45984"
  },
  {
    "Cupons": "1-88735, 1-71808, 1-78193, 1-57472, 1-72706, 1-64985, 1-67222, 1-59917, 1-66688, 1-48428, 1-42148, 1-91256, 1-64760, 1-47958"
  },
  {
    "Cupons": "1-33373"
  },
  {
    "Cupons": "1-37348"
  },
  {
    "Cupons": "1-24657"
  },
  {
    "Cupons": "1-57218, 1-81514, 1-31552, 1-69755"
  },
  {
    "Cupons": "1-46594, 1-13567, 1-36063, 1-17493, 1-12968, 1-58483, 1-38552, 1-95431, 1-47917, 1-48909, 1-35982, 1-79846, 1-84432, 1-68448"
  },
  {
    "Cupons": "1-45559"
  },
  {
    "Cupons": "1-83736, 1-86674, 1-15420, 1-71052"
  },
  {
    "Cupons": "1-11692, 1-41304, 1-75233, 1-39621, 1-36304, 1-69561, 1-27596, 1-43419, 1-80913, 1-45780, 1-46716, 1-41973, 1-32231, 1-77958"
  },
  {
    "Cupons": "1-16748"
  },
  {
    "Cupons": "1-29346"
  },
  {
    "Cupons": "1-80839, 1-92399, 1-99000, 1-98071, 1-44712, 1-98550, 1-26886, 1-48892, 1-92245, 1-68769, 1-62006, 1-10217, 1-83667, 1-41601"
  },
  {
    "Cupons": "1-46065"
  },
  {
    "Cupons": "1-66126, 1-78208, 1-72728, 1-53960"
  },
  {
    "Cupons": "1-43153, 1-58120, 1-25776, 1-10390"
  },
  {
    "Cupons": "1-21120, 1-42129, 1-42407, 1-56497, 1-67567, 1-24817, 1-34475, 1-97400, 1-47352, 1-92382, 1-96030, 1-64189, 1-79476, 1-40540"
  },
  {
    "Cupons": "1-85033"
  },
  {
    "Cupons": "1-35674"
  },
  {
    "Cupons": "1-29575, 1-56741, 1-58245, 1-67816, 1-11625, 1-49217, 1-10184, 1-65550, 1-67420, 1-60225, 1-57483, 1-91712, 1-10671, 1-91941"
  },
  {
    "Cupons": "1-64834, 1-85579, 1-52879, 1-18279"
  },
  {
    "Cupons": "1-64763, 1-55198, 1-33246, 1-76831"
  },
  {
    "Cupons": "1-22479"
  },
  {
    "Cupons": "1-48250, 1-66722, 1-18975, 1-23051, 1-55946, 1-25390, 1-56445, 1-82709, 1-40472, 1-52076, 1-43380, 1-13748, 1-87496, 1-66192"
  },
  {
    "Cupons": "1-25269"
  },
  {
    "Cupons": "1-37015, 1-73178, 1-24433, 1-10951"
  },
  {
    "Cupons": "1-46972, 1-87919, 1-60643, 1-55367"
  },
  {
    "Cupons": "1-95269"
  },
  {
    "Cupons": "1-18175, 1-90834, 1-52251, 1-29632"
  },
  {
    "Cupons": "1-26626, 1-18218, 1-16294, 1-78685, 1-55063, 1-14939, 1-42463, 1-41767, 1-72113, 1-72544, 1-21914, 1-24658, 1-53122, 1-63635"
  },
  {
    "Cupons": "1-33436"
  },
  {
    "Cupons": "1-73223"
  },
  {
    "Cupons": "1-32890, 1-28672, 1-26686, 1-37415"
  },
  {
    "Cupons": "1-16668, 1-34950, 1-92969, 1-58376, 1-24429, 1-62471, 1-99737, 1-79054, 1-28689, 1-20631, 1-12357, 1-94768, 1-27105, 1-28090"
  },
  {
    "Cupons": "1-74008, 1-72440, 1-54522, 1-63363, 1-24269, 1-20790, 1-66314, 1-54686, 1-30361, 1-63448, 1-66057, 1-26973, 1-91221, 1-66092"
  },
  {
    "Cupons": "1-96187, 1-70985, 1-44826, 1-70919, 1-59868, 1-50728, 1-61097, 1-53141, 1-82463, 1-59819, 1-17183, 1-89462, 1-49948, 1-14117"
  },
  {
    "Cupons": "1-83109, 1-26382, 1-43285, 1-23336, 1-74379, 1-33578, 1-79693, 1-71847, 1-54805, 1-22887, 1-13196, 1-30753, 1-97395, 1-31580"
  },
  {
    "Cupons": "1-33736, 1-91502, 1-35526, 1-50636, 1-38036, 1-53504, 1-56379, 1-60527, 1-74583, 1-86168, 1-25929, 1-76725, 1-33573, 1-60922"
  },
  {
    "Cupons": "1-18383"
  },
  {
    "Cupons": "1-95003, 1-86130, 1-52736, 1-15724"
  },
  {
    "Cupons": "1-22273, 1-43425, 1-31455, 1-96861"
  },
  {
    "Cupons": "1-42978"
  },
  {
    "Cupons": "1-40933, 1-39672, 1-94426, 1-42124, 1-73918, 1-53854, 1-55802, 1-54958, 1-80441, 1-56323, 1-14295, 1-19590, 1-62614, 1-62659"
  },
  {
    "Cupons": "1-13548"
  },
  {
    "Cupons": "1-47756"
  },
  {
    "Cupons": "1-80704"
  },
  {
    "Cupons": "1-21721, 1-82202, 1-11784, 1-50539"
  },
  {
    "Cupons": "1-37822, 1-17308, 1-24547, 1-10449"
  },
  {
    "Cupons": "1-97025, 1-54503, 1-57901, 1-66978, 1-95293, 1-94927, 1-35821, 1-12141, 1-42919, 1-41064, 1-42382"
  },
  {
    "Cupons": "1-89318"
  },
  {
    "Cupons": "1-92751"
  },
  {
    "Cupons": "1-82204"
  },
  {
    "Cupons": "1-24769"
  },
  {
    "Cupons": "1-69875"
  },
  {
    "Cupons": "1-68049, 1-14327, 1-20556, 1-43479, 1-62312, 1-46463, 1-36049, 1-59233, 1-58999, 1-38659, 1-54001, 1-70171, 1-74182, 1-63129"
  },
  {
    "Cupons": "1-59355, 1-60894, 1-96230, 1-76563, 1-32900, 1-58293, 1-68818, 1-29477, 1-43889, 1-42300, 1-74865"
  },
  {
    "Cupons": "1-30130"
  },
  {
    "Cupons": "1-65524, 1-24797, 1-23507, 1-23989, 1-58594, 1-31841, 1-83189, 1-22069, 1-17538, 1-62814, 1-41246"
  },
  {
    "Cupons": "1-13564"
  },
  {
    "Cupons": "1-94453"
  },
  {
    "Cupons": "1-54362"
  },
  {
    "Cupons": "1-37373, 1-70696, 1-72211, 1-46121, 1-91682, 1-29901, 1-53026, 1-70557, 1-12261, 1-47667, 1-31197"
  },
  {
    "Cupons": "1-48173"
  },
  {
    "Cupons": "1-41980, 1-72793, 1-23120, 1-18157, 1-40461, 1-99179, 1-13484, 1-18779, 1-67076, 1-73774, 1-59766, 1-38597, 1-46504, 1-20714"
  },
  {
    "Cupons": "1-80976"
  },
  {
    "Cupons": "1-92425"
  },
  {
    "Cupons": "1-58459, 1-65238, 1-59801, 1-99494, 1-79677, 1-32695, 1-13947, 1-86669, 1-66922, 1-40292, 1-64717, 1-38065, 1-66937, 1-91552"
  },
  {
    "Cupons": "1-90507, 1-31570, 1-30055, 1-91087"
  },
  {
    "Cupons": "1-47835"
  },
  {
    "Cupons": "1-18731"
  },
  {
    "Cupons": "1-56978"
  },
  {
    "Cupons": "1-76285"
  },
  {
    "Cupons": "1-83969"
  },
  {
    "Cupons": "1-49955"
  },
  {
    "Cupons": "1-32715, 1-16043, 1-20813, 1-26882"
  },
  {
    "Cupons": "1-74314, 1-41933, 1-35760, 1-22662, 1-20331, 1-42146, 1-12751, 1-50148, 1-44388, 1-67201, 1-56705, 1-60710, 1-75423, 1-74708"
  },
  {
    "Cupons": "1-38068"
  },
  {
    "Cupons": "1-57033, 1-31399, 1-72303, 1-23574"
  },
  {
    "Cupons": "1-12398"
  },
  {
    "Cupons": "1-45986"
  },
  {
    "Cupons": "1-45951"
  },
  {
    "Cupons": "1-41387, 1-99931, 1-39912, 1-82682, 1-67611, 1-25243, 1-96339, 1-23810, 1-54660, 1-79993, 1-35703"
  },
  {
    "Cupons": "1-34583"
  },
  {
    "Cupons": "1-95724, 1-99352, 1-85620, 1-94973, 1-61620, 1-56123, 1-21857, 1-75429, 1-60663, 1-15367, 1-10180, 1-29925, 1-23220, 1-37454"
  },
  {
    "Cupons": "1-23854"
  },
  {
    "Cupons": "1-48618"
  },
  {
    "Cupons": "1-22574, 1-34086, 1-96033, 1-18641"
  },
  {
    "Cupons": "1-22005"
  },
  {
    "Cupons": "1-95073"
  },
  {
    "Cupons": "1-23926"
  },
  {
    "Cupons": "1-16950"
  },
  {
    "Cupons": "1-13245, 1-67281, 1-90937, 1-57834, 1-13215, 1-71321, 1-36353, 1-63165, 1-36949, 1-25279, 1-59257"
  },
  {
    "Cupons": "1-73689"
  },
  {
    "Cupons": "1-26545"
  },
  {
    "Cupons": "1-56272"
  },
  {
    "Cupons": "1-19000"
  },
  {
    "Cupons": "1-75032"
  },
  {
    "Cupons": "1-39585, 1-98360, 1-54983, 1-80188, 1-14011, 1-57225, 1-47898, 1-77935, 1-35470, 1-78356, 1-98126"
  },
  {
    "Cupons": "1-90850, 1-11334, 1-55131, 1-36802, 1-66083, 1-76346, 1-39040, 1-97890, 1-28713, 1-13243, 1-59068, 1-45602, 1-67546, 1-95155"
  },
  {
    "Cupons": "1-18237"
  },
  {
    "Cupons": "1-43874"
  },
  {
    "Cupons": "1-67849"
  },
  {
    "Cupons": "1-37666, 1-11117, 1-20924, 1-13633, 1-38679, 1-14186, 1-97530, 1-75503, 1-48247, 1-85291, 1-23724, 1-82373, 1-77875, 1-19647"
  },
  {
    "Cupons": "1-83241, 1-32442, 1-79989, 1-75318, 1-48620, 1-57785, 1-79787, 1-67615, 1-32104, 1-66818, 1-90663, 1-79472, 1-75917, 1-90647"
  },
  {
    "Cupons": "1-62881, 1-23231, 1-36046, 1-21595, 1-76849, 1-60546, 1-67375, 1-32150, 1-49018, 1-59120, 1-74167, 1-92637, 1-92318, 1-28622"
  },
  {
    "Cupons": "1-71127"
  },
  {
    "Cupons": "1-79923"
  },
  {
    "Cupons": "1-71040, 1-33538, 1-13038, 1-23266, 1-78797, 1-49905, 1-37634, 1-29429, 1-62584, 1-29228, 1-39641, 1-34317, 1-47759, 1-73061"
  },
  {
    "Cupons": "1-54939, 1-94117, 1-61929, 1-63563"
  },
  {
    "Cupons": "1-46598"
  },
  {
    "Cupons": "1-19469"
  },
  {
    "Cupons": "1-63101, 1-56917, 1-36573, 1-84001"
  },
  {
    "Cupons": "1-66168"
  },
  {
    "Cupons": "1-13678"
  },
  {
    "Cupons": "1-72436"
  },
  {
    "Cupons": "1-54689"
  },
  {
    "Cupons": "1-13202"
  },
  {
    "Cupons": "1-54246"
  },
  {
    "Cupons": "1-52421"
  },
  {
    "Cupons": "1-25430, 1-61828, 1-69783, 1-77005, 1-90149, 1-63370, 1-46967, 1-53281, 1-23558, 1-17937, 1-19391, 1-67116, 1-57132, 1-82687"
  },
  {
    "Cupons": "1-26824"
  },
  {
    "Cupons": "1-28245"
  },
  {
    "Cupons": "1-84722"
  },
  {
    "Cupons": "1-68467, 1-27297, 1-21383, 1-28536"
  },
  {
    "Cupons": "1-94244"
  },
  {
    "Cupons": "1-15900, 1-69985, 1-93294, 1-41284"
  },
  {
    "Cupons": "1-41448, 1-86762, 1-75729, 1-33219, 1-13523, 1-83750, 1-65383, 1-22994, 1-43928, 1-91245, 1-16870, 1-31705, 1-18952, 1-96080"
  },
  {
    "Cupons": "1-28097"
  },
  {
    "Cupons": "1-62193"
  },
  {
    "Cupons": "1-70274, 1-75526, 1-43105, 1-75470, 1-28490, 1-29746, 1-78854, 1-64343, 1-39408, 1-35892, 1-76846, 1-47631, 1-46026, 1-31900"
  },
  {
    "Cupons": "1-79080"
  },
  {
    "Cupons": "1-71453, 1-57605, 1-40419, 1-81156"
  },
  {
    "Cupons": "1-22251"
  },
  {
    "Cupons": "1-78997, 1-48339, 1-91402, 1-71814"
  },
  {
    "Cupons": "1-23435"
  },
  {
    "Cupons": "1-80556, 1-22222, 1-20775, 1-66761"
  },
  {
    "Cupons": "1-30903"
  },
  {
    "Cupons": "1-58431"
  },
  {
    "Cupons": "1-27051"
  },
  {
    "Cupons": "1-21368, 1-10196, 1-84352, 1-64540, 1-38692, 1-18340, 1-91426, 1-85859, 1-75660, 1-59499, 1-12214, 1-72569, 1-56679, 1-43866"
  },
  {
    "Cupons": "1-33065"
  },
  {
    "Cupons": "1-97301, 1-32016, 1-59044, 1-21060, 1-53068, 1-71735, 1-44914, 1-60088, 1-98159, 1-12995, 1-92629, 1-19634, 1-95133, 1-36777"
  },
  {
    "Cupons": "1-56211"
  },
  {
    "Cupons": "1-47356, 1-56046, 1-18674, 1-14935, 1-81891, 1-86218, 1-53526, 1-28289, 1-70431, 1-65394, 1-84398, 1-27973, 1-72523, 1-60998"
  },
  {
    "Cupons": "1-69374"
  },
  {
    "Cupons": "1-61979"
  },
  {
    "Cupons": "1-49492"
  },
  {
    "Cupons": "1-66687"
  },
  {
    "Cupons": "1-73108"
  },
  {
    "Cupons": "1-73017"
  },
  {
    "Cupons": "1-81642, 1-92293, 1-61051, 1-74356"
  },
  {
    "Cupons": "1-47199"
  },
  {
    "Cupons": "1-62502"
  },
  {
    "Cupons": "1-52257"
  },
  {
    "Cupons": "1-18980, 1-95625, 1-74218, 1-48427"
  },
  {
    "Cupons": "1-19152, 1-94905, 1-74491, 1-91023"
  },
  {
    "Cupons": "1-47493, 1-38946, 1-18762, 1-58418"
  },
  {
    "Cupons": "1-97217"
  },
  {
    "Cupons": "1-35145"
  },
  {
    "Cupons": "1-76290"
  },
  {
    "Cupons": "1-70222, 1-75541, 1-63445, 1-35887"
  },
  {
    "Cupons": "1-32254"
  },
  {
    "Cupons": "1-93614"
  },
  {
    "Cupons": "1-90915"
  },
  {
    "Cupons": "1-91439"
  },
  {
    "Cupons": "1-60919, 1-63784, 1-42263, 1-59374"
  },
  {
    "Cupons": "1-21504, 1-11979, 1-22491, 1-35810"
  },
  {
    "Cupons": "1-17893, 1-47194, 1-52449, 1-98206, 1-50672, 1-66835, 1-95991, 1-88027, 1-25237, 1-19722, 1-62763, 1-60943, 1-48064, 1-13590"
  },
  {
    "Cupons": "1-44951, 1-47595, 1-38685, 1-21265"
  },
  {
    "Cupons": "1-83200, 1-92172, 1-81284, 1-12109, 1-89278, 1-99508, 1-51667, 1-94436, 1-43878, 1-42085, 1-30260, 1-83120, 1-98198, 1-77321"
  },
  {
    "Cupons": "1-40290"
  },
  {
    "Cupons": "1-13030"
  },
  {
    "Cupons": "1-66217"
  },
  {
    "Cupons": "1-49655"
  },
  {
    "Cupons": "1-23518, 1-71637, 1-11490, 1-48366, 1-20131, 1-62427, 1-58111, 1-33216, 1-26976, 1-77511, 1-80603, 1-30164, 1-16083, 1-19987"
  },
  {
    "Cupons": "1-38771"
  },
  {
    "Cupons": "1-61061"
  },
  {
    "Cupons": "1-48644, 1-40114, 1-71013, 1-45304"
  },
  {
    "Cupons": "1-86873, 1-10064, 1-55145, 1-47265"
  },
  {
    "Cupons": "1-47138, 1-61063, 1-77522, 1-81151"
  },
  {
    "Cupons": "1-11298, 1-60498, 1-53211, 1-65711"
  },
  {
    "Cupons": "1-93185, 1-28596, 1-93171, 1-85752"
  },
  {
    "Cupons": "1-11568, 1-66406, 1-55431, 1-90624, 1-20056, 1-56034, 1-35474, 1-22831, 1-52232, 1-21512, 1-58705, 1-33917, 1-33718, 1-73375"
  },
  {
    "Cupons": "1-41982"
  },
  {
    "Cupons": "1-91156, 1-17347, 1-44520, 1-64654"
  },
  {
    "Cupons": "1-18768, 1-57725, 1-24475, 1-27127"
  },
  {
    "Cupons": "1-42639"
  },
  {
    "Cupons": "1-57101"
  },
  {
    "Cupons": "1-54772"
  },
  {
    "Cupons": "1-53580"
  },
  {
    "Cupons": "1-53664"
  },
  {
    "Cupons": "1-32834"
  },
  {
    "Cupons": "1-80614, 1-92237, 1-31408, 1-76898"
  },
  {
    "Cupons": "1-27530, 1-62561, 1-58728, 1-77392"
  },
  {
    "Cupons": "1-14986, 1-50180, 1-94506, 1-14530, 1-55687, 1-59489, 1-31975, 1-87995, 1-58458, 1-95437, 1-80695, 1-68132, 1-71818, 1-15611"
  },
  {
    "Cupons": "1-85037"
  },
  {
    "Cupons": "1-29403"
  },
  {
    "Cupons": "1-92549, 1-68241, 1-57519, 1-28368"
  },
  {
    "Cupons": "1-99259"
  },
  {
    "Cupons": "1-94209"
  },
  {
    "Cupons": "1-74311"
  },
  {
    "Cupons": "1-69083, 1-29153, 1-12549, 1-18046"
  },
  {
    "Cupons": "1-37794, 1-74040, 1-42128, 1-71999"
  },
  {
    "Cupons": "1-37833"
  },
  {
    "Cupons": "1-42503"
  },
  {
    "Cupons": "1-52684, 1-77291, 1-60362, 1-27227, 1-22971, 1-98985, 1-35128, 1-23511, 1-32820, 1-88975, 1-92109"
  },
  {
    "Cupons": "1-90586, 1-51186, 1-80540, 1-46483"
  },
  {
    "Cupons": "1-26071"
  },
  {
    "Cupons": "1-87873"
  },
  {
    "Cupons": "1-18512"
  },
  {
    "Cupons": "1-70237, 1-71850, 1-87575, 1-25263"
  },
  {
    "Cupons": "1-81889, 1-95687, 1-65006, 1-66209"
  },
  {
    "Cupons": "1-58552, 1-99866, 1-49619, 1-90715"
  },
  {
    "Cupons": "1-31135, 1-74175, 1-83735, 1-89909, 1-27151, 1-55095, 1-42730, 1-31321, 1-54551, 1-24305, 1-27992, 1-62040, 1-44383, 1-50197"
  },
  {
    "Cupons": "1-86194, 1-95658, 1-44797, 1-10924"
  },
  {
    "Cupons": "1-61902"
  },
  {
    "Cupons": "1-70916"
  },
  {
    "Cupons": "1-13494"
  },
  {
    "Cupons": "1-20850, 1-45187, 1-66890, 1-14202, 1-54298, 1-99173, 1-61761, 1-93345, 1-29473, 1-68701, 1-33955, 1-90468, 1-16040, 1-52608"
  },
  {
    "Cupons": "1-87016"
  },
  {
    "Cupons": "1-43033"
  },
  {
    "Cupons": "1-82436"
  },
  {
    "Cupons": "1-87406"
  },
  {
    "Cupons": "1-46798"
  },
  {
    "Cupons": "1-26638"
  },
  {
    "Cupons": "1-85438"
  },
  {
    "Cupons": "1-79034, 1-99517, 1-50718, 1-79704, 1-93338, 1-74137, 1-73598, 1-85203, 1-45399, 1-14917, 1-35187, 1-74217, 1-95796, 1-19977"
  },
  {
    "Cupons": "1-12800"
  },
  {
    "Cupons": "1-56514"
  },
  {
    "Cupons": "1-59261, 1-87359, 1-14095, 1-98437, 1-42155, 1-81748, 1-53080, 1-93458, 1-99744, 1-17219, 1-86748"
  },
  {
    "Cupons": "1-13550, 1-17854, 1-26256, 1-58466, 1-84133, 1-53307, 1-82142, 1-54138, 1-89714, 1-20496, 1-35285, 1-83600, 1-84580, 1-49465"
  },
  {
    "Cupons": "1-17957"
  },
  {
    "Cupons": "1-86321"
  },
  {
    "Cupons": "1-51701"
  },
  {
    "Cupons": "1-25412"
  },
  {
    "Cupons": "1-76140"
  },
  {
    "Cupons": "1-51059, 1-55882, 1-44512, 1-60624, 1-98502, 1-96027, 1-67718, 1-71628, 1-35042, 1-78894, 1-14103, 1-25978, 1-88442, 1-14853"
  },
  {
    "Cupons": "1-13881"
  },
  {
    "Cupons": "1-10273, 1-40452, 1-13733, 1-32578"
  },
  {
    "Cupons": "1-57863"
  },
  {
    "Cupons": "1-90275"
  },
  {
    "Cupons": "1-17083"
  },
  {
    "Cupons": "1-55751"
  },
  {
    "Cupons": "1-61562"
  },
  {
    "Cupons": "1-60924"
  },
  {
    "Cupons": "1-17270, 1-76158, 1-76536, 1-54841, 1-92313, 1-41416, 1-59974, 1-39690, 1-23799, 1-26987, 1-21495, 1-40815, 1-95508, 1-40086"
  },
  {
    "Cupons": "1-37578, 1-56218, 1-29015, 1-42575"
  },
  {
    "Cupons": "1-97794, 1-93951, 1-52683, 1-76770, 1-72796, 1-13591, 1-67865, 1-86706, 1-73578, 1-30263, 1-87024, 1-51904, 1-44478, 1-44966"
  },
  {
    "Cupons": "1-91936, 1-47623, 1-42761, 1-27085, 1-94875, 1-73352, 1-23468, 1-65627, 1-86541, 1-71717, 1-35689, 1-57522, 1-71633, 1-20416"
  },
  {
    "Cupons": "1-32538"
  },
  {
    "Cupons": "1-55419"
  },
  {
    "Cupons": "1-27608"
  },
  {
    "Cupons": "1-59480, 1-71910, 1-73490, 1-28390, 1-96363, 1-92436, 1-66623, 1-61341, 1-79546, 1-89594, 1-79550, 1-18097, 1-27136, 1-33749"
  },
  {
    "Cupons": "1-12832, 1-64385, 1-28573, 1-79003"
  },
  {
    "Cupons": "1-83229"
  },
  {
    "Cupons": "1-68768"
  },
  {
    "Cupons": "1-39126"
  },
  {
    "Cupons": "1-50733"
  },
  {
    "Cupons": "1-15475"
  },
  {
    "Cupons": "1-32814"
  },
  {
    "Cupons": "1-98128"
  },
  {
    "Cupons": "1-70206"
  },
  {
    "Cupons": "1-50870, 1-63849, 1-82961, 1-63198"
  },
  {
    "Cupons": "1-12947"
  },
  {
    "Cupons": "1-64246, 1-75905, 1-49901, 1-91349"
  },
  {
    "Cupons": "1-42950"
  },
  {
    "Cupons": "1-47796, 1-27344, 1-57814, 1-66294, 1-52022, 1-87085, 1-48903, 1-75292, 1-24526, 1-91500, 1-17191"
  },
  {
    "Cupons": "1-11872"
  },
  {
    "Cupons": "1-77524"
  },
  {
    "Cupons": "1-55537"
  },
  {
    "Cupons": "1-91274, 1-54415, 1-22437, 1-63504"
  },
  {
    "Cupons": "1-46195"
  },
  {
    "Cupons": "1-29491"
  },
  {
    "Cupons": "1-37687, 1-88313, 1-95665, 1-72508"
  },
  {
    "Cupons": "1-15691"
  },
  {
    "Cupons": "1-99071"
  },
  {
    "Cupons": "1-97945"
  },
  {
    "Cupons": "1-35299, 1-88529, 1-25249, 1-69642"
  },
  {
    "Cupons": "1-51145"
  },
  {
    "Cupons": "1-30862, 1-71136, 1-10492, 1-84195"
  },
  {
    "Cupons": "1-80379, 1-45646, 1-82824, 1-48516"
  },
  {
    "Cupons": "1-27750"
  },
  {
    "Cupons": "1-37262"
  },
  {
    "Cupons": "1-20557"
  },
  {
    "Cupons": "1-69054"
  },
  {
    "Cupons": "1-73765"
  },
  {
    "Cupons": "1-19884"
  },
  {
    "Cupons": "1-38756, 1-74588, 1-29627, 1-17542, 1-67949, 1-76867, 1-65693, 1-21236, 1-28976, 1-74173, 1-39832"
  },
  {
    "Cupons": "1-81219"
  },
  {
    "Cupons": "1-33233, 1-77604, 1-40654, 1-95413, 1-45072, 1-73861, 1-67589, 1-79839, 1-42015, 1-84258, 1-45351, 1-37536, 1-69447, 1-13520"
  },
  {
    "Cupons": "1-40886, 1-70482, 1-62244, 1-33397, 1-51209, 1-81028, 1-84190, 1-47122, 1-72387, 1-34598, 1-72083, 1-15127, 1-58283, 1-39240"
  },
  {
    "Cupons": "1-11527, 1-53331, 1-26219, 1-97680"
  },
  {
    "Cupons": "1-35616"
  },
  {
    "Cupons": "1-41105"
  },
  {
    "Cupons": "1-25812, 1-64898, 1-14396, 1-81224"
  },
  {
    "Cupons": "1-29069"
  },
  {
    "Cupons": "1-43498, 1-21007, 1-19303, 1-75735"
  },
  {
    "Cupons": "1-54869, 1-75396, 1-64849, 1-20887, 1-83708, 1-47955, 1-17697, 1-78659, 1-34389, 1-32199, 1-91822, 1-78458, 1-91663, 1-58791"
  },
  {
    "Cupons": "1-98399, 1-24610, 1-11348, 1-18981, 1-38485, 1-95071, 1-28720, 1-79811, 1-64126, 1-57518, 1-98535"
  },
  {
    "Cupons": "1-33423, 1-21484, 1-61359, 1-85357"
  },
  {
    "Cupons": "1-50336"
  },
  {
    "Cupons": "1-89709, 1-99295, 1-33178, 1-40068"
  },
  {
    "Cupons": "1-70034"
  },
  {
    "Cupons": "1-58180, 1-39934, 1-25961, 1-87840, 1-72894, 1-66609, 1-78932, 1-49806, 1-86776, 1-89547, 1-54436"
  },
  {
    "Cupons": "1-73221"
  },
  {
    "Cupons": "1-40644"
  },
  {
    "Cupons": "1-19601"
  },
  {
    "Cupons": "1-55782"
  },
  {
    "Cupons": "1-13746"
  },
  {
    "Cupons": "1-70633"
  },
  {
    "Cupons": "1-90046, 1-54716, 1-40335, 1-61369, 1-40807, 1-10074, 1-65919, 1-47635, 1-59906, 1-30664, 1-42970"
  },
  {
    "Cupons": "1-68414"
  },
  {
    "Cupons": "1-72188"
  },
  {
    "Cupons": "1-89684, 1-55191, 1-70803, 1-14114"
  },
  {
    "Cupons": "1-98895, 1-46172, 1-91640, 1-68957"
  },
  {
    "Cupons": "1-64716"
  },
  {
    "Cupons": "1-26713"
  },
  {
    "Cupons": "1-32909"
  },
  {
    "Cupons": "1-15591"
  },
  {
    "Cupons": "1-69342, 1-40082, 1-57346, 1-18059"
  },
  {
    "Cupons": "1-82854, 1-14302, 1-69008, 1-68577"
  },
  {
    "Cupons": "1-93098"
  },
  {
    "Cupons": "1-29151"
  },
  {
    "Cupons": "1-67289"
  },
  {
    "Cupons": "1-20514"
  },
  {
    "Cupons": "1-80551"
  },
  {
    "Cupons": "1-35774"
  },
  {
    "Cupons": "1-33706"
  },
  {
    "Cupons": "1-17354"
  },
  {
    "Cupons": "1-51043"
  },
  {
    "Cupons": "1-85740"
  },
  {
    "Cupons": "1-56404"
  },
  {
    "Cupons": "1-99055"
  },
  {
    "Cupons": "1-42032"
  },
  {
    "Cupons": "1-76018"
  },
  {
    "Cupons": "1-56814"
  },
  {
    "Cupons": "1-79651"
  },
  {
    "Cupons": "1-73938"
  },
  {
    "Cupons": "1-67325"
  },
  {
    "Cupons": "1-89003"
  },
  {
    "Cupons": "1-22418"
  },
  {
    "Cupons": "1-56015"
  },
  {
    "Cupons": "1-11931, 1-62493, 1-49940, 1-48092"
  },
  {
    "Cupons": "1-62450, 1-36213, 1-86919, 1-72130"
  },
  {
    "Cupons": "1-10908"
  },
  {
    "Cupons": "1-44013, 1-88677, 1-63147, 1-86276"
  },
  {
    "Cupons": "1-30540"
  },
  {
    "Cupons": "1-70716, 1-78291, 1-97248, 1-13287, 1-15506, 1-93207, 1-42549, 1-64529, 1-35439, 1-64902, 1-31431, 1-75256, 1-81916, 1-64055"
  },
  {
    "Cupons": "1-93004"
  },
  {
    "Cupons": "1-18872, 1-58622, 1-44840, 1-62141"
  },
  {
    "Cupons": "1-37508, 1-80568, 1-33019, 1-34772"
  },
  {
    "Cupons": "1-45034, 1-62177, 1-45182, 1-78678"
  },
  {
    "Cupons": "1-75694"
  },
  {
    "Cupons": "1-56991"
  },
  {
    "Cupons": "1-47215, 1-21142, 1-94352, 1-75785"
  },
  {
    "Cupons": "1-88252"
  },
  {
    "Cupons": "1-46508, 1-75562, 1-65483, 1-81907"
  },
  {
    "Cupons": "1-53723"
  },
  {
    "Cupons": "1-60277, 1-12519, 1-49535, 1-57633"
  },
  {
    "Cupons": "1-53851, 1-18186, 1-85361, 1-65031, 1-38175, 1-85830, 1-90200, 1-54126, 1-99239, 1-28424, 1-66413, 1-24149, 1-78437, 1-61804"
  },
  {
    "Cupons": "1-82601"
  },
  {
    "Cupons": "1-80219, 1-95006, 1-83588, 1-76593"
  },
  {
    "Cupons": "1-40917"
  },
  {
    "Cupons": "1-34509"
  },
  {
    "Cupons": "1-71200"
  },
  {
    "Cupons": "1-10146, 1-89376, 1-63289, 1-40268, 1-79524, 1-95513, 1-11515, 1-87049, 1-78218, 1-28166, 1-66850, 1-88159, 1-81612, 1-80078"
  },
  {
    "Cupons": "1-93897"
  },
  {
    "Cupons": "1-11041"
  },
  {
    "Cupons": "1-88687, 1-90923, 1-48633, 1-77928, 1-20517, 1-99756, 1-96719, 1-16562, 1-77456, 1-33000, 1-27586, 1-51221, 1-15957, 1-31305"
  },
  {
    "Cupons": "1-36876, 1-44584, 1-94541, 1-96536"
  },
  {
    "Cupons": "1-40684, 1-92147, 1-54904, 1-41707"
  },
  {
    "Cupons": "1-22448"
  },
  {
    "Cupons": "1-79213"
  },
  {
    "Cupons": "1-66051, 1-27383, 1-81507, 1-19545, 1-71078, 1-97596, 1-83978, 1-53069, 1-50730, 1-70285, 1-56224, 1-56813, 1-68102, 1-51405"
  },
  {
    "Cupons": "1-51757"
  },
  {
    "Cupons": "1-21849, 1-12266, 1-29584, 1-33234"
  },
  {
    "Cupons": "1-69136, 1-27034, 1-53159, 1-34872, 1-14583, 1-76583, 1-87017, 1-14080, 1-37100, 1-68202, 1-97806, 1-26441, 1-59246, 1-76488"
  },
  {
    "Cupons": "1-98319"
  },
  {
    "Cupons": "1-58835"
  },
  {
    "Cupons": "1-44629"
  },
  {
    "Cupons": "1-26742, 1-35107, 1-68015, 1-36239, 1-18570, 1-16007, 1-84415, 1-85978, 1-83583, 1-53418, 1-23721, 1-43151, 1-24060, 1-60683"
  },
  {
    "Cupons": "1-91533"
  },
  {
    "Cupons": "1-20641, 1-70198, 1-25127, 1-59014"
  },
  {
    "Cupons": "1-28427"
  },
  {
    "Cupons": "1-18560, 1-70745, 1-81099, 1-41220"
  },
  {
    "Cupons": "1-94279, 1-25391, 1-81873, 1-67468"
  },
  {
    "Cupons": "1-38754"
  },
  {
    "Cupons": "1-47916, 1-21544, 1-97550, 1-90847"
  },
  {
    "Cupons": "1-43563"
  },
  {
    "Cupons": "1-73839, 1-64718, 1-86987, 1-81470"
  },
  {
    "Cupons": "1-10793, 1-74071, 1-38381, 1-31468"
  },
  {
    "Cupons": "1-63946"
  },
  {
    "Cupons": "1-75225, 1-78333, 1-52997, 1-22999"
  },
  {
    "Cupons": "1-99802"
  },
  {
    "Cupons": "1-98627, 1-92988, 1-80479, 1-83506, 1-91185, 1-52912, 1-36472, 1-90545, 1-82054, 1-33559, 1-49025"
  },
  {
    "Cupons": "1-52305"
  },
  {
    "Cupons": "1-97584"
  },
  {
    "Cupons": "1-38993"
  },
  {
    "Cupons": "1-76682"
  },
  {
    "Cupons": "1-51457"
  },
  {
    "Cupons": "1-74862, 1-59033, 1-89392, 1-66684"
  },
  {
    "Cupons": "1-73595"
  },
  {
    "Cupons": "1-92077"
  },
  {
    "Cupons": "1-82309"
  },
  {
    "Cupons": "1-50741"
  },
  {
    "Cupons": "1-83139, 1-37910, 1-84732, 1-86540, 1-36462, 1-17408, 1-75357, 1-26708, 1-26077, 1-23013, 1-12991, 1-89986, 1-82895, 1-99033"
  },
  {
    "Cupons": "1-32107, 1-93221, 1-36289, 1-64980"
  },
  {
    "Cupons": "1-57383"
  },
  {
    "Cupons": "1-25369"
  },
  {
    "Cupons": "1-31603"
  },
  {
    "Cupons": "1-43288, 1-46677, 1-98859, 1-74171"
  },
  {
    "Cupons": "1-78379, 1-39187, 1-80424, 1-86823"
  },
  {
    "Cupons": "1-33048, 1-76608, 1-78280, 1-83387"
  },
  {
    "Cupons": "1-76668"
  },
  {
    "Cupons": "1-67762"
  },
  {
    "Cupons": "1-65129, 1-52233, 1-59586, 1-31118"
  },
  {
    "Cupons": "1-67867, 1-73198, 1-74562, 1-74843"
  },
  {
    "Cupons": "1-94341"
  },
  {
    "Cupons": "1-48287"
  },
  {
    "Cupons": "1-78345"
  },
  {
    "Cupons": "1-29292, 1-14494, 1-81449, 1-73590"
  },
  {
    "Cupons": "1-31771, 1-80770, 1-90926, 1-73477"
  },
  {
    "Cupons": "1-29336, 1-95208, 1-70147, 1-35326"
  },
  {
    "Cupons": "1-27709, 1-86392, 1-60596, 1-26156, 1-63297, 1-72622, 1-89695, 1-63585, 1-99034, 1-29392, 1-32361, 1-80827, 1-61303, 1-50034"
  },
  {
    "Cupons": "1-58144, 1-69359, 1-36077, 1-69047"
  },
  {
    "Cupons": "1-96858, 1-16200, 1-83245, 1-84313, 1-51574, 1-38376, 1-33212, 1-73638, 1-93837, 1-89942, 1-98961, 1-64526, 1-58834, 1-30922"
  },
  {
    "Cupons": "1-95915"
  },
  {
    "Cupons": "1-79867, 1-23428, 1-20659, 1-45161"
  },
  {
    "Cupons": "1-66183, 1-83669, 1-25403, 1-64489, 1-98682, 1-50749, 1-68098, 1-88525, 1-63135, 1-95374, 1-42014, 1-38324, 1-51843, 1-30737"
  },
  {
    "Cupons": "1-47683, 1-61663, 1-51867, 1-99820, 1-96335, 1-44084, 1-71754, 1-21064, 1-87543, 1-72607, 1-20264, 1-71399, 1-99587, 1-16467"
  },
  {
    "Cupons": "1-11909"
  },
  {
    "Cupons": "1-53221"
  },
  {
    "Cupons": "1-40705"
  },
  {
    "Cupons": "1-85324, 1-82442, 1-27053, 1-16432, 1-55916, 1-63091, 1-80670, 1-49770, 1-99602, 1-58187, 1-70795"
  },
  {
    "Cupons": "1-94491"
  },
  {
    "Cupons": "1-29203"
  },
  {
    "Cupons": "1-68938"
  },
  {
    "Cupons": "1-10744"
  },
  {
    "Cupons": "1-83327"
  },
  {
    "Cupons": "1-85866"
  },
  {
    "Cupons": "1-61846, 1-65014, 1-56884, 1-97295, 1-54259, 1-19456, 1-13413, 1-23299, 1-78761, 1-42982, 1-67470, 1-14362, 1-17832, 1-30938"
  },
  {
    "Cupons": "1-40097"
  },
  {
    "Cupons": "1-25799, 1-70756, 1-13385, 1-71701, 1-75058, 1-25634, 1-82917, 1-45166, 1-78286, 1-42456, 1-73021, 1-96195, 1-96461, 1-89151"
  },
  {
    "Cupons": "1-26989"
  },
  {
    "Cupons": "1-93484, 1-12468, 1-75182, 1-39852"
  },
  {
    "Cupons": "1-87966, 1-74861, 1-69039, 1-26909"
  },
  {
    "Cupons": "1-57847, 1-70884, 1-39860, 1-96665"
  },
  {
    "Cupons": "1-23390, 1-57931, 1-49555, 1-96259"
  },
  {
    "Cupons": "1-53114, 1-77573, 1-65615, 1-10416, 1-33022, 1-78349, 1-65857, 1-20334, 1-75780, 1-26972, 1-27401, 1-40819, 1-56133, 1-63433"
  },
  {
    "Cupons": "1-89926"
  },
  {
    "Cupons": "1-28177"
  },
  {
    "Cupons": "1-63011"
  },
  {
    "Cupons": "1-62776, 1-94649, 1-89843, 1-42042"
  },
  {
    "Cupons": "1-77069, 1-91351, 1-58128, 1-92501, 1-85136, 1-90211, 1-94488, 1-93727, 1-44733, 1-57995, 1-44448, 1-35447, 1-55092, 1-69188"
  },
  {
    "Cupons": "1-93296, 1-97284, 1-98017, 1-83264"
  },
  {
    "Cupons": "1-59531"
  },
  {
    "Cupons": "1-56038, 1-37628, 1-99428, 1-97267"
  },
  {
    "Cupons": "1-38373, 1-23182, 1-14819, 1-82415, 1-65280, 1-13973, 1-21436, 1-64188, 1-45954, 1-55717, 1-26845, 1-44242, 1-83221, 1-61970"
  },
  {
    "Cupons": "1-26279"
  },
  {
    "Cupons": "1-35208, 1-72845, 1-48551, 1-51529"
  },
  {
    "Cupons": "1-69749"
  },
  {
    "Cupons": "1-74641, 1-82586, 1-40136, 1-44597"
  },
  {
    "Cupons": "1-88640"
  },
  {
    "Cupons": "1-11129, 1-59172, 1-21427, 1-30407"
  },
  {
    "Cupons": "1-98193, 1-68504, 1-70758, 1-99099"
  },
  {
    "Cupons": "1-47318, 1-84364, 1-91341, 1-28923, 1-25164, 1-14323, 1-99709, 1-97508, 1-54028, 1-73147, 1-20565"
  },
  {
    "Cupons": "1-35570, 1-24957, 1-20698, 1-89395"
  },
  {
    "Cupons": "1-77091, 1-52493, 1-18630, 1-18506, 1-90506, 1-13128, 1-83357, 1-64131, 1-29620, 1-64735, 1-42799, 1-12905, 1-28915, 1-43924"
  },
  {
    "Cupons": "1-16133, 1-95121, 1-59581, 1-88589, 1-90789, 1-34350, 1-27223, 1-33370, 1-55460, 1-79066, 1-44282"
  },
  {
    "Cupons": "1-17173, 1-72323, 1-48591, 1-35894, 1-54516, 1-86234, 1-17946, 1-58027, 1-81484, 1-10489, 1-86956, 1-95175, 1-64746, 1-68491"
  },
  {
    "Cupons": "1-83733"
  },
  {
    "Cupons": "1-99123, 1-45155, 1-34193, 1-73086"
  },
  {
    "Cupons": "1-16237"
  },
  {
    "Cupons": "1-70771"
  },
  {
    "Cupons": "1-46679"
  },
  {
    "Cupons": "1-18174"
  },
  {
    "Cupons": "1-58408, 1-93962, 1-28241, 1-96329, 1-87201, 1-43961, 1-29724, 1-30110, 1-15366, 1-14485, 1-96151, 1-71907, 1-78059, 1-49862"
  },
  {
    "Cupons": "1-84690"
  },
  {
    "Cupons": "1-21957"
  },
  {
    "Cupons": "1-16104, 1-53453, 1-10335, 1-33589"
  },
  {
    "Cupons": "1-38400, 1-58630, 1-72834, 1-32598, 1-29989, 1-33761, 1-13351, 1-81550, 1-41453, 1-66589, 1-23736, 1-60432, 1-54556, 1-86342"
  },
  {
    "Cupons": "1-51439, 1-10127, 1-62445, 1-45649"
  },
  {
    "Cupons": "1-31677"
  },
  {
    "Cupons": "1-77902"
  },
  {
    "Cupons": "1-57354, 1-88094, 1-93073, 1-34423, 1-50813, 1-29140, 1-86269, 1-58776, 1-29231, 1-79162, 1-92070, 1-23405, 1-49845, 1-20223"
  },
  {
    "Cupons": "1-99374"
  },
  {
    "Cupons": "1-53334"
  },
  {
    "Cupons": "1-78205, 1-56047, 1-93417, 1-25349, 1-65101, 1-37021, 1-67002, 1-23748, 1-38738, 1-19577, 1-55634, 1-30811, 1-74289, 1-67412"
  },
  {
    "Cupons": "1-58197"
  },
  {
    "Cupons": "1-13613"
  },
  {
    "Cupons": "1-26674"
  },
  {
    "Cupons": "1-18438, 1-53992, 1-96607, 1-77830"
  },
  {
    "Cupons": "1-17616, 1-88210, 1-97199, 1-36771, 1-29287, 1-59635, 1-34373, 1-24802, 1-98405, 1-30042, 1-29210, 1-73547, 1-96252, 1-10800"
  },
  {
    "Cupons": "1-35183"
  },
  {
    "Cupons": "1-97583"
  },
  {
    "Cupons": "1-30637"
  },
  {
    "Cupons": "1-57266"
  },
  {
    "Cupons": "1-96907"
  },
  {
    "Cupons": "1-82936, 1-77281, 1-27877, 1-40071"
  },
  {
    "Cupons": "1-38389"
  },
  {
    "Cupons": "1-78344, 1-32378, 1-81756, 1-54188"
  },
  {
    "Cupons": "1-65911, 1-51131, 1-78136, 1-51809, 1-86543, 1-68047, 1-76297, 1-86064, 1-68481, 1-21101, 1-44260, 1-89927, 1-81575, 1-35785"
  },
  {
    "Cupons": "1-11307"
  },
  {
    "Cupons": "1-28719, 1-96181, 1-26789, 1-12078, 1-35127, 1-89403, 1-43443, 1-63329, 1-72488, 1-84834, 1-49576, 1-28376, 1-45594, 1-20110"
  },
  {
    "Cupons": "1-12657, 1-71208, 1-10915, 1-37172, 1-89510, 1-32868, 1-29972, 1-13441, 1-27846, 1-70226, 1-68544, 1-61017, 1-90255, 1-83089"
  },
  {
    "Cupons": "1-72907, 1-15677, 1-42663, 1-82813"
  },
  {
    "Cupons": "1-96759"
  },
  {
    "Cupons": "1-41990"
  },
  {
    "Cupons": "1-76282"
  },
  {
    "Cupons": "1-69768"
  },
  {
    "Cupons": "1-34089, 1-69301, 1-45687, 1-75848, 1-61797, 1-27073, 1-10157, 1-93845, 1-67344, 1-81003, 1-74362"
  },
  {
    "Cupons": "1-27653"
  },
  {
    "Cupons": "1-82806"
  },
  {
    "Cupons": "1-92482"
  },
  {
    "Cupons": "1-53802"
  },
  {
    "Cupons": "1-98044"
  },
  {
    "Cupons": "1-47150"
  },
  {
    "Cupons": "1-86774"
  },
  {
    "Cupons": "1-22307"
  },
  {
    "Cupons": "1-33624"
  },
  {
    "Cupons": "1-58905"
  },
  {
    "Cupons": "1-57888"
  },
  {
    "Cupons": "1-20361"
  },
  {
    "Cupons": "1-53650, 1-50259, 1-93260, 1-15072, 1-25376, 1-59351, 1-40663, 1-30618, 1-78839, 1-27514, 1-14724, 1-27018, 1-46189, 1-70195"
  },
  {
    "Cupons": "1-44215, 1-28628, 1-40178, 1-70416"
  },
  {
    "Cupons": "1-99487"
  },
  {
    "Cupons": "1-85653"
  },
  {
    "Cupons": "1-61268, 1-98203, 1-45401, 1-95201"
  },
  {
    "Cupons": "1-27824"
  },
  {
    "Cupons": "1-17906"
  },
  {
    "Cupons": "1-67708, 1-86429, 1-53701, 1-57917"
  },
  {
    "Cupons": "1-76331, 1-28158, 1-86717, 1-55561"
  },
  {
    "Cupons": "1-30178"
  },
  {
    "Cupons": "1-35863"
  },
  {
    "Cupons": "1-53758"
  },
  {
    "Cupons": "1-54690, 1-58888, 1-18393, 1-25647, 1-50134, 1-73711, 1-73588, 1-37667, 1-41275, 1-85449, 1-14647, 1-45426, 1-70036, 1-60215"
  },
  {
    "Cupons": "1-93697, 1-33722, 1-20712, 1-26750"
  },
  {
    "Cupons": "1-94909"
  },
  {
    "Cupons": "1-33926"
  },
  {
    "Cupons": "1-53815, 1-78486, 1-17704, 1-51840, 1-62866, 1-84201, 1-12866, 1-26641, 1-31077, 1-88000, 1-25324, 1-10952, 1-79522, 1-59966"
  },
  {
    "Cupons": "1-97060"
  },
  {
    "Cupons": "1-29804, 1-88150, 1-47030, 1-51121"
  },
  {
    "Cupons": "1-78352"
  },
  {
    "Cupons": "1-76008"
  },
  {
    "Cupons": "1-32125"
  },
  {
    "Cupons": "1-18203"
  },
  {
    "Cupons": "1-88324"
  },
  {
    "Cupons": "1-89801"
  },
  {
    "Cupons": "1-70631"
  },
  {
    "Cupons": "1-47393"
  },
  {
    "Cupons": "1-51872"
  },
  {
    "Cupons": "1-26728"
  },
  {
    "Cupons": "1-13788"
  },
  {
    "Cupons": "1-75457"
  },
  {
    "Cupons": "1-44027"
  },
  {
    "Cupons": "1-77539"
  },
  {
    "Cupons": "1-69588, 1-21677, 1-54442, 1-14056"
  },
  {
    "Cupons": "1-30312"
  },
  {
    "Cupons": "1-21916, 1-78621, 1-15487, 1-90240, 1-67263, 1-14119, 1-75219, 1-89920, 1-15281, 1-22875, 1-65359, 1-50867, 1-53300, 1-60572"
  },
  {
    "Cupons": "1-76996, 1-16820, 1-14247, 1-35827, 1-23951, 1-55917, 1-32915, 1-29487, 1-93875, 1-48256, 1-74053"
  },
  {
    "Cupons": "1-32387"
  },
  {
    "Cupons": "1-57451"
  },
  {
    "Cupons": "1-71154"
  },
  {
    "Cupons": "1-37156"
  },
  {
    "Cupons": "1-69207"
  },
  {
    "Cupons": "1-68410"
  },
  {
    "Cupons": "1-92690"
  },
  {
    "Cupons": "1-36844"
  },
  {
    "Cupons": "1-63109"
  },
  {
    "Cupons": "1-80629"
  },
  {
    "Cupons": "1-21840"
  },
  {
    "Cupons": "1-68296, 1-59334, 1-36037, 1-82575, 1-70081, 1-99759, 1-47291, 1-82910, 1-52206, 1-99902, 1-33299, 1-96477, 1-43672, 1-60809"
  },
  {
    "Cupons": "1-22718"
  },
  {
    "Cupons": "1-54424"
  },
  {
    "Cupons": "1-36152"
  },
  {
    "Cupons": "1-47587"
  },
  {
    "Cupons": "1-90142, 1-53008, 1-20273, 1-81148, 1-80579, 1-14997, 1-67035, 1-26157, 1-73890, 1-63357, 1-75824, 1-96385, 1-94351, 1-20287"
  },
  {
    "Cupons": "1-28651"
  },
  {
    "Cupons": "1-51502, 1-57435, 1-99043, 1-25957"
  },
  {
    "Cupons": "1-31595"
  },
  {
    "Cupons": "1-10773"
  },
  {
    "Cupons": "1-67033"
  },
  {
    "Cupons": "1-51564"
  },
  {
    "Cupons": "1-18254"
  },
  {
    "Cupons": "1-99005"
  },
  {
    "Cupons": "1-43555"
  },
  {
    "Cupons": "1-66285"
  },
  {
    "Cupons": "1-50544, 1-43225, 1-25896, 1-29870"
  },
  {
    "Cupons": "1-99104"
  },
  {
    "Cupons": "1-27087"
  },
  {
    "Cupons": "1-32662"
  },
  {
    "Cupons": "1-79765"
  },
  {
    "Cupons": "1-22358"
  },
  {
    "Cupons": "1-88765, 1-20956, 1-10383, 1-99839"
  },
  {
    "Cupons": "1-36192"
  },
  {
    "Cupons": "1-14715"
  },
  {
    "Cupons": "1-70150, 1-57686, 1-28869, 1-68383"
  },
  {
    "Cupons": "1-21862, 1-39216, 1-45567, 1-75381"
  },
  {
    "Cupons": "1-23238, 1-19412, 1-43079, 1-54586"
  },
  {
    "Cupons": "1-91808, 1-31830, 1-99207, 1-52749"
  },
  {
    "Cupons": "1-88600, 1-44022, 1-67042, 1-87245"
  },
  {
    "Cupons": "1-65875, 1-36474, 1-35579, 1-85744"
  },
  {
    "Cupons": "1-20453"
  },
  {
    "Cupons": "1-96664"
  },
  {
    "Cupons": "1-14053, 1-69748, 1-51532, 1-33287"
  },
  {
    "Cupons": "1-29002, 1-41431, 1-89433, 1-73796, 1-81270, 1-96314, 1-54756, 1-56168, 1-23596, 1-31459, 1-94118, 1-50174, 1-41927, 1-20220"
  },
  {
    "Cupons": "1-46832"
  },
  {
    "Cupons": "1-14333"
  },
  {
    "Cupons": "1-21406, 1-87977, 1-73026, 1-90427, 1-40728, 1-81192, 1-28243, 1-24132, 1-30694, 1-37483, 1-29501, 1-84835, 1-60497, 1-91632"
  },
  {
    "Cupons": "1-12939"
  },
  {
    "Cupons": "1-28143"
  },
  {
    "Cupons": "1-79757"
  },
  {
    "Cupons": "1-14422, 1-60648, 1-86812, 1-94267, 1-50011, 1-60475, 1-62103, 1-15213, 1-95445, 1-89222, 1-97385, 1-72711, 1-68733, 1-22183"
  },
  {
    "Cupons": "1-94898"
  },
  {
    "Cupons": "1-77184, 1-48184, 1-37433, 1-65762, 1-82045, 1-67858, 1-89398, 1-86938, 1-90123, 1-21678, 1-96130, 1-91286, 1-27570, 1-23460"
  },
  {
    "Cupons": "1-15667"
  },
  {
    "Cupons": "1-25069"
  },
  {
    "Cupons": "1-35265"
  },
  {
    "Cupons": "1-88266"
  },
  {
    "Cupons": "1-91814"
  },
  {
    "Cupons": "1-38158"
  },
  {
    "Cupons": "1-92442"
  },
  {
    "Cupons": "1-76978"
  },
  {
    "Cupons": "1-91791, 1-16759, 1-81075, 1-35534"
  },
  {
    "Cupons": "1-32143, 1-33268, 1-47437, 1-35380"
  },
  {
    "Cupons": "1-92276"
  },
  {
    "Cupons": "1-60519"
  },
  {
    "Cupons": "1-75797, 1-33545, 1-51698, 1-84793, 1-27404, 1-64276, 1-97179, 1-36722, 1-31304, 1-34544, 1-15941, 1-48730, 1-87398, 1-18481"
  },
  {
    "Cupons": "1-43965, 1-36176, 1-44926, 1-94212"
  },
  {
    "Cupons": "1-94881"
  },
  {
    "Cupons": "1-62036, 1-16424, 1-91765, 1-86891"
  },
  {
    "Cupons": "1-69969, 1-56546, 1-33174, 1-88612, 1-94080, 1-38361, 1-48681, 1-21295, 1-50136, 1-43047, 1-67722, 1-85549, 1-70954, 1-52469"
  },
  {
    "Cupons": "1-55479, 1-40682, 1-64110, 1-52998, 1-87760, 1-29438, 1-70001, 1-66772, 1-70628, 1-53719, 1-65826, 1-56997, 1-39015, 1-83815"
  },
  {
    "Cupons": "1-38907, 1-86103, 1-28500, 1-50682, 1-73925, 1-33282, 1-48189, 1-11773, 1-14706, 1-67889, 1-57765, 1-99218, 1-59230, 1-84880"
  },
  {
    "Cupons": "1-81833"
  },
  {
    "Cupons": "1-42598, 1-19720, 1-83602, 1-95170"
  },
  {
    "Cupons": "1-70420, 1-90261, 1-12373, 1-65959, 1-80058, 1-40238, 1-90681, 1-31410, 1-44042, 1-83536, 1-92612, 1-56204, 1-86261, 1-86995"
  },
  {
    "Cupons": "1-76099, 1-10371, 1-82277, 1-33314"
  },
  {
    "Cupons": "1-58986"
  },
  {
    "Cupons": "1-67877"
  },
  {
    "Cupons": "1-61237"
  },
  {
    "Cupons": "1-95861, 1-90802, 1-77813, 1-71965, 1-64356, 1-13555, 1-27067, 1-61905, 1-84691, 1-10128, 1-76639, 1-76121, 1-97707, 1-27130"
  },
  {
    "Cupons": "1-56150"
  },
  {
    "Cupons": "1-66067, 1-57246, 1-27518, 1-41603"
  },
  {
    "Cupons": "1-43801, 1-57666, 1-37633, 1-96534"
  },
  {
    "Cupons": "1-79086, 1-53699, 1-62243, 1-63569"
  },
  {
    "Cupons": "1-68641, 1-47789, 1-72272, 1-99936, 1-25495, 1-91943, 1-21083, 1-60463, 1-64646, 1-83748, 1-93085, 1-50492, 1-81123, 1-95836"
  },
  {
    "Cupons": "1-20193"
  },
  {
    "Cupons": "1-27726, 1-24207, 1-63877, 1-69978"
  },
  {
    "Cupons": "1-98903, 1-69994, 1-67394, 1-33877"
  },
  {
    "Cupons": "1-13221"
  },
  {
    "Cupons": "1-67830, 1-83974, 1-81004, 1-20169"
  },
  {
    "Cupons": "1-76580"
  },
  {
    "Cupons": "1-18150"
  },
  {
    "Cupons": "1-45603"
  },
  {
    "Cupons": "1-41026"
  },
  {
    "Cupons": "1-14543"
  },
  {
    "Cupons": "1-80627, 1-83420, 1-86278, 1-96521, 1-19452, 1-55143, 1-91662, 1-95488, 1-47503, 1-62981, 1-11328, 1-35966, 1-99893, 1-59912"
  },
  {
    "Cupons": "1-82162, 1-73407, 1-16087, 1-14261"
  },
  {
    "Cupons": "1-62488, 1-90189, 1-97712, 1-40469"
  },
  {
    "Cupons": "1-50398, 1-88869, 1-84154, 1-89657, 1-37779, 1-65428, 1-68886, 1-61851, 1-15303, 1-52345, 1-84073, 1-83194, 1-11406, 1-50040"
  },
  {
    "Cupons": "1-96370, 1-37104, 1-26315, 1-64801"
  },
  {
    "Cupons": "1-38045"
  },
  {
    "Cupons": "1-64264, 1-74745, 1-46657, 1-74227, 1-21875, 1-91826, 1-21392, 1-52135, 1-53229, 1-43169, 1-26888, 1-18881, 1-81443, 1-96766"
  },
  {
    "Cupons": "1-61787, 1-74647, 1-60781, 1-85933"
  },
  {
    "Cupons": "1-56068, 1-32658, 1-56703, 1-75754"
  },
  {
    "Cupons": "1-96967, 1-22517, 1-91471, 1-38307"
  },
  {
    "Cupons": "1-59602, 1-49597, 1-46168, 1-17856"
  },
  {
    "Cupons": "1-36350"
  },
  {
    "Cupons": "1-56969"
  },
  {
    "Cupons": "1-20122, 1-33574, 1-87533, 1-10993, 1-76189, 1-88683, 1-22518, 1-78672, 1-83444, 1-70360, 1-90953, 1-61391, 1-67741, 1-64021"
  },
  {
    "Cupons": "1-98769, 1-61224, 1-55674, 1-62796"
  },
  {
    "Cupons": "1-83615"
  },
  {
    "Cupons": "1-42867"
  },
  {
    "Cupons": "1-89897, 1-17845, 1-52962, 1-35124"
  },
  {
    "Cupons": "1-85069, 1-88269, 1-70178, 1-46848, 1-95420, 1-99959, 1-42815, 1-25231, 1-26405, 1-79310, 1-85133, 1-38614, 1-58123, 1-19559"
  },
  {
    "Cupons": "1-65892"
  },
  {
    "Cupons": "1-51252"
  },
  {
    "Cupons": "1-77507, 1-50585, 1-73423, 1-20155"
  },
  {
    "Cupons": "1-77714, 1-31996, 1-51703, 1-41714"
  },
  {
    "Cupons": "1-90920"
  },
  {
    "Cupons": "1-48528, 1-69001, 1-71479, 1-78423, 1-32980, 1-51441, 1-87826, 1-76958, 1-72485, 1-11846, 1-91116, 1-83128, 1-96540, 1-37044"
  },
  {
    "Cupons": "1-92819"
  },
  {
    "Cupons": "1-39255, 1-36195, 1-16489, 1-87249, 1-16706, 1-64600, 1-33204, 1-27916, 1-60832, 1-12359, 1-80120, 1-73954, 1-22173, 1-74549"
  },
  {
    "Cupons": "1-36455, 1-61578, 1-40783, 1-47873"
  },
  {
    "Cupons": "1-36320, 1-41740, 1-13051, 1-30746, 1-58831, 1-36720, 1-67596, 1-31878, 1-31678, 1-77863, 1-26664, 1-57052, 1-69002, 1-25848"
  },
  {
    "Cupons": "1-62815"
  },
  {
    "Cupons": "1-18870"
  },
  {
    "Cupons": "1-27488"
  },
  {
    "Cupons": "1-96761"
  },
  {
    "Cupons": "1-94095, 1-19615, 1-93108, 1-34330"
  },
  {
    "Cupons": "1-29749"
  },
  {
    "Cupons": "1-47988"
  },
  {
    "Cupons": "1-91076"
  },
  {
    "Cupons": "1-18339, 1-44934, 1-58898, 1-88154, 1-53449, 1-21966, 1-31647, 1-62394, 1-48195, 1-45825, 1-90809, 1-30071, 1-29822, 1-77900"
  },
  {
    "Cupons": "1-46933, 1-68936, 1-12938, 1-88680, 1-37847, 1-30716, 1-11192, 1-75753, 1-10906, 1-55079, 1-84063, 1-77462, 1-41712, 1-11482"
  },
  {
    "Cupons": "1-45631"
  },
  {
    "Cupons": "1-15467"
  },
  {
    "Cupons": "1-97906"
  },
  {
    "Cupons": "1-12326"
  },
  {
    "Cupons": "1-25962, 1-25174, 1-41190, 1-40542"
  },
  {
    "Cupons": "1-12475"
  },
  {
    "Cupons": "1-59293"
  },
  {
    "Cupons": "1-68235"
  },
  {
    "Cupons": "1-92219, 1-95729, 1-58115, 1-67204"
  },
  {
    "Cupons": "1-17776"
  },
  {
    "Cupons": "1-93093"
  },
  {
    "Cupons": "1-42823, 1-36584, 1-97571, 1-11639, 1-21877, 1-55182, 1-12348, 1-13207, 1-68435, 1-28264, 1-15207, 1-32416, 1-96734, 1-15786"
  },
  {
    "Cupons": "1-97465"
  },
  {
    "Cupons": "1-92948, 1-92404, 1-19170, 1-23440, 1-81759, 1-25304, 1-92266, 1-94792, 1-93806, 1-26277, 1-15604, 1-55273, 1-87036, 1-18621"
  },
  {
    "Cupons": "1-24474, 1-54292, 1-89013, 1-65027"
  },
  {
    "Cupons": "1-39139"
  },
  {
    "Cupons": "1-72468"
  },
  {
    "Cupons": "1-94290"
  },
  {
    "Cupons": "1-10881, 1-78473, 1-54993, 1-51608, 1-36118, 1-16441, 1-99361, 1-85232, 1-82115, 1-14783, 1-94421, 1-63978, 1-45353, 1-56488"
  },
  {
    "Cupons": "1-11153, 1-34594, 1-58900, 1-11052"
  },
  {
    "Cupons": "1-78662"
  },
  {
    "Cupons": "1-88686, 1-88345, 1-43665, 1-30516"
  },
  {
    "Cupons": "1-32739"
  },
  {
    "Cupons": "1-51935"
  },
  {
    "Cupons": "1-92853"
  },
  {
    "Cupons": "1-41913"
  },
  {
    "Cupons": "1-20678"
  },
  {
    "Cupons": "1-71348, 1-65513, 1-96156, 1-58491"
  },
  {
    "Cupons": "1-62911"
  },
  {
    "Cupons": "1-43239, 1-63682, 1-53268, 1-87681"
  },
  {
    "Cupons": "1-89937"
  },
  {
    "Cupons": "1-25321"
  },
  {
    "Cupons": "1-37497"
  },
  {
    "Cupons": "1-63906"
  },
  {
    "Cupons": "1-23138"
  },
  {
    "Cupons": "1-69782, 1-60071, 1-30137, 1-94366, 1-59629, 1-30796, 1-26470, 1-18016, 1-55341, 1-11738, 1-85840, 1-39165, 1-75217, 1-36356"
  },
  {
    "Cupons": "1-20888"
  },
  {
    "Cupons": "1-60736, 1-48426, 1-53323, 1-41863"
  },
  {
    "Cupons": "1-75781"
  },
  {
    "Cupons": "1-50483, 1-41299, 1-53465, 1-31026"
  },
  {
    "Cupons": "1-88151, 1-67527, 1-83250, 1-83900"
  },
  {
    "Cupons": "1-65689, 1-12836, 1-22241, 1-24063"
  },
  {
    "Cupons": "1-42810, 1-28640, 1-40978, 1-19256"
  },
  {
    "Cupons": "1-24838, 1-45672, 1-46380, 1-74712"
  },
  {
    "Cupons": "1-49412"
  },
  {
    "Cupons": "1-55093"
  },
  {
    "Cupons": "1-17351"
  },
  {
    "Cupons": "1-28992"
  },
  {
    "Cupons": "1-99198, 1-85092, 1-44607, 1-29679"
  },
  {
    "Cupons": "1-31154, 1-74999, 1-78222, 1-57254"
  },
  {
    "Cupons": "1-10177"
  },
  {
    "Cupons": "1-98948"
  },
  {
    "Cupons": "1-33788"
  },
  {
    "Cupons": "1-61353"
  },
  {
    "Cupons": "1-56911"
  },
  {
    "Cupons": "1-16809"
  },
  {
    "Cupons": "1-83612"
  },
  {
    "Cupons": "1-42192"
  },
  {
    "Cupons": "1-91837"
  },
  {
    "Cupons": "1-64991, 1-72171, 1-99056, 1-43985"
  },
  {
    "Cupons": "1-80359"
  },
  {
    "Cupons": "1-50484"
  },
  {
    "Cupons": "1-94554"
  },
  {
    "Cupons": "1-53491"
  },
  {
    "Cupons": "1-51496, 1-86989, 1-93151, 1-98509, 1-88025, 1-48399, 1-80849, 1-72385, 1-77006, 1-55824, 1-91429, 1-94893, 1-14400, 1-92413"
  },
  {
    "Cupons": "1-62642, 1-57262, 1-47970, 1-78833"
  },
  {
    "Cupons": "1-14644"
  },
  {
    "Cupons": "1-85925"
  },
  {
    "Cupons": "1-71442"
  },
  {
    "Cupons": "1-88107, 1-63395, 1-20527, 1-38083"
  },
  {
    "Cupons": "1-94523"
  },
  {
    "Cupons": "1-51525"
  },
  {
    "Cupons": "1-90195, 1-51461, 1-33556, 1-26942"
  },
  {
    "Cupons": "1-78092"
  },
  {
    "Cupons": "1-10864, 1-30276, 1-34316, 1-97819, 1-54148, 1-26132, 1-32678, 1-56080, 1-75221, 1-38201, 1-92255"
  },
  {
    "Cupons": "1-57583, 1-32066, 1-31142, 1-33767, 1-72242, 1-81967, 1-24607, 1-23965, 1-71394, 1-57500, 1-99111, 1-78994, 1-19837, 1-90899, 1-57013, 1-72736, 1-43405, 1-54985, 1-60743, 1-39556, 1-73430, 1-82470, 1-34713, 1-73330, 1-17790, 1-78892, 1-57789, 1-79474, 1-74584, 1-67511, 1-70889, 1-58475, 1-39669, 1-36601, 1-72670, 1-69660, 1-93055, 1-40677, 1-24831, 1-28210, 1-57998, 1-33863, 1-36377, 1-15552"
  },
  {
    "Cupons": "1-83771, 1-79540, 1-52927, 1-53954"
  },
  {
    "Cupons": "1-78178, 1-86807, 1-92122, 1-58904, 1-58714, 1-59800, 1-22818, 1-16564, 1-34452, 1-74499, 1-64454, 1-39265, 1-69916, 1-75074"
  },
  {
    "Cupons": "1-16155, 1-31095, 1-64358, 1-53362"
  },
  {
    "Cupons": "1-40094, 1-74049, 1-57103, 1-26460, 1-62729, 1-25057, 1-57195, 1-81615, 1-63921, 1-27197, 1-36426, 1-76795, 1-91310, 1-49020"
  },
  {
    "Cupons": "1-96510, 1-82391, 1-39623, 1-90252, 1-51011, 1-55449, 1-44954, 1-33851, 1-77246, 1-56695, 1-55847, 1-55947, 1-51548, 1-95597"
  },
  {
    "Cupons": "1-23473, 1-38375, 1-19194, 1-94005"
  }
];

export default function CodeAdmin() {

  useEffect(() => {
    const geral = [];
    cupons.map(e => {
      const parcial = e.Cupons.split(', ');
      parcial.map(parc => {
        geral.push(parc);
      });
    })
    console.log(geral.sort());
  }, []);

  return (
    <div className="main-admin">

    </div>
  );
}
