import React, { useEffect } from "react";
import "./index.scss";
import { Firestore } from "../../utils/firebaseUtils";

const usuariosAgrishow = require("./relatorios_jsons/usuarios_agrishow.json")
const dadosClient = require("./relatorios_jsons/relatorio-agrishow-glossario.json")
const dadosCruzados = require("./relatorios_jsons/dadosCruzados.json")

export default function DistribuirPontos() {
  // useEffect(() => {
  //   console.log(usuariosAgrishow);
  // }, []);

  async function cruzarDados() {
    const usuarios = [];
    const userClientes = [];
    const CPFSInvalidos = [];
    dadosClient.map((e) => {
      const valuesVerify = usuariosAgrishow.find(eFind => apenasNumeros(eFind.cpf) === e.NR_CPF_CNPJ_CLI);
      if (valuesVerify) {
        valuesVerify.total = functionCalcQtdNumbers({ ...e, ...valuesVerify });
        if (!validarCPF(valuesVerify.cpf) && !validarCNPJ(valuesVerify.cpf)) {
          CPFSInvalidos.push(valuesVerify);
        } else {
          userClientes.push({ ...e, ...valuesVerify });
        }
      } else {
        usuarios.push(e);
      }
    })
    console.log("usuários", usuarios);
    console.log("Dados Cruzados", userClientes);
    console.log("CPFS Inválidos", CPFSInvalidos);
  }

  function functionCalcQtdNumbers(element) {
    let totalReturn = 1;

    // VERIFICA SE É CLIENTE BB
    if (element.clienteBB === 'VERDADEIRO') {
      totalReturn = 4;
    }

    // VERIFICA SE É CLIENTE BB SEGUROS
    if (element.IN_CLI_SGDD) {
      totalReturn = 10;
    }

    // VERIFICA SE CONTRATOU SEGURO NO EVENTO
    console.log(element.DT_CTRC_CTR)
    if (element.IN_CLI_SGDD && element.DT_CTRC_CTR) {
      totalReturn = 30;
    }

    // VERIFICA SE PAGOU COM CARTÃO DE CRÉDITO
    console.log(element.TX_FMA_PGTO);
    if (element.TX_FMA_PGTO === "Cartão de Crédito") {
      totalReturn += 30;
    }

    return totalReturn;
  }

  function apenasNumeros(string) {
    var numsStr = string.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
      return false;
    }

    var soma = 0;
    for (var i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }

    var resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(9))) {
      return false;
    }

    soma = 0;
    for (var j = 0; j < 10; j++) {
      soma += parseInt(cpf.charAt(j)) * (11 - j);
    }

    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  }

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14 || /^(.)\1+$/.test(cnpj)) {
      return false;
    }

    // Verifica se os dígitos verificadores são válidos
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var j = tamanho; j >= 1; j--) {
      soma += parseInt(numeros.charAt(tamanho - j)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  }

  function distribuirNumeros() {
    const JsonFinal = [];
    // Cria a lista de números aleatórios
    const numerosAleatorios = [];
    for (let i = 10000; i <= 99999; i++) {
      numerosAleatorios.push(i);
    }
    for (let i = numerosAleatorios.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numerosAleatorios[i], numerosAleatorios[j]] = [numerosAleatorios[j], numerosAleatorios[i]];
    }

    // Loop pelos usuários para adicionar o índice com os valores aleatórios
    dadosCruzados.forEach((usuario) => {
      const totalGeral = usuario["total"]; // Quantidade de números que serão adicionados
      const numerosSelecionados = []; // Array para armazenar os números selecionados
      while (numerosSelecionados.length < totalGeral) {
        const numeroAleatorio = numerosAleatorios.pop(); // Remove o último número da lista de números aleatórios
        numerosSelecionados.push("1-" + numeroAleatorio); // Adiciona o número ao array
        // Remove o número da lista de números aleatórios
        const indice = numerosAleatorios.indexOf(numeroAleatorio);
        if (indice !== -1) {
          numerosAleatorios.splice(indice, 1);
        }
      }
      usuario["numeros da sorte"] = numerosSelecionados.join(", "); // Converte o array em uma string separada por vírgulas e adiciona ao índice "numeros da sorte" do objeto usuário

      JsonFinal.push(usuario);
    });
    console.log(JsonFinal);
  }

  function notEnvio() {
    Firestore.collection("errosDistribuiNumber_agrishow_2").get().then((response) => {
      const dataResponse = [];
      response.docs.map(e => {
        const myData = e.data();
        dataResponse.push({...myData, values: JSON.parse(myData.data)});
      });
      const filtro = [];
      dataResponse.map((e) => {
        const valuesVerify = dadosCruzados.find(eFind => eFind.cpf === e.values.cpf);
        if (valuesVerify) {
          filtro.push(valuesVerify) ;
        }
      })
      console.log(filtro);
      console.log(dataResponse);
    })
  }

  return (
    <div>
      <button>Distribuir</button>
      <button onClick={cruzarDados}>Cruzar Dados</button>
      <button onClick={distribuirNumeros}>Distribuir Numeros da sorte</button>
      <button onClick={notEnvio}>Get nao enviados</button>
    </div>
  );
}
