import React from "react";
import "./index.scss";

export default function PageNotFound() {
  return (
    <div className="page-interna">
      <div className="content">
        <div className="left"> 
          <div>
            <h2 className="title">Página não encontrada</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
