import React, { useState, useContext, createContext } from 'react';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const context = useGlobalProvider();
  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

function useGlobalProvider() {
  const [user, setUser] = useState();
  const [showRegister, setShowRegister] = useState(false);

  const dataEvent = {
    uf: 'BR',
    label: 'Brasil',
    name: 'Brasil',
    bd: 'brasil',
    diaSorteio: '15/04',
  }

  return {
    user,
    setUser,
    showRegister,
    setShowRegister,
    dataEvent,
  };
}
