import React from "react";
import "./index.scss";
import OwlCarousel from "react-owl-carousel";
import TecnoshowImage1 from "../../assets/img/tecnoshow2024/img1.jpeg";
import TecnoshowImage2 from "../../assets/img/tecnoshow2024/img2.jpeg";
import TecnoshowImage3 from "../../assets/img/tecnoshow2024/img3.jpeg";

export default function PageTecnoshow2024() {
  return (
    <div className="page-interna page-showRural">
      <div className="content">
        <div className="left">
          <div>
            <h2 className="title">POR ONDE PASSAMOS 2024</h2>
            <h3>Tecnoshow Comigo 2024</h3>
            <p>
              O evento se denomina como uma extensa vitrine de tecnologias para
              o homem do campo, seja ele pequeno, médio ou grande produtor,
              trazendo máquinas e equipamentos agropecuários, agrícolas, animais
              das mais variadas espécies, e a BB Seguros esteve presente, mais
              uma vez no estande do Banco do Brasil, reforçando sua relevância
              para o setor e a promoção “Colheita de Prêmios”, que irá sortear
              uma picape Ram Rampage Laramie 0km.
            </p>

            <p>
              A Tecnoshow Comigo aconteceu em Rio Verde, Goiás entre os dias 08
              e 12 de abril de 2024.
            </p>
          </div>
        </div>
        <div className="right">
          <OwlCarousel className="owl-theme" margin={50} nav options items={1}>
            <div class="item">
              <img src={TecnoshowImage1} />
            </div>
            <div class="item">
              <img src={TecnoshowImage2} />
            </div>
            <div class="item">
              <img src={TecnoshowImage3} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
