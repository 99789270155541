import React, { useEffect, useState } from "react";
import "./index.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Firestore } from "../../utils/firebaseUtils";
export default function ReceberNotificacao() {
  const [loading, setLoading] = useState(true);
  const [error, seterror] = useState(false);
  const params = useParams();

  useEffect(() => {
    console.log(params);
    getStart();
    getMails('agrishow');
  }, []);

  function getMails(type) {
    Firestore.collection(type)
      .get()
      .then(function (querySnapshot) {
        const dataconsole = [];
        querySnapshot.forEach(function (doc) {
          dataconsole.push(doc.data());
        });
        console.log(dataconsole);
      })
  }

  function getStart() {
    Firestore
      .collection(params.base)
      .where('email', '==', params.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.update({ aceiteContato: false })
            .then(function () {
              setLoading(false);
            })
            .catch(function (error) {
              seterror(true)
              setLoading(false);
            });
        });
      })
      .catch(function (error) {
        seterror(true)
        setLoading(false);
      });
  }


  return (
    <div className="page-interna page-receber">
      <div className="content">
        <div className="center">

          {loading ?
            <h4>Carregando...</h4>
            :
            error ?
              <h2>Houve um erro ao remover seu e-mail, tente novamente mais tarde</h2>
              :
              <h2>Seu e-mail foi descadastrado, com sucesso!</h2>
          }

        </div>
      </div>
    </div>
  );
}
