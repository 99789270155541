import React from "react";
import "../index.scss";

export default function Step05(props) {
  function validNext() {
    if (props.form.copavel === null) {
      return true;
    } else if (props.form.copavel && props.form.protocolo_copavel === "") {
      return true
    }
    return false;
  }
  return (
    <>
      <h2>Seguro Copavel</h2>
      <form action="">
        <div className="form-group">
          <span>
            <input type="radio" id="sim" name="radio-group"
              value={props.form.copavel}
              checked={props.form.copavel === true}
              onChange={() => props.setform({ ...props.form, copavel: true })}
            />
            <label for="sim">Sim</label>
          </span>
          <span>
            <input type="radio" id="nao" name="radio-group"
              value={props.form.copavel}
              checked={props.form.copavel === false}
              onChange={() => props.setform({ ...props.form, copavel: false })}
            />
            <label for="nao">Não</label>
          </span>
        </div>
        {/* <h2>Se sim,</h2> */}
        {props.form.copavel &&
          <div className="form-group">
            <input
              disabled={!props.form.copavel}
              type="text"
              placeholder="Informe o protocolo do contrato"
              value={props.form.protocolo_copavel}
              onChange={(value) => props.setform({ ...props.form, protocolo_copavel: value.target.value })}
            />
          </div>
        }
        <div className="actions">
          <button onClick={() => props.setStep(4)} >Voltar</button>
          <button disabled={validNext()} onClick={() => props.setStep(6)} >Próximo</button>
        </div>
      </form>
    </>
  );
}
