import React from "react";
import "../index.scss";
import { ReactComponent as CheckSuccess } from "../../../assets/img/check_sucess.svg";
import { useGlobalContext } from "../../../context/GlobalContext";

export default function FinishSuccess(props) {
  const globalContext = useGlobalContext();
  var query = window.location.search.slice(1);

  return (
    <div className="finish">
      <CheckSuccess />
      <h1>Parabéns!</h1>
      <h2>Cadastro efetuado com sucesso.</h2>
      <p>
        Enviaremos os seus números da sorte por email <br />
        até o dia {globalContext.dataEvent.diaSorteio}. Fique ligado na sua caixa de spam. <br />
        <b>Boa sorte!</b>
      </p>
      {(query === 'promotor') &&
        <a href="https://pt.surveymonkey.com/r/Agrishow2024">Ir para pesquisa</a>
      }
    </div>
  );
}
